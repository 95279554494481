import { combineReducers } from 'redux';

import authReducer from './auth';
import helpReducer from './help';
import layoutReducer from './layout';
import messagesReducer from "./messages.reducer"
import chartDataReducer from './dragableChartData';
import campaignStatusReducer from './campaignStatus'
import apiCallsInProgress from './apiCallStatusReducer';


const rootReducer = combineReducers({
  authReducer,
  helpReducer,
  layoutReducer,
  messagesReducer,
  chartDataReducer,
  apiCallsInProgress,
  campaignStatusReducer,

});

export default rootReducer;