import React, { useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

/* Chart code */
// Themes begin
am4core.useTheme(am4themes_animated);
am4core.addLicense("ch-custom-attribution");
// Themes end
function BarChart(props) {
// Create chart instance
useLayoutEffect(() => {
let chart = am4core.create("modalChartdiv", am4charts.XYChart);

// Add data
chart.data = [{
    "category": "Click-rate",
    "rate": parseInt(props.click_rate),
    "color": "#DCD562",
  },
  {
    "category": "Open-rate",
    "rate": parseInt(props.open_rate),
    "color": "#98D0A0",
  },
  {
    "category": "Bounce-rate",
    "rate": parseInt(props.bounce_rate),
    "color": "#E2B4F4",
  },
  {
    "category": "Spam-rate",
    "rate": parseInt(props.spam_rate),
    "color": "#FF7D7D",
  },];

// Create axes

let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
categoryAxis.dataFields.category = "category";
categoryAxis.renderer.grid.template.location = 0;
categoryAxis.renderer.minGridDistance = 60;

categoryAxis.renderer.labels.template.adapter.add("dy", function(dy, target) {
  return dy;
});

let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

// Create series
let series = chart.series.push(new am4charts.ColumnSeries());
series.dataFields.valueY = "rate";
series.dataFields.categoryX = "category";
series.name = "rate";
series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}%[/]";
series.columns.template.fillOpacity = .8;
series.columns.template.width = 50;
series.columns.template.propertyFields.fill = "color"

let columnTemplate = series.columns.template;
columnTemplate.strokeWidth = 1;
columnTemplate.strokeOpacity = 1;

return () => {
    chart.dispose();
  };
}, [props]);

return <div id="modalChartdiv" style={{ width: "100%", height: "280px" }}></div>;
}

export default BarChart;