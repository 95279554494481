import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { toggleOffCanvasData } from '../../store/actions/layout'
import { setHelpFragment, setIsToggle } from '../../store/actions/help'
import './help.scss'

const HelpComponent = ({ filePath, show }) => {
	const dispatch = useDispatch()

	const handleOpen = () => {
		if (show) {
			dispatch(setIsToggle(false))
		} else {
			dispatch(setHelpFragment(filePath))
			dispatch(toggleOffCanvasData('help'))
			dispatch(setIsToggle(true))
		}
	}
	useEffect(() => {
		if (show) {
			let open = document.querySelector('.email-view')
			open.classList.remove('close-view')
			open.classList.add('email-view-open')
		} else {
			let close = document.querySelector('.email-view')
			close.classList.remove('email-view-open')
			close.classList.add('close-view')
		}
	}, [show])

	return (
		<>
			<span id='openBtn' onClick={handleOpen} className='text-lowercase'>
				(help)
			</span>
		</>
	)
}

function mapStateToProps(state) {
	return {
		show: state.helpReducer.isToggle,
	}
}

export default connect(mapStateToProps, null)(HelpComponent)
