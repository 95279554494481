import React from 'react'
import { Redirect } from 'react-router-dom'

import {
  Login,
  Register,
  VerifyEmail,
  ResetPassword,
  EmailVerification,
  ResetPasswordForm,
} from '../pages/Auth'
import Faq from '../pages/help'
import Plans from '../pages/Plan/Plans'
import Settings from '../pages/Settings'
import Sequences from '../pages/Sequences'
import Support from '../pages/Support/Support'
import WarmUpEmails from '../pages/WarmUpEmails'
import Dashboard from '../pages/Dashboard/Dashboard'
import Stats from '../pages/WarmUpEmails/Stats/Stats'
import DnsChecker from '../pages/DNSChecker/DnsChecker'
import AffilatePrograme from '../pages/AffilatePrograme'
import SMSSequence from '../pages/Sequences/SMSSequence'
import DomainHealth from '../pages/DNSChecker/DomainHealth'
import Analyzer from '../pages/Grader/Analyzer'
import VoiceSequence from '../pages/Sequences/VoiceSequence'
import InboxSpamChecker from '../pages/InboxSpamChecker/index'
import SpamChecker from '../pages/InboxSpamChecker/SpamChecker'
import ChatBotSequence from '../pages/Sequences/ChatBotSequence'
import PotentialInboxing from '../pages/Grader/index'
import EmailSequence from '../pages/Sequences/email/EmailSequence'
import SequenceDetail from '../pages/Sequences/email/SequenceDetail'
import FavoriteSequence from '../pages/Sequences/favorite/FavoriteSequence'
import UnlockedSequences from '../pages/Sequences/unlocked/UnlockedSequences'
import TrackCompetitor from '../pages/Sequences/trackCompetitor/TrackCompetitor'
import Table from '../atoms/Table'
import PrivacyPolicy from '../pages/privacy_policy/PrivacyPolicy'

const authProtectedRoutes = [
  { path: '/dashboard', component: Dashboard },

  { path: '/warm-up-emails/:page?', component: WarmUpEmails },
  { path: '/warm-up-emails/stats/:id', component: Stats },
  { path: '/dns-checker', component: DnsChecker },
  { path: '/inbox-spam-checker', component: InboxSpamChecker },
  { path: '/inbox-spam-checker/spam-checker', component: SpamChecker },
  { path: '/email-inboxing-grader', component: PotentialInboxing },

  { path: '/help', component: Faq },
  { path: '/support', component: Support },
  { path: '/affilate-programe', component: AffilatePrograme },
  { path: '/settings', component: Settings },
  { path: '/sequences', component: Sequences },
  { path: '/email-sequences', component: EmailSequence },
  { path: `/email-sequences/:id`, component: SequenceDetail },
  { path: '/track-competitor-sequences', component: TrackCompetitor },
  { path: '/unlocked-sequences', component: UnlockedSequences },
  { path: '/fav-sequences', component: FavoriteSequence },
  { path: '/sms-sequences', component: SMSSequence },
  { path: '/voice-sequences', component: VoiceSequence },
  { path: '/chatbot-sequences', component: ChatBotSequence },
  { path: '/table', component: Table },
  { path: '/', exact: true, component: () => <Redirect to='/dashboard' /> },

]

const publicRoutes = [
  { path: '/register', component: Register },
  { path: '/login:status?', component: Login },
  { path: '/privacyPolicy', component: PrivacyPolicy },
  { path: '/reset-password', component: ResetPassword },
  { path: '/password/reset/:signedEmail', component: ResetPasswordForm },
  { path: '/email-verification', component: EmailVerification },
  { path: '/v1/verification/verify/:user', component: VerifyEmail },
]

const customAuthRoutes = [
  { path: '/email-inboxing-grader/analyzer/:id', component: Analyzer },
  { path: '/dns-checker/domain-health/:id', component: DomainHealth },
  { path: '/plans', component: Plans },
]

export { authProtectedRoutes, publicRoutes, customAuthRoutes }
