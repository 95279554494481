import React, { useEffect, useState } from "react"

const WarmupSuggestionModal = () => {
  const [warmupSuggestion, setWarmupSuggestion] = useState(
    localStorage.getItem("warmupSuggestion") === "true" ? false : true
  )

  useEffect(() => {
    localStorage.setItem("warmupSuggestion", warmupSuggestion)
  }, [warmupSuggestion])

  return (
    <div
      tabIndex='-1'
      className='modal fade'
      aria-hidden='true'
      id='warmupSuggestionModal'
      style={{ overflowY: "initial" }}
      aria-labelledby='warmupSuggestionModal'
    >
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title' id='warmupSuggestionModal'>
              Warming Up Recommendations
            </h5>
          </div>
          <div className='modal-body'>
            It's Highly Recommend That You Set A 3 Month Warming Up Start & End
            Date the reason is, By setting up 3-month warming up campaign you
            will start to develop trust with the different Email Service
            Providers with this length of a campaign not only will show
            consistency with your domain and emails but will greatly improve
            your inboxing %.
          </div>
          <div className='modal-footer'>
            <div className='form-check'>
              <input
                type='checkbox'
                id='flexCheckChecked'
                className='form-check-input'
                checked={!warmupSuggestion}
                onChange={() => setWarmupSuggestion(!warmupSuggestion)}
              />
              <label className='form-check-label' htmlFor='flexCheckChecked'>
                Dont't show again
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WarmupSuggestionModal
