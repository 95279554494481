export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const REGISTER = 'REGISTER'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'


export const GETMESSAGES = 'GETMESSAGES'
export const SETMESSAGES = 'SETMESSAGES'

export const TOGGLE_MENU = 'TOGGLE_MENU'
export const CAMPAIGN_STATUS = 'CAMPAIGN_STATUS'
export const DRAGABLE_CHART_DATA = 'DRAGABLE_CHART_DATA'

export const LOAD_FRAGMENT = 'LOAD_FRAGMENT'
export const TOUR_COMPLETE = 'TOUR_COMPLETE'
export const TOGGLE_HELP_PANEL = 'TOGGLE_HELP_PANEL'
export const TOGGLE_OFFCANVAS_DATA = 'TOGGLE_OFFCANVAS_DATA'

export const API_CALL_IN_PROGRESS = 'API_CALL_IN_PROGRESS'
export const API_CALL_NOT_IN_PROGRESS = 'API_CALL_NOT_IN_PROGRESS'