import './filter.scss'
import { toast } from 'react-toastify'
import React, { useEffect, useState } from 'react'
import SequencesService from '../../api-services/sequencesService'
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri'
import Carousel from '../../pages/InboxSpamChecker/carousel/Carousel'
import { ReactComponent as EmailIcon } from '../../components/Assets/icons/mail-icon.svg'
import { ReactComponent as PaperPlaneIcon } from '../../components/Assets/icons/paperPlane-icon.svg'

const Filter = ({
	status,
	setLoading,
	setMetaData,
	searchInput,
	setSequences,
	handleSearch,
	selectedBrand,
	setSearchInput,
	filterByViewed,
	fetchSequences,
	selectedCategory,
	setSelectedBrand,
	handleClearFilter,
	setFilterByViewed,
	sequenceActivePage,
	filterByCompetitor,
	setSelectedCategory,
	fetchTrackedCompetitors,
}) => {
	const [brands, setBrands] = useState([])
	const [options, setOptions] = useState([])
	const [activePage, setActivePage] = useState(1)
	const [searchQuery, setSearchQuery] = useState('')
	const [lastBrandPage, setLastBrandPage] = useState(1)
	const [currentFilter, setCurrentFilter] = useState('')
	const [brandActivePage, setBrandActivePage] = useState(1)
	const [loadingBrands, setLoadingBrands] = useState(false)
	const [loadingCategories, setLoadingCategories] = useState(false)

	const listBox = document.getElementById('react-select-3-listbox')

	const brandsDropdownObserver = new IntersectionObserver(
		entries => {
			entries.forEach(entry => {
				console.log(entry)
				if (entry.isIntersecting) {
					setActivePage(activePage + 1)
				}
			})
		},
		{
			root: listBox,
			rootMargin: '0px 0px 0px 0px',
			threshold: 1,
		}
	)

	const handleObservers = () => {
		if (
			document.querySelector(
				`#react-select-3-option-${(activePage - 1) * 10 + 9}`
			) !== null
		)
			setTimeout(() => {
				brandsDropdownObserver.observe(
					document.querySelector(
						`#react-select-3-option-${(activePage - 1) * 10 + 9}`
					)
				)
			}, 100)
	}

	const getCategories = async () => {
		setLoadingCategories(true)

		let tempArr = []

		const response = await SequencesService.getAllCategories()

		if (response.email_type !== undefined) {
			response.email_type.map(item => {
				tempArr.push({
					value: item.id,
					label: item.name,
				})
			})
			setOptions(tempArr)
			setLoadingCategories(false)
		} else {
			setLoadingCategories(false)
			toast.error(response.message, {
				autoClose: 2500,
				closeOnClick: true,
				position: 'top-right',
				hideProgressBar: false,
			})
		}

		setLoadingCategories(false)
	}

	const getBrandsByCategory = async incomingBrandActivePage => {
		setLoadingBrands(true)
		setCurrentFilter('category')

		let tempArr = []
		let totalPages = 0
		let perPage = 0

		const response = await SequencesService.filterBrands(
			searchQuery,
			selectedCategory !== null ? selectedCategory.value : '',
			incomingBrandActivePage
		)

		if (response.success) {
			response.brands.data.forEach(singleBrand => {
				tempArr.push({
					label: singleBrand.name,
					value: singleBrand.id,
				})
			})
			setLastBrandPage(response.brands.last_page)
			setLoadingBrands(false)

			setBrands(tempArr)
			const sequencesResponse = await SequencesService.listSequences(
				sequenceActivePage,
				selectedBrand !== null ? selectedBrand.value : '',
				selectedCategory !== null ? selectedCategory.value : ''
			)

			if (sequencesResponse.data.success) {
				// setLoading(false)
				if (sequencesResponse.data.sequence.data.length !== 0) {
					setSequences(sequencesResponse.data.sequence.data)
					totalPages = sequencesResponse.data.sequence.total
					perPage = sequencesResponse.data.sequence.per_page
					setMetaData({ totalPages: totalPages, perPage: perPage })
				}
			}
		}
	}

	const fetchBrands = async () => {
		setLoadingBrands(true)
		let tempArr = []

		const response = await SequencesService.filterBrands(
			searchQuery,
			'',
			brandActivePage
		)

		if (response.success) {
			response.brands.data.forEach(singleBrand => {
				tempArr.push({
					label: singleBrand.name,
					value: singleBrand.id,
				})
			})
			setLastBrandPage(response.brands.last_page)
			setBrands(tempArr)
		}

		setLoadingBrands(false)
	}

	const getSequencesByBrand = async () => {
		setCurrentFilter('brand')
		let totalPages = 0
		let perPage = 0

		const response = await SequencesService.listSequences(
			sequenceActivePage,
			selectedBrand !== null ? selectedBrand.value : '',
			selectedCategory !== null ? selectedCategory.value : ''
		)

		if (response.data.success) {
			setSequences(response.data.sequence.data)
			totalPages = response.data.sequence.total
			perPage = response.data.sequence.per_page
			setMetaData({ totalPages: totalPages, perPage: perPage })
		}
	}

	const searchInputChange = e => {
		e.preventDefault()
		setSearchInput(e.target.value)
	}

	const handleBrandSearch = async e => {
		setLoadingBrands(true)
		setBrandActivePage(1)
		let tempArr = []
		setSearchQuery(e.target.value)

		if (
			selectedCategory !== '' &&
			selectedCategory !== null &&
			status === 'email-sequences'
		) {
			const response = await SequencesService.filterBrands(
				e.target.value,
				selectedCategory.value,
				brandActivePage
			)

			if (response.success) {
				response.brands.data.forEach(singleBrand => {
					tempArr.push({
						label: singleBrand.name,
						value: singleBrand.id,
					})
				})
				setLastBrandPage(response.brands.last_page)
				setBrands(tempArr)
			} else {
				toast.error(response.message, {
					autoClose: 2500,
					closeOnClick: true,
					position: 'top-right',
					hideProgressBar: false,
				})
			}
		} else {
			const response = await SequencesService.filterBrands(
				e.target.value,
				'',
				brandActivePage
			)

			if (response.success) {
				response.brands.data.forEach(singleBrand => {
					tempArr.push({
						label: singleBrand.name,
						value: singleBrand.id,
					})
				})
				setLastBrandPage(response.brands.last_page)
				setBrands(tempArr)
			} else {
				toast.error(response.message, {
					autoClose: 2500,
					closeOnClick: true,
					position: 'top-right',
					hideProgressBar: false,
				})
			}
		}
		setLoadingBrands(false)
	}

	useEffect(() => {
		if (selectedBrand !== '' && status === 'email-sequences')
			getSequencesByBrand()
	}, [selectedBrand])

	useEffect(() => {
		if (
			selectedCategory !== '' &&
			selectedCategory !== null &&
			status === 'email-sequences'
		)
			getBrandsByCategory()
	}, [selectedCategory])

	useEffect(() => {
		if (status === 'email-sequences') {
			getCategories()
			fetchBrands()
		}

		return () => {
			setOptions([])
		}
	}, [])

	useEffect(() => {
		if (
			selectedCategory !== '' &&
			selectedCategory !== null &&
			status === 'email-sequences'
		)
			getBrandsByCategory(brandActivePage)
		else fetchBrands()
	}, [brandActivePage])

	return (
		<>
			{status === 'email-sequences' ? (
				<div
					id='multiCollapseExample1'
					className='row show multi-collapse open-filter'
				>
					<div className='col'>
						<div className='d-flex justify-content-between text-center mb-4'>
							<div type='button' style={{ cursor: 'default' }}>
								<EmailIcon
									style={{ marginRight: '11px' }}
									fill={
										currentFilter === 'category' || currentFilter === 'brand'
											? '#0994F8'
											: '#BDBDBD'
									}
								/>
								<b className='tabText'>Categories</b>
							</div>
							<hr
								className={
									currentFilter === 'brand'
										? 'lineWithBorderColor mx-4 flex-grow-1'
										: 'line mx-4 flex-grow-1'
								}
							/>
							<div type='button' style={{ cursor: 'default' }}>
								<PaperPlaneIcon
									style={{ marginRight: '11px' }}
									fill={currentFilter === 'brand' ? '#0994F8' : '#BDBDBD'}
								/>
								<b className='tabText'>Select a Brand</b>
							</div>
						</div>
					</div>
					<div className='col-lg-12' style={{ width: '100%' }}>
						<label
							htmlFor='protocol'
							className='label-search'
							style={{ fontSize: '16px' }}
						>
							Step 1: Select Category
						</label>
						<div className='d-flex justify-content-center'>
							{loadingCategories ? (
								<p className='text-center'>Loading Categories</p>
							) : options.length === 0 ? (
								<p className='text-center'>No Categories Found</p>
							) : (
								<div className='col-lg-12'>
									<Carousel
										data={options}
										usageFlag={'categories'}
										selectedCategory={selectedCategory}
										handleCategorySelection={setSelectedCategory}
									/>
								</div>
							)}
						</div>
						{/* <Select
              // isClearable
              options={options}
              value={selectedCategory}
              isLoading={loadingCategories}
              onChange={setSelectedCategory}
            /> */}
					</div>
					<div className='col-lg-12 mt-4' style={{ width: '100%' }}>
						<div className='d-flex'>
							<div className='flex-grow-1'>
								<label
									htmlFor='protocol'
									className='label-search'
									style={{ fontSize: '16px' }}
								>
									Step 2: Select Brand
								</label>
							</div>
							<div className='flex-grow-1'>
								<div
									style={{
										// width: '565px',
										borderRadius: '5px',
										border: '1px solid #E5E5E5',
									}}
									className='input-group mb-3 float-right'
								>
									<input
										type='text'
										value={searchQuery}
										className='form-control'
										style={{ border: 'none' }}
										onChange={handleBrandSearch}
										aria-describedby='basic-addon2'
										aria-label='Search By Brand Name'
										placeholder='Search By Brand Name'
									/>
									<span
										id='basic-addon2'
										style={{
											width: '80px',
											border: 'none',
											fontSize: '18px',
											background: '#F3FDFF',
										}}
										className='input-group-text d-flex justify-content-center'
									>
										<i
											className='far fa-search'
											style={{ color: '#61BBFB' }}
										></i>
									</span>
								</div>
							</div>
						</div>
						<div className='d-flex flex-row'>
							<div
								style={{
									cursor: 'pointer',
									pointerEvents: brandActivePage - 1 <= 0 ? 'none' : '',
								}}
								onClick={() => setBrandActivePage(brandActivePage - 1)}
								className='align-items-center justify-content-center d-flex'
							>
								<RiArrowLeftSLine
									color={brandActivePage - 1 <= 0 ? '#E5E5E5' : '#0994F8'}
									size='1.5em'
								/>
							</div>

							<div className='brand-container flex-grow-1 justify-content-center align-items-center'>
								{loadingBrands ? (
									<p
										className='d-flex flex-grow-1 justify-content-center align-items-center'
										style={{
											width: '894px',
											height: '202.5px',
											fontWeight: '600',
											fontSize: 'large',
										}}
									>
										Loading Brands
									</p>
								) : brands.length === 0 &&
								  selectedCategory.label !== undefined ? (
									<p
										className='text-center'
										style={{ fontWeight: '600', fontSize: 'large' }}
									>
										No Brands in {selectedCategory.label}
									</p>
								) : brands.length === 0 &&
								  selectedCategory.label === undefined ? (
									<p className='text-center'>Select a brand to continue</p>
								) : (
									brands.map((brand, i) => (
										<div
											key={i}
											className={
												selectedBrand.value === brand.value
													? 'brand-selected-div'
													: 'brand-div'
											}
											onClick={() => {
												if (selectedBrand.value === brand.value) {
													setSelectedBrand({})
												} else {
													setSelectedBrand({
														value: brand.value,
														label: brand.label,
													})
												}
											}}
										>
											<p className='brand-label'>{brand.label}</p>
										</div>
									))
								)}
							</div>

							<div
								style={{
									cursor: 'pointer',
									pointerEvents:
										brandActivePage + 1 >= lastBrandPage ? 'none' : '',
								}}
								onClick={() => setBrandActivePage(brandActivePage + 1)}
								className='align-items-center justify-content-center d-flex'
							>
								<RiArrowRightSLine
									color={
										brandActivePage + 1 >= lastBrandPage ? '#E5E5E5' : '#0994F8'
									}
									size='1.5em'
								/>
							</div>
						</div>
						<div className='d-flex flex-row align-items-center mt-3'>
							<label
								htmlFor='protocol'
								className='label-search flex-grow-1'
								style={{ fontSize: '16px' }}
							>
								Filter Sequences By
							</label>
							<div className='flex-grow-1'>
								<select
									value={filterByViewed}
									className='form-control'
									style={{
										// width: '565px',
										borderRadius: '5px',
										border: '1px solid #E5E5E5',
									}}
									onChange={e => {
										setFilterByViewed(e.target.value)
										// fetchSequences()
									}}
								>
									<option value=''>Select an Option</option>
									<option value={'1'}>Already Viewed</option>
									<option value={'2'}>Not Already Viewed</option>
								</select>
							</div>
						</div>
						{/* <Select
              isClearable
              isSearchable
              options={brands}
              id="async-select"
              value={selectedBrand}
              isLoading={loadingBrands}
              onChange={setSelectedBrand}
              onMenuOpen={handleObservers}
            // onMenuClose={flushObservers}
            /> */}
					</div>
					<div className='col' style={{ marginTop: '30px' }}>
						<button
							type='button'
							id='button-addon2'
							className='search-clear-btn'
							onClick={() => {
								setSelectedBrand('')
								setFilterByViewed('')
								setSelectedCategory('')
								fetchSequences()
							}}
						>
							Clear
						</button>
					</div>
				</div>
			) : status === 'unlocked-sequences' ? (
				<div id='multiCollapseExample1' className='row collapse open-filter'>
					<div className='col-sm-3'>
						<label htmlFor='protocol' className='label-search'>
							Filter Sequences By
						</label>
						<div className='input-group'>
							<select
								value={filterByViewed}
								className='form-control'
								onChange={e => {
									setFilterByViewed(e.target.value)
									fetchSequences(e.target.value)
								}}
							>
								<option value=''>Select an Option</option>
								<option value={1}>Already Viewed</option>
								<option value={2}>Not Already Viewed</option>
							</select>
						</div>
					</div>
					<div className='col d-flex align-items-end'>
						<button
							type='button'
							id='button-addon2'
							className='search-clear-btn'
							disabled={filterByViewed === ''}
							onClick={() => {
								setFilterByViewed('')
								fetchSequences()
							}}
							style={{
								pointerEvents: filterByViewed === '' ? 'none' : 'initial',
							}}
						>
							<i id='clear-icon' className='fas fa-times mr-2'></i>
							Clear
						</button>
					</div>
				</div>
			) : (
				<div id='multiCollapseExample1' className='row collapse open-filter'>
					<div className='col-sm-3'>
						<label htmlFor='protocol' className='label-search'>
							{status === 'warm-up'
								? 'Enter campaign name'
								: 'Enter email address'}
						</label>
						<div className='input-group'>
							<input
								value={searchInput}
								className='form-control'
								aria-describedby='button-addon2'
								aria-label="Recipient's username"
								onChange={e => setSearchInput(e.target.value)}
								type={status === 'warm-up' ? 'text' : 'email'}
								placeholder={
									status === 'warm-up' ? 'Campaign name' : 'Email address'
								}
							/>
						</div>
					</div>
					<div className='col d-flex align-items-end'>
						<button
							type='button'
							id='button-addon2'
							className='filter-btn'
							onClick={handleSearch}
							disabled={searchInput === ''}
							style={{ pointerEvents: searchInput === '' ? 'none' : 'initial' }}
						>
							<i id='filter-icon' className='fas fa-filter mr-2'></i>
							Filter
						</button>
						&nbsp;&nbsp;&nbsp;
						<button
							type='button'
							id='button-addon2'
							onClick={handleClearFilter}
							className='search-clear-btn'
							disabled={searchInput === ''}
							style={{ pointerEvents: searchInput === '' ? 'none' : 'initial' }}
						>
							<i id='clear-icon' className='fas fa-times mr-2'></i>
							Clear
						</button>
					</div>
				</div>
			)}
		</>
	)
}

export default Filter
