import React from "react"

export const EmailSequenceHeader = ({ consumedCount, allowedCount }) => {
  return (
    <div className='d-flex align-item-center'>
      <div className='shld flex-grow-1 flex-shrink-0 text-center mr-2'>
        <img src='/assets/img/email-blue.png' alt='warm-up' />
      </div>
      <div className='flex-grow-1'>
        <div className='text-bold heading'>
          Email Sequences &nbsp;
          <span>
            {/* <HelpComponent
          filePath={"/help-fragments/3-warmupHelp.html"}
        /> */}
          </span>
        </div>
        <p className='sub-heading'>
          View email sequences of subscribed brands.
          {allowedCount === undefined || allowedCount === 0 ? (
            <></>
          ) : (
            <span className='ml-1' style={{ fontWeight: "bold" }}>
              ({consumedCount}/{allowedCount} used)
            </span>
          )}
        </p>
      </div>
      <div
        className='py-0 align-self-center text-right'
        style={{ minWidth: "130px", maxWidth: "130px" }}
      >
        <button
          role='button'
          aria-expanded='false'
          className='filter-btn'
          data-toggle='collapse'
          data-target='#multiCollapseExample1'
          aria-controls='multiCollapseExample1'
        >
          <i id='filter-icon' className='fas fa-filter mr-2'></i>Filter
        </button>
      </div>
    </div>
  )
}
