import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import AuthService from '../../api-services/authService'
import { toast } from 'react-toastify'
import LoadingBar from 'react-top-loading-bar'

const EmailVerification = ({ sUser, history }) => {
	const ref = useRef(null)
	const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')))
	const [error, setError] = useState(null)
	const [errors, setErrors] = useState({})
	const [loading, setLoading] = useState(false)

	const notify = () => toast('Email Verification Resend Successfully.')

	useEffect(() => {
		if (sUser.email_verified_at != null && sUser.email_verified_at != false) {
			history.push('/login')
		}
	}, [])

	const handleResendEmailVerification = async event => {
		event.preventDefault()
		if (user.email !== '') {
			setLoading(true)
			ref.current.continuousStart()
			try {
				// dispatch Login action
				await AuthService.resendEmailVerification({ email: user.email })
				notify()
			} catch (e) {
				if (e.response.status == 422) {
					const errors = e.response.data.errors
					setErrors(errors)
				} else {
					setError(e)
				}
			} finally {
				setLoading(false)
				ref.current.complete()
			}
		}
	}

	return (
		<div className='container py-4'>
			<LoadingBar height={5} color='#5D95EA' ref={ref} />
			<header className='pb-3 mb-4 border-bottom'>
				<a
					href='/'
					className='d-flex align-items-center text-dark text-decoration-none'
				>
					<span className='fs-4'>Inbox Better</span>
				</a>
			</header>
			<div className='p-4 mb-4 bg-light  rounded-3'>
				<div className='container-fluid py-5'>
					<p className='col-md-12 fs-6'>
						Before Proceeding Please Verify Your Email Address at{' '}
						<strong className='text-info'> {user.email} </strong>
					</p>
					<button
						disabled={loading}
						className='btn btn-sm btn-primary'
						type='button'
						onClick={handleResendEmailVerification}
					>
						Resend Email
					</button>
				</div>
			</div>
		</div>
	)
}

function mapStateToProps(state) {
	return {
		sUser: state.authReducer.user,
	}
}

export default connect(mapStateToProps, null)(EmailVerification)
