const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

let date_months = [];

function isDate(dateArg) {
  var t =
    dateArg instanceof Date
      ? dateArg
      : new Date(dateArg.replace(/-/g, "/").replace(/T.+/, ""));
  return !isNaN(t.valueOf());
}

function isValidRange(minDate, maxDate) {
  return (
    new Date(minDate.replace(/-/g, "/").replace(/T.+/, "")) <=
    new Date(maxDate.replace(/-/g, "/").replace(/T.+/, ""))
  );
}

const dateBetween = (startDt, endDt) => {
  var error =
    isDate(endDt) && isDate(startDt) && isValidRange(startDt, endDt)
      ? false
      : true;
  var between = [];
  if (error) {
    // console.log("error occured!!!... Please Enter Valid Dates");
  } else {
    var currentDate = new Date(startDt.replace(/-/g, "/").replace(/T.+/, "")),
      end = new Date(endDt.replace(/-/g, "/").replace(/T.+/, ""));
    while (currentDate <= end) {
      between.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
  }
  return between;
};

function getDates(s, e) {
  let a = dateBetween(s, e);
  // let date_months = dateBetween(s,e);
  let allDates = [...Object.values(a)];
  if (allDates.length) {
    let date_months = allDates.map((date) => {
      return monthNames[date.getMonth()].slice(0, 3) + "" + date.getDate();
    });

    return date_months;
  }
}

export default getDates;
