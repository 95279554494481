import { tourCompleted } from "../store/actions/layout";
import API from "./api"

const TourService = {
  completeTour: () => {
    return API.get('/user/mark-tour-completed')
      .then(({ data }) => {
        tourCompleted(true)
        return data;
      })
      .catch((err) => {
        throw err;
      });
  }
}

export default TourService