import React, { Component } from "react"
import * as am4core from "@amcharts/amcharts4/core"
import * as am4charts from "@amcharts/amcharts4/charts"
import am4themes_animated from "@amcharts/amcharts4/themes/animated"
import getDates from "./ChartHelpers/dateBetween"
import getFullDates from "./ChartHelpers/fullDates"
import { connect } from "react-redux"
import { setChartData } from "../../../store/actions/dragableChartData"

am4core.useTheme(am4themes_animated)

class Dragable2 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dates: [],
      fDates: [],
      chartData: [],
      emails: 0,
      isRandom: this.props.randomActive,
      isEdited: false,
    }
  }
  handleDrag(event, valueAxis) {
    let cData = this.state.chartData

    var dataItem = event.target.dataItem
    // convert coordinate to value
    var value = valueAxis.yToValue(event.target.pixelY)
    // set new value
    dataItem.valueY = value
    // make column hover
    dataItem.column.isHover = true
    // hide tooltip not to interrupt
    dataItem.column.hideTooltip(0)
    // make bullet hovered (as it might hide if mouse moves away)
    event.target.isHover = true

    let total_sent = Math.round(value)
    for (var i = 0; i < this.state.chartData.length; i++) {
      if (cData[i].formatted_date == dataItem.categoryX) {
        cData[i].total_send = total_sent
      }
    }
    this.setState({ ChartData: cData })
    this.props.setChartData(cData)
  }

  componentDidUpdate(prevProps) {
    if (isNaN(this.props.inboxForm.emails_sent_per_day)) {
      this.props.inboxForm.emails_sent_per_day = 40
    }
    // if(this.props.inboxForm.emails_sent_per_day !== prevProps.inboxForm.emails_sent_per_day) {
    //   this.setState({ emails: this.props.inboxForm.emails_sent_per_day }, () => {
    //     this.componentDidMount()
    //   })
    // }
    else if (
      this.props.inboxForm.emails_sent_per_day !==
      prevProps.inboxForm.emails_sent_per_day
    ) {
      this.setState(
        { emails: this.props.inboxForm.emails_sent_per_day },
        () => {
          this.componentDidMount()
        }
      )
    } else if (this.props.period.startDate > this.props.period.endDate) {
      var dt = new Date(this.props.period.endDate)
      dt.setDate(dt.getDate() - 1)
      const month = parseInt(dt.getMonth()) + 1
      let date = dt.getFullYear() + "-" + month + "-" + dt.getDate()
      this.setState(
        {
          dates: getFullDates(date, this.props.period.endDate),
          fDates: getDates(date, this.props.period.endDate),
        },
        () => {
          this.componentDidMount()
        }
      )
    } else if (this.props.period !== prevProps.period) {
      this.setState(
        {
          dates: getFullDates(
            this.props.period.startDate,
            this.props.period.endDate
          ),
          fDates: getDates(
            this.props.period.startDate,
            this.props.period.endDate
          ),
        },
        () => {
          this.componentDidMount()
        }
      )
    } else if (this.props.randomActive !== prevProps.randomActive) {
      this.setState(
        {
          isRandom: this.props.randomActive,
        },
        () => {
          this.componentDidMount()
        }
      )
    }
  }

  componentDidMount() {
    var chart = am4core.create("schedulerchartdiv", am4charts.XYChart)
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())

    let email = this.props.inboxForm.emails_sent_per_day
    let datePeriod = this.props.period

    let fullDates = getFullDates(datePeriod.startDate, datePeriod.endDate)
    let humanDates = getDates(datePeriod.startDate, datePeriod.endDate)

    this.setState({ emails: email })
    this.setState({ dates: humanDates })
    this.setState({ fDates: fullDates }, () => {
      let data1 = []
      let obj = { date: "", formatted_date: "", sent: "" }

      let customId = 0
      if (data1.length === 0 && this.state.dates.length !== 0) {
        if (this.state.isRandom) {
          let random = 0
          for (let x = 0; x < this.state.dates.length; x++) {
            random = Math.floor(Math.random() * this.state.emails + 1)
            obj = {
              id: ++customId,
              formatted_date: this.state.dates[x],
              date: this.state.fDates[x],
              sent: random,
              total_send: random,
            }
            data1.push(obj)
            categoryAxis.maxZoomCount = 10
          }
        } else if (this.props.isEdit && !this.state.isEdited) {
          for (
            let x = 0;
            x <
            Object.keys(this.props.campaignData.warmup_campaign_schedules)
              .length;
            x++
          ) {
            obj = {
              id: ++customId,
              formatted_date:
                this.props.campaignData.warmup_campaign_schedules[x]
                  .formatted_date,
              date: this.props.campaignData.warmup_campaign_schedules[x].date,
              sent: this.props.campaignData.warmup_campaign_schedules[x]
                .total_send,
              total_send:
                this.props.campaignData.warmup_campaign_schedules[x].total_send,
            }
            data1.push(obj)
          }
          categoryAxis.maxZoomCount = 10

          this.setState({
            fDates: this.props.campaignData.warmup_campaign_schedules.map(
              (s) => s.formatted_date
            ),
          })
          this.setState({
            dates: this.props.campaignData.warmup_campaign_schedules.map(
              (s) => s.date
            ),
          })

          this.setState({ isEdited: true })
        } else {
          for (let x = 0; x < this.state.dates.length; x++) {
            obj = {
              id: ++customId,
              formatted_date: this.state.dates[x],
              date: this.state.fDates[x],
              sent: this.state.emails,
              total_send: this.state.emails,
            }
            data1.push(obj)
          }
          categoryAxis.maxZoomCount = 10
        }
        this.setState({ chartData: data1 }, () => {
          chart.data = this.state.chartData
        })
        this.props.setChartData(data1)
      }
    })
    // var chart = am4core.create("schedulerchartdiv", am4charts.XYChart);
    chart.hiddenState.properties.opacity = 0 // this makes initial fade in effect
    chart.responsive.enabled = true

    // chart.responsive.rules.push({
    // relevant: function(target) {
    //   if (target.pixelWidth <= 400) {
    //     return true;
    //   }
    //   return false;
    // }
    // });

    //

    //   relevant: function (target) {
    //     // Code that determines if rule is currently relevant
    //     // and returns true or false
    //     return false;
    //   },
    // });
    chart.padding(40, 40, 0, 0)
    chart.maskBullets = false
    // allow bullets to go out of plot area

    var text = chart.plotContainer.createChild(am4core.Label)
    text.text = "Drag column bullet to change its value"
    text.y = -50
    text.x = am4core.percent(100)
    text.horizontalCenter = "right"
    text.zIndex = 100
    text.fillOpacity = 0.7

    let num = 0
    if (this.state.chartData.length == 0) {
      num = 1
    } else {
      num = 1 / Math.ceil(this.state.chartData.length / 10)
    }
    // category axis
    // var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "formatted_date"
    categoryAxis.renderer.grid.template.disabled = true
    categoryAxis.renderer.grid.template.location = 0
    categoryAxis.renderer.minGridDistance = 10
    categoryAxis.start = 0
    // categoryAxis.end = 1/ num;
    categoryAxis.maxZoomCount = 0
    // {this.state.dates.length <= 10 ? categoryAxis.end = 1 : this.state.dates.length <= 20 ? categoryAxis.end = 0.8 : this.state.dates.length <= 30 ? categoryAxis.end = 0.40 : this.state.dates.length <= 40 ? categoryAxis.end = 0.25 : categoryAxis.end = 0.125};
    // categoryAxis.renderer.cellStartLocation = 0;
    // categoryAxis.renderer.cellEndLocation = 1;
    // categoryAxis.keepSelection = true;

    // value axis
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
    // we set fixed min/max and strictMinMax to true, as otherwise value axis will adjust min/max while dragging and it won't look smooth
    valueAxis.strictMinMax = true
    valueAxis.min = 0
    valueAxis.maxPrecision = 0
    valueAxis.integersOnly = true
    valueAxis.max = 60
    // this.props.inboxForm.emails_sent_per_day;
    valueAxis.renderer.minWidth = 50

    // series
    var series = chart.series.push(new am4charts.ColumnSeries())
    series.dataFields.categoryX = "formatted_date"
    series.dataFields.valueY = "sent"
    series.tooltip.pointerOrientation = "vertical"
    series.tooltip.dy = -8
    series.sequencedInterpolation = true
    series.defaultState.interpolationDuration = 1500
    series.columns.template.strokeOpacity = 0
    // series.columns.template.width = am4core.percent(100);
    series.columns.template.width = 60
    series.fill = am4core.color("#0994F8").lighten(0.3)

    // setColumnValue(series.columns);

    // // label bullet
    var labelBullet = new am4charts.LabelBullet()
    series.bullets.push(labelBullet)
    labelBullet.label.text = "{valueY.value.formatNumber('#.')}"
    labelBullet.strokeOpacity = 0
    labelBullet.stroke = am4core.color("#dadada")
    labelBullet.dy = -20

    // // series bullet
    var bullet = series.bullets.create()
    bullet.stroke = am4core.color("#ffffff")
    bullet.strokeWidth = 3
    bullet.opacity = 1 // initially invisible
    bullet.defaultState.properties.opacity = 1
    // resize cursor when over
    bullet.cursorOverStyle = am4core.MouseCursorStyle.verticalResize
    bullet.draggable = true
    bullet.fill = am4core.color("#0994F8")

    // // create hover state
    var hoverState = bullet.states.create("hover")
    hoverState.properties.opacity = 1 // visible when hovered

    // // add circle sprite to bullet
    var circle = bullet.createChild(am4core.Circle)
    circle.radius = 8

    // // while dragging
    bullet.events.on("drag", (event) => {
      this.handleDrag(event, valueAxis)
    })

    bullet.events.on("dragstop", (event) => {
      this.handleDrag(event, valueAxis)
      var dataItem = event.target.dataItem
      dataItem.column.isHover = false
      event.target.isHover = false
    })
    // if(this.state.chart.length >= 10){
    chart.scrollbarX = new am4core.Scrollbar()
    chart.scrollbarX.parent = chart.topAxesContainer
    chart.scrollbarX.Width = 40
    chart.scrollbarX.minHeight = 10
    chart.scrollbarX.marginBottom = 60
    chart.scrollbarX.startGrip.disabled = true
    chart.scrollbarX.endGrip.disabled = true
    // chart.scrollbarX.start = 0;
    // chart.scrollbarX.end = num;
    // }
    chart.zoomOutButton.disabled = true
    chart.swipeable = true
    // // column template
    var columnTemplate = series.columns.template
    columnTemplate.column.cornerRadiusTopLeft = 8
    columnTemplate.column.cornerRadiusTopRight = 8
    columnTemplate.column.fillOpacity = 0.8
    columnTemplate.tooltipText = "drag me"
    columnTemplate.tooltipY = 0 // otherwise will point to middle of the column

    // // hover state
    var columnHoverState = columnTemplate.column.states.create("hover")
    columnHoverState.properties.fillOpacity = 1
    // you can change any property on hover state and it will be animated
    columnHoverState.properties.cornerRadiusTopLeft = 35
    columnHoverState.properties.cornerRadiusTopRight = 35

    // // show bullet when hovered
    columnTemplate.events.on("over", (event) => {
      var dataItem = event.target.dataItem
      var itemBullet = dataItem.bullets.getKey(bullet.uid)
      itemBullet.isHover = true
    })

    // hide bullet when mouse is out
    columnTemplate.events.on("out", (event) => {
      var dataItem = event.target.dataItem
      var itemBullet = dataItem.bullets.getKey(bullet.uid)
      itemBullet.isHover = false
    })

    // start dragging bullet even if we hit on column not just a bullet, this will make it more friendly for touch devices
    columnTemplate.events.on("down", (event) => {
      var dataItem = event.target.dataItem
      var itemBullet = dataItem.bullets.getKey(bullet.uid)
      itemBullet.dragStart(event.pointer)
    })

    // when columns position changes, adjust minX/maxX of bullets so that we could only dragg vertically
    columnTemplate.events.on("positionchanged", (event) => {
      var dataItem = event.target.dataItem
      var itemBullet = dataItem.bullets.getKey(bullet.uid)
      var column = dataItem.column
      itemBullet.minX = column.pixelX + column.pixelWidth / 2
      itemBullet.maxX = itemBullet.minX
      itemBullet.minY = 0
      itemBullet.maxY = chart.seriesContainer.pixelHeight
    })
    this.chart = chart
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose()
    }
  }

  render() {
    return (
      <div
        id='schedulerchartdiv'
        style={{ width: "100%", height: "350px" }}
      ></div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setChartData: (data) => dispatch(setChartData(data)),
  }
}

function mapStateToProps(state) {
  return {
    chartData: state.chartDataReducer.dragableChartData,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dragable2)
