import { useDispatch } from 'react-redux'
import LoadingBar from 'react-top-loading-bar'
import React, { useState, useRef } from 'react'
import { logout } from '../../../store/actions/auth'
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io'
import { NavLink, Link, withRouter, useRouteMatch } from 'react-router-dom'

import { ReactComponent as SMSIcon } from '../../Assets/icons/sms-icon.svg'
import { ReactComponent as SMSIconBlue } from '../../Assets/icons/sms-blue-icon.svg'

import { ReactComponent as HomeIcon } from '../../Assets/icons/home-icon.svg'
import { ReactComponent as HomeIconBlue } from '../../Assets/icons/home-blue-icon.svg'

import { ReactComponent as TrackIcon } from '../../Assets/icons/track-icon.svg'
import { ReactComponent as TrackIconBlue } from '../../Assets/icons/track-blue-icon.svg'

import { ReactComponent as VoiceIcon } from '../../Assets/icons/voice-icon.svg'
import { ReactComponent as VoiceIconBlue } from '../../Assets/icons/voice-blue-icon.svg'

import { ReactComponent as WarmupIcon } from '../../Assets/icons/warm-up-icon.svg'
import { ReactComponent as WarmupIconBlue } from '../../Assets/icons/warm-up-blue-icon.svg'

import { ReactComponent as ChatBotIcon } from '../../Assets/icons/chatbot-icon.svg'
import { ReactComponent as ChatBotIconBlue } from '../../Assets/icons/chatbot-blue-icon.svg'

import { ReactComponent as SupportIcon } from '../../Assets/icons/support-icon.svg'
import { ReactComponent as SupportIconBlue } from '../../Assets/icons/support-blue-icon.svg'

import { ReactComponent as UnlockedIcon } from '../../Assets/icons/unlocked-icon.svg'
import { ReactComponent as UnlockedIconBlue } from '../../Assets/icons/unlocked-blue-icon.svg'

import { ReactComponent as FavoriteIcon } from '../../Assets/icons/favorite-icon.svg'
import { ReactComponent as FavoriteIconBlue } from '../../Assets/icons/favorite-blue-icon.svg'

import { ReactComponent as SettingsIcon } from '../../Assets/icons/settings-icon.svg'
import { ReactComponent as SettingsIconBlue } from '../../Assets/icons/settings-blue-icon.svg'

import { ReactComponent as AffiliateIcon } from '../../Assets/icons/affiliate-icon.svg'
import { ReactComponent as AffiliateIconBlue } from '../../Assets/icons/affiliate-blue-icon.svg'

import { ReactComponent as DNSCheckerIcon } from '../../Assets/icons/dns-checker-icon.svg'
import { ReactComponent as DNSCheckerIconBlue } from '../../Assets/icons/dns-checker-blue-icon.svg'

import { ReactComponent as SpamCheckerIcon } from '../../Assets/icons/spam-checker-icon.svg'
import { ReactComponent as SpamCheckerIconBlue } from '../../Assets/icons/spam-checker-blue-icon.svg'

import { ReactComponent as EmailSequenceIcon } from '../../Assets/icons/email-sequence-icon.svg'
import { ReactComponent as EmailSequenceIconBlue } from '../../Assets/icons/email-sequence-blue-icon.svg'

import { ReactComponent as InboxingGraderIcon } from '../../Assets/icons/inboxing-grader-icon.svg'
import { ReactComponent as InboxingGraderIconBlue } from '../../Assets/icons/inboxing-grader-blue-icon.svg'

import { ReactComponent as SequencesDashboardIcon } from '../../Assets/icons/sequence-dashboard-icon.svg'
import { ReactComponent as SequencesDashboardIconBlue } from '../../Assets/icons/sequence-dashboard-blue-icon.svg'

const Sidebar = ({ history, setDisabledActions }) => {
	const ref = useRef(null)
	const dispatch = useDispatch()
	const { url } = useRouteMatch()

	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(false)
	const [collapse, setCollapse] = useState(false)

	const handleLogout = async event => {
		event.preventDefault()

		setLoading(true)
		ref.current.continuousStart()
		try {
			await dispatch(logout(history))
			history.push('/login')
		} catch (e) {
			setError(e)
		} finally {
			setLoading(false)
			if (ref.current) ref.current.complete()
		}
	}

	const active = () => {
		let a_link = document.querySelector('.nav-items')
		if (a_link === !null) a_link.classList.add('link-active')
	}

	return (
		<>
			<LoadingBar height={5} color='#5D95EA' ref={ref} />
			<aside
				id='sidebar-main'
				className='sidebar color-white d-flex flex-column justify-content-between'
			>
				<div className='sidebarbg'></div>
				<nav className='navbar sidebarnav navbar-expand-sm pt-2 px-0'>
					<ul className='menu w-100 list-unstyled'>
						<li className='nav-items'>
							<NavLink
								to='/dashboard'
								className='nav-link'
								activeClassName='link-active'
							>
								{url === '/dashboard' ? <HomeIconBlue /> : <HomeIcon />}
								<span
									className='sidebar-span'
									style={{ color: url === '/dashboard' ? '#0092fb' : '' }}
								>
									{' '}
									Dashboard
								</span>
							</NavLink>
						</li>
						<li className='nav-item'>
							<NavLink
								className='nav-link'
								to='/warm-up-emails'
								activeClassName='link-active'
							>
								{url.includes('warm-up-emails') ? (
									<WarmupIconBlue />
								) : (
									<WarmupIcon />
								)}
								<span
									className='sidebar-span'
									style={{
										color: url.includes('warm-up-emails') ? '#0092fb' : '',
									}}
								>
									{' '}
									Warm-up Emails
								</span>
							</NavLink>
						</li>

						<li className='nav-item'>
							<NavLink
								to='/dns-checker'
								data-tour='step-1'
								className='nav-link'
								activeClassName='link-active'
							>
								{url.includes('dns-checker') ? (
									<DNSCheckerIconBlue />
								) : (
									<DNSCheckerIcon />
								)}
								<span
									className='sidebar-span'
									style={{
										color: url.includes('dns-checker') ? '#0092fb' : '',
									}}
								>
									Email Blacklist & DNS Checker
								</span>
							</NavLink>
						</li>

						<li className='nav-item'>
							<NavLink
								data-tour='step-5'
								className='nav-link'
								to='/inbox-spam-checker'
								activeClassName='link-active'
							>
								{url.includes('inbox-spam-checker') ? (
									<SpamCheckerIconBlue />
								) : (
									<SpamCheckerIcon />
								)}
								<span
									className='sidebar-span'
									style={{
										color: url.includes('inbox-spam-checker') ? '#0092fb' : '',
									}}
								>
									Inbox Spam Checker
								</span>
							</NavLink>
						</li>

						<li className='nav-item'>
							<NavLink
								data-tour='step-11'
								className='nav-link'
								to='/email-inboxing-grader'
								activeClassName='link-active'
							>
								{url.includes('email-inboxing-grader') ? (
									<InboxingGraderIconBlue />
								) : (
									<InboxingGraderIcon />
								)}
								<span
									className='sidebar-span'
									style={{
										color: url.includes('email-inboxing-grader')
											? '#0092fb'
											: '',
									}}
								>
									{' '}
									Email Inboxing Grader
								</span>
							</NavLink>
						</li>
						<li className='nav-item'>
							<a
								className='nav-link'
								data-toggle='collapse'
								style={{ cursor: 'pointer' }}
								data-target='#collapseExample'
								onClick={() => setCollapse(!collapse)}
							>
								<img src='/assets/img/email-sequence.png' />
								<span className='sidebar-span'>
									Sequences{' '}
									{!collapse ? (
										<IoIosArrowDown
											className='float-right mt-1'
											style={{ pointerEvents: 'none' }}
										/>
									) : (
										<IoIosArrowUp
											className='float-right mt-1'
											style={{ pointerEvents: 'none' }}
										/>
									)}
								</span>
							</a>
						</li>
						<div id='collapseExample' className='collapse'>
							<ul className='menu w-100 list-unstyled'>
								<li className='nav-item'>
									<NavLink
										to='/sequences'
										className='nav-link'
										activeClassName={url === '/sequences' ? 'link-active' : ''}
									>
										{url === '/sequences' ? (
											<SequencesDashboardIconBlue
												style={{ marginLeft: '10px' }}
											/>
										) : (
											<SequencesDashboardIcon style={{ marginLeft: '10px' }} />
										)}
										<span
											className='sidebar-span'
											style={{
												color: url === '/sequences' ? '#0092fb' : '',
											}}
										>
											Dashboard
										</span>
									</NavLink>
								</li>
								<li className='nav-item'>
									<NavLink
										className='nav-link'
										to='/email-sequences'
										activeClassName={
											url.includes('email-sequences') ? 'link-active' : ''
										}
									>
										{url.includes('email-sequences') ? (
											<EmailSequenceIconBlue style={{ marginLeft: '10px' }} />
										) : (
											<EmailSequenceIcon style={{ marginLeft: '10px' }} />
										)}
										<span
											className='sidebar-span'
											style={{
												color: url.includes('email-sequences') ? '#0092fb' : '',
											}}
										>
											Email
										</span>
									</NavLink>
								</li>

								<li className='nav-item'>
									<NavLink
										className='nav-link'
										to='/unlocked-sequences'
										activeClassName={
											url.includes('unlocked-sequences') ? 'link-active' : ''
										}
									>
										{url.includes('unlocked-sequences') ? (
											<UnlockedIconBlue style={{ marginLeft: '16px' }} />
										) : (
											<UnlockedIcon style={{ marginLeft: '16px' }} />
										)}
										<span
											className='sidebar-span'
											style={{
												color: url.includes('unlocked-sequences')
													? '#0092fb'
													: '',
											}}
										>
											Unlocked
										</span>
									</NavLink>
								</li>

								<li className='nav-item'>
									<NavLink
										data-tour='step-15'
										className='nav-link'
										to='/track-competitor-sequences'
										activeClassName={
											url.includes('track-competitor-sequences')
												? 'link-active'
												: ''
										}
									>
										{url.includes('track-competitor-sequences') ? (
											<TrackIconBlue style={{ marginLeft: '10px' }} />
										) : (
											<TrackIcon style={{ marginLeft: '10px' }} />
										)}
										<span
											className='sidebar-span'
											style={{
												color: url.includes('track-competitor-sequences')
													? '#0092fb'
													: '',
											}}
										>
											Submit & Track Competitor
										</span>
									</NavLink>
								</li>

								<li className='nav-item'>
									<NavLink
										to='/fav-sequences'
										className='nav-link'
										activeClassName={
											url.includes('fav-sequences') ? 'link-active' : ''
										}
									>
										{url.includes('fav-sequences') ? (
											<FavoriteIconBlue style={{ marginLeft: '10px' }} />
										) : (
											<FavoriteIcon style={{ marginLeft: '10px' }} />
										)}
										<span
											className='sidebar-span'
											style={{
												color: url.includes('fav-sequences') ? '#0092fb' : '',
											}}
										>
											Favorite
										</span>
									</NavLink>
								</li>

								<li className='nav-item'>
									<NavLink
										to='/sms-sequences'
										className='nav-link'
										activeClassName={
											url.includes('sms-sequences') ? 'link-active' : ''
										}
									>
										{url.includes('sms-sequences') ? (
											<SMSIconBlue style={{ marginLeft: '10px' }} />
										) : (
											<SMSIcon style={{ marginLeft: '10px' }} />
										)}
										<span
											className='sidebar-span'
											style={{
												color: url.includes('sms-sequences') ? '#0092fb' : '',
											}}
										>
											<del>SMS</del>
										</span>
									</NavLink>
								</li>

								<li className='nav-item'>
									<NavLink
										className='nav-link'
										to='/chatbot-sequences'
										activeClassName={
											url.includes('chatbot-sequences') ? 'link-active' : ''
										}
									>
										{url.includes('chatbot-sequences') ? (
											<ChatBotIconBlue style={{ marginLeft: '10px' }} />
										) : (
											<ChatBotIcon style={{ marginLeft: '10px' }} />
										)}
										<span
											className='sidebar-span'
											style={{
												color: url.includes('chatbot-sequences')
													? '#0092fb'
													: '',
											}}
										>
											<del>Chat Bot</del>
										</span>
									</NavLink>
								</li>

								<li className='nav-item'>
									<NavLink
										className='nav-link'
										to='/voice-sequences'
										activeClassName={
											url.includes('voice-sequences') ? 'link-active' : ''
										}
									>
										{url.includes('voice-sequences') ? (
											<VoiceIconBlue style={{ marginLeft: '10px' }} />
										) : (
											<VoiceIcon style={{ marginLeft: '10px' }} />
										)}
										<span
											className='sidebar-span'
											style={{
												color: url.includes('voice-sequences') ? '#0092fb' : '',
											}}
										>
											<del>Voice</del>
										</span>
									</NavLink>
								</li>
							</ul>
						</div>
					</ul>
				</nav>

				<nav className='navbar sidebarnav navbar-expand-sm pt-2 bttom-sidebar mb-2 px-0'>
					<ul className='menu w-100 list-unstyled'>
						<li className='nav-item'>
							<NavLink
								to='/help'
								className='nav-link'
								activeClassName='link-active'
							>
								<i
									style={{
										fontSize: '22px',
										color: url.includes('help') ? '#0092fb' : '',
									}}
									className='fal fa-question ml-1'
								></i>
								<span
									className='sidebar-span'
									style={{
										color: url.includes('help') ? '#0092fb' : '',
									}}
								>
									FAQ
								</span>
							</NavLink>
						</li>
						<li className='nav-item'>
							<NavLink
								to='/support'
								className='nav-link'
								activeClassName='link-active'
							>
								{url.includes('support') ? (
									<SupportIconBlue />
								) : (
									<SupportIcon />
								)}
								<span
									className='sidebar-span'
									style={{
										color: url.includes('support') ? '#0092fb' : '',
									}}
								>
									Support
								</span>
							</NavLink>
						</li>

						<li className='nav-item'>
							<NavLink
								className='nav-link'
								to='/affilate-programe'
								activeClassName='link-active'
							>
								{url.includes('affilate-programe') ? (
									<AffiliateIconBlue />
								) : (
									<AffiliateIcon />
								)}
								<span
									className='sidebar-span'
									style={{
										color: url.includes('affilate-programe') ? '#0092fb' : '',
									}}
								>
									Affiliate Program
								</span>
							</NavLink>
						</li>

						<li className='nav-item'>
							<NavLink
								to='/settings'
								className='nav-link'
								activeClassName='link-active'
							>
								{url.includes('settings') ? (
									<SettingsIconBlue />
								) : (
									<SettingsIcon />
								)}
								<span
									className='sidebar-span'
									style={{
										color: url.includes('settings') ? '#0092fb' : '',
									}}
								>
									Settings
								</span>
							</NavLink>
						</li>
						<li className='nav-item'>
							<NavLink onClick={handleLogout} className='nav-link' to=''>
								<img
									width='20px'
									height='20px'
									alt='logout icon'
									style={{ marginLeft: '5px' }}
									src='/assets/img/logout-icon.png'
								/>
								<span className='sidebar-span'>Logout</span>
							</NavLink>
						</li>
					</ul>
				</nav>
			</aside>
		</>
	)
}

export default withRouter(Sidebar)
