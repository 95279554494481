import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import AuthService from "../../api-services/authService";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";
import { useParams, Link, Redirect } from "react-router-dom";
import ValidateEmail from '../../Helper/validator'

const ResetPasswordForm = ({ user, history, location }) => {
  const ref = useRef(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { signedEmail } = useParams();
  const [queryParms, setQueryParms] = useState({});

  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [emailErr, setEmailErr] = useState(true);


  const notify = () => toast("Password has been Reset.");

  useEffect(() => {
    let parsedQueryString = queryString.parse(location.search);
    setQueryParms(parsedQueryString);
    setEmail(parsedQueryString.email);
  }, []);

  const handleResetPasswordFormSubmit = async (event) => {
    event.preventDefault();
    setEmailErr(ValidateEmail(email))
    setLoading(true);
    ref.current.continuousStart();
    try {
      await AuthService.resetPassword({
        token: signedEmail,
        email: email,
        password: password,
        password_confirmation: confirmPassword,
      });
      notify();
      setTimeout(() => {
        history.push("/login");
      }, 3000);
    } catch (e) {
      if (e.response.status == 422) {
        const errors = e.response.data.errors;
        setErrors(errors);
      } else {
        setError(e);
      }
    } finally {
      setLoading(false);
      if (ref.current) ref.current.complete();
    }
  };

  return (
    <React.Fragment>
      <section className="login">
        <LoadingBar height={5} color="#5D95EA" ref={ref} />
        <div class="w-100">
          <div class="row mx-0">
            <div class="col-md-7 left-bg text-center">
              <div class="left-main mx-md-auto">
                <div class="logo-login">
                  <img src="/assets/img/logo.png" class="img-fluid" />
                </div>
                <div class="login-img">
                  <img src="/assets/img/sign-in.png" class="img-fluid" />
                </div>
                <p>
                  Use The "Perfect Inbox" A.i To Unlock Your Email
                  Deliverability, While Testing Your Email Creatives & Avoiding
                  The Spam Folder
                </p>
              </div>
            </div>

            <div class="col-md-5 login-riht">
              <div class="right-main">
                <h1> Reset Your Password </h1>
                <form onSubmit={handleResetPasswordFormSubmit}>
                  <div class="form-container">
                    <div class="mb-4">
                      <label for="Email Address" class="form-label">
                        Email Address *
                      </label>
                      <input
                        className="form-control"
                        type="email"
                        name="email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        placeholder="Email"
                        required
                        readOnly
                      />
                      <div className="text-danger mt-2 ml-1">{emailErr ? "" : "Please Enter Valid Email"}</div>
                    </div>

                    {errors.hasOwnProperty("email")
                      ? errors.email.map((e) => (
                          <span className="text-danger mt-2 ml-1"> {e} </span>
                        ))
                      : ""}

                    <div class="mb-4">
                      <label for="password" class="form-label">
                        Password *
                      </label>
                      <input
                        className="form-control"
                        type="password"
                        name="password"
                        onChange={(event) => setPassword(event.target.value)}
                        placeholder="Password"
                        // required
                      />
                      {errors.hasOwnProperty("password")
                        ? errors.password.map((e) => (
                            <span className="text-danger mt-2 ml-1">
                              {" "}
                              {e} <br />{" "}
                            </span>
                          ))
                        : ""}
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="password_confirmation"
                        className="form-label"
                      >
                        Confirm Password *
                      </label>
                      <input
                        className="form-control"
                        type="password"
                        name="password_confirmation"
                        onChange={(event) =>
                          setConfirmPassword(event.target.value)
                        }
                        placeholder="Confirm Password"
                        // required
                      />
                    </div>

                    <div className="col-md-12 pt-4">
                      <button
                        disabled={loading}
                        type="submit"
                        className="btn admin-button"
                      >
                        Reset Password
                      </button>
                    </div>

                    <div className="col-md-12 pt-4 inbox-better">
                      <p>
                        <Link to="/login">Login</Link>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    user: state.authReducer.user,
  };
}

export default connect(mapStateToProps, null)(ResetPasswordForm);
