import React from 'react'

const InputField = ({
	min,
	max,
	name,
	type,
	value,
	errors,
	disabled,
	required,
	on_change,
	label_name,
	error_name,
	class_name,
	default_value,
}) => {
	return (
		<>
			<div className={class_name === 'col' ? 'col' : 'col-sm-6'}>
				{' '}
				<label className='form-label'>
					{label_name} <span className='text-danger'>*</span>
				</label>
				<input
					max={max}
					min={min}
					type={type}
					name={name}
					disabled={disabled}
					required={required}
					onChange={on_change}
					className='form-control'
					// id='inputWithoutBackground'
					defaultValue={default_value}
				/>
				{errors.hasOwnProperty(name)
					? error_name.map(e => (
							<div className='text-danger ml-1'>
								{' '}
								<b> {e}</b>{' '}
							</div>
					  ))
					: ''}
			</div>
		</>
	)
}

export default InputField
