import React from 'react'
import moment from 'moment'
import Pagination from 'react-js-pagination'

const PayoutsTable = ({ payouts, metaData, handlePageChange, activePage }) => {
	return (
		<div className='d-flex flex-column flex-grow-1'>
			<div className='table-responsive'>
				<table className='table'>
					<thead>
						<tr>
							<th scope='col'>
								&nbsp;&nbsp;&nbsp;Invoice#
								<span className='sort-controls'>
									<img className='up' src='/assets/img/up-sort.png' />
									<img className='down' src='/assets/img/down-sort.png' />
								</span>
							</th>
							<th scope='col'>
								Amount
								<span className='sort-controls'>
									<img className='up' src='/assets/img/up-sort.png' />
									<img className='down' src='/assets/img/down-sort.png' />
								</span>
							</th>
							<th scope='col'>
								Created At
								<span className='sort-controls'>
									<img className='up' src='/assets/img/up-sort.png' />
									<img className='down' src='/assets/img/down-sort.png' />
								</span>
							</th>
						</tr>
					</thead>
					<tbody>
						{payouts
							.filter(payout => payout.amount !== 0)
							.map((payout, i) => (
								<tr>
									<td>INB-{payout.id}</td>
									<td>$ {payout.amount}</td>
									<td>{moment(payout.created_at).format('MMM D, YYYY')}</td>
								</tr>
							))}
					</tbody>
				</table>
			</div>
			<div className='align-self-end'>
				<Pagination
					itemClass='page-item'
					linkClass='page-link'
					pageRangeDisplayed={4}
					activePage={activePage}
					totalItemsCount={metaData.total}
					itemsCountPerPage={metaData.per_page}
					onChange={pageNumber => handlePageChange(pageNumber)}
				/>
			</div>
		</div>
	)
}

export default PayoutsTable
