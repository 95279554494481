import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  setStep,
  setShowNav,
  steps,
  ...rest
}) => {
  const isLoggedIn = useSelector(state => state.authReducer.isLoggedIn)

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthProtected && !isLoggedIn) {
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          )
        }
        return (
          <Layout>
            <Component {...props} setStep={setStep} setShowNav={setShowNav} />
          </Layout>
        )
      }}
    />
  )
}

AppRoute.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  // layout: PropTypes.any,
}

export default AppRoute
