import React from "react"
import { ReactComponent as UnlockedIconBlue } from "../../../../components/Assets/icons/unlocked-blue-icon.svg"
export const UnlockedHeader = () => {
  return (
    <div className='d-flex align-item-center justify-content-between'>
      <div className='col'>
        <div className='row'>
          <div className='col shld text-center pt-3'>
            <UnlockedIconBlue />
          </div>
          <div className='col'>
            <div className='mt-2 text-bold heading'>
              Unlocked Email Sequences &nbsp;
              <span>
                {/* <HelpComponent
            filePath={"/help-fragments/3-warmupHelp.html"}
          /> */}
              </span>
            </div>
            <p className='col sub-heading'>
              View your unlocked email sequences of subscribed brands
            </p>
          </div>
        </div>
      </div>
      <div
        className=' py-0 align-self-center text-right'
        style={{ minWidth: "120px", maxWidth: "110px" }}
      >
        <button
          role='button'
          aria-expanded='false'
          className='filter-btn'
          data-toggle='collapse'
          data-target='#multiCollapseExample1'
          aria-controls='multiCollapseExample1'
        >
          <i id='filter-icon' className='fas fa-filter mr-2' />
          Filter
        </button>
      </div>
    </div>
  )
}
