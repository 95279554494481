import { useState } from 'react'
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { setIsToggle } from '../../store/actions/help'
import TicketService from '../../api-services/ticketService'
import { toggleOffCanvasData } from '../../store/actions/layout'
import { listMessagesByTicket } from '../../store/actions/messages.action'

const TicketsTable = ({ tickets, fetchTickets }) => {
	const dispatch = useDispatch()

	let messageSection = document.getElementById('message-section')

	const [show, setShow] = useState(false)

	const handleOpen = ticketId => {
		if (show) {
			dispatch(setIsToggle(false))
		} else {
			dispatch(listMessagesByTicket(ticketId))
			dispatch(setIsToggle(true))
		}
	}

	const handleCloseTicket = async ticketId => {
		try {
			const response = await TicketService.closeTicket(ticketId)

			fetchTickets()
			messageSection.classList.remove('view-message-section')
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		if (show) {
			let open = document.querySelector('.email-view')
			open.classList.remove('close-view')
			open.classList.add('email-view-open')
		} else {
			let close = document.querySelector('.email-view')
			close.classList.add('close-view')
			close.classList.remove('email-view-open')
		}
	}, [show])

	return (
		<>
			<table className='table' id='dataTable' width='100%' cellSpacing='0'>
				<thead>
					<tr style={{ fontSize: '18px', lineHeight: '23px' }}>
						<th>
							<b>Title</b>
							<span className='sort-controls'>
								<img className='up' src='/assets/img/up-sort.png' />
								<img className='down' src='/assets/img/down-sort.png' />
							</span>
						</th>
						<th>
							<b>Description</b>
							<span className='sort-controls'>
								<img className='up' src='/assets/img/up-sort.png' />
								<img className='down' src='/assets/img/down-sort.png' />
							</span>
						</th>
						<th>
							<b>Status</b>
							<span className='sort-controls'>
								<img className='up' src='/assets/img/up-sort.png' />
								<img className='down' src='/assets/img/down-sort.png' />
							</span>
						</th>
						<th className='text-center'>
							<b>Action</b>
						</th>
					</tr>
				</thead>
				<tbody>
					{tickets.map((ticket, i) => (
						<tr key={i}>
							<td>{ticket.title}</td>
							<td
								dangerouslySetInnerHTML={{
									__html: ticket.description,
								}}
							></td>
							<td>{ticket.is_closed ? 'Closed' : 'Open'}</td>
							<td className='text-center'>
								<div className='dropdown'>
									<i
										aria-hidden='true'
										aria-haspopup='true'
										aria-expanded='false'
										data-toggle='dropdown'
										id='dropdownMenuButton'
										className='fa fa-ellipsis-v'
									></i>
									<div
										className='dropdown-menu'
										aria-labelledby='dropdownMenuButton'
									>
										<a
											style={{
												cursor: 'pointer',
											}}
											className='dropdown-item px-0'
											onClick={() => {
												localStorage.setItem(
													'currentTicket',
													JSON.stringify(ticket)
												)
												dispatch(toggleOffCanvasData('chat'))
												handleOpen(ticket.id)
											}}
										>
											<span
												color='#0994F8'
												className='d-flex align-items-center justify-content-center'
											>
												<i
													aria-hidden='true'
													className='fa fa-eye ml-3'
													style={{ color: '#4CAFFA' }}
												></i>
												<span
													style={{ color: '#4CAFFA' }}
													className='flex-fill text-center'
												>
													View
												</span>
											</span>
										</a>
										{ticket.is_closed ? (
											<></>
										) : (
											<a
												className='dropdown-item px-0'
												style={{
													cursor: 'pointer',
												}}
											>
												<span
													style={{ color: '#ef233c' }}
													onClick={() => {
														handleCloseTicket(ticket.id)
													}}
													className='d-flex align-items-center justify-content-center'
												>
													<i
														aria-hidden='true'
														className='fa fa-times ml-3'
														style={{ cursor: 'pointer', color: '#df2935' }}
													></i>
													<span
														className='flex-fill text-center'
														style={{ color: '#df2935' }}
													>
														Close
													</span>
												</span>
											</a>
										)}
									</div>
								</div>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</>
	)
}

const mapStateToProps = state => {
	return {}
}

export default connect(mapStateToProps, null)(TicketsTable)
