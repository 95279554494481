import './Dashboard.scss'
import { Modal } from 'bootstrap'
import { Link } from 'react-router-dom'
import LineChart from './Chart/LineChart'
import FadeLoader from 'react-spinners/FadeLoader'
import React, { useState, useEffect } from 'react'
import HelpComponent from '../../components/Help/Help'
import warmupService from '../../api-services/warmupService'
import { useTour } from '@reactour/tour'

function Dashboard() {
	const { setIsOpen, ...rest } = useTour()

	const [stats, setStats] = useState({})
	const [loading, setLoading] = useState(true)
	const [chartData, setChartData] = useState([])
	const [selectId, setSelectId] = useState(null)
	const [chartLoading, setChartLoading] = useState(true)
	const [activeCampaigns, setActiveCampaigns] = useState([])
	const [campaignLoading, setCampaignLoading] = useState(true)

	const ipBlockModalInstance = document.getElementById('ipBlockModal')

	async function fetchData() {
		setLoading(true)
		try {
			const response = await warmupService.getDashboardStats()
			setStats(response)
			setLoading(false)
		} catch (e) {
			setLoading(false)
		}
	}
	const getActiveCampaigns = async () => {
		setCampaignLoading(true)
		try {
			const response = await warmupService.getActiveCampaignList()
			setActiveCampaigns(response.activeWcCampaigns)
			setCampaignLoading(false)
		} catch (e) {
			setCampaignLoading(false)
		}
	}
	const handleSelect = e => {
		if (activeCampaigns.length !== 0)
			activeCampaigns.map(
				i => i.campaign_name === e.target.value && setSelectId(i.id)
			)
	}

	const getActiveCampaignsStats = async () => {
		setChartLoading(true)
		try {
			const response = await warmupService.getActiveCampaignStat(selectId)
			setChartData(response.activeWcCampaigns.warmup_campaign_schedules)
			setChartLoading(false)
		} catch (e) {
			setChartLoading(false)
		}
	}

	useEffect(() => {
		// var ipBlockModal = Modal.getOrCreateInstance(ipBlockModalInstance)
		setTimeout(() => {
			if (localStorage.getItem('tourComplete') === 'false') setIsOpen(true)
		}, 500)
		fetchData()
		getActiveCampaigns()

		// if (JSON.parse(localStorage.getItem('user')).is_secure) {
		// 	ipBlockModal.show()
		// }
	}, [])

	useEffect(() => {
		if (activeCampaigns.length !== 0) {
			setSelectId(activeCampaigns[0].id)
		}
	}, [activeCampaigns])

	useEffect(() => {
		if (selectId !== null) {
			getActiveCampaignsStats()
		}
	}, [selectId])

	return (
		<>
			<div className='container-fluid'>
				<div className='d-flex flex-lg-row flex-md-column flex-sm-column flex-column justify-content-around mt-lg-3 mt-md-3 mt-sm-3 mt-3'>
					<div
						id='plan-card'
						className='card d-flex align-items-center flex-fill flex-row pb-2 mr-lg-3 mb-lg-0 mb-md-2 mb-sm-2 mb-2'
					>
						<div className='dcard_icond ml-4' style={{ background: '#e6f8fc' }}>
							<img
								alt='icon'
								className='dcard_imgd'
								src='/assets/img/total_compaign.png'
							/>
						</div>
						<div className='card-body'>
							<h5
								style={{ fontSize: '30px' }}
								className='card-title font-weight-bold mb-0'
							>
								{loading
									? 0
									: stats === undefined
									? 0
									: stats.totalCampainsCount}
							</h5>
							<p className='card-text text-muted'>Total Campaigns / Inbox</p>
						</div>
					</div>
					<div
						id='plan-card'
						className='card d-flex align-items-center flex-fill flex-row pb-2 mr-lg-3 mb-lg-0 mb-md-2 mb-sm-2 mb-2'
					>
						<div className='dcard_icond ml-4' style={{ background: '#E2F4E5' }}>
							<img
								alt='icon'
								className='dcard_imgd'
								src='/assets/img/active_compaign.png'
							/>
						</div>
						<div className='card-body'>
							<h5
								style={{ fontSize: '30px' }}
								className='card-title font-weight-bold mb-0'
							>
								{loading
									? 0
									: stats === undefined
									? 0
									: stats.activeWcCampaignsCount}
							</h5>
							<p className='card-text text-muted'>Active Campaigns / Inbox</p>
						</div>
					</div>
					<div
						id='plan-card'
						className='card d-flex align-items-center flex-fill flex-row pb-2 '
					>
						<div className='dcard_icond ml-4' style={{ background: '#EFE2F4' }}>
							<img
								alt='icon'
								className='dcard_imgd'
								src='/assets/img/inactive_compaign.png'
							/>
						</div>
						<div className='card-body'>
							<h5
								style={{ fontSize: '30px' }}
								className='card-title font-weight-bold mb-0'
							>
								{loading
									? 0
									: stats === undefined
									? 0
									: stats.inactiveWcCampaignsCount}
							</h5>
							<p className='card-text text-muted'>Inactive Campaigns / Inbox</p>
						</div>
					</div>
				</div>
			</div>
			<div className='container-fluid'>
				{campaignLoading && loading ? (
					<div style={{ marginLeft: '47%', height: '100px', marginTop: '20%' }}>
						<FadeLoader
							width={5}
							height={20}
							color={'#0092FB'}
							loading={campaignLoading}
						/>
					</div>
				) : activeCampaigns.length !== 0 ? (
					<>
						<div
							id='plan-card'
							className='row mt-3 w-100 bg-white m-0 dashboard-dropdown-row'
						>
							<div>
								<select
									style={{
										cursor: 'pointer',
									}}
									onChange={handleSelect}
									className='form-control px-3 select'
								>
									{activeCampaigns.map((acc, i) => (
										<option key={i} id={acc.id}>
											{acc.campaign_name}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className='d-flex align-items-center justify-content-center flex-lg-row flex-md-column flex-sm-column flex-column mt-3'>
							{/* <div className='col-sm-6'> */}
							<div
								id='plan-card'
								className='card flex-fill align-self-md-stretch align-self-sm-stretch mr-lg-2 mb-md-0 mb-sm-2'
							>
								<h5 className='font-weight-bold ml-3 mt-3'>Total Inboxed</h5>
								{/* <div className="row px-4"> */}
								{/* <div className="col-md-6 float-left input_field">
                  <label>From</label>
                  <br />
                  <input type="date" placeholder="" />
                </div>
                <div className="col-md-6 float-left input_field">
                  <label>To</label>
                  <br />
                  <input type="date" placeholder="" />
                </div> */}

								{/* </div> */}
								{chartLoading ? (
									<div
										style={{
											height: '360px',
											marginTop: '20%',
											marginLeft: '47%',
										}}
									>
										<FadeLoader
											width={5}
											height={20}
											color={'#0092FB'}
											loading={chartLoading}
										/>
									</div>
								) : (
									<div className='col-md-12'>
										<LineChart id={'chart1'} chartData={chartData} />
									</div>
								)}
							</div>
							{/* </div>
							<div className='col-sm-6'> */}
							<div
								id='plan-card'
								className='card flex-fill align-self-md-stretch align-self-sm-stretch'
							>
								<h5 className='font-weight-bold ml-3 mt-3'>Total Spamed</h5>
								{/* <div className="row px-4">
                  <div className="col-md-6 float-left input_field">
                    <label>From</label>
                    <br />
                    <input type="date" placeholder="" />
                  </div>
                  <div className="col-md-6 float-left input_field ">
                    <label>To</label>
                    <br />
                    <input type="date" placeholder="" />
                  </div>
                </div> */}
								{chartLoading ? (
									<div
										style={{
											height: '360px',
											marginTop: '20%',
											marginLeft: '47%',
										}}
									>
										<FadeLoader
											width={5}
											height={20}
											color={'#0092FB'}
											loading={chartLoading}
										/>
									</div>
								) : (
									<div className='col-md-12'>
										<LineChart id={'chart2'} chartData={chartData} />
									</div>
								)}
							</div>
							{/* </div> */}
						</div>
					</>
				) : (
					<div className='text-center starter-inboxes mt-5'>
						<div className='dns_sbg'>
							<img src='/assets/img/inboxes.png' />
						</div>
						<p className='mt-4 sp' style={{ fontSize: '18px' }}>
							<b>You haven't add any warmup campaign</b>
						</p>
						<p className='sp mt-1' style={{ fontSize: '14px' }}>
							<span>
								Add your first inbox to start to warm-up your email account
								&nbsp;
								<span>
									<HelpComponent
										filePath={'/help-fragments/3-warmupHelp.html'}
									/>
								</span>
							</span>
						</p>
						<Link
							to={{
								pathname: `/warm-up-emails`,
							}}
						>
							<button
								className='btn c-btn mt-4'
								// data-toggle="modal"
								// data-target="#exampleModal"
							>
								Get Started
							</button>
						</Link>
					</div>
				)}
			</div>
			<div class='modal' id='ipBlockModal' tabindex='-1'>
				<div class='modal-dialog'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h5 class='modal-title text-danger'>Warning</h5>
						</div>
						<div class='modal-body'>
							<p>
								Warning you account is being accessed by multiple ip addresses.
								Kindly secure your account if this continues your account will
								be temporarily blocked until this issue is resolved.
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Dashboard
