import axios from "axios"

const FaqService = {
  list: (tag = 'all', page = 1) => {
    return axios.get(process.env.REACT_APP_INBOX_BETTER_API_URL + `/faqs/${tag}?page=${page}`)
      .then(({ data }) => {
        return data
      })
      .catch((err) => {
        throw err
      })
  },
}

export default FaqService
