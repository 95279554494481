import React from "react"
import Progressbar from "react-js-progressbar"
import getSliceColor from "./getColor"

const ProvidersCircularProgress = ({ name, percentage, detail }) => {
  return (
    <>
      <div
        className='col'
        style={{ filter: percentage === "block" ? `blur(${"5px"})` : "" }}
      >
        <div className='card overflow-hidden analyzer-card'>
          <div className='card-content text-center'>
            <div className='card-body cleartfix px-1 py-3'>
              <div className='media align-items-stretch d-flex justify-content-center'>
                <div className='align-self-center'>
                  <div>
                    <span style={{ fontSize: "0.8vw", fontWeight: "bold" }}>
                      {name}
                      <br />
                      <i
                        className='fas fa-info-circle'
                        data-toggle='modal'
                        data-target='#detailModal'
                        style={{ cursor: "pointer" }}
                      ></i>
                    </span>
                  </div>
                  <div className='mt-4 mb-4'>
                    <Progressbar
                      input={
                        percentage === "block"
                          ? Math.floor(Math.random() * (90 - 10) + 10)
                          : percentage
                      }
                      pathWidth={30}
                      pathShadow='none'
                      pathColor={getSliceColor(
                        percentage === "block"
                          ? Math.floor(Math.random() * (90 - 10) + 10)
                          : percentage
                      )} // use an array for gradient color.
                      trailWidth={50}
                      trailColor='#F2F2F2' // use a string for solid color.
                      animation={{ ease: "linear" }}
                      ease={"linear"}
                      size={70}
                      pathLinecap={"round"}
                      textStyle={{
                        fontWeight: "800",
                        fontSize: "44px",
                      }}
                    ></Progressbar>
                  </div>
                  <div>
                    {/* <button
                      className="view-detail-button"
                      data-toggle="modal"
                      data-target="#detailModal"
                      style={{ backgroundColor: getSliceColor(percentage) }}
                    >
                      View Detail
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className='modal fade'
        id='detailModal'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalCenterTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header-dns'>
              <h5 className='modal-title-dns' id='exampleModalLongTitle'>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <i
                  className='fa fa-question-circle'
                  aria-hidden='true'
                  style={{
                    color: "red",
                    fontSize: "20px",
                  }}
                ></i>
                &nbsp;&nbsp;&nbsp; Details
              </h5>
            </div>
            <div className='modal-body-dns'>
              <h6>{detail.message}</h6>
              <ul>
                {detail.spamWords === "block"
                  ? "Blocked"
                  : detail.spamWords.length == 0
                  ? ""
                  : detail.spamWords.map((item, i) => <li key={i}>{item}</li>)}
              </ul>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary modal-button bg-white border text-dark'
                data-dismiss='modal'
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProvidersCircularProgress
