import React from 'react'
import HelpComponent from '../../components/Help/Help'
import FadeLoader from 'react-spinners/FadeLoader'

const AffiliateDashboard = ({ loading, myAffiliates }) => {
	return (
		<>
			<div className='card'>
				<div className='card-body p-0'>
					<div className='d-flex align-items-center p-4'>
						<div className='shld col-sm-2 text-center p-1'>
							<img
								src='/assets/img/affiliate-dashboard-blue.png'
								alt='warm-up'
							/>
						</div>
						<div className='col-sm-7'>
							<div className='mt-2 text-bold heading'>
								Affiliate Dashboard &nbsp;
								<span>
									<HelpComponent
										filePath={'/help-fragments/3-warmupHelp.html'}
									/>
								</span>
							</div>
							<p className='sub-heading'>
								View your shareable affiliate joining link and corresponding
								insights
							</p>
						</div>
					</div>
					<div id='divider' className='mx-2' />
					{loading ? (
						<div className='text-center pb-5 my-5'>
							<FadeLoader
								loading={loading}
								color={'#0092FB'}
								height={20}
								width={5}
							/>
						</div>
					) : (
						<div className='row p-4'>
							<div className='col bg-white main-card mr-2'>
								<div className='row p-3'>
									<div
										className='secondary-card-icon-rate d-flex align-items-center justify-content-center'
										style={{ backgroundColor: '#E2F4E5' }}
									>
										<img src='/assets/img/refferals.png' alt=''></img>
									</div>
									<div className='col-md-10 col-sm-10 col-lg-8 col-xxl-8'>
										<h5 className='my-2'>
											<b>
												{myAffiliates.length == 0
													? '0'
													: myAffiliates.UserTotalAffiliates}
											</b>
										</h5>
										<p className='my-1 p-0 text-muted'>Referals</p>
									</div>
								</div>
							</div>
							<div className='col bg-white main-card mr-2'>
								<div className='row p-3'>
									<div
										className='secondary-card-icon-rate d-flex align-items-center justify-content-center'
										style={{ backgroundColor: '#FFEBEB' }}
									>
										<img src='/assets/img/customers.png' alt=''></img>
									</div>
									<div className='col-md-10 col-sm-10 col-lg-8 col-xxl-8 pr-0'>
										<h5 className='my-2'>
											<b>
												$
												{myAffiliates.length == 0
													? '0'
													: myAffiliates['UnpaidAmount']}
											</b>
										</h5>
										<p className='my-1 p-0 text-muted'>Remaining Payout</p>
									</div>
								</div>
							</div>
							<div className='col bg-white main-card mr-2'>
								<div className='row p-3'>
									<div
										className='secondary-card-icon-rate d-flex align-items-center justify-content-center'
										style={{ backgroundColor: '#F4F3E2' }}
									>
										<img src='/assets/img/clicks.png' alt=''></img>
									</div>
									<div className='col-md-10 col-sm-10 col-lg-8 col-xxl-8'>
										<h5 className='my-2'>
											<b>
												{myAffiliates.length == 0
													? '0'
													: myAffiliates['TotalClicks']}
											</b>
										</h5>
										<p className='my-1 p-0 text-muted'>Clicks</p>
									</div>
								</div>
							</div>
							<div className='col bg-white main-card mr-2'>
								<div className='row p-3'>
									<div
										className='secondary-card-icon-rate d-flex align-items-center justify-content-center'
										style={{ backgroundColor: '#EFE2F4' }}
									>
										<img src='/assets/img/earnings.png' alt=''></img>
									</div>
									<div className='col-md-10 col-sm-10 col-lg-8 col-xxl-8'>
										<h5 className='my-2'>
											<b>
												$
												{myAffiliates.length == 0
													? '0'
													: myAffiliates['TotalEarned']}
											</b>
										</h5>
										<p className='my-1 p-0 text-muted'>Earnings</p>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	)
}

export default AffiliateDashboard
