import API from "./api"

const TicketService = {
  list: (page = 1) => {
    return API.get(`/user/support-tickets?page=${page}`)
      .then(({ data }) => {
        return data
      })
      .catch((err) => {
        throw err
      })
  },
  create: (data) => {
    return API.post("/user/support-tickets", data)
      .then(({ data }) => {
        return data
      })
      .catch((err) => {
        throw err
      })
  },
  listMessagesByTicket: (id, pageNumber = 1) => {
    return API.get(`/user/support-tickets/${id}/messages`)
      .then(({ data }) => {
        return data
      })
      .catch((err) => {
        return err
      })
  },
  addMessageToTicket: (messageObject) => {
    return API.post(`/user/support-tickets/messages`, messageObject)
      .then(({ data }) => {
        return data
      })
      .catch((err) => {
        return err
      })
  },
  closeTicket: (ticketId) => {
    return API.get(`/user/support-tickets/${ticketId}/close`)
      .then(({ data }) => {
        return data
      })
      .catch((err) => {
        return err
      })
  },
}

export default TicketService
