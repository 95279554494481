import Cropper from 'react-easy-crop'
import { useDispatch } from 'react-redux'
import { LOGIN } from '../../store/types/index'
import React, { useState, useRef } from 'react'
import { generateDownload } from './ImageCropper/CropImage'
import warmupService from '../../api-services/warmupService'

function ImageCropperModal(props) {
	const { notify, setUserInfo, userInfo } = props

	const inputRef = useRef()
	const dispatch = useDispatch()

	const triggerFileSelectPopup = () => inputRef.current.click()

	const [zoom, setZoom] = useState(1)
	const [image, setImage] = useState(null)
	const [crop, setCrop] = useState({ x: 0, y: 0 })
	const [croppedArea, setCroppedArea] = useState(null)

	const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
		setCroppedArea(croppedAreaPixels)
	}

	const onSelectFile = event => {
		if (event.target.files && event.target.files.length > 0) {
			const reader = new FileReader()
			reader.readAsDataURL(event.target.files[0])
			reader.addEventListener('load', () => {
				setImage(reader.result)
			})
		}
	}

	const onSave = () => {
		generateDownload(image, croppedArea)
			.then(response => {
				setUserInfo({ ...userInfo, avatar: response })
				handleAvatarUpload(response)
			})
			.catch(err => {
				// console.log(err);
			})
	}

	const handleAvatarUpload = async res => {
		try {
			const response = await warmupService.avatarUpload({ avatar: res })
			localStorage.setItem('user', JSON.stringify(response.data))
			let tempObj = {
				user: response.data,
				token: localStorage.getItem('token'),
				expiresIn: localStorage.getItem('expiresIn'),
			}
			if (response.data) {
				notify(response)

				dispatch({ type: LOGIN, payload: tempObj })
			}

			try {
				const modal = document.getElementById('cropperModal')
				document.body.classList.remove('modal-open')
				document.body.removeAttribute('style')
				let header = document.getElementsByClassName('header')
				header[0].removeAttribute('style')
				modal.classList.remove('show')
				modal.setAttribute('aria-hidden', 'true')
				modal.style.display = 'none'
				modal.setAttribute('aria-modal', 'false')
				modal.setAttribute('data-bs-dismiss', 'modal')
				const modalBackdrops = document.getElementsByClassName('modal-backdrop')
				modalBackdrops[0].classList.remove('show')
				document.body.removeChild(modalBackdrops[0])
			} catch (e) {
				// console.log(e);
			}
		} catch (error) {
			if (error.response.status === 422) {
				notify(error.response.status)
			}
		}
	}

	const handleZoom = e => {
		setZoom(e.target.value)
	}
	return (
		<>
			<div
				className='modal fade add-inbox-modal'
				id='cropperModal'
				tabIndex='-1'
				aria-labelledby='exampleModalLabel'
			>
				<div
					className='modal-dialog modal-dialog-centered'
					style={{ maxWidth: '1230px' }}
				>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title' id='exampleModalLabel'>
								Image Cropper
								<img
									className='float-right'
									style={{ cursor: 'pointer' }}
									src='/assets/img/close-icon.svg'
									data-dismiss='modal'
									width='30px'
									height='30px'
								/>
							</h5>
						</div>
						<div className='modal-body' style={{ height: '810px' }}>
							<div className='image-container'>
								<div className='container-cropper'>
									{image ? (
										<>
											<div className='cropper'>
												<Cropper
													image={image}
													crop={crop}
													zoom={zoom}
													aspect={1}
													onCropChange={setCrop}
													onZoomChange={setZoom}
													onCropComplete={onCropComplete}
													cropShape='round'
												/>
											</div>

											<div className='slider'>
												<input
													type='range'
													min={1}
													max={3}
													step={0.1}
													value={zoom}
													onChange={handleZoom}
													className='cropper-zoom'
												/>
											</div>
										</>
									) : null}
								</div>

								<div className='container-buttons'>
									<input
										type='file'
										accept='image/*'
										ref={inputRef}
										onChange={onSelectFile}
										style={{ display: 'none' }}
									/>
									<button
										variant='contained'
										color='primary'
										onClick={triggerFileSelectPopup}
										style={{ marginRight: '10px' }}
										className='btn btn-secondary modal-button bg-white border text-dark'
									>
										Choose
									</button>
									<button
										variant='contained'
										color='secondary'
										onClick={onSave}
										className='btn btn-primary mr-3 modal-button '
									>
										Save
									</button>
								</div>
								<div></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default ImageCropperModal
