import TicketService from "../../api-services/ticketService"
import { API_CALL_NOT_IN_PROGRESS, SETMESSAGES } from "../types/index"

export const listMessagesByTicket = (params) => (dispatch) => {
  return TicketService.listMessagesByTicket(params)
    .then((data) => {
      dispatch({ type: API_CALL_NOT_IN_PROGRESS })
      dispatch({ type: SETMESSAGES, payload: data.supportTicketMessages })
    })
    .catch((err) => {
      dispatch({ type: API_CALL_NOT_IN_PROGRESS })
      throw err
    })
}