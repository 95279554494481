import React, { useEffect } from "react"
import { useState } from "react"
import PlanService from "../../api-services/plan.service"
import Subscription from "../Settings/Billing/Subscription"

const Plans = () => {
  const [plans, setPlans] = useState([])

  const fetchPlans = async () => {
    const response = await PlanService.list()

    setPlans(response.packageDetails)
  }

  useEffect(() => {
    fetchPlans()
  }, [])

  return (
    <>
      {plans.length !== 0 ? (
        <Subscription plans={plans} />
      ) : (
        <p>No Plans Found...</p>
      )}
    </>
  )
}

export default Plans
