import API from "./api"

const NotificationService = {
  list: () => {
    return API.get(`/global-notification?id=${JSON.parse(localStorage.getItem('user')).id}`)
      .then(({ data }) => {
        return data
      })
      .catch((err) => {
        throw err
      })
  },
  markAllAsRead: () => {
    return API.get(`/user-view-notification/${JSON.parse(localStorage.getItem('user')).id}`)
      .then(({ data }) => {
        return data
      })
      .catch((err) => {
        throw err
      })
  },
  checkSubscriptionStatus: () => {
    return API.get(`/stripe-grace-period`)
      .then(({ data }) => {
        return data
      })
      .catch((err) => {
        throw err
      })
  },
}

export default NotificationService