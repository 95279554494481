import React, { useState } from 'react'
import HelpComponent from '../../../components/Help/Help'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'

const PasswordChange = ({
	error,
	errors,
	userPassword,
	setUserPassword,
	handlePasswordChange,
}) => {
	const [showPass, setShowPass] = useState(false)
	const [showCurrentPass, setShowCurrentPass] = useState(false)
	const [showConfirmPass, setShowConfirmPass] = useState(false)

	const hanldeShowCurrentPass = () => {
		setShowCurrentPass(!showCurrentPass)
	}

	const hanldeShowPass = () => {
		setShowPass(!showPass)
	}

	const hanldeShowConfirmPass = () => {
		setShowConfirmPass(!showConfirmPass)
	}
	return (
		<>
			<div className='card' id='plan-card'>
				<div
					style={{ background: '#FFFFFF' }}
					className='card-header d-flex justify-content-between'
				>
					<h5 className='text-capitalize text-bold heading mb-0 align-self-center'>
						Change Password &nbsp;
						<span>
							<HelpComponent filePath={'/help-fragments/5-passwordHelp.html'} />
						</span>{' '}
					</h5>
					<button onClick={handlePasswordChange} className='btn save-btn'>
						Change
					</button>
				</div>
				<form className='card-body'>
					<div className='row p-5 pt-5'>
						<div className='col'>
							<div>
								<label htmlFor='currentPassword' className='form-label'>
									Current Password <span className='text-danger'>*</span>
								</label>
								<div className='input-group flex-nowrap'>
									<input
										required
										name='password'
										className='form-control'
										placeholder='Current Password'
										onChange={({ target }) =>
											setUserPassword({
												...userPassword,
												current_password: target.value,
											})
										}
										value={userPassword.current_password}
										type={showCurrentPass ? 'text' : 'password'}
									/>
									<span
										id='addon-wrapping'
										className='input-group-text'
										style={{ cursor: 'pointer' }}
										onClick={hanldeShowCurrentPass}
									>
										{showCurrentPass ? (
											<AiOutlineEye size={24} />
										) : (
											<AiOutlineEyeInvisible size={24} />
										)}
									</span>
								</div>
								{errors.hasOwnProperty('current_password')
									? errors.current_password.map(e => (
											<span className='text-danger mt-2 ml-1'>{e}</span>
									  ))
									: ''}
							</div>
						</div>
					</div>
					<div className='row pl-5 pr-5 pt-2'>
						<div className='col'>
							<div className='mb-4'>
								<label htmlFor='Email Address' className='form-label'>
									Password <span className='text-danger'>*</span>
								</label>
								<div className='input-group flex-nowrap'>
									<input
										required
										name='password'
										placeholder='Password'
										className='form-control'
										value={userPassword.password}
										type={showPass ? 'text' : 'password'}
										onChange={({ target }) =>
											setUserPassword({
												...userPassword,
												password: target.value,
											})
										}
									/>
									<span
										id='addon-wrapping'
										onClick={hanldeShowPass}
										className='input-group-text'
										style={{ cursor: 'pointer' }}
									>
										{showPass ? (
											<AiOutlineEye size={24} />
										) : (
											<AiOutlineEyeInvisible size={24} />
										)}
									</span>
								</div>
								{errors.hasOwnProperty('password')
									? errors.password.map(e => (
											<span className='text-danger mt-2 ml-1'>
												{e} <br />
											</span>
									  ))
									: ''}
							</div>
						</div>
						<div className='col'>
							<div className='mb-4'>
								<label htmlFor='confirmPassword' className='form-label'>
									Confirm Password <span className='text-danger'>*</span>
								</label>
								<div className='input-group flex-nowrap'>
									<input
										required
										className='form-control'
										name='password_confirmation'
										placeholder='Confirm Password'
										value={userPassword.password_confirmation}
										type={showConfirmPass ? 'text' : 'password'}
										onChange={({ target }) =>
											setUserPassword({
												...userPassword,
												password_confirmation: target.value,
											})
										}
									/>
									<span
										style={{
											border: 'none',
											cursor: 'pointer',
										}}
										id='addon-wrapping'
										className='input-group-text'
										onClick={hanldeShowConfirmPass}
									>
										{showConfirmPass ? (
											<AiOutlineEye size={24} />
										) : (
											<AiOutlineEyeInvisible size={24} />
										)}
									</span>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	)
}

export default PasswordChange
