import './support.scss'
import { useEffect } from 'react'
import React, { useState } from 'react'
import TicketsTable from './TicketsTable'
import SupportModal from './SupportModal'
import FadeLoader from 'react-spinners/FadeLoader'
import HelpComponent from '../../components/Help/Help'
import TicketService from '../../api-services/ticketService'
import Pagination from 'react-js-pagination'

const Support = () => {
	const [errors, setErrors] = useState([])
	const [tickets, setTickets] = useState([])
	const [loading, setLoading] = useState(true)
	const [metaData, setMetaData] = useState({})
	const [activePage, setActivePage] = useState(1)

	const fetchTickets = async () => {
		setLoading(true)
		try {
			let perPage = 0
			let totalPages = 0

			const response = await TicketService.list()

			setTickets(response.supportTickets.data)

			totalPages = response.supportTickets.total
			perPage = response.supportTickets.per_page
			setMetaData({ totalPages: totalPages, perPage: perPage })
		} catch (e) {
			console.log(e)
		} finally {
			setLoading(false)
		}
	}

	const handlePageChange = async pageNumber => {
		setLoading(true)

		let perPage = 0
		let totalPages = 0

		const response = await TicketService.list(pageNumber)

		setActivePage(pageNumber)
		setTickets(response.supportTickets.data)

		totalPages = response.supportTickets.total
		perPage = response.supportTickets.per_page
		setMetaData({ totalPages: totalPages, perPage: perPage })

		setLoading(false)
	}

	useEffect(() => {
		fetchTickets()
	}, [])

	return (
		<>
			<div className='col-lg-12 col-md-12 col-sm-12 mt-4 title-col'>
				<div className='card full-width' id='plan-card'>
					<div className='card-body'>
						<div className='d-flex align-items-center'>
							<div className='shld col text-center'>
								<img src='/assets/img/support-blue.png' alt='warm-up' />
							</div>
							<div className='col'>
								<div className='mt-2 text-bold heading'>
									Support &nbsp;
									<span>
										<HelpComponent
											filePath={'/help-fragments/3-warmupHelp.html'}
										/>
									</span>
								</div>
								<p className='sub-heading'>Always there for you</p>
							</div>
							<div className='col text-right'>
								<button
									type='button'
									className='btn btn-sm c-btn'
									style={{
										backgroundColor: '#0994F8',
										outline: 'none',
									}}
									data-toggle='modal'
									data-target='#supportModal'
								>
									<img
										src='/assets/img/plus-icon.svg'
										style={{
											marginRight: '13px',
											marginBottom: '2px',
											fontSize: '18px',
										}}
									/>
									Add Ticket
								</button>
							</div>
						</div>
						<div className='row mt-4'>
							<div className='col-12'>
								{loading ? (
									<div className='text-center pb-5 mb-5'>
										<FadeLoader
											width={5}
											height={20}
											color={'#0092FB'}
											loading={loading}
										/>
									</div>
								) : tickets.length === 0 ? (
									<p className='d-flex justify-content-center align-item-center font-weight-bold text-capitalize'>
										No Tickets found...
									</p>
								) : (
									<>
										<TicketsTable
											tickets={tickets}
											fetchTickets={fetchTickets}
										/>
										<div className='row float-right'>
											<div>
												<Pagination
													itemClass='page-item'
													linkClass='page-link'
													pageRangeDisplayed={4}
													activePage={activePage}
													itemsCountPerPage={metaData.perPage}
													totalItemsCount={metaData.totalPages}
													onChange={pageNumber => handlePageChange(pageNumber)}
												/>
											</div>
										</div>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<SupportModal setTickets={setTickets} />
		</>
	)
}

export default Support
