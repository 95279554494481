import './Stats.scss'
import moment from 'moment'
import Charts from './Charts'
import PieChart from './PieChart'
import Switch from 'react-switch'
import { Modal } from 'bootstrap'
import { toast } from 'react-toastify'
import Pagination from 'react-js-pagination'
import WarmupTimeline from '../Timeline/Timeline'
import React, { useEffect, useState } from 'react'
import FadeLoader from 'react-spinners/FadeLoader'
import { connect, useDispatch } from 'react-redux'
import { useParams, withRouter } from 'react-router-dom'
import NewInboxModal from '../NewInboxModal/NewInboxModal'
import warmupService from '../../../api-services/warmupService'
import { toggleSwitch } from '../../../store/actions/campaignData'
import { ReactComponent as EditIcon } from '../../../components/Assets/icons/edit-icon.svg'

const Stats = props => {
	const { isSwitchToggled } = props

	let { id } = useParams()
	const dispatch = useDispatch()

	const [pid, setPid] = useState()
	const [cData, setCData] = useState([])
	const [tData, setTData] = useState([])
	const [status, setStatus] = useState()
	const [isEdit, setIsEdit] = useState(false)
	const [replyRate, setReplyRate] = useState()
	const [loading, setLoading] = useState(false)
	const [activePage, setActivePage] = useState(1)
	const [inboxData, setinboxData] = useState(null)
	const [scheduleData, setScheduleData] = useState([])
	const [campaignData, setCampaignData] = useState({})
	const [editInboxForm, setEditInboxForm] = useState({})
	const [timelineFilter, setTimelineFilter] = useState({
		start_date: '',
		end_date: '',
	})
	const [isReschedule, setIsReschedule] = useState(false)
	const [tableLoading, setTableLoading] = useState(false)
	const [statusLoader, setStatusLoader] = useState(false)
	const [recentLoading, setRecentLoading] = useState(true)
	const [timelineEntries, setTimelineEntries] = useState([])
	const [recentActivities, setRecentActivities] = useState([])
	const [metaData, setMetaData] = useState({ per_page: 0, total: 0 })

	const modalInstanceEdit = document.getElementById('exampleModal')

	const notify = response => {
		response === 422
			? toast.error('Invalid or empty fields.')
			: response.success === true
			? toast.info(response.message)
			: toast.error(response.message)
	}

	const closeModal = () => {
		const modalInstanceEdit = document.getElementById('exampleModal')

		var myModal = Modal.getInstance(modalInstanceEdit)
		myModal.hide()
	}

	const openModal = () => {
		const modalInstanceEdit = document.getElementById('exampleModal')

		var myModal = Modal.getOrCreateInstance(modalInstanceEdit)
		myModal.show()
	}

	const handleRescheduleCampaign = () => {
		setIsReschedule(true)

		if (Object.keys(campaignData).length !== 0) {
			setEditInboxForm({
				api_key: '',
				end_date: campaignData.end_date,
				from_email: campaignData.from_email,
				start_date: campaignData.start_date,
				host:
					campaignData.email_account === null
						? null
						: campaignData.email_account.host,
				port:
					campaignData.email_account === null
						? null
						: campaignData.email_account.port,
				campaign_name: campaignData.campaign_name,
				username:
					campaignData.email_account === null
						? null
						: campaignData.email_account.username,
				password:
					campaignData.email_account === null
						? null
						: campaignData.email_account.password,
				encryption:
					campaignData.email_account === null
						? null
						: campaignData.email_account.encryption,
				reply_rate_per_day: campaignData.reply_rate_per_day,
				emails_sent_per_day: campaignData.emails_sent_per_day,
				email_account_provider_id:
					campaignData.email_account === null
						? null
						: campaignData.email_account.email_account_provider_id,
			})

			openModal()
		}
	}

	const handleEditCompaign = () => {
		setIsEdit(true)

		let tempInboxForm = {}

		if (Object.keys(campaignData).length !== 0) {
			setEditInboxForm(
				(tempInboxForm = {
					api_key: '',
					end_date: campaignData.end_date,
					from_email: campaignData.from_email,
					start_date: campaignData.start_date,
					host:
						campaignData.email_account === null
							? null
							: campaignData.email_account.host,
					port:
						campaignData.email_account === null
							? null
							: campaignData.email_account.port,
					campaign_name: campaignData.campaign_name,
					username:
						campaignData.email_account === null
							? null
							: campaignData.email_account.username,
					password:
						campaignData.email_account === null
							? null
							: campaignData.email_account.password,
					encryption:
						campaignData.email_account === null
							? null
							: campaignData.email_account.encryption,
					reply_rate_per_day: campaignData.reply_rate_per_day,
					emails_sent_per_day: campaignData.emails_sent_per_day,
					email_account_provider_id:
						campaignData.email_account === null
							? null
							: campaignData.email_account.email_account_provider_id,
				})
			)
			openModal(modalInstanceEdit)
		}
	}

	const fetchTimeline = async (start_date, end_date) => {
		let tempObj = {
			perPage: 0,
			totalPages: 0,
		}

		const entires = await warmupService.getWarmupTimeline(
			id,
			start_date,
			end_date
		)
		setTimelineEntries(entires.data.timelineEnteries.data)
		tempObj.perPage = entires.data.timelineEnteries.per_page
		tempObj.totalPages = entires.data.timelineEnteries.total
		setMetaData(tempObj)
	}

	async function fetchData() {
		setLoading(true)
		setTableLoading(true)
		let tempObj = {
			perPage: 0,
			totalPages: 0,
		}

		try {
			const response = await warmupService.getBarChartData(id)
			setReplyRate(response.reply_rate_per_day)
			setCData(response.warmup_campaign_schedules)
			handlePageChange()

			// get warmup timeline entries
			const entires = await warmupService.getWarmupTimeline(id)
			setTimelineEntries(entires.data.timelineEnteries.data)
			tempObj.perPage = entires.data.timelineEnteries.per_page
			tempObj.totalPages = entires.data.timelineEnteries.total
			setMetaData(tempObj)

			const activities = await warmupService.getRecentActivities(id)
			setRecentActivities(activities)
			setRecentLoading(false)

			const warmup = await warmupService.getWarmup(id)
			setinboxData(warmup.data)
			setStatus(warmup.data.is_active)

			setLoading(false)
			setTableLoading(false)
		} catch (e) {
			setLoading(false)
			setTableLoading(false)
		}

		try {
			setLoading(true)
			const response = await warmupService.getCampaignData(id)
			if (response.success) {
				setCampaignData(response.warmupEmailCampaign)
				setPid(
					response.warmupEmailCampaign.email_account.email_account_provider_id
				)
			} else {
				toast.error('Bad Request')
			}
			setLoading(false)
		} catch (e) {}
	}

	const handlePageChange = async (pageNumber = 1) => {
		setTableLoading(true)
		try {
			const response = await warmupService.getWarmupCampaigns(id, pageNumber)
			setTData(response.data)
			setScheduleData(response)
			setTableLoading(false)
		} catch (e) {
			setTableLoading(false)
		}
	}

	const handleTimelinePageChange = async pageNumber => {
		let tempObj = {
			perPage: 0,
			totalPages: 0,
		}

		setActivePage(pageNumber)

		const entires = await warmupService.getWarmupTimeline(
			id,
			timelineFilter.start_date,
			timelineFilter.end_date,
			pageNumber
		)
		setTimelineEntries(entires.data.timelineEnteries.data)
		tempObj.perPage = entires.data.timelineEnteries.per_page
		tempObj.totalPages = entires.data.timelineEnteries.total
		setMetaData(tempObj)
	}

	useEffect(() => {
		fetchData()
	}, [])

	// useEffect(() => {
	// 	if (props.location.state && props.location.state !== undefined) {
	// 		setinboxData(props.location.state.warmupAccountData)
	// 		setStatus(props.location.state.campaignStatus)
	// 	} else if (localStorage.getItem('warmupAccount')) {
	// 		JSON.parse(localStorage.getItem('warmupAccount')).map(acc => {
	// 			if (acc.id == id) {
	// 				setinboxData(acc)
	// 			}
	// 		})
	// 	}
	// }, [props, isEdit])

	useEffect(() => {
		status === 1 ? dispatch(toggleSwitch(true)) : dispatch(toggleSwitch(false))
	}, [status])

	const getPercentage = i => {
		let percentage = (i.total_sent / i.total_send) * 100

		return percentage
	}

	const deactivateSwitch = async () => {
		setStatusLoader(true)
		try {
			const response = await warmupService.deactivateCampaign(id)
			notify(response)
			setStatusLoader(false)
		} catch (e) {}
	}

	const activateSwitch = async () => {
		setStatusLoader(true)
		try {
			const response = await warmupService.activateCampaign(id)
			notify(response)
			setStatusLoader(false)
		} catch (e) {}
	}

	const handleToggle = () => {
		if (!isSwitchToggled) {
			activateSwitch()
			dispatch(toggleSwitch(true))
		} else {
			deactivateSwitch()
			dispatch(toggleSwitch(false))
		}
	}

	// const handleTimelineFilterChange = () => {
	// 	fetchTimeline(timelineFilter.start_date, timelineFilter.end_date)
	// }

	useEffect(() => {
		// if (timelineFilter.end_date !== '' && timelineFilter.start_date !== '')
		fetchTimeline(timelineFilter.start_date, timelineFilter.end_date)
	}, [timelineFilter])

	return (
		<>
			{inboxData ? (
				<>
					<div className='px-0'>
						<div className='right_header mt-1 d-flex flex-sm-column flex-md-column flex-lg-row align-items-lg-center justify-content-between'>
							<div className='mt-4'>
								{/* <div className="row"> */}
								<img
									className='left_arrow'
									style={{
										width: '15px',
										height: '15px',
										cursor: 'pointer',
										marginLeft: '30px',
										marginRight: '10px',
									}}
									src='/assets/img/caret-left-icon.svg'
									onClick={() => props.history.push(`/warm-up-emails`)}
								/>
								<nav
									aria-label='breadcrumb'
									style={{ marginLeft: '40px', marginTop: '-31px' }}
								>
									<ol className='breadcrumb' style={{ background: 'white' }}>
										<li className='breadcrumb-item'>
											<a href='/warm-up-emails' style={{ fontSize: '15px' }}>
												Home
											</a>
										</li>
										<li
											className='breadcrumb-item active'
											aria-current='page'
											style={{ fontSize: '15px', color: 'black' }}
										>
											<b>{inboxData.campaign_name}</b>&nbsp;
											<span>({inboxData.emailAccount.username})</span>
										</li>
									</ol>
								</nav>
								{/* </div> */}
							</div>

							<div className='d-flex justify-content-end align-items-center'>
								<div className='d-flex align-items-center justify-content-between'>
									<div className='col'>
										{campaignData.is_completed ? (
											<button
												style={{ borderRadius: '3px' }}
												className='header_btn_stat btn-sm'
												disabled={loading && recentLoading}
												onClick={() => handleRescheduleCampaign()}
											>
												<EditIcon className='mb-1 mr-1' />
												Reschedule warm-up
											</button>
										) : (
											<></>
										)}
										{campaignData.email_account === null ? (
											<></>
										) : (
											<button
												className='header_btn_stat btn-sm ml-1'
												onClick={() => {
													if (campaignData.email_account !== null)
														handleEditCompaign()
												}}
												disabled={
													loading ||
													recentLoading ||
													campaignData.email_account === null
												}
												style={{
													borderRadius: '3px',
													pointerEvents:
														campaignData.email_account === null
															? 'none'
															: 'initial',
												}}
											>
												<EditIcon className='mb-1 mr-1' />
												Edit warm-up
											</button>
										)}
									</div>
									<div className='d-flex flex-row justify-content-between align-items-center'>
										<div className='mr-3 mb-1'>Warm-up active</div>
										<div className='mr-3'>
											<Switch
												width={42}
												height={19}
												onColor='#2196F3'
												offColor='#CCCCCC'
												checkedIcon={false}
												uncheckedIcon={false}
												disabled={statusLoader}
												onChange={handleToggle}
												checked={isSwitchToggled}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='container-fluid'>
						<div className='d-flex flex-column flex-sm-column flex-md-row flex-lg-row mt-4 ml-2 mb-2'>
							<div
								id='plan-card'
								className='card d-flex align-items-center flex-fill flex-row pb-2 mr-lg-2 mr-md-2 mr-sm-0 mb-lg-0 mb-md-2 mb-sm-2 mb-2'
							>
								<div className='card_icon image ml-4'>
									<img
										className='card_img'
										src='/assets/img/stats-email-sent.png'
										alt='icon'
									/>
								</div>
								<div className='card-body'>
									<div className='d-flex flex-column align-items-start'>
										<div className='font-weight-bolder'>
											{inboxData.stats.emails_sent}
										</div>
										<div className=''>Emails Sent</div>
									</div>
								</div>
							</div>

							<div
								className='card d-flex align-items-center flex-fill flex-row pb-2 mr-lg-2 mr-md-2 mr-sm-0 mb-lg-0 mb-md-2 mb-sm-2 mb-2'
								id='plan-card'
							>
								<div className='d-flex card-body flex-shrink-1'>
									<div className='d-flex align-items-center'>
										<div className='card_icon image mr-4'>
											<img
												className='card_img'
												src='/assets/img/stats-interaction.png'
												alt='icon'
											/>
										</div>
										<div className=''>
											<div className='font-weight-bolder'>
												{inboxData.stats.interactions}
											</div>
											<div className=''>Interactions</div>
										</div>
									</div>
								</div>
							</div>

							<div
								className='card d-flex align-items-center flex-fill flex-row pb-2 mr-lg-2 mr-md-2 mr-sm-0 mb-lg-0 mb-md-2 mb-sm-2 mb-2'
								id='plan-card'
							>
								<div className='card-body'>
									<div className='d-flex align-items-center'>
										<div className='card_icon image mr-4'>
											<img
												className='card_img'
												src='/assets/img/stats-replies.png'
												alt='icon'
											/>
										</div>
										<div className=''>
											<div className='font-weight-bolder'>
												{inboxData.stats.total_replies}
											</div>
											<div className=''>Replies</div>
										</div>
									</div>
								</div>
							</div>

							<div
								className='card d-flex align-items-center flex-fill flex-row pb-2 mb-lg-0 mb-md-2 mb-sm-2 mb-2'
								id='plan-card'
							>
								<div className='card-body'>
									<div className='d-flex align-items-center'>
										<div className='card_icon image mr-4'>
											<img
												className='card_img'
												src='/assets/img/stats-spam-rate.png'
												alt='card-icon'
											/>
										</div>
										<div className=''>
											<div className='font-weight-bolder'>
												{inboxData.stats.spam_rate}%
											</div>
											<div className=''>Spam Rate</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className='d-flex flex-column flex-sm-column flex-md-column flex-lg-row ml-2 mb-2'>
							{/* <div className='col-lg-9 col-xxl-9 col-12 col-md-12 col-sm-12'> */}
							<div
								id='plan-card'
								className='d-flex flex-fill card chart mr-lg-2 mb-sm-2 mb-md-2 mb-lg-0 mb-2'
							>
								{cData.length === 0 ? (
									<div style={{ height: '400px' }}>
										<div className='text-center' style={{ marginTop: '150px' }}>
											<FadeLoader
												width={5}
												height={20}
												color={'#0092FB'}
												loading={loading}
											/>
										</div>
									</div>
								) : (
									<Charts data={cData} />
								)}
							</div>
							{/* </div> */}

							{/* <div className='right_bar_u col-md-12 col-sm-12 col-lg-3 col-xl-3'> */}
							<div id='plan-card' className='card pie-chart pt-3'>
								<h5 className='ml-2'>Inbox vs Spam</h5>
								<PieChart
									id={'stats'}
									height={'330px'}
									spam_rate={inboxData.stats.spam_rate}
									inbox_rate={inboxData.stats.inbox_rate}
								/>
							</div>
							{/* </div> */}
						</div>

						<div className='d-flex flex-column flex-sm-column flex-md-column flex-lg-row ml-2'>
							<div
								id='plan-card'
								className='d-flex flex-column flex-fill p-3 bg-white mr-md-0 mr-lg-2 mr-sm-0 mr-0 mb-2 mb-sm-2 mb-md-2 mb-lg-0'
							>
								{tableLoading ? (
									<div style={{ marginLeft: '50%', height: '100px' }}>
										<FadeLoader
											width={5}
											height={20}
											color={'#0092FB'}
											loading={tableLoading}
										/>
									</div>
								) : Object.keys(cData).length === 0 ? (
									<h5 className='ml-4 text-muted'>No Data Available</h5>
								) : (
									<table
										width='100%'
										id='dataTable'
										cellSpacing='0'
										className='table caption-top'
									>
										<caption className='pt-0' style={{ captionSide: 'top' }}>
											<h5 className='mb-0'>Custom Schedule</h5>
										</caption>
										<thead>
											<tr style={{ fontSize: '18px', lineHeight: '23px' }}>
												<th>Date</th>
												<th>Interactions</th>
												<th>Reply Percent</th>
												<th>Progress</th>
												<th>Sent</th>
											</tr>
										</thead>
										{tData.map((item, i) => (
											<tbody key={i}>
												<tr>
													<td>
														{item.formatted_date.replace(',', ' ') +
															', ' +
															item.date.slice(0, 4)}
													</td>
													<td>{item.total_interactions}</td>
													<td>{replyRate}%</td>
													<td>
														<div className='row'>
															<div
																className='progress d-flex'
																style={{
																	height: '5px',
																	width: '50px',
																	margin: '10px',
																	backgroundColor: '#D3D3D3',
																}}
															>
																<div
																	className='progress-bar'
																	role='progressbar'
																	style={{
																		width: getPercentage(item) + '%',
																		backgroundColor: '#05C9A7',
																	}}
																	aria-valuenow={getPercentage(item)}
																	aria-valuemin='0'
																	aria-valuemax='100'
																></div>
															</div>
															<div className='d-flex'>
																{getPercentage(item) === 100 ? (
																	<svg
																		width='16'
																		height='16'
																		fill='currentColor'
																		viewBox='0 0 16 16'
																		style={{
																			marginTop: '4px',
																			color: '#05C9A7',
																		}}
																		className='bi bi-check-circle'
																		xmlns='http://www.w3.org/2000/svg'
																	>
																		<path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' />
																		<path d='M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z' />
																	</svg>
																) : (
																	Math.round(getPercentage(item)) + '%'
																)}
															</div>
														</div>
													</td>
													<td>{item.total_sent}</td>
												</tr>
											</tbody>
										))}
									</table>
								)}
								<div className='d-flex justify-content-end mr-4'>
									<Pagination
										itemClass='page-item'
										linkClass='page-link'
										pageRangeDisplayed={4}
										activePage={scheduleData.current_page}
										itemsCountPerPage={scheduleData.per_page}
										totalItemsCount={
											scheduleData.length === 0 ? 0 : scheduleData.total
										}
										onChange={pageNumber => handlePageChange(pageNumber)}
									/>
								</div>
							</div>

							<div
								id='plan-card'
								className='bg-white mt-sm-2 mt-md-0 mt-lg-0 mt-2 d-flex flex-column py-3 px-2'
								// style={{ minWidth: '299.4px' }}
							>
								<h5 className='fw-bold mb-0'>Recent Activity</h5>
								<>
									<div
										style={{ height: '100%' }}
										className=' recent-activities mt-3 scroll-box d-flex align-items-center flex-column pr-5'
									>
										{recentLoading ? (
											<div>
												<FadeLoader
													width={5}
													height={20}
													color={'#0092FB'}
													loading={recentLoading}
												/>
											</div>
										) : recentActivities.length === 0 ? (
											<h5 className='text-muted mb-0 text-center'>
												No recent activity yet.
											</h5>
										) : (
											recentActivities.map((ra, i) => (
												<div key={i} className='d-flex flex-row mb-3 w-100'>
													{ra.is_sent === 1 ? (
														<div
															className='card_icon'
															style={{
																width: '40px',
																height: '40px',
																backgroundColor: '#0994F8',
															}}
														>
															<i
																className='fal fa-envelope'
																style={{ fontSize: '25px', color: 'white' }}
															></i>
														</div>
													) : (
														<div
															className='card_icon'
															style={{
																width: '40px',
																height: '40px',
																backgroundColor: '#886CFF',
															}}
														>
															<i
																className='far fa-reply'
																style={{ color: 'White', fontSize: '25px' }}
															></i>
														</div>
													)}
													<div key={ra.id} className='ml-2'>
														<h6 className='mb-0'>
															{ra.is_sent == 1 ? 'Email Sent' : 'Email Replied'}
														</h6>
														<span className='text-muted'>
															{/* {moment(ra.created_at).format('MMM DD, YYYY')}
															&nbsp;&nbsp;&nbsp;&nbsp; */}
															{moment(ra.created_at).fromNow()}
														</span>
													</div>
												</div>
											))
										)}
									</div>
									<div className='cover-bar'></div>
								</>
							</div>
						</div>
						<div className='d-flex flex-column flex-sm-column flex-md-column flex-lg-row ml-2 mb-2 mt-3'>
							<div id='plan-card' className='d-flex flex-fill card chart p-3'>
								<div className='d-flex flex-row'>
									<h5 className='mb-0 align-self-center'>Warm-up Timeline</h5>
									<div className='ml-4 align-self-center'>
										<label>Start Date: </label>
										<input
											type='date'
											name='start_date'
											className='form-control'
                      max={timelineFilter.end_date}
											onChange={e => {
												setTimelineFilter({
													...timelineFilter,
													start_date: e.target.value,
												})
												// handleTimelineFilterChange()
											}}
										/>
									</div>
									<div className='ml-2 align-self-center'>
										<label>End Date: </label>
										<input
											type='date'
											name='end_date'
                      min={timelineFilter.start_date}
											className='form-control'
											onChange={e => {
												setTimelineFilter({
													...timelineFilter,
													end_date: e.target.value,
												})
												// handleTimelineFilterChange()
											}}
										/>
									</div>
									<div className='d-flex align-items-center justify-content-end flex-grow-1'>
										<Pagination
											itemClass='page-item'
											linkClass='page-link'
											pageRangeDisplayed={4}
											activePage={activePage}
											onChange={pageNumber =>
												handleTimelinePageChange(pageNumber)
											}
											itemsCountPerPage={metaData.perPage}
											totalItemsCount={metaData.totalPages}
										/>
									</div>
								</div>
								<div className='mt-3 text-center'>
									{tableLoading ? (
										<div style={{ height: '100px' }}>
											<FadeLoader
												width={5}
												height={20}
												color={'#0092FB'}
												loading={recentLoading}
											/>
										</div>
									) : timelineEntries !== undefined ? (
										timelineEntries.length !== 0 ? (
											<WarmupTimeline entries={timelineEntries} />
										) : (
											<b>No Timeline yet</b>
										)
									) : (
										<b>No Timeline yet</b>
									)}
								</div>
							</div>
						</div>
					</div>
				</>
			) : (
				''
			)}
			{Object.keys(campaignData).length === 0 ? (
				''
			) : (
				<NewInboxModal
					id={id}
					isEdit={isEdit}
					fetchData={fetchData}
					closeModal={closeModal}
					isReschedule={isReschedule}
					campaignData={campaignData}
					editInboxForm={editInboxForm}
					modalInstanceAdd={modalInstanceEdit}
				/>
			)}
		</>
	)
}

function mapStateToProps(state) {
	return {
		isSwitchToggled: state.campaignStatusReducer.isSwitchToggled,
	}
}

export default connect(mapStateToProps, null)(withRouter(Stats))
