import React from "react"

export const NotFound = ({ dataNotFound }) => {
  console.log(dataNotFound)
  return (
    <div className='text-center starter-inboxes text-muted'>
      <p className='mt-4 sp' style={{ fontSize: "18px" }}>
        <b>{dataNotFound}</b>
      </p>
    </div>
  )
}
