import axios from "axios"

const noAuthServices = {
  getDNSReport: (id) => {
    return axios.post(`https://inboxbetter.comocrm.com/api/domain-health/report`, { id: id })
      .then(data => {
        return data
      })
      .catch(error => {
        throw error
      })
  }
}

export default noAuthServices