import "./Settings.scss"
import { toast } from "react-toastify"
import Invoices from "./Billing/Invoices"
import LoadingBar from "react-top-loading-bar"
import UserInfo from "./UserSettings/UserInfo"
import React, { useState, useRef } from "react"
import Subscription from "./Billing/Subscription"
import { useDispatch, connect } from "react-redux"
import ImageCropperModal from "./ImageCropperModal"
import authService from "../../api-services/authService"
import { updateProfile } from "../../store/actions/auth"
import PasswordChange from "./UserSettings/PasswordChange"

const Settings = ({ user }) => {
  const ref = useRef(null);
  const dispatch = useDispatch();

  const [userInfo, setUserInfo] = useState({
    name: "",
    avatar: "",
  });
  const [userPassword, setUserPassword] = useState({
    is_secure: 0,
    password: "",
    ipcount: null,
    ipaddress_2: null,
    ipaddress_1: null,
    current_password: "",
    // password_confirmation: "",
  });
  const [error, setError] = useState(null)
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [userActive, setUserActive] = useState(true)
  const [tabName, setTabName] = useState("userInformation")
  const [passwordActive, setPasswordActive] = useState(false)
  const [invoicesActive, setInvoicesActive] = useState(false)
  const [subscriptionActive, setSubscriptionActive] = useState(false)

  const passwordChangeNotify = () => toast("Password Changed.")
  const userInfoChangeNotify = () => toast("Display Name Changed.")

  const notify = (response) => {
    response === 422
      ? toast.error("Invalid or empty fields.")
      : response.success === true
        ? toast.info(response.message)
        : toast.error(response.message);
  };

  const handleUserInfoSave = async (event) => {
    event.preventDefault();
    setLoading(true);
    ref.current.continuousStart();
    try {
      // Update user info
      // await authService.updateProfile(userInfo);
      // dispatch Login action
      await dispatch(updateProfile(userInfo));
      userInfoChangeNotify();
      setUserInfo({ ...userActive, name: "" });
      setErrors({});
    } catch (e) {
      if (e.response.status == 422) {
        setErrors(e.response.data.errors);
      } else {
        setError(e);
      }
    } finally {
      setLoading(false);
      if (ref.current) ref.current.complete();
    }
  };

  const handlePasswordChange = async (event) => {
    event.preventDefault();
    setLoading(true);
    ref.current.continuousStart();
    try {
      // Update password
      await authService.updatePassword(userPassword);
      localStorage.setItem('warningCount', 1)
      passwordChangeNotify();
      setUserPassword({
        password: "",
        current_password: "",
        password_confirmation: "",
      });
      setErrors({});
    } catch (e) {
      if (e.response.status == 422) {
        setErrors(e.response.data.errors);
      } else {
        setError(e);
      }
    } finally {
      setLoading(false);
      if (ref.current) ref.current.complete();
    }
  };

  const handleTab = (e) => {
    if (e.target.id === "user_select") {
      setTabName("userInformation");
      setUserActive(true);

      setPasswordActive(false);
      setInvoicesActive(false);
      setSubscriptionActive(false);
    } else if (e.target.id === "password_select") {
      setTabName("userPassword");
      setPasswordActive(true);

      setUserActive(false);
      setInvoicesActive(false);
      setSubscriptionActive(false);
    } else if (e.target.id === "subscription") {
      setTabName("subscription")
      setSubscriptionActive(true)

      setUserActive(false)
      setPasswordActive(false)
      setInvoicesActive(false)
    } else {
      setTabName("invoices")
      setInvoicesActive(true)

      setUserActive(false)
      setPasswordActive(false)
      setSubscriptionActive(false)
    }
  };

  // useEffect(() => {
  //   dispatch(updateProfile({ ...userInfo, name: user.name }));
  // }, [userInfo]);  

  return (
    <>
      <LoadingBar height={5} color="#5D95EA" ref={ref} />
      <div className="col-lg-12 col-md-12 col-sm-12 mt-4 ml-2 title-col" style={{ height: '70%' }}>
        <div className="card" id='plan-card'>
          <div className="card-body p-0">
            <div className="row">
              <div className="col-sm-12">
                <div className="d-flex justify-content-between">
                  <div id="left-div" className="settings-card flex-fill" style={{ height: '100%' }}>
                    <div className="d-flex flex-column">
                      <h4 className="text-muted s-label">SETTINGS</h4>
                      <div
                        id="user_select"
                        onClick={handleTab}
                        className={userActive ? "s-item-active" : "s-item"}
                      >
                        <img
                          id="user_select"
                          onClick={handleTab}
                          src={
                            userActive
                              ? "/assets/img/user-info-blue.png"
                              : "/assets/img/info-icon.png"
                          }
                          className="info-icon"
                        />
                        <div onClick={handleTab} id="user_select">
                          <b id="user_select" onClick={handleTab}>
                            Information
                          </b>
                        </div>
                      </div>
                      <div
                        className={
                          passwordActive ? "s-item-active" : "s-item"
                        }
                        id="password_select"
                        onClick={handleTab}
                      >
                        <img
                          id="password_select"
                          onClick={handleTab}
                          src={
                            passwordActive
                              ? "/assets/img/user-password-blue.png"
                              : "/assets/img/password-icon.png"
                          }
                          className="pass-icon"
                        />
                        <div id="password_select" onClick={handleTab}>
                          <b id="password_select" onClick={handleTab}>
                            Password
                          </b>
                        </div>
                      </div>
                      {JSON.parse(localStorage.getItem('user')) !== null
                        ? JSON.parse(localStorage.getItem('user')).is_admin === true
                          ? <></>
                          : <>
                            <h4 className="text-muted s-label">BILLING</h4>
                            <div
                              className={
                                subscriptionActive ? "s-item-active" : "s-item"
                              }
                              onClick={handleTab}
                              id="subscription"
                            >
                              <img
                                id="subscription"
                                onClick={handleTab}
                                src={
                                  subscriptionActive
                                    ? "/assets/img/payment-sm-blue.png"
                                    : "/assets/img/payment-info.png"
                                }
                                className="info-icon"
                              />
                              <div onClick={handleTab} id="subscription">
                                <b id="subscription" onClick={handleTab}>
                                  Subscription
                                </b>
                              </div>
                            </div>
                            {JSON.parse(localStorage.getItem('user')).package === null
                              ? <></>
                              : JSON.parse(localStorage.getItem('user')).stripePayment === null
                                ? <></>
                                : JSON.parse(localStorage.getItem('user')).stripePayment.customer_id === null
                                  ? <></>
                                  : <div
                                    className={
                                      invoicesActive ? "s-item-active" : "s-item"
                                    }
                                    id="invoices"
                                    onClick={handleTab}
                                  >
                                    <img
                                      id="invoices"
                                      onClick={handleTab}
                                      src={
                                        invoicesActive
                                          ? "/assets/img/payout-sm-blue.png"
                                          : "/assets/img/payouts.png"
                                      }
                                      className="pass-icon"
                                    />
                                    <div id="invoices" onClick={handleTab}>
                                      <b id="invoices" onClick={handleTab}>
                                        Invoices
                                      </b>
                                    </div>
                                  </div>
                            }
                          </>
                        : <></>
                      }
                    </div>
                  </div>

                  <div className="col-sm-10 mt-4" style={{ height: '80vh' }}>
                    {(tabName === "userInformation" && (
                      <UserInfo
                        user={user}
                        error={error}
                        errors={errors}
                        userInfo={userInfo}
                        userActive={userActive}
                        setUserInfo={setUserInfo}
                        handleUserInfoSave={handleUserInfoSave}
                      />
                    )) ||
                      (tabName === "userPassword" && (
                        <PasswordChange
                          error={error}
                          errors={errors}
                          userPassword={userPassword}
                          setUserPassword={setUserPassword}
                          handlePasswordChange={handlePasswordChange}
                        />
                      )) ||
                      (tabName === "subscription" && <Subscription />) ||
                      (tabName === "invoices" && <Invoices />)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ImageCropperModal
        notify={notify}
        userInfo={userInfo}
        setUserInfo={setUserInfo}
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    user: state.authReducer.user,
  };
}

export default connect(mapStateToProps, null)(Settings);
