import React from "react"

const Table = ({ tableHeader, extractedData, className }) => {
  return (
    <div className='table-responsive'>
      <table width='100%' id='dataTable' cellSpacing='0' className='table'>
        <thead>
          <tr style={{ fontSize: "18px", lineHeight: "20px" }}>
            {tableHeader.map((data, index) => {
              return (
                <th
                  key={index}
                  style={{ textAlign: index === 0 ? "" : "center" }}
                >
                  <b>{data.th}</b>
                  {data.sort && (
                    <span className='sort-controls'>
                      <img className='up' src='/assets/img/up-sort.png' />
                      <img className='down' src='/assets/img/down-sort.png' />
                    </span>
                  )}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {extractedData.map((data, index) => {
            return (
              <tr key={index}>
                {data.map((cell, index) => {
                  return (
                    <td
                      key={index}
                      className={
                        index === 0
                          ? `${className} py-3`
                          : "inbox-item py-3 text-center"
                      }
                    >
                      {cell.cellData}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default Table
