import React from "react"

export const DeleteModal = ({
  modalCenterDlt,
  modalCenterTitle,
  confirmation,
  close,
  DeleteItem,
}) => {
  return (
    <div
      tabIndex='-1'
      role='dialog'
      aria-hidden='true'
      className='modal fade'
      id={modalCenterDlt}
      aria-labelledby={modalCenterTitle}
    >
      <div className='modal-dialog modal-dialog-centered' role='document'>
        <div className='modal-content'>
          <div className='modal-header-dns'>
            <h5 className='modal-title-dns' id='exampleModalLongTitle'>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <i
                className='fa fa-question-circle'
                aria-hidden='true'
                style={{
                  color: "red",
                  fontSize: "20px",
                }}
              ></i>
              &nbsp;&nbsp;&nbsp; Delete Campaign
            </h5>
          </div>
          <div className='modal-body-dns'>
            <h6>{confirmation}</h6>
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              onClick={close}
              className='btn btn-secondary modal-button bg-white border text-dark'
            >
              Close
            </button>
            <button
              type='button'
              className='btn btn-primary mr-3 modal-button '
              onClick={DeleteItem}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
