import { LOAD_FRAGMENT, TOGGLE_HELP_PANEL } from '../types/index';

const initialState = {
  helpFragment: '',
  isToggle: false
}

const helpReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case LOAD_FRAGMENT:
      return {
        ...state,
        helpFragment: payload
      }
    case TOGGLE_HELP_PANEL:
      return {
        ...state,
        isToggle: payload
      }
    default: {
      return state
    }
  }

}

export default helpReducer