import { PDFDocument, PDFCheckBox, PDFTextField } from 'pdf-lib'
import React, { useEffect, useState } from 'react'
import FadeLoader from 'react-spinners/FadeLoader'
import { toast } from 'react-toastify'
import affiliateService from '../../api-services/affiliateService'
import w9Form from '../../components/Assets/docs/w9form.pdf'

const W9Form = () => {
	const [formUri, setFormUri] = useState('')
	const [loading, setLoading] = useState(false)
	const [formInstance, setFormInstance] = useState('')
	const [formState, setFormState] = useState({
		SSN: '',
		EIN: '',
		name: '',
		location: '',
		businessName: '',
		legal_address: '',
		FATCAExemption: '',
		accountNumbers: '',
		exemptPayeeCode: '',
		requesterNameAndAddress: '',
		taxClassification: { classifier: [], description: '', llcClassifier: '' },
	})

	const handleSubmit = async e => {
		// const fileFormData = new FormData()

		// fileFormData.append('file', e.target.files[0])

		// // const response = await affiliateService.submitW9Form(fileFormData)

		// if (response.success) {
		// 	setLoading(false)

		// 	toast.success('Form Submitted!!')
		// 	// let tempObj = JSON.parse(localStorage.getItem('user'))

		// 	// tempObj.w9form = response.W9Form
		// 	// localStorage.setItem('user', JSON.stringify(tempObj))
		// } else {
		// 	setLoading(false)
		// 	toast.error('Something Went Wrong...')
		// }
		const reader = new FileReader()

		reader.readAsArrayBuffer(e.target.files[0])

		reader.onload = async function (e) {
			var arrayBuffer = reader.result
			const W9FormPDF = await PDFDocument.load(arrayBuffer)

			const pdfDataUri = await W9FormPDF.saveAsBase64({ dataUri: true })

			setFormUri(pdfDataUri)
			setFormInstance(W9FormPDF)

			const form = W9FormPDF.getForm()
			const fields = form.getFields()

			const tempFormState = {
				SSN: '',
				EIN: '',
				name: '',
				location: '',
				businessName: '',
				legal_address: '',
				llcClassifier: '',
				accountNumbers: '',
				FATCAExemption: '',
				exemptPayeeCode: '',
				taxClassification: {
					classifier: [],
					description: '',
				},
				requesterNameAndAddress: '',
			}

			fields.forEach(field => {
				var type = field.constructor.name
				const name = field.getName()
				// if () {
				//     type = 'PDFTextField'
				//   }
				// if () {
				// const checkBox = form.getCheckBox(name)
				//     type = 'PDFCheckBox'
				//   }

				if (field instanceof PDFTextField) {
					const textField = form.getTextField(name)
					const text = textField.getText()

					if (name === 'topmostSubform[0].Page1[0].f1_1[0]')
						tempFormState.name = text
					else if (name === 'topmostSubform[0].Page1[0].f1_2[0]')
						tempFormState.businessName = text
					else if (
						name ===
						'topmostSubform[0].Page1[0].FederalClassification[0].f1_3[0]'
					)
						tempFormState.taxClassification.llcClassifier = text
					else if (
						name ===
						'topmostSubform[0].Page1[0].FederalClassification[0].f1_4[0]'
					)
						tempFormState.taxClassification.description = text
					else if (name === 'topmostSubform[0].Page1[0].Exemptions[0].f1_5[0]')
						tempFormState.exemptPayeeCode = text
					else if (name === 'topmostSubform[0].Page1[0].Exemptions[0].f1_6[0]')
						tempFormState.FATCAExemption = text
					else if (name === 'topmostSubform[0].Page1[0].Address[0].f1_7[0]')
						tempFormState.legal_address = text
					else if (name === 'topmostSubform[0].Page1[0].Address[0].f1_8[0]')
						tempFormState.location = text
					else if (name === 'topmostSubform[0].Page1[0].f1_10[0]')
						tempFormState.accountNumbers = text.split(',')
					else if (name === 'topmostSubform[0].Page1[0].f1_9[0]')
						tempFormState.requesterNameAndAddress = text
					else if (name === 'topmostSubform[0].Page1[0].SSN[0].f1_11[0]')
						tempFormState.SSN = text
					else if (name === 'topmostSubform[0].Page1[0].SSN[0].f1_12[0]')
						tempFormState.SSN += text
					else if (name === 'topmostSubform[0].Page1[0].SSN[0].f1_13[0]')
						tempFormState.SSN += text
					else if (name === 'topmostSubform[0].Page1[0].EmployerID[0].f1_14[0]')
						tempFormState.EIN = text
					else if (name === 'topmostSubform[0].Page1[0].EmployerID[0].f1_15[0]')
						tempFormState.EIN += text
				} else if (field instanceof PDFCheckBox) {
					const checkBox = form.getCheckBox(name)

					if (
						name ===
							'topmostSubform[0].Page1[0].FederalClassification[0].c1_1[0]' &&
						checkBox.isChecked()
					) {
						let tempArr = [...formState.taxClassification.classifier]
						tempArr.push('Individual/Sole Proprietor')
						tempFormState.taxClassification.classifier = tempArr
					} else if (
						name ===
							'topmostSubform[0].Page1[0].FederalClassification[0].c1_1[1]' &&
						checkBox.isChecked()
					) {
						let tempArr = [...formState.taxClassification.classifier]
						tempArr.push('C Corporation')
						tempFormState.taxClassification.classifier = tempArr
					} else if (
						name ===
							'topmostSubform[0].Page1[0].FederalClassification[0].c1_1[2]' &&
						checkBox.isChecked()
					) {
						let tempArr = [...formState.taxClassification.classifier]
						tempArr.push('S Corporation')
						tempFormState.taxClassification.classifier = tempArr
					} else if (
						name ===
							'topmostSubform[0].Page1[0].FederalClassification[0].c1_1[3]' &&
						checkBox.isChecked()
					) {
						let tempArr = [...formState.taxClassification.classifier]
						tempArr.push('Partnership')
						tempFormState.taxClassification.classifier = tempArr
					} else if (
						name ===
							'topmostSubform[0].Page1[0].FederalClassification[0].c1_1[4]' &&
						checkBox.isChecked()
					) {
						let tempArr = [...formState.taxClassification.classifier]
						tempArr.push('Trust/estate')
						tempFormState.taxClassification.classifier = tempArr
					} else if (
						name ===
							'topmostSubform[0].Page1[0].FederalClassification[0].c1_1[5]' &&
						checkBox.isChecked()
					) {
						let tempArr = [...formState.taxClassification.classifier]
						tempArr.push('LLC')

						tempFormState.taxClassification.classifier = tempArr
					} else if (
						name ===
							'topmostSubform[0].Page1[0].FederalClassification[0].c1_1[6]' &&
						checkBox.isChecked()
					) {
						let tempArr = [...formState.taxClassification.classifier]
						tempArr.push('Others')

						tempFormState.taxClassification.classifier = tempArr
					}
				}
			})

			setFormState(tempFormState)
		}
	}

	const submitForm = async () => {
		setLoading(true)
		let tempFormStateObj = { ...formState }

		tempFormStateObj.taxClassification = JSON.stringify(
			tempFormStateObj.taxClassification
		)

		let tempReqObj = {
			name: JSON.parse(localStorage.getItem('user')).name,
			user_id: JSON.parse(localStorage.getItem('user')).id,
			...tempFormStateObj,
		}

		const response = await affiliateService.submitW9Form(tempReqObj)

		if (response.success) {
			setLoading(false)

			toast.success('Form Submitted!!')
			let tempObj = JSON.parse(localStorage.getItem('user'))

			tempObj.w9form = response.W9Form
			localStorage.setItem('user', JSON.stringify(tempObj))
		} else {
			setLoading(false)
			toast.error('Something Went Wrong...')
		}
	}

	useEffect(() => {
		if (formState.name !== '') submitForm()
	}, [formState])

	return (
		<>
			<div className='card'>
				<div className='card-body p-0'>
					<div className='d-flex align-items-center p-4'>
						<div className='shld col-sm-2 text-center p-1'>
							<img
								src='/assets/img/affiliate-dashboard-blue.png'
								alt='warm-up'
							/>
						</div>
						<div className='col-sm-7'>
							<div className='mt-2 text-bold heading'>W9 Form &nbsp;</div>
							<p className='sub-heading'>
								Fill out your w9 Form if you haven't already
							</p>
						</div>
					</div>
					<div id='divider' className='mx-2' />
					<div
						style={{ height: '65vh' }}
						className='row p-4 d-flex align-items-center justify-content-center'
					>
						{loading ? (
							<div className='text-center pb-5 my-5'>
								<FadeLoader
									width={5}
									height={20}
									loading={loading}
									color={'#0092FB'}
								/>
							</div>
						) : JSON.parse(localStorage.getItem('user')).w9form === null &&
						  formUri === '' ? (
							<div className='d-flex align-items-center justify-content-center flex-column'>
								<label
									htmlFor='w9-form-select'
									style={{ cursor: 'pointer' }}
									className='btn filter-btn mt-1'
								>
									<i className='far fa-file-pdf mr-2'></i>Select your W9 Form{' '}
									<input
										type='file'
										accept='.pdf'
										id='w9-form-select'
										onChange={handleSubmit}
										style={{ display: 'none' }}
									/>
								</label>
								<h4>OR</h4>
								<a
									download
									href={w9Form}
									id='download-w9-form'
									className='btn filter-btn mt-1'
								>
									<i className='fas fa-arrow-alt-down mr-2'></i>Download W9 Form{' '}
								</a>
							</div>
						) : formUri !== '' ? (
							<div
								className='d-flex flex-column justify-content-center'
								style={{ width: '100%', height: '100%' }}
							>
								<div className='flex-grow-1 flex-fill'>
									<object
										id='pdf'
										data={formUri}
										type='application/pdf'
										style={{ width: '100%', height: '100%' }}
									>
										<div>No online PDF viewer installed</div>
									</object>
								</div>
							</div>
						) : (
							<div className='d-flex align-items-center justify-content-center flex-column'>
								<b>Form Already Submitted!!!</b>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	)
}

export default W9Form
