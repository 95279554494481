import axios from "axios";
import API from "./api";

const analyzerService = {
  addAnalyzer: (data) => {
    return API.post("/potentialinboxrequest", data)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  getAnalyzerReports: (url) => {
    return API.get(url)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  deleteAnalyzerTest: (id) => {
    return API.delete(`/delete_potentialinboxing_request/${id}`)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  analyzeEmail: (emailContent) => {
    // console.log(emailContent)
    return API.get(`/get-report-sequence/${emailContent.id}`
      // , {
      //   emailSequence: emailContent.email_type.name,
      //   ecommerceNiche: "Ecommerce",
      //   fromEmail: "none",
      //   subjectLine: emailContent.email_content.subject,
      //   html: emailContent.email_content.body,
      //   yourEmail: "ai@emailsequence.io"
      // }
    )
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  }
};

export default analyzerService;
