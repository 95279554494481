import React from "react"
import "./owlCarousel.scss"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri"

const Carousel = ({
  data,
  usageFlag,
  selectedCategory,
  handleCategorySelection,
}) => {
  const settings = {
    arrows: true,
    slidesToShow: 8,
    slidesToScroll: 4,
    variableWidth: true,
    className: "slides",
    nextArrow: <RiArrowRightSLine color='#0994F8' size='1.5em' />,
    prevArrow: <RiArrowLeftSLine color='#0994F8' size='1.5em' />,
  }

  return (
    <div>
      {data !== undefined ? (
        data.length === 0 ? (
          <p>Loading</p>
        ) : (
          <Slider {...settings}>
            {usageFlag === "categories" ? (
              data.map((singleData, i) => (
                <div
                  key={i}
                  id='single-category-div'
                  className={
                    selectedCategory.value === singleData.value
                      ? "item-selected"
                      : ""
                  }
                  onClick={() => {
                    handleCategorySelection({
                      value: singleData.value,
                      label: singleData.label,
                    })
                  }}
                >
                  <span
                    style={{
                      border: "none",
                      fontWeight: "700",
                      paddingLeft: "34px",
                      paddingRight: "34px",
                    }}
                    className='d-flex align-items-center'
                  >
                    {singleData.label}
                  </span>
                </div>
              ))
            ) : (
              <>
                <div className='item' style={{ width: "50px" }}>
                  <img src='/assets/img/ftr-gmail.png' alt='logo' />
                </div>
                <div className='item' style={{ width: "55px" }}>
                  <img src='/assets/img/ftr-outlook.png' alt='logo' />
                </div>
                <div className='item' style={{ width: "50px" }}>
                  <img src='/assets/img/ftr-yahoo.png' alt='logo' />
                </div>
                <div className='item' style={{ width: "100px" }}>
                  <img
                    src='/assets/img/ftr-icloud.png'
                    alt='logo'
                    style={{ marginTop: "-5px" }}
                  />
                </div>
                <div className='item' style={{ width: "80px" }}>
                  <img
                    src='/assets/img/ftr-aol.png'
                    alt='logo'
                    style={{ marginTop: "10px" }}
                  />
                </div>
                <div className='item' style={{ width: "120px" }}>
                  <img
                    src='/assets/img/mail-logo.png'
                    alt='logo'
                    height='100px'
                    style={{ marginTop: "-20px" }}
                  />
                </div>
              </>
            )}
          </Slider>
        )
      ) : (
        <></>
      )}
    </div>
  )
}

export default Carousel
