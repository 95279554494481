import React, { useState } from 'react'
import { toast } from 'react-toastify'
import SequencesService from '../../../api-services/sequencesService'
import { ReactComponent as TrackIconBlue } from '../../../components/Assets/icons/track-blue-icon.svg'

const AddCompetitorModal = ({ fetchTrackedCompetitors, closeModal }) => {
	const [loading, setLoading] = useState(false)
	const [competitorData, setCompetitorData] = useState({ name: '', url: '' })

	const handleOnChange = e => {
		const { name, value } = e.target

		setCompetitorData({ ...competitorData, [name]: value })
	}

	const addCompetitor = async () => {
		setLoading(true)
		let perPage = 0
		let totalPages = 0

		const response = await SequencesService.addCompetitors(
			competitorData.name,
			competitorData.url
		)

		if (response !== undefined) {
			if (response.success) {
				toast.success('Competitor Added', {
					autoClose: 2500,
					closeOnClick: true,
					position: 'top-right',
					hideProgressBar: false,
				})
				closeModal()
				fetchTrackedCompetitors()
			}
		} else {
			toast.error(response.message, {
				autoClose: 2500,
				closeOnClick: true,
				position: 'top-right',
				hideProgressBar: false,
			})
		}
		setLoading(false)
	}

	return (
		<div
			tabIndex='-1'
			role='dialog'
			aria-hidden='true'
			className='modal fade'
			id='addCompetitorModal'
			aria-labelledby='addCompetitorModal'
		>
			<div
				role='document'
				style={{ maxWidth: '818px' }}
				className='modal-dialog modal-dialog-centered'
			>
				<div className='modal-content'>
					<div className='modal-header-dns'>
						<h5 className='modal-title-dns' id='exampleModalLongTitle'>
							&nbsp;&nbsp;&nbsp;&nbsp;
							<TrackIconBlue />
							&nbsp;&nbsp;&nbsp; Submit Competitor
						</h5>
						<button
							type='button'
							aria-label='Close'
							onClick={closeModal}
							className='modal-close'
							style={{ outline: 'none' }}
						>
							<img src='/assets/img/cross.png' alt='' />
						</button>
					</div>
					<div className='modal-body-dns'>
						{/* <h6>
							Send an email to the address below, from the email you want to
							test &nbsp;
							<span>
								<HelpComponent
									filePath={'/help-fragments/1-dns-checker.html'}
								/>
							</span>
						</h6> */}
						<div className='mb-3' data-tour='step-17'>
							<h6>
								<b>Competitor Name</b>
							</h6>
							<input
								name='name'
								type='text'
								onChange={handleOnChange}
								value={competitorData.name}
								className='form-control border'
							/>
						</div>
						<div data-tour='step-18'>
							<h6>
								<b>Competitor URL</b>
							</h6>
							<input
								name='url'
								type='text'
								onChange={handleOnChange}
								value={competitorData.url}
								className='form-control border'
							/>
						</div>
					</div>
					<div className='modal-footer'>
						<button
							type='button'
							onClick={closeModal}
							className='search-clear-btn'
						>
							Close
						</button>
						<button
							type='button'
							data-tour='step-19'
							onClick={addCompetitor}
							disabled={
								loading ||
								competitorData.url === '' ||
								competitorData.name === ''
							}
							className='filter-btn'
						>
							Add
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AddCompetitorModal
