import React from "react"
import HelpComponent from "../components/Help/Help"

export const GetStarted = ({ dataTarget, title, addFirst, helpPath }) => {
  return (
    <div className='text-center starter-inboxes'>
      <div className='dns_sbg'>
        <img src='/assets/img/inboxes.png' />
      </div>
      <p className='mt-4 sp' style={{ fontSize: "18px" }}>
        <b>{title}</b>
      </p>
      <p className='sp mt-1' style={{ fontSize: "14px" }}>
        <span>
          {addFirst}
          <span>
            <HelpComponent filePath={`/help-fragments/${helpPath}`} />
          </span>
        </span>
      </p>
      <button
        data-toggle='modal'
        className='btn c-btn mt-4'
        data-target={dataTarget}
      >
        Get Started
      </button>
    </div>
  )
}
