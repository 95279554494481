import moment from "moment"
import { toast } from "react-toastify"
import { Link } from "react-router-dom"
import "../../components/Filter/filter.scss"
import LoadingBar from "react-top-loading-bar"
import FadeLoader from "react-spinners/FadeLoader"
import React, { useEffect, useState, useRef } from "react"
import SequencesService from "../../api-services/sequencesService"

const Sequences = () => {
  const ref = useRef(null)

  const [updates, setUpdates] = useState([])
  const [showdata, setShowdata] = useState([])
  const [loading, setLoading] = useState(true)
  const [tabtoggle, setTabtoggle] = useState(false)
  const [notification, setNotification] = useState([])
  const [recentupdate, setRecentupdate] = useState([])
  const [recentSequences, setRecentSequences] = useState([])
  const [dailyUnlockedCount, setDailyUnlockedCount] = useState([])

  const fetch = async () => {
    try {
      const statsResponse = await SequencesService.sequencesDashboardStats()
      const recentlyUnlockedResponse =
        await SequencesService.recentlyUnlockedSequences()

      if (statsResponse.success) {
        setShowdata(statsResponse.data)
        setUpdates(statsResponse.data.email.updates)
        setRecentSequences(statsResponse.sequence)
      }

      if (recentlyUnlockedResponse.data.success) {
        setDailyUnlockedCount(recentlyUnlockedResponse.data.sequence)
      }
    } catch (error) {
      console.log(error)
    }

    setLoading(false)
  }

  const unlockSequence = async (id) => {
    try {
      const response = await SequencesService.unlockSequence(id)

      if (response.success) {
        toast.success(response.message, {
          autoClose: 2500,
          closeOnClick: true,
          position: "top-right",
        })
        fetch()
      } else if (!response.success) {
        toast.error(response.message, {
          autoClose: 2500,
          closeOnClick: true,
          position: "top-right",
        })
        fetch()
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetch()
  }, [])

  useEffect(() => {
    setNotification(updates.filter((item) => item.is_notification))
    setRecentupdate(updates.filter((item) => !item.is_notification))
    setTabtoggle(
      updates.filter((item) => item.is_notification).length === 0 ? false : true
    )
  }, [updates])

  return (
    // <div className='container-fluid'>
    // 	<div className='col-md-6 offset-md-3 mt-3 d-flex justify-content-center align-middle'>
    // 		<figure>
    // 			<img
    // 				src='/assets/img/coming_soon.gif'
    // 				width='550px'
    // 				height='550px'
    // 				alt=''
    // 			/>
    // 		</figure>
    // 	</div>
    // </div>
    <>
      <LoadingBar height={5} color='#5D95EA' ref={ref} />

      {/* navbar */}
      <div className='col-lg-12 col-md-12 col-sm-12'>
        <div className='row py-4' style={{ backgroundColor: "#ffffff" }}>
          <div className='col-12'>
            <p className='mb-0 pl-3'>
              <Link to='/'>
                <span className='font-design'>
                  <img
                    className='left_arrow'
                    src='/assets/img/caret-left-icon.svg'
                    style={{
                      width: "15px",
                      height: "15px",
                      cursor: "pointer",
                      marginRight: "10px",
                    }}
                  />
                  InboxBetter &nbsp;
                </span>
              </Link>
              <span className='font-design'>/ &nbsp;Sequence Dashboard</span>
            </p>
          </div>
        </div>
      </div>

      {loading ? (
        <div className='d-flex align-items-center justify-content-center'>
          <FadeLoader
            width={5}
            height={20}
            color={"#0092FB"}
            loading={loading}
          />
        </div>
      ) : (
        <div className='card full-width m-3' id='plan-card'>
          <div className='col px-0'>
            <div className='row mx-2 mt-2'>
              <div className='col-md-8 col-12 card pl-1 pr-3'>
                <table
                  className='mb-0'
                  id='dataTable'
                  width='100%'
                  cellSpacing='0'
                >
                  <thead
                    style={{
                      height: "39px",
                    }}
                  >
                    <tr
                      className='tr'
                      style={{
                        backgroundColor: "#e2f1f4",
                        // position: "relative",
                      }}
                    >
                      <th
                        className='th'
                        style={{ paddingLeft: "20px", textAlign: "left" }}
                      >
                        Sequences
                      </th>
                      {/* <th style={{ textAlign: "center" }}>Active</th> */}
                      <th style={{ textAlign: "center" }}>Unlocked Today</th>
                      <th style={{ textAlign: "center" }}>Remaining Unlocks</th>
                      <th style={{ textAlign: "center" }}>Already Viewed</th>
                      <th style={{ textAlign: "center" }}>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(showdata).map((key, i) => (
                      <tr
                        className='tr'
                        key={i}
                        style={{
                          borderBottom:
                            key === "voice" ? "0" : "1px solid #e2f1f4",
                        }}
                      >
                        <td
                          className='td'
                          style={{
                            textAlign: "left",
                            fontWeight: "bold",
                            paddingLeft: "20px",
                            textTransform: "capitalize",
                          }}
                        >
                          {key + " " + "sequences"}
                        </td>
                        <td className='td'>
                          <Link to='/unlocked-sequences'>
                            {key === "email"
                              ? showdata[key]["unlockedToday"]
                              : 0}
                          </Link>
                        </td>
                        <td className='td'>
                          {key === "email"
                            ? showdata[key]["todayRemainingUnlocks"]
                            : 0}
                        </td>
                        <td className='td'>
                          {key === "email" ? showdata[key]["alreadyViewed"] : 0}
                        </td>
                        <td className='td'>
                          {key === "email" ? showdata[key]["total"] : 0}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className='col-md-4 col-12 tab-bg-clr px-0 card full-width'>
                <ul
                  className='d-flex nav nav-pills mb-3'
                  id='pills-tab'
                  role='tablist'
                >
                  <li className='nav-item' style={{ flex: 1 }}>
                    <a
                      role='tab'
                      data-toggle='pill'
                      href='#pills-home'
                      id='pills-home-tab'
                      aria-selected='true'
                      aria-controls='pills-home'
                      className={`${
                        tabtoggle ? "active show" : ""
                      } nav-link  tab-design py-3 text-center`}
                      style={{
                        fontSize: "1rem",
                        borderBottomLeftRadius: "0",
                        borderBottomRightRadius: "0",
                      }}
                    >
                      Notifications
                    </a>
                  </li>
                  <li className='nav-item' style={{ flex: 1 }}>
                    <a
                      role='tab'
                      data-toggle='pill'
                      aria-selected='false'
                      href='#pills-profile'
                      id='pills-profile-tab'
                      aria-controls='pills-profile'
                      className={`${
                        tabtoggle ? "" : "active show"
                      } nav-link  tab-design py-3 text-center`}
                      style={{
                        borderBottomLeftRadius: "0",
                        borderBottomRightRadius: "0",
                      }}
                    >
                      Recent Updates
                    </a>
                  </li>
                </ul>
                <div
                  id='pills-tabContent'
                  className='tab-content'
                  style={{ maxHeight: "150px" }}
                >
                  <div
                    id='pills-home'
                    role='tabpanel'
                    aria-labelledby='pills-home-tab'
                    className={`${tabtoggle ? "active show" : ""}tab-pane fade`}
                  >
                    {notification.length === 0 ? (
                      <div className='d-flex justify-content-center align-content-center font-weight-bold text-capitalize'>
                        <p>No Notification</p>
                      </div>
                    ) : (
                      notification.map((item, i) => (
                        <div key={i} className='txt-clr'>
                          <span>
                            {item.subject}
                            <span
                              style={{
                                color: "#ef463e",
                                fontWeight: "bold",
                                paddingLeft: "4px",
                              }}
                            >
                              {item.is_important === 1 ? "important" : ""}
                            </span>
                          </span>
                          <br />
                          <span className='duration-design'>
                            {moment(item.created_at, "YYYYMMDD").fromNow()}
                          </span>
                          <span className='duration-design1'>
                            {moment(item.updated_at).format("LL")}
                          </span>
                        </div>
                      ))
                    )}
                  </div>
                  <div
                    role='tabpanel'
                    id='pills-profile'
                    aria-labelledby='pills-profile-tab'
                    className={`${
                      tabtoggle ? "" : "show active"
                    } tab-pane fade`}
                  >
                    {recentupdate.length === 0 ? (
                      <div>
                        <div className='d-flex justify-content-center align-item-center font-weight-bold text-capitalize'>
                          <p>no notification</p>
                        </div>
                      </div>
                    ) : (
                      recentupdate.map((item, i) => (
                        <div key={i} className='txt-clr'>
                          <span>
                            {item.subject}
                            <span
                              style={{
                                color: "#ef463e",
                                fontWeight: "bold",
                                paddingLeft: "4px",
                              }}
                            >
                              {item.is_important === 1 ? "important" : ""}
                            </span>
                          </span>
                          <br />
                          <span className='duration-design'>
                            {moment(item.created_at, "YYYYMMDD").fromNow()}
                          </span>
                          <span className='duration-design1'>
                            {moment(item.updated_at).format("LL")}
                          </span>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='row mt-5 mx-2'>
              <div className='col-lg-12 col-12 card px-0'>
                <table
                  width='100%'
                  id='dataTable'
                  cellSpacing='0'
                  className='mb-0'
                >
                  <thead
                    style={{
                      height: "39px",
                    }}
                  >
                    <tr className='tr' style={{ backgroundColor: "#e2f1f4" }}>
                      {/* <th
                        className="th"
                        style={{ paddingLeft: "20px", textAlign: "left" }}
                      >
                        Email Types
                      </th> */}
                      <th style={{ paddingLeft: "20px", textAlign: "left" }}>
                        Sequence Name
                      </th>
                      <th style={{ textAlign: "left" }}>Viewed</th>
                      <th style={{ textAlign: "left" }}>Brand Name</th>
                      <th style={{ textAlign: "center" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {recentSequences !== undefined ? (
                      recentSequences.length === 0 ? (
                        <p>No Recent Sequences found...</p>
                      ) : (
                        recentSequences.map((recentSequence, i) => (
                          <tr className='tr' key={i}>
                            {/* <td
                              className="td"
                              style={{
                                fontWeight: "bold",
                                paddingLeft: "20px",
                                textAlign: "left",
                              }}
                            >
                              Email Sequence
                            </td> */}
                            {recentSequence.is_unlocked ? (
                              <td
                                className='inbox-item-name'
                                style={{ paddingLeft: "20px" }}
                              >
                                <Link
                                  to={{
                                    pathname: `/email-sequences/${recentSequence.id}`,
                                  }}
                                >
                                  {recentSequence.name}
                                </Link>
                              </td>
                            ) : (
                              <td
                                className='inbox-item'
                                style={{ paddingLeft: "20px" }}
                              >
                                {recentSequence.name}
                              </td>
                            )}
                            <td
                              className='inbox-item text-muted'
                              style={{
                                paddingLeft: "20px",
                                color: recentSequence.is_viewed
                                  ? "#1cc88a"
                                  : "#000",
                                fontWeight: "bold",
                              }}
                            >
                              {recentSequence.is_viewed ? "Yes" : "No"}
                            </td>
                            <td
                              className='td'
                              style={{ paddingLeft: "20px", textAlign: "left" }}
                            >
                              {recentSequence.brand !== undefined
                                ? recentSequence.brand.name
                                : ""}
                            </td>
                            <td className='text-center'>
                              <span>
                                <>
                                  {recentSequence.is_unlocked ? (
                                    <>
                                      <Link
                                        to={{
                                          pathname: `/email-sequences/${recentSequence.id}`,
                                        }}
                                      >
                                        <i
                                          className='fa fa-eye ml-3'
                                          aria-hidden='true'
                                          style={{
                                            color: "#1cc88a",
                                            cursor: "pointer",
                                          }}
                                        ></i>
                                      </Link>
                                      <i className='fas fa-lock-open ml-2 text-muted'></i>
                                    </>
                                  ) : (
                                    <div
                                      onClick={() =>
                                        unlockSequence(recentSequence.id)
                                      }
                                      style={{ cursor: "pointer" }}
                                      className='d-flex align-items-center justify-content-center text-muted'
                                    >
                                      <i className='fas fa-lock'></i>{" "}
                                      <p className='mb-0 ml-1'> Unlock</p>
                                    </div>
                                  )}
                                </>
                              </span>
                            </td>
                          </tr>
                        ))
                      )
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Sequences
