import axios from 'axios'
import API from './api'

const SequencesService = {
  listSequences: (
    pageNumber = 1,
    brandId = '',
    categoryId = '',
    isViewed = ''
  ) => {
    return API.get(
      `${process.env.REACT_APP_INBOX_BETTER_API_URL}/sequence?brand_id=${brandId}&category_id=${categoryId}&page=${pageNumber}&is_viewed=${isViewed}`
    )
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  filterBrands: (query = '', id, pageNumber = 1) => {
    return API.get(
      `${process.env.REACT_APP_INBOX_BETTER_API_URL}/all-brands-filter?query=${query}&category_id=${id}&page=${pageNumber}`
    )
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  listBrands: (pageNumber = 1) => {
    return API.get(
      `${process.env.REACT_APP_INBOX_BETTER_API_URL}/brands?page=${pageNumber}`
    )
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  listBrandsByCategory: (id, pageNumber = 1) => {
    return API.get(
      `${process.env.REACT_APP_INBOX_BETTER_API_URL}/category-brands/${id}?page=${pageNumber}`
    )
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  getSequenceDetail: id => {
    return API.get(
      `${process.env.REACT_APP_INBOX_BETTER_API_URL}/sequence-detail/${id}`
    )
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  getEmailContent: id => {
    return API.get(
      `${process.env.REACT_APP_INBOX_BETTER_API_URL}/get-email-content/${id}`
    )
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  getAllCategories: () => {
    return API.get(`${process.env.REACT_APP_INBOX_BETTER_API_URL}/categories`)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  getAllBrands: () => {
    return API.get(`${process.env.REACT_APP_INBOX_BETTER_API_URL}/brands`).then(
      ({ data }) => {
        return data
      }
    )
  },
  downloadPDF: id => {
    return API.get(
      `${process.env.REACT_APP_INBOX_BETTER_API_URL}/email-content-pdf/${id}`
    )
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  downloadImage: id => {
    return API.get(
      `${process.env.REACT_APP_INBOX_BETTER_API_URL}/email-content-image/${id}`
    )
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  sequencesDashboardStats: () => {
    return API.get(`${process.env.REACT_APP_INBOX_BETTER_API_URL}/dashboard`)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  recentlyUnlockedSequences: (viewedFlag = '') => {
    return API.get(
      `${process.env.REACT_APP_INBOX_BETTER_API_URL}/dail-unlocked-sequences?is_viewed=${viewedFlag}`
    )
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  searchBrands: (searchString, pageNumber = 1) => {
    return API.get(
      `${process.env.REACT_APP_INBOX_BETTER_API_URL}/brand-filter?name=${searchString}`
    )
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  unlockSequence: id => {
    return API.get(
      `${process.env.REACT_APP_INBOX_BETTER_API_URL}/unlock-sequence/${id}`
    )
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  favoriteSequence: id => {
    return API.get(
      `${process.env.REACT_APP_INBOX_BETTER_API_URL}/favourite-sequences/${id}`
    )
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  listFavoriteSequence: (pageNumber) => {
    return API.get(
      `${process.env.REACT_APP_INBOX_BETTER_API_URL}/favourite-list?page=${pageNumber}`
    )
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  unFavoriteSequence: id => {
    return API.get(
      `${process.env.REACT_APP_INBOX_BETTER_API_URL}/unfavourite-sequences/${id}`
    )
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  addCompetitors: (name = '', url = '') => {
    return API.post(
      `${process.env.REACT_APP_INBOX_BETTER_API_URL}/submit-competitor-brand?competitor_name=${name}&competitor_url=${url}`
    )
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  listTrackedCompetitors: (pageNumber = 1) => {
    return API.get(
      `${process.env.REACT_APP_INBOX_BETTER_API_URL}/brand-list?page=${pageNumber}`
    )
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
}

export default SequencesService
