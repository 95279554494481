import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'

import LoadingBar from 'react-top-loading-bar'
import queryString from 'query-string'
import AuthService from '../../api-services/authService'

import { register } from '../../store/actions/auth'
import ValidateEmail from '../../Helper/validator'
import ValidateName from '../../Helper/nameValidator'

const Register = ({ history, location, user, isLoggedIn }) => {
	const ref = useRef(null)
	const dispatch = useDispatch()
	const [registerForm, setRegisterForm] = useState({
		name: '',
		email: '',
		password: '',
		password_confirmation: '',
		is_affiliate: false,
		ref: '',
		timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
	})

	const [error, setError] = useState(null)
	const [errors, setErrors] = useState({})
	const [loading, setLoading] = useState(false)
	const [emailErr, setEmailErr] = useState(true)
	const [nameErr, setNameErr] = useState(true)

	const handleRegisterFormChange = event => {
		event.preventDefault()
		const { name, value } = event.target
		setRegisterForm({ ...registerForm, [name]: value })
	}

	const handleUserRegister = async event => {
		event.preventDefault()

		setNameErr(ValidateName(registerForm.name))
		setEmailErr(ValidateEmail(registerForm.email))
    
		setLoading(true)
		ref.current.continuousStart()
		try {
			// dispatch Register action
			await dispatch(register(registerForm, history))
		} catch (e) {
			if (e.response.status == 422) {
				const errors = e.response.data.errors
				setErrors(errors)
			} else {
				setError(e)
			}
		} finally {
			setLoading(false)
			if (ref.current) ref.current.complete()
		}
	}

	const handleIsAffiliate = e => {
		if (e.target.checked) {
			setRegisterForm({ ...registerForm, is_affiliate: true })
		} else {
			setRegisterForm({ ...registerForm, is_affiliate: false })
		}
	}

	const sendReferalToken = async () => {
		const response = await AuthService.sendRefferalToken(
			queryString.parse(location.search).ref
		)
	}

	useEffect(() => {
		setRegisterForm({
			...registerForm,
			ref: queryString.parse(location.search).ref,
		})
		sendReferalToken()
	}, [])

	return (
		<React.Fragment>
			{isLoggedIn && <Redirect to='/dashboard' />}
			<section className='login'>
				<LoadingBar height={5} color='#5D95EA' ref={ref} />
				<div class='w-100'>
					<div class='h-100 w-100 mx-0 position-absolute row login-row'>
						<div
							className='align-items-lg-center align-self-center col-12 col-lg-7 col-md-7 col-xxl-7 d-flex justify-content-center mx-auto w-100 login-left-col'
							style={{ height: '100vh' }}
						>
							<div class='align-items-center d-flex flex-column h-100 justify-content-center left-main'>
								<div className='logo-login'>
									<a
										href='https://inboxbetter.comocrm.com/'
										style={{ background: 'none' }}
									>
										<img src='/assets/img/logo.png' className='img-fluid' />
									</a>
								</div>
								<div class='login-img'>
									<img src='/assets/img/sign-up.jpg' className='img-fluid' />
								</div>
								<p className='login-p'>
									Use The "Perfect Inbox" AI To Unlock Your Email
									Deliverability, While Testing Your Email Creatives & Avoiding
									The Spam Folder
								</p>
							</div>
						</div>

						<div class='col-lg-5 col-xxl-5 col-md-5 col-12 col-sm-5 mx-auto d-flex justify-content-center align-items-center mt-4'>
							<div class='right-main'>
								<h1 className='right-heading'> Join the Community </h1>
								<p>Creat an account and start to warm-up your email account.</p>
								<div class='form-container'>
									<form onSubmit={handleUserRegister}>
										<div className='mb-3'>
											<label htmlFor='name' className='form-label'>
												Full Name <span style={{ color: 'red' }}>*</span>
											</label>
											<input
												className='form-control'
												type='text'
												name='name'
												onChange={handleRegisterFormChange}
												placeholder='Full Name'
												required
											/>
											<div className='text-danger mt-2 ml-1'>
												{nameErr ? '' : 'Please Enter Full Name'}
											</div>
											{errors.hasOwnProperty('name')
												? errors.name.map(e => (
														<span className='text-danger mt-2 ml-1'> {e} </span>
												  ))
												: ''}
										</div>

										<div className='mb-3'>
											<label htmlFor='Email Address' className='form-label'>
												Email Address <span style={{ color: 'red' }}>*</span>
											</label>
											<input
												className='form-control'
												type='email'
												name='email'
												onChange={handleRegisterFormChange}
												placeholder='Email'
												required
											/>
											<div className='text-danger mt-2 ml-1'>
												{emailErr ? '' : 'Please Enter Valid Email'}
											</div>
											{errors.hasOwnProperty('email')
												? errors.email.map(e => (
														<span className='text-danger mt-2 ml-1'> {e} </span>
												  ))
												: ''}
										</div>

										<div className='mb-3'>
											<label htmlFor='password' className='form-label'>
												Password <span style={{ color: 'red' }}>*</span>
											</label>
											<input
												className='form-control'
												type='password'
												name='password'
												onChange={handleRegisterFormChange}
												placeholder='Password'
												required
											/>
											{errors.hasOwnProperty('password')
												? errors.password.map(e => (
														<span className='text-danger mt-2 ml-1'>
															{' '}
															{e} <br />
														</span>
												  ))
												: ''}
										</div>

										<div className='mb-5'>
											<label
												htmlFor='password_confirmation'
												className='form-label'
											>
												Confirm Password <span style={{ color: 'red' }}>*</span>
											</label>
											<input
												className='form-control'
												type='password'
												name='password_confirmation'
												onChange={handleRegisterFormChange}
												placeholder='Confirm Password'
												required
											/>
										</div>

										<div className='row mb-3'>
											<div className='col-md-12'>
												<div className='form-check form-switch'>
													<input
														className='form-check-input'
														type='checkbox'
														required
													/>
													<label className='form-check-label'>
														I accept the{' '}
														<span
															style={{
																textDecoration: 'underline',
																color: '#0994f8',
															}}
														>
															Term &#38; Conditions
														</span>{' '}
														and the{' '}
														<span
															style={{
																textDecoration: 'underline',
																color: '#0994f8',
															}}
														>
															Privacy Policy
														</span>
													</label>
												</div>
											</div>
										</div>
										<div className='row'>
											<div className='col-md-12'>
												<div className='form-check form-switch'>
													<input
														className='form-check-input'
														type='checkbox'
														name='is_affiliate'
														// value={isAffiliate}
														onChange={handleIsAffiliate}
													/>
													<label className='form-check-label'>
														Click to become{' '}
														<span
															style={{
																textDecoration: 'underline',
																color: '#0994f8',
															}}
														>
															Affiliate user
														</span>
													</label>
												</div>
											</div>
										</div>

										<div className='col-md-12 pt-4'>
											<button
												disabled={loading}
												type='submit'
												className='btn admin-button'
											>
												Create my account
											</button>
										</div>

										<div className='col-md-12 pt-4 inbox-better'>
											<p className='inbox-p'>
												Already have an account?{' '}
												<Link to='/login'>
													<span>Sign In</span>
												</Link>
											</p>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</React.Fragment>
	)
}

function mapStateToProps(state) {
	return {
		user: state.authReducer.user,
		isLoggedIn: state.authReducer.isLoggedIn,
	}
}

export default connect(mapStateToProps, null)(Register)
