import { LOGIN, REGISTER, LOGOUT, UPDATE_PROFILE } from "../types/index";

const initialState = {
  token: localStorage.getItem("token") || "",
  isLoggedIn: !!localStorage.getItem("token"),
  expiresIn: localStorage.getItem("expiresIn") || "",
  user: JSON.parse(localStorage.getItem("user")) || {},
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
        token: payload.token,
        expiresIn: payload.expires_in,
      };

    case REGISTER:
      return {
        ...state,
        user: payload,
      };

    case LOGOUT:
      return {
        ...state,
        user: {},
        token: "",
        expiresIn: "",
        isLoggedIn: false,
      };

    case UPDATE_PROFILE:
      return {
        ...state,
        user: payload,
      };

    default: {
      return state;
    }
  }
};

export default authReducer;
