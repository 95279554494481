import './ProviderContent.scss'
import { useState, useEffect } from 'react'
import HelpComponent from '../../../components/Help/Help'
import warmupService from '../../../api-services/warmupService'

const providers = [
	{
		id: 'gmail',
		icon: '/assets/img/google-logo.png',
		title: 'Gmail - Gsuite',
		subTitle: 'Google inbox',
		render: false,
	},
	{
		id: 'outlook',
		icon: '/assets/img/outlook-logo.png',
		title: 'Outlook',
		subTitle: 'Microsoft 365',
		render: false,
	},
	{
		id: 'yahoo',
		icon: '/assets/img/yahoo-logo.png',
		title: 'Yahoo!',
		subTitle: 'Your inbox',
		render: false,
	},
	{
		id: 'icloud',
		icon: '/assets/img/iCloud-logo.png',
		title: 'iCloud',
		subTitle: 'Apple inbox',
		render: false,
	},
	{
		id: 'aol',
		icon: '/assets/img/aol-logo.png',
		title: 'AOL Mail',
		subTitle: 'Verizon inbox',
		render: false,
	},
	{
		id: 'zoho',
		icon: '/assets/img/zoho-logo.png',
		title: 'Zoho',
		subTitle: 'Zoho inbox',
		render: false,
	},
	{
		id: 'yandex',
		icon: '/assets/img/yandex-logo.png',
		title: 'Yandex',
		subTitle: 'Yandex inbox',
		render: false,
	},
	{
		id: 'sendgrid',
		icon: '/assets/img/sendgrid-logo.png',
		title: 'Sendgrid',
		subTitle: 'Sendgrid inbox',
		render: false,
	},
	{
		id: 'sendinblue',
		icon: '/assets/img/sendinBlue-logo.png',
		title: 'SendinBlue',
		subTitle: 'SendinBlue inbox',
		render: false,
	},
	{
		id: 'mailgun',
		icon: '/assets/img/mailgun-logo.png',
		title: 'Mailgun',
		subTitle: 'Mailgun inbox',
		render: false,
	},
	{
		id: 'amazonses',
		icon: '/assets/img/amazon-logo.png',
		title: 'Amazon SES',
		subTitle: 'Amazon inbox',
		render: false,
	},
	{
		id: 'others',
		icon: '/assets/img/fxemoji_envelope.png',
		title: 'Others',
		subTitle: 'Add with SMTP',
		render: true,
	},
]

const ProviderContent = ({
	selectedProvider,
	setSelectedProvider,
	setEmail_account_provider_id,
	email_account_provider_id,
	isEdit,
	campaignData,
}) => {
	const [hideProviders, setHideProviders] = useState(true)
	const [emailAccountProviders, setEmailAccountProviders] = useState([])

	useEffect(() => {
		if (isEdit) {
			;(async () => {
				try {
					setEmailAccountProviders(
						await warmupService.getEmailAccountProviders()
					)
				} catch (e) {
				} finally {
				}
			})()
			setEmail_account_provider_id(
				campaignData.email_account.email_account_provider_id
			)
		} else {
			;(async () => {
				try {
					setEmailAccountProviders(
						await warmupService.getEmailAccountProviders()
					)
				} catch (e) {
				} finally {
				}
			})()
			setSelectedProvider('')
			setEmail_account_provider_id(0)
		}
	}, [])

	useEffect(() => {}, [emailAccountProviders])

	const handleProviderClick = e => {
		let providerId = parseInt(
			document.getElementById(e.target.id).getAttribute('data-id')
		)
		setEmail_account_provider_id(providerId)
		setSelectedProvider(providerId)
	}

	return (
		<div className='inbox-provider-content'>
			<section className='inbox-provider-content-header'>
				{/* Text */}
				<b>
					Select the provider of the inbox you want to warm-up{' '}
					<span>
						<HelpComponent />
					</span>
				</b>
			</section>
			<section className='inbox-provider-card-container'>
				{emailAccountProviders.map((prov, i) => (
					<div key={i}>
						<div
							id={'provider-card-' + prov.id}
							onClick={handleProviderClick}
							className={
								email_account_provider_id === prov.id
									? 'inbox-provider-card-selected'
									: prov.is_active === 0
									? 'inbox-provider-card-disabled'
									: 'inbox-provider-card'
							}
							style={{
								maxWidth: '210px',
								// pointerEvents: prov.id === 19 ? 'none' : 'initial',
							}}
							data-id={prov.id}
						>
							<div
								className='inner-inbox-provider-card'
								id={'provider-card-' + prov.id}
							>
								<img
									style={{
										marginBottom:
											prov.id === 15 ? '-5px' : prov.id === 18 ? '-10px' : '',
										marginTop: prov.id == 18 ? '-25px' : '',
									}}
									id={'provider-card-' + prov.id}
									className='provider-logo'
									src={'/assets/img/' + prov.icon_img}
									alt=''
									width={prov.id == 17 ? '165px' : ''}
									height={prov.id == 17 ? '47px' : ''}
								/>
								<p id={'provider-card-' + prov.id} className='provider-title'>
									{prov.name}
								</p>
								<p
									id={'provider-card-' + prov.id}
									className='provider-subtitle'
								>
									{prov.label}
								</p>
								{/* <p
									id={'provider-card-' + prov.id}
									className='provider-subtitle'
								>
									{prov.id === 19 ? 'Coming Soon' : ''}
								</p> */}
							</div>
						</div>
					</div>
				))}
				{/* Providers Cards */}
			</section>
		</div>
	)
}

export default ProviderContent
