import Package from './Package'
import { Modal } from 'bootstrap'
import { toast } from 'react-toastify'
import CheckoutForm from './CheckoutForm'
import { loadStripe } from '@stripe/stripe-js'
import React, { useEffect, useState } from 'react'
import FadeLoader from 'react-spinners/FadeLoader'
import { Elements } from '@stripe/react-stripe-js'
import PlanService from '../../../api-services/plan.service'

const Subscription = () => {
	const [plans, setPlans] = useState([])
	const [priceId, setPriceId] = useState('')
	const [customerId, setCustomerId] = useState('')
	const [selectedPlan, setSelectedPlan] = useState({})
	const [clientSecret, setClientSecret] = useState('')
	const [subscribing, setSubscribing] = useState(false)
	const [loadingPlans, setLoadingPlans] = useState(false)
	const [subscriptionId, setSubscriptionId] = useState('')
	const [selectedPackageId, setSelectedPackageId] = useState(0)
	const [loadingPaymentElements, setloadingPaymentElements] = useState(true)
	const [stripePromise, setStripePromise] = useState(() =>
		loadStripe(
			process.env.REACT_APP_TEST_MODE === 'true'
				? 'pk_test_51K9wCBCS6ywz511Ria36w4YXMry4tekv8nMYKPijWo3i5VrKn7CeYZRzQOrhkSdEQsWIeRNo8iEsI90K0enL0oi200GweX3Jy6'
				: 'pk_test_HGwbj9cQIFbvCl0BpeS0oJNu'
		)
	)

	const modalInstance = document.getElementById('exampleModal')

	const fetchPlans = async () => {
		setLoadingPlans(true)
		const response = await PlanService.list()

		if (response.success) {
			setPlans(response.packageDetails)
			setLoadingPlans(false)
		} else {
			setLoadingPlans(false)
			toast.error('Something went wrong', {
				autoClose: 2500,
				closeOnClick: true,
				position: 'top-right',
				hideProgressBar: false,
			})
		}
	}

	const options = {
		clientSecret: clientSecret,
		appearance: {
			theme: 'stripe',
			labels: 'floating',
		},
	}

	const closeModal = () => {
		var myModal = Modal.getInstance(modalInstance)

		myModal.hide()
	}

	const handleUpgradePlan = async (price, packageId, planObj) => {
		setSubscribing(true)
		if (packageId === 2) {
			try {
				const response = await PlanService.getSinglePaymentClientSecret(
					price,
					customerId === ''
						? JSON.parse(localStorage.getItem('user')).stripePayment === null
							? null
							: JSON.parse(localStorage.getItem('user')).stripePayment
									.customer_id
						: customerId
				)

				if (response.success) {
					setSelectedPlan(planObj)
					setSelectedPackageId(packageId)
					// setPriceId(response.Data.priceId)
					setCustomerId(response.Data.CustomerId)
					setClientSecret(response.Data.clientSecret)
					// setSubscriptionId(response.Data.SubscriptionId)
					options.clientSecret = response.Data.clientSecret

					let localUser = JSON.parse(localStorage.getItem('user'))

					// localUser.stripePayment.customer_id = response.Data.CustomerId
					localUser.stripePayment.subscription_id = ''
					localUser.stripePayment.price_id = ''

					localUser.package.price_id = ''

					localStorage.setItem('user', JSON.stringify(localUser))

					var myModal = Modal.getOrCreateInstance(modalInstance)

					myModal.show()

					setSubscribing(false)

					toast.success(response.message, {
						autoClose: 2500,
						closeOnClick: true,
						position: 'top-right',
						hideProgressBar: false,
					})
				} else {
					closeModal()
					setSubscribing(false)
				}
			} catch (error) {
				console.log(error)
				setSubscribing(false)
			}
		} else {
			try {
				const response = await PlanService.upgradePlan(
					planObj.price_id,
					JSON.parse(localStorage.getItem('user')).stripePayment
						.subscription_id,
					packageId
				)

				if (response.success) {
					setSelectedPlan(planObj)
					setSelectedPackageId(packageId)
					setPriceId(response.user.package.priceId)
					// setCustomerId(response.Data.CustomerId)
					setSubscriptionId(response.user.stripePayment.SubscriptionId)

					localStorage.setItem('user', JSON.stringify(response.user))

					setSubscribing(false)

					toast.success(response.message, {
						autoClose: 2500,
						closeOnClick: true,
						position: 'top-right',
						hideProgressBar: false,
					})

					fetchPlans()
				} else {
					setSubscribing(false)

					toast.error(response.message, {
						autoClose: 2500,
						closeOnClick: true,
						position: 'top-right',
						hideProgressBar: false,
					})
				}
			} catch (e) {
				setSubscribing(false)

				// toast.error(e.response.data.message, {
				// 	autoClose: 2500,
				// 	closeOnClick: true,
				// 	position: 'top-right',
				// 	hideProgressBar: false,
				// })
			}
		}
	}

	const handlePurchase = async (price, packageId, planObj) => {
		setClientSecret('')
		setSubscribing(true)

		if (packageId === 2 || packageId === 1) {
			try {
				const response = await PlanService.getSinglePaymentClientSecret(
					price,
					customerId === ''
						? JSON.parse(localStorage.getItem('user')).stripePayment === null
							? ''
							: JSON.parse(localStorage.getItem('user')).stripePayment
									.customer_id
						: customerId
				)

				if (response.success) {
					setSelectedPlan(planObj)
					setSelectedPackageId(packageId)
					// setPriceId(response.Data.priceId)
					setCustomerId(response.Data.CustomerId)
					setClientSecret(response.Data.clientSecret)
					// setSubscriptionId(response.Data.SubscriptionId)
					options.clientSecret = response.Data.clientSecret

					setSubscribing(false)
					var myModal = Modal.getOrCreateInstance(modalInstance)

					myModal.show()

					let localUser = JSON.parse(localStorage.getItem('user'))

					localUser.stripePayment = {
						customer_id: response.Data.CustomerId,
					}
					// localUser.stripePayment.subscription_id = ''
					// localUser.stripePayment.price_id = ''

					// localUser.package.price_id = ''

					localStorage.setItem('user', JSON.stringify(localUser))
				} else {
					setSubscribing(false)
					closeModal()
				}
			} catch (error) {
				setSubscribing(false)

				console.log(error)
			}
		} else {
			try {
				const response = await PlanService.getSubClientSecret(
					price,
					packageId,
					customerId === '' || customerId === undefined || customerId === null
						? JSON.parse(localStorage.getItem('user')).stripePayment === null ||
						  JSON.parse(localStorage.getItem('user')).stripePayment ===
								undefined
							? ''
							: JSON.parse(localStorage.getItem('user')).stripePayment
									.customer_id === undefined ||
							  JSON.parse(localStorage.getItem('user')).stripePayment
									.customer_id === null
							? ''
							: JSON.parse(localStorage.getItem('user')).stripePayment
									.customer_id
						: customerId,
					planObj.price_id === null ? '' : planObj.price_id
				)

				if (response.success) {
					setSelectedPlan(planObj)
					setSelectedPackageId(packageId)
					setPriceId(response.Data.priceId)
					setCustomerId(response.Data.CustomerId)
					setClientSecret(response.Data.clientSecret)
					setSubscriptionId(response.Data.SubscriptionId)
					options.clientSecret = response.Data.clientSecret

					let localUser = JSON.parse(localStorage.getItem('user'))

					// if (localUser.stripePayment === null) {
					localUser.stripePayment = {
						// price_id: response.Data.priceId,
						customer_id: response.Data.CustomerId,
						// subscription_id: response.Data.SubscriptionId,
					}

					// 	localUser.package = {
					// 		price_id: response.Data.priceId,
					// 	}
					// } else {
					// 	localUser.stripePayment.subscription_id =
					// 		response.Data.SubscriptionId
					// 	localUser.stripePayment.price_id = response.Data.priceId
					// 	localUser.stripePayment.customer_id = response.Data.CustomerId

					// 	localUser.package = {
					// 		price_id: response.Data.priceId,
					// 	}
					// }

					localStorage.setItem('user', JSON.stringify(localUser))

					setSubscribing(false)

					var myModal = Modal.getOrCreateInstance(modalInstance)

					myModal.show()
				} else {
					setSubscribing(false)

					closeModal()
				}
			} catch (error) {
				setSubscribing(false)

				console.log(error)
			}
		}
	}

	const handlePauseSubscription = async packageId => {
		setSubscribing(true)

		try {
			const response = await PlanService.pauseSubscription(packageId)

			if (response.success) {
				setSubscribing(false)
				localStorage.setItem('user', JSON.stringify(response.user))
				fetchPlans()
			} else {
				setSubscribing(false)
			}
		} catch (error) {
			setSubscribing(false)

			console.log(error)
		}
	}

	const handleResumeSubscription = async packageId => {
		setSubscribing(true)

		try {
			const response = await PlanService.resumeSubscription(packageId)

			if (response.success) {
				setSubscribing(false)
				localStorage.setItem('user', JSON.stringify(response.user))
				fetchPlans()
			} else {
				setSubscribing(false)
			}
		} catch (error) {
			setSubscribing(false)

			console.log(error)
		}
	}

	const handleUnsubscription = async packageId => {
		try {
			const response = await PlanService.unsubscribe(packageId)
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		fetchPlans()
	}, [])

	return (
		<>
			<div className='row'>
				<div className='col text-center'>
					<span className='subscription-heading'>Subscription Plans</span>
					<br />
					<span className='text-muted'>
						We have extremely transparent plans
					</span>
				</div>
			</div>
			<div
				id='packages-container-div'
				className='py-4 mt-2'
				style={{
					height: '80%',
					display: 'flex',
					flexWrap: 'wrap',
					overflowY: 'auto',
				}}
			>
				{loadingPlans ? (
					<div
						style={{ marginLeft: '50%', height: '30px' }}
						className='d-flex align-items-center justify-content-center'
					>
						<FadeLoader
							width={5}
							height={20}
							color={'#0092FB'}
							loading={loadingPlans}
						/>
					</div>
				) : plans.length === 0 ? (
					<h4 className='text-center'>No Plans Found...</h4>
				) : (
					plans
						.filter(plan => !plan.is_disabled)
						.filter(plan => plan.id !== 8)
						.map((plan, i) => (
							<Package
								key={i}
								style={{
									padding: '15px 18px',
									background: '#E2F1F4',
								}}
								options={options}
								price={plan.price}
								packageId={plan.id}
								pkg_name={plan.name}
								priceId={plan.price_id}
								subscribing={subscribing}
								clientSecret={clientSecret}
								stripePromise={stripePromise}
								subscriptionId={subscriptionId}
								handlePurchase={handlePurchase}
								duration={plan.duration_in_days}
								img_src={'/assets/img/solo_pkg.png'}
								selectedPackageId={selectedPackageId}
								handleUpgradePlan={handleUpgradePlan}
								handleUnsubscription={handleUnsubscription}
								loadingPaymentElements={loadingPaymentElements}
								handlePauseSubscription={handlePauseSubscription}
								handleResumeSubscription={handleResumeSubscription}
								setloadingPaymentElements={setloadingPaymentElements}
								email_per_day={
									plan.packagefeatures !== undefined
										? plan.packagefeatures.find(
												feature => feature.system_feature_id === 1
										  ) !== undefined
											? plan.packagefeatures.find(
													feature => feature.system_feature_id === 1
											  ).max_allowed_count
											: ''
										: ''
								}
								spamChecker={
									plan.packagefeatures !== undefined
										? plan.packagefeatures.find(
												feature => feature.system_feature_id === 3
										  ) !== undefined
											? plan.packagefeatures.find(
													feature => feature.system_feature_id === 3
											  ).max_allowed_count
											: ''
										: ''
								}
								dnsChecker={
									plan.packagefeatures !== undefined
										? plan.packagefeatures.find(
												feature => feature.system_feature_id === 2
										  ) !== undefined
											? plan.packagefeatures.find(
													feature => feature.system_feature_id === 2
											  ).max_allowed_count
											: ''
										: ''
								}
								emailGrader={
									plan.packagefeatures !== undefined
										? plan.packagefeatures.find(
												feature => feature.system_feature_id === 4
										  ) !== undefined
											? plan.packagefeatures.find(
													feature => feature.system_feature_id === 4
											  ).max_allowed_count
											: ''
										: ''
								}
								sequences={
									plan.packagefeatures !== undefined
										? plan.packagefeatures.find(
												feature => feature.system_feature_id === 5
										  ) !== undefined
											? plan.packagefeatures.find(
													feature => feature.system_feature_id === 5
											  ).max_allowed_count
											: ''
										: ''
								}
							/>
						))
				)}
			</div>
			<div
				tabIndex='-1'
				id='exampleModal'
				aria-hidden='true'
				className='modal fade'
				aria-labelledby='exampleModalLabel'
			>
				<div className='modal-dialog modal-xl'>
					<div className='modal-content'>
						<div className='row'>
							<div className='col'>
								<i
									onClick={closeModal}
									className='fas fa-times float-right pr-3 pt-2'
									style={{ cursor: 'pointer', fontSize: '20px' }}
								></i>
							</div>
						</div>
						<div className='d-flex align-items-center justify-content-center row modal-body'>
							{clientSecret !== '' ? (
								<>
									<div className='flex-grow-1'>
										<div className='mb-3' style={{ margin: '10px' }}>
											<div
												id='plan-card'
												className='card text-center'
												style={{
													background: '#E2F1F4',
													border: '2px solid #0994F8',
												}}
											>
												<div
													className='card-header p-4'
													style={{
														background: '#E2F1F4',
														borderBottom: '1px solid #0994F8',
													}}
												>
													<div
														style={{
															padding: '15px 18px',
															background: '#E2F1F4',
														}}
														className='pkg-icon-bg mb-4'
													>
														<img
															src={'/assets/img/solo_pkg.png'}
															alt='package_icon'
														/>
													</div>
													<div>
														<span className='price_pkg'>
															${selectedPlan.price}{' '}
														</span>
														<span className='month_text'>
															{selectedPlan.duration === 30 ? '/mo' : ''}
														</span>
														<br />
														<span className='pkg_name'>
															{selectedPlan.pkg_name}
														</span>
													</div>
												</div>
												<div className='card-body p-4'>
													<div
														className='mt-2'
														style={{
															display: 'flex',
															flexDirection: 'column',
															justifyContent: 'center',
														}}
													>
														<div>
															<div
																style={{
																	display: 'flex',
																	justifyContent: 'center',
																}}
															>
																<div className='col-4 px-0'>
																	<span className='pkg_detail_clr'>
																		{selectedPlan.email_per_day}
																	</span>
																</div>
																<div className='col-8'>
																	<span className='pkg_detail'>
																		{' '}
																		Warmups/month
																	</span>
																</div>
															</div>
														</div>
														<br />
														<div className='row'>
															<div className='col-4 px-0'>
																<span className='pkg_detail_clr'>
																	{selectedPlan.spamChecker}{' '}
																</span>
															</div>
															<div className='col-8'>
																<span className='pkg_detail'>
																	Spam Checks/day
																</span>
															</div>
														</div>
														<br />

														<div className='row'>
															<div className='col-4 px-0'>
																<span className='pkg_detail_clr'>
																	{selectedPlan.dnsChecker}{' '}
																</span>
															</div>
															<div className='col-8'>
																<span className='pkg_detail'>
																	DNS Checks/day
																</span>
															</div>
														</div>
														<br />

														<div className='row'>
															<div className='col-4 px-0'>
																<span className='pkg_detail_clr'>
																	{selectedPlan.emailGrader}{' '}
																</span>
															</div>
															<div className='col-8'>
																<span className='pkg_detail'>Gradings/day</span>
															</div>
														</div>
														<br />

														<div className='row'>
															<div className='col-4 px-0'>
																<span className='pkg_detail_clr'>
																	{selectedPlan.sequences}{' '}
																</span>
															</div>
															<div className='col-8'>
																<span className='pkg_detail'>
																	Sequences/day
																</span>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className='flex-grow-1' style={{ margin: '10px' }}>
										<Elements options={options} stripe={stripePromise}>
											<CheckoutForm
												priceId={priceId}
												customerId={customerId}
												fetchPlans={fetchPlans}
												packageId={selectedPackageId}
												subscriptionId={subscriptionId}
												setClientSecret={setClientSecret}
												setloadingPaymentElements={setloadingPaymentElements}
											/>
										</Elements>
									</div>
								</>
							) : (
								<div
									style={{
										height: '642px',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<FadeLoader
										width={5}
										height={20}
										loading={true}
										color={'#0092FB'}
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Subscription
