import '../Settings/Settings.scss'
import { toast } from 'react-toastify'
import LoadingBar from 'react-top-loading-bar'
import FadeLoader from 'react-spinners/FadeLoader'
import FaqService from '../../api-services/faq.service'
import React, { useState, useRef, useEffect } from 'react'
import { ReactComponent as WarmupIcon } from '../../components/Assets/icons/warm-up-icon.svg'
import { ReactComponent as WarmupIconBlue } from '../../components/Assets/icons/warm-up-blue-icon.svg'
import { ReactComponent as DNSCheckerIcon } from '../../components/Assets/icons/dns-checker-icon.svg'
import { ReactComponent as DNSCheckerIconBlue } from '../../components/Assets/icons/dns-checker-blue-icon.svg'
import { ReactComponent as SpamCheckerIcon } from '../../components/Assets/icons/spam-checker-icon.svg'
import { ReactComponent as SpamCheckerIconBlue } from '../../components/Assets/icons/spam-checker-blue-icon.svg'
import { ReactComponent as InboxingGraderIcon } from '../../components/Assets/icons/inboxing-grader-icon.svg'
import { ReactComponent as InboxingGraderIconBlue } from '../../components/Assets/icons/inboxing-grader-blue-icon.svg'
import { ReactComponent as SequencesDashboardIcon } from '../../components/Assets/icons/sequence-dashboard-icon.svg'
import { ReactComponent as SequencesDashboardIconBlue } from '../../components/Assets/icons/sequence-dashboard-blue-icon.svg'
import Pagination from 'react-js-pagination'

const Faq = () => {
	const ref = useRef(null)

	const [faqs, setFaqs] = useState([])
	const [errors, setErrors] = useState([])
	const [loading, setLoading] = useState(true)
	const [tabName, setTabName] = useState('all')
	const [activePage, setActivePage] = useState(1)
	const [dnsActive, setDnsActive] = useState(false)
	const [spamActive, setSpamActive] = useState(false)
	const [warmupActive, setWarmupActive] = useState(false)
	const [graderActive, setGraderActive] = useState(false)
	const [sequencesActive, setSequencesActive] = useState(false)
	const [metaData, setMetaData] = useState({ perPage: 0, totalItems: 0 })

	const notify = response => {
		response === 422
			? toast.error('Invalid or empty fields.')
			: response.success === true
			? toast.info(response.message)
			: toast.error(response.message)
	}

	const handleTab = e => {
		if (e.target.id === 'warmup') {
			setTabName('warmup')

			setDnsActive(false)
			setSpamActive(false)
			setGraderActive(false)
			setSequencesActive(false)
			setWarmupActive(true)
		} else if (e.target.id === 'dns') {
			setTabName('dns')

			setSpamActive(false)
			setWarmupActive(false)
			setGraderActive(false)
			setSequencesActive(false)
			setDnsActive(true)
		} else if (e.target.id === 'spam') {
			setTabName('spam')

			setDnsActive(false)
			setGraderActive(false)
			setWarmupActive(false)
			setSequencesActive(false)
			setSpamActive(true)
		} else if (e.target.id === 'grader') {
			setTabName('grader')

			setDnsActive(false)
			setSpamActive(false)
			setWarmupActive(false)
			setSequencesActive(false)
			setGraderActive(true)
		} else {
			setTabName('sequences')

			setDnsActive(false)
			setSpamActive(false)
			setWarmupActive(false)
			setGraderActive(false)
			setSequencesActive(true)
		}

		// fetchFaqs()
	}

	const handlePageChange = pageNumber => {
		setActivePage(pageNumber)

		// fetchFaqs()
	}

	const fetchFaqs = async () => {
		setLoading(true)
		try {
			const response = await FaqService.list(tabName, activePage)

			if (response.success) {
				let tempMetaData = metaData
				setFaqs(response.faqs.data)
				tempMetaData.perPage = response.faqs.per_page
				tempMetaData.totalItems = response.faqs.total
				setMetaData(tempMetaData)
			}
		} catch (e) {
			console.log(e)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		fetchFaqs()
	}, [activePage, tabName])

	return (
		<>
			<LoadingBar height={5} color='#5D95EA' ref={ref} />
			<div
				className='col-lg-12 col-md-12 col-sm-12 mt-4 ml-2 title-col'
				style={{ height: '70%' }}
			>
				<div className='card' id='plan-card'>
					<div className='card-body p-0'>
						<div className='row'>
							<div className='col-sm-12'>
								<div className='d-flex flex-lg-row flex-md-row flex-sm-column flex-column'>
									<div
										id='left-div'
										className='settings-card flex-grow-0'
										style={{ height: '100%' }}
									>
										<div className='d-inline-flex flex-column'>
											<h4 className='d-inline-flex text-muted s-label'>
												Frequently Asked Questions
											</h4>
											<div
												id='warmup'
												onClick={handleTab}
												className={`d-flex flex-row align-items-center ${
													warmupActive ? 's-item-active' : 's-item'
												}`}
											>
												{warmupActive ? (
													<WarmupIconBlue
														className='ml-3'
														onClick={handleTab}
													/>
												) : (
													<WarmupIcon onClick={handleTab} className='ml-3' />
												)}
												<div onClick={handleTab} id='warmup' className='px-3'>
													<b id='warmup' onClick={handleTab}>
														Warm-up
													</b>
												</div>
											</div>
											<div
												id='dns'
												onClick={handleTab}
												className={`d-flex flex-row align-items-center ${
													dnsActive ? 's-item-active' : 's-item'
												}`}
											>
												{dnsActive ? (
													<DNSCheckerIconBlue
														className='ml-3'
														onClick={handleTab}
													/>
												) : (
													<DNSCheckerIcon
														className='ml-3'
														onClick={handleTab}
													/>
												)}
												<div id='dns' onClick={handleTab} className='px-3'>
													<b id='dns' onClick={handleTab}>
														Email Blacklist & DNS Checker
													</b>
												</div>
											</div>
											<div
												id='spam'
												onClick={handleTab}
												className={`d-flex flex-row align-items-center ${
													spamActive ? 's-item-active' : 's-item'
												}`}
											>
												{spamActive ? (
													<SpamCheckerIconBlue
														className='ml-3'
														onClick={handleTab}
													/>
												) : (
													<SpamCheckerIcon
														className='ml-3'
														onClick={handleTab}
													/>
												)}
												<div id='spam' onClick={handleTab} className='px-3'>
													<b id='spam' onClick={handleTab}>
														Spam Checker
													</b>
												</div>
											</div>
											<div
												id='grader'
												onClick={handleTab}
												className={`d-flex flex-row align-items-center ${
													graderActive ? 's-item-active' : 's-item'
												}`}
											>
												{graderActive ? (
													<InboxingGraderIconBlue
														className='ml-3'
														onClick={handleTab}
													/>
												) : (
													<InboxingGraderIcon
														className='ml-3'
														onClick={handleTab}
													/>
												)}
												<div id='grader' onClick={handleTab} className='px-3'>
													<b id='grader' onClick={handleTab}>
														Email Inboxing Grader
													</b>
												</div>
											</div>
											<div
												id='sequences'
												onClick={handleTab}
												className={`d-flex flex-row align-items-center ${
													sequencesActive ? 's-item-active' : 's-item'
												}`}
											>
												<img
													src='/assets/img/email-sequence.png'
													className='ml-3'
												/>
												<div
													id='sequences'
													className='px-3'
													onClick={handleTab}
												>
													<b id='sequences' onClick={handleTab}>
														Sequences
													</b>
												</div>
											</div>
										</div>
									</div>

									<div
										style={{ height: '80vh' }}
										className='d-flex flex-grow-0 flex-column px-3 w-75'
									>
										<h4 className='text-muted s-label pl-0'>
											{warmupActive
												? 'Warmup'
												: dnsActive
												? 'Email Blacklist & DNS Checker'
												: spamActive
												? 'Spam Checker'
												: graderActive
												? 'Email Inbox Grader'
												: sequencesActive
												? 'Sequences'
												: 'All'}
										</h4>
										<div
											style={{ overflowY: 'auto', height: '100%' }}
											className='d-flex flex-grow-0 flex-column'
										>
											{loading ? (
												<div className='text-center pb-5 mb-5'>
													<FadeLoader
														width={5}
														height={20}
														color={'#0092FB'}
														loading={loading}
													/>
												</div>
											) : faqs !== undefined ? (
												faqs.length !== 0 ? (
													faqs.map((faq, i) => (
														<div
															key={i}
															id='plan-card'
															className='card rounded mb-1'
															style={{ border: '1px solid rgba(0,0,0,.125)' }}
														>
															<div className='card-header'>
																<b
																	style={{ fontSize: '17px' }}
																	className='text-primary'
																>
																	{faq.question}
																</b>
															</div>
															<div className='card-body'>{faq.answer}</div>
														</div>
													))
												) : (
													<b className='text-muted text-center d-flex align-items-center justify-content-center'>
														No FAQs Found
													</b>
												)
											) : (
												<></>
											)}
										</div>
										<div className='d-flex justify-content-end'>
											{metaData.totalItems === 0 ? (
												<></>
											) : (
												<Pagination
													itemClass='page-item'
													linkClass='page-link'
													pageRangeDisplayed={4}
													activePage={activePage}
													totalItemsCount={metaData.totalItems}
													itemsCountPerPage={metaData.per_page}
													onChange={pageNumber => handlePageChange(pageNumber)}
												/>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Faq
