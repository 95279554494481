import './spamChecker.scss'
import EmailDetails from './EmailDetails'
import Carousel from './carousel/Carousel'
import React, { useState, useEffect } from 'react'
import HelpComponent from '../../components/Help/Help'

import { ReactComponent as YahooIcon } from '../../components/Assets/icons/yahoo-icon.svg'
import { ReactComponent as AolIcon } from '../../components/Assets/icons/aol-icon.svg'
import { ReactComponent as GmailIcon } from '../../components/Assets/icons/gmail-icon.svg'
import { ReactComponent as MailComIcon } from '../../components/Assets/icons/mail.com-icon.svg'
import { ReactComponent as ICloudIcon } from '../../components/Assets/icons/icloud-icon.svg'
import { ReactComponent as OutlookIcon } from '../../components/Assets/icons/outlook-icon.svg'

const SpamChecker = props => {
	const [provider, setProvider] = useState('gmail')
	const [spamData, setSpamData] = useState([])
	const [spamReport, setSpamReport] = useState([])
	const [email, setEmail] = useState('')

	const handletab = e => {
		if (e.target.id === 'gmail') {
			setProvider('gmail')
		} else if (e.target.id === 'yahoo') {
			setProvider('yahoo')
		} else if (e.target.id === 'outlook') {
			setProvider('outlook')
		} else if (e.target.id === 'aol') {
			setProvider('aol')
		} else if (e.target.id === 'icloud') {
			setProvider('icloud')
		} else if (e.target.id === 'mail.com') {
			setProvider('mail.com')
		}
	}

	useEffect(() => {
		if (props.location.state.tempObj.report === null) {
			setSpamData([])
		} else if (props.location.state && props.location.state !== undefined) {
			setSpamData(props.location.state.tempObj.report)
			setEmail(props.location.state.tempObj.from_email)
		} else if (localStorage.getItem('spam-checker-data')) {
			setSpamData(JSON.parse(localStorage.getItem('spam-checker-data')))
		}
	}, [])

	useEffect(() => {
		if (spamData.length !== 0) {
			setSpamReport(JSON.parse(spamData))
		}
	}, [spamData])

	// console.log(spamReport, "spam report");

	return (
		<>
			{spamData ? (
				<>
					<div className='container-fluid bg-white my-1 right-header'>
						<div className='row d-flex align-items-center justify-content-between'>
							<div className='pl-3 my-4'>
								<span style={{ fontSize: '15px' }}>
									<img
										className='left_arrow'
										style={{
											marginLeft: '30px',
											marginRight: '10px',
											marginTop: '-2px',
											cursor: 'pointer',
											height: '15px',
											width: '15px',
										}}
										src='/assets/img/caret-left-icon.svg'
										onClick={() => props.history.push('/inbox-spam-checker')}
									/>
									&nbsp;&nbsp;&nbsp;
									<b> Spam Checker:</b>&nbsp;&nbsp;
									{email}
									<b>
										&nbsp;
										<span>
											<HelpComponent
												filePath={'/help-fragments/2-domainHelp.html'}
											/>
										</span>
									</b>
								</span>
							</div>
						</div>
					</div>
					<div className='container-fluid'>
						<div className='d-flex flex-row align-items-center justify-content-between mt-3 uper-dashboard'>
							<div className='d-flex flex-row align-items-center bg-white flex-fill main-card mr-2 p-3'>
								<div
									className='secondary-card-icon-rate d-flex align-items-center justify-content-center'
									style={{ backgroundColor: '#F3FDFF' }}
								>
									<img
										alt=''
										src='/assets/img/total-email.png'
										// style={{ marginTop: "11px" }}
									></img>
								</div>
								<div className='ml-3'>
									<h5 className='my-2'>
										<b>
											{Object.keys(spamReport).length === 0
												? 0
												: spamReport.total_emails}
										</b>
									</h5>
									<p className='my-1 p-0 text-muted'>Total Emails</p>
								</div>
							</div>
							<div className='d-flex flex-row align-items-center bg-white flex-fill main-card mr-2 p-3'>
								<div
									className='secondary-card-icon-rate d-flex align-items-center justify-content-center'
									style={{ backgroundColor: '#E2F4E5' }}
								>
									<img
										src='/assets/img/total-inbox.png'
										alt=''
										// style={{ marginTop: "8px", marginLeft: "11px" }}
									></img>
								</div>
								<div className='ml-3'>
									<h5 className='my-2'>
										<b>
											{Object.keys(spamReport).length === 0
												? 0
												: spamReport.total_inbox}
										</b>
									</h5>
									<p className='my-1 p-0 text-muted'>Total Inboxed</p>
								</div>
							</div>
							<div className='d-flex flex-row align-items-center bg-white flex-fill main-card mr-2 p-3'>
								<div
									className='secondary-card-icon-rate d-flex align-items-center justify-content-center'
									style={{ backgroundColor: '#F4F3E2' }}
								>
									<img
										src='/assets/img/total-important.png'
										alt=''
										// style={{ marginTop: "11px", marginLeft: "11px" }}
									></img>
								</div>
								<div className='ml-3'>
									<h5 className='my-2'>
										<b>
											{Object.keys(spamReport).length === 0
												? 0
												: spamReport.total_important}
										</b>
									</h5>
									<p className='my-1 p-0 text-muted'>Total Important</p>
								</div>
							</div>
							<div className='d-flex flex-row align-items-center flex-fill bg-white main-card p-3'>
								<div
									className='secondary-card-icon-rate d-flex align-items-center justify-content-center'
									style={{ backgroundColor: '#FFEBEB' }}
								>
									<img
										src='/assets/img/total-spam.png'
										alt=''
										// style={{ marginTop: "11px", marginLeft: "11px" }}
									></img>
								</div>
								<div className='ml-3'>
									<h5 className='my-2'>
										<b>
											{Object.keys(spamReport).length === 0
												? 0
												: spamReport.total_spam}
										</b>
									</h5>
									<p className='my-1 p-0 text-muted'>Total Spammed</p>
								</div>
							</div>
						</div>
					</div>
					<div className='container-fluid'>
						<div className='step1 bg-white col-lg-12 col-xl-12 col-md-12 col-sm-12 p-2 mt-3'>
							<span>
								{' '}
								<b>Step 3:</b> Find your emails below to see where they land.
							</span>
						</div>
						<div className='email_menu'>
							<ul>
								<li onClick={handletab}>
									<div
										className={
											provider === 'gmail' ? 'nav_item_active' : 'nav_item'
										}
										id='gmail'
									>
										<img src='/assets/img/gmail-small.png' alt='' id='gmail' />
										Gmail
									</div>
								</li>
								<li onClick={handletab}>
									<div
										className={
											provider === 'yahoo' ? 'nav_item_active' : 'nav_item'
										}
										id='yahoo'
									>
										<img src='/assets/img/yahoo-small.png' alt='' id='yahoo' />
										Yahoo
									</div>
								</li>
								{/* <li onClick={handletab}>
                  <div
                    className={
                      provider === "outlook" ? "nav_item_active" : "nav_item"
                    }
                    id="outlook"
                  >
                    <img
                      src="/assets/img/outlook-small.png"
                      alt=""
                      id="outlook"
                    />
                    Outlook
                  </div>
                </li> */}
								<li onClick={handletab}>
									<div
										className={
											provider === 'aol' ? 'nav_item_active' : 'nav_item'
										}
										id='aol'
									>
										<img src='/assets/img/aol-small.png' alt='' id='aol' />
										AOL Mail
									</div>
								</li>
								{/* <li onClick={handletab}>
                  <div
                    className={
                      provider === "icloud" ? "nav_item_active" : "nav_item"
                    }
                    id="icloud"
                  >
                    <img
                      src="/assets/img/icloud-black.png"
                      alt=""
                      id="icloud"
                      width="33px"
                      height="25px"
                    />
                    ICloud
                  </div>
                </li> */}
								<li onClick={handletab}>
									<div
										className={
											provider === 'mail.com' ? 'nav_item_active' : 'nav_item'
										}
										id='mail.com'
									>
										<img
											src='/assets/img/mail.com-black.png'
											alt=''
											id='mail.com'
										/>
										Mail.com
									</div>
								</li>
							</ul>
						</div>
						{/* <div className="row w-100 p-0 mt-5 d-flex step1">
              <div className="flex-grow-1 ">
                <input
                  className="search-bar w-100"
                  type="text"
                  placeholder="     Search by adress, domain, subject or ESP"
                />
              </div>
              <div className="">
                <button className="search-btn">
                  <i className="fas fa-search"></i>
                </button>
              </div>
            </div> */}

						<div className='row mt-3'>
							<div className='col-xl-3 col-md-6 col-6'>
								<div className='card overflow-hidden'>
									<div className='card-content'>
										<div className='card-body cleartfix p-2'>
											<div className='media align-items-stretch'>
												<div className='align-self-center'>
													{provider === 'gmail' ? (
														<GmailIcon />
													) : provider === 'yahoo' ? (
														<YahooIcon />
													) : provider === 'aol' ? (
														<AolIcon />
													) : provider === 'mail.com' ? (
														<MailComIcon />
													) : provider === 'icloud' ? (
														<ICloudIcon />
													) : provider === 'outlook' ? (
														<OutlookIcon />
													) : (
														// <img
														// 	className='float-left'
														// 	src={
														// 		provider === 'outlook'
														// 			? '/assets/img/outlook.svg'
														// 			: ''
														// 	}
														// 	alt=''
														// 	width='30px'
														// 	height='20px'
														// ></img>
														<></>
													)}
												</div>
												<div className='media-body ml-3'>
													<b>Total Emails</b>
												</div>
												<div className='align-self-center text-center'>
													<div
														style={{
															backgroundColor: '#E2F1F4',
															width: '40px',
														}}
													>
														<span style={{ color: '#4285F4' }}>
															{Object.keys(spamReport).length === 0
																? 0
																: provider === 'gmail'
																? 'Gmail - Gsuit' in spamReport
																	? spamReport['Gmail - Gsuit'].total
																	: 0
																: provider === 'yahoo'
																? 'Yahoo!' in spamReport
																	? spamReport['Yahoo!'].total
																	: 0
																: provider === 'outlook'
																? 'Outlook' in spamReport
																	? spamReport['Outlook'].total
																	: 0
																: provider === 'aol'
																? 'Aol' in spamReport
																	? spamReport['Aol'].total
																	: 0
																: provider === 'icloud'
																? 'iCloud' in spamReport
																	? spamReport['iCloud'].total
																	: 0
																: provider === 'mail.com'
																? 'Mail.com' in spamReport
																	? spamReport['Mail.com'].total
																	: 0
																: 0}
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='col-xl-3 col-md-6 col-6'>
								<div className='card overflow-hidden'>
									<div className='card-content'>
										<div className='card-body cleartfix p-2'>
											<div className='media align-items-stretch'>
												<div className='align-self-center'>
													<img
														className='float-left'
														src='/assets/img/inboxed.png'
														alt=''
													></img>
												</div>
												<div className='media-body ml-3'>
													<b>Inboxed</b>
												</div>
												<div className='align-self-center text-center'>
													<div
														style={{
															backgroundColor: '#E2F4E5',
															width: '40px',
														}}
													>
														<span style={{ color: '#34A853' }}>
															{Object.keys(spamReport).length === 0
																? 0
																: provider === 'gmail'
																? 'Gmail - Gsuit' in spamReport
																	? spamReport['Gmail - Gsuit'].inbox
																	: 0
																: provider === 'yahoo'
																? 'Yahoo!' in spamReport
																	? spamReport['Yahoo!'].inbox
																	: 0
																: provider === 'outlook'
																? 'Outlook' in spamReport
																	? spamReport['Outlook'].inbox
																	: 0
																: provider === 'aol'
																? 'Aol' in spamReport
																	? spamReport['Aol'].inbox
																	: 0
																: provider === 'icloud'
																? 'iCloud' in spamReport
																	? spamReport['iCloud'].inbox
																	: 0
																: provider === 'mail.com'
																? 'Mail.com' in spamReport
																	? spamReport['Mail.com'].inbox
																	: 0
																: 0}
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='col-xl-3 col-md-6 col-6'>
								<div className='card overflow-hidden'>
									<div className='card-content'>
										<div className='card-body cleartfix p-2'>
											<div className='media align-items-stretch'>
												<div className='align-self-center'>
													<img
														className='float-left'
														src='/assets/img/important.png'
														alt=''
													></img>
												</div>
												<div className='media-body ml-3'>
													<b>Important</b>
												</div>
												<div className='align-self-center text-center'>
													<div
														style={{
															backgroundColor: '#F4F3E2',
															width: '40px',
														}}
													>
														<span style={{ color: '#FF7676' }}>
															{Object.keys(spamReport).length === 0
																? 0
																: provider === 'gmail'
																? 'Gmail - Gsuit' in spamReport
																	? spamReport['Gmail - Gsuit'].important
																	: 0
																: provider === 'yahoo'
																? 'Yahoo!' in spamReport
																	? spamReport['Yahoo!'].important
																	: 0
																: provider === 'outlook'
																? 'Outlook' in spamReport
																	? spamReport['Outlook'].important
																	: 0
																: provider === 'aol'
																? 'Aol' in spamReport
																	? spamReport['Aol'].important
																	: 0
																: provider === 'icloud'
																? 'iCloud' in spamReport
																	? spamReport['iCloud'].important
																	: 0
																: provider === 'mail.com'
																? 'Mail.com' in spamReport
																	? spamReport['Mail.com'].important
																	: 0
																: 0}
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='col-xl-3 col-md-6 col-6'>
								<div className='card overflow-hidden'>
									<div className='card-content'>
										<div className='card-body cleartfix p-2'>
											<div className='media align-items-stretch'>
												<div className='align-self-center'>
													<img
														className='float-left'
														src='/assets/img/spammed.png'
														alt=''
													></img>
												</div>
												<div className='media-body ml-3'>
													<b>Spammed</b>
												</div>
												<div className='align-self-center text-center'>
													<div
														style={{
															backgroundColor: '#FFEBEB',
															width: '40px',
														}}
													>
														<span style={{ color: '#FF7676' }}>
															{Object.keys(spamReport).length === 0
																? 0
																: provider === 'gmail'
																? 'Gmail - Gsuit' in spamReport
																	? spamReport['Gmail - Gsuit'].spam
																	: 0
																: provider === 'yahoo'
																? 'Yahoo!' in spamReport
																	? spamReport['Yahoo!'].spam
																	: 0
																: provider === 'outlook'
																? 'Outlook' in spamReport
																	? spamReport['Outlook'].spam
																	: 0
																: provider === 'aol'
																? 'Aol' in spamReport
																	? spamReport['Aol'].spam
																	: 0
																: provider === 'icloud'
																? 'iCloud' in spamReport
																	? spamReport['iCloud'].spam
																	: 0
																: provider === 'mail.com'
																? 'Mail.com' in spamReport
																	? spamReport['Mail.com'].spam
																	: 0
																: 0}
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='container-fluid'>
						<div className='row mt-2 mr-0 email-tables'>
							<div className='col-12'>
								<EmailDetails
									width={
										provider === 'icloud'
											? '50px'
											: provider === 'mail.com'
											? '38px'
											: ''
									}
									height={
										provider === 'icloud' || provider === 'mail.com'
											? '30px'
											: ''
									}
									style={
										provider === 'gmail'
											? ''
											: provider === 'yahoo'
											? '5px'
											: provider === 'outlook'
											? '10px 5px'
											: provider === 'aol'
											? '10px 5px'
											: provider === 'icloud'
											? '6px -5px'
											: '6px 0px'
									}
									color={
										provider === 'gmail'
											? '#FEE8E7'
											: provider === 'yahoo'
											? '#EFE2F4'
											: provider === 'outlook'
											? '#E2F1F4'
											: provider === 'aol'
											? '#F3F2F1'
											: provider === 'icloud'
											? '#E2F1F4'
											: '#F3F2F1'
									}
									path={
										provider === 'gmail'
											? '/assets/img/GmailEmail.png'
											: provider === 'yahoo'
											? '/assets/img/yahooEmail.png'
											: provider === 'outlook'
											? '/assets/img/outlook.svg'
											: provider === 'aol'
											? '/assets/img/aol.svg'
											: provider === 'icloud'
											? '/assets/img/iCloud-logo.png'
											: '/assets/img/mail.com-icon.png'
									}
									provider={provider}
									spamReport={
										Object.keys(spamReport).length !== 0
											? provider === 'gmail'
												? 'Gmail - Gsuit' in spamReport
													? spamReport['Gmail - Gsuit']
													: []
												: provider === 'yahoo'
												? 'Yahoo!' in spamReport
													? spamReport['Yahoo!']
													: []
												: provider === 'outlook'
												? 'Outlook' in spamReport
													? spamReport['Outlook']
													: []
												: provider === 'aol'
												? 'Aol' in spamReport
													? spamReport['Aol']
													: []
												: provider === 'icloud'
												? 'iCloud' in spamReport
													? spamReport['iCloud']
													: []
												: provider === 'mail.com'
												? 'Mail.com' in spamReport
													? spamReport['Mail.com']
													: []
												: []
											: []
									}
									email={email}
								/>
							</div>
						</div>
					</div>
					<div>
						<div className='row text-center mt-5'>
							<div className='col-12'>
								<div className='spam-footer'>
									Inbox Better works with every Email Service Provider!
								</div>
								<div className='mute-text mt-3'>
									If you can send an email, Inbox Better will show you exactly
									where it’s going.
								</div>
							</div>
						</div>
						{/* <div className="row text-center mt-5 align-items-center">
            <div className="col-12 d-flex justify-content-center ">
              <div className="spam-footer-logo">
                <img src="/assets/img/ftr-gmail.png" alt="logo" />
              </div>
              <div className="spam-footer-logo">
                <img src="/assets/img/ftr-outlook.png" alt="logo" />
              </div>
              <div className="spam-footer-logo">
                <img src="/assets/img/ftr-yahoo.png" alt="logo" />
              </div>

              <div className="spam-footer-logo">
                <img src="/assets/img/ftr-icloud.png" alt="logo" />
              </div>
              <div className="spam-footer-logo">
                <img
                  src="/assets/img/ftr-aol.png"
                  alt="logo"
                  style={{ marginTop: "13px" }}
                />
              </div>
              <div className="spam-footer-logo">
                <img
                  src="/assets/img/mail-logo.png"
                  alt="logo"
                  style={{ marginTop: "-10px" }}
                />
              </div> */}
						{/* <div className="spam-footer-logo">
                <img
                  src="/assets/img/ftr-zoho.png"
                  alt="logo"
                  style={{ marginTop: "13px" }}
                />
              </div> */}
						{/* <div className="spam-footer-logo">
                <img src="/assets/img/ftr-yandex.png" alt="logo" />
              </div> */}
						{/* mmm */}
						{/* </div> */}
						{/* </div> */}
						<div className='mt-5 container-fluid d-flex justify-content-center'>
							<div className='row' style={{ width: '550px' }}>
								<Carousel />
							</div>
						</div>
					</div>
				</>
			) : (
				''
			)}
		</>
	)
}

export default SpamChecker
