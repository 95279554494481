import API from "./api";

const warmupService = {
  addWarmupEmailAccount: (data) => {
    return API.post("/add-warmup-email-account", data)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },

  getWarmupAccounts: (url) => {
    return API.get(url)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },

  rescheduleWarmup: (data) => {
    return API.post("/reschedule-warmup-campaign", data)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },

  getWarmup: id => {
    return API.get(`/warmup-campaigns/campaign/${id}`)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },

  getWarmupTimeline: (warmupId, start_date = '', end_date = '', pageNumber = 1) => {
    return API.get(
      start_date === '' && end_date === '' ? `/warmup-campaign-timeline/${warmupId}?page=${pageNumber}`
        : start_date !== '' && end_date !== '' ? `/warmup-campaign-timeline/${warmupId}?start_date=${start_date}&end_date=${end_date}&page=${pageNumber}`
          : `/warmup-campaign-timeline/${warmupId}?page=${pageNumber}`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },

  checkSMTPConnection: (data) => {
    return API.post("/smtp-connection-check", data)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },

  getEmailAccountProviders: () => {
    return API.get(`/email-account-providers`)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },

  getBarChartData: (id) => {
    return API.get(`/warmup-campaigns/stats/${id}`)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },

  getRecentActivities: (id) => {
    return API.get(`/warmup-campaigns/recent-activities/${id}`)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },

  getWarmupCampaigns: (id, page = 1) => {
    return API.get(
      `/warmup-campaigns/get-paginated-schedules/${id}?page=${page}`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },

  getDomainHealths: (url) => {
    return API.get(url)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  postRunNewTest: (data) => {
    return API.post("/domain-healths/run-a-new-test", data)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  getCampaignData: (id) => {
    return API.get(`/edit-warmup-email-account/${id}`)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },

  updateWarmupEmailCampaign: (id, data) => {
    return API.put(`/update-warmup-email-account/${id}`, data)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  deleteWarmupCampaign: (id) => {
    return API.delete(`/delete-warmup-email-account/${id}`)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  activateCampaign: (id) => {
    return API.get(`/warmup-campaigns/activate/${id}`)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  deactivateCampaign: (id) => {
    return API.get(`/warmup-campaigns/deactivate/${id}`)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  addKlaviyoWarmup: (data) => {
    return API.post("/klaviyo/create-warmup-campagin", data)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  addMailChimpWarmup: (data) => {
    return API.post("/Mailchimp/create-warmup-campagin", data)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  getEmailList: () => {
    return API.get("/imap-email-accounts")
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  addFromEmail: (data) => {
    return API.post("/spam-checkers/add-new-inbox", data)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  getSpamCheckers: (url) => {
    return API.get(url)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  addSendInBlueWarmup: (data) => {
    return API.post("/SendinBlue/create-warmup-campagin", data)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  getDashboardStats: () => {
    return API.get("/dashboard/stats")
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  getActiveCampaignList: () => {
    return API.get("/dashboard/active-campaigns")
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  getActiveCampaignStat: (id) => {
    return API.get(`/dashboard/active-campaign-stats/${id}`)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  avatarUpload: (data) => {
    return API.post("/user/settings/upload-avatar", data)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  getProfileAndWhois: (data) => {
    return API.post("/hyper-mail-system/get-profiles-and-whois", data)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  addHyperMailWarmup: (data) => {
    return API.post("/hyper-mail-system/create-warmup-campaign", data)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  addSendgridWarmup: (data) => {
    return API.post("/SendinGrid/create-warmup-campagin", data)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  addActiveCampaignWarmup: (data) => {
    return API.post("/active-campaign/create-warmup-campagin", data)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  addAWeberCampaignWarmup: (data) => {
    return API.post("/aweber/create-warmup-campaign", data)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  deleteDnsTest: (id) => {
    return API.delete(`/delete_dns_request/${id}`)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  deleteSpamCheckerTest: (id) => {
    return API.delete(`/delete_spamchecker_request/${id}`)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  authenticateWithAweber: (data) => {
    return API.post(`/aweber/auth`, data)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  getAweberAccountData: (token) => {
    return API.get(`/aweber/get-account-data/${token}`)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  getMailchimpAccountData: (apiKey) => {
    return API.post(`/mailchimp/get-account-detail`, { 'api_key': apiKey })
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  getFromFieldsGetResponse: (apiKey) => {
    return API.post(`/get-response/get-from-fields`, { 'api_key': apiKey })
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  getFromFieldsSendInBlue: (apiKey) => {
    return API.post(`/send-in-blue/get-from-fields`, { 'api_key': apiKey })
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  getFromFieldsSendGrid: (apiKey) => {
    return API.post(`/send-grid/get-from-fields`, { 'api_key': apiKey })
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  addGetResponseCampaign: (data) => {
    return API.post(`/get-response/create-warmup-campaign`, data)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
};

export default warmupService;
