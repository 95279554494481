import './message.scss'
import moment from 'moment'
import { useState } from 'react'
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import TicketService from '../../api-services/ticketService'
import { listMessagesByTicket } from '../../store/actions/messages.action'
import { API_CALL_IN_PROGRESS } from '../../store/types'

const MessageSection = ({ messagesByTicket, apiCallInProgress }) => {
	const dispatch = useDispatch()

	const [messageObject, setMessageObject] = useState({
		message: '',
		support_ticket_id: 0,
	})
	const [ticketId, setTicketId] = useState(0)
	const [sending, setSending] = useState(false)
	const [ticketOpenStatus, setTicketOpenStatus] = useState(false)

	const sendMessage = async e => {
		e.preventDefault()
		setSending(true)

		try {
			const response = await TicketService.addMessageToTicket(messageObject)

			dispatch(listMessagesByTicket(ticketId))
			setMessageObject({ ...messageObject, message: '' })
		} catch (error) {
		} finally {
			setSending(false)
		}
	}

	useEffect(() => {
		const interval = setInterval(() => {
			// if (ticketId !== 0) {
			if (!apiCallInProgress) {
				dispatch({ type: API_CALL_IN_PROGRESS })
				dispatch(listMessagesByTicket(ticketId))
			}
			// }
		}, 15000)

		return () => clearInterval(interval)
	}, [apiCallInProgress])

	useEffect(() => {
		if (messagesByTicket !== undefined) {
			if (messagesByTicket.length !== 0) {
				setMessageObject({
					...messageObject,
					support_ticket_id: JSON.parse(localStorage.getItem('currentTicket'))
						.id,
				})
				setTicketId(JSON.parse(localStorage.getItem('currentTicket')).id)
				setTicketOpenStatus(
					!JSON.parse(localStorage.getItem('currentTicket')).is_closed
				)
			} else {
				setMessageObject({
					...messageObject,
					support_ticket_id: JSON.parse(localStorage.getItem('currentTicket'))
						.id,
				})
				setTicketId(JSON.parse(localStorage.getItem('currentTicket')).id)
				setTicketOpenStatus(
					!JSON.parse(localStorage.getItem('currentTicket')).is_closed
				)
			}
		}
	}, [messagesByTicket])

	return (
		<div
			className='px-0'
			// style={{
			// 	height: '84vh',
			// }}
		>
			<div
				style={{
					height: '100%',
					overflowY: 'auto',
					marginRight: '2px',
				}}
				className='row mb-1 ml-1 d-flex align-items-end'
			>
				<div
					className='col'
					style={{ zIndex: '-1' }}
					// style={{ position: 'fixed', bottom: '5.5%', width: '32%' }}
				>
					{messagesByTicket !== undefined ? (
						messagesByTicket.length === 0 ? (
							<p
								className='d-flex justify-content-center align-items-center'
								style={{ fontWeight: 'bold', fontSize: 'large' }}
							>
								Send a message to start chat...
							</p>
						) : (
							messagesByTicket.map((message, i) => (
								<div
									className='row'
									style={{
										justifyContent: message.user.is_admin
											? 'flex-start'
											: 'flex-end',
									}}
								>
									<div
										id={
											message.user.is_admin
												? 'message-container-receiver'
												: 'message-container-sender'
										}
									>
										<div className='col pl-0'>
											<p className='p-0 m-0 row'>
												<div className='col'>{message.message}</div>
											</p>
											<small className='p-0 row'>
												<div className='col pr-0'>
													<div style={{ float: 'right' }}>
														{moment(message.created_at).format('hh:mm A')}
													</div>
												</div>
											</small>
										</div>
									</div>
								</div>
							))
						)
					) : (
						<></>
					)}
				</div>
			</div>

			{ticketOpenStatus ? (
				<div className='d-flex align-items-end px-3'>
					<div className='flex-grow-1 align-self-stretch mr-2'>
						<input
							type='text'
							className='input-border'
							value={messageObject.message}
							style={{ width: '100%', height: '100%', fontSize: 'medium' }}
							onChange={e =>
								setMessageObject({ ...messageObject, message: e.target.value })
							}
						/>
					</div>
					<div className=''>
						<button
							type='submit'
							onClick={sendMessage}
							disabled={messageObject.message === ''}
							className='btn btn-sm c-btn text-center'
						>
							Send
						</button>
					</div>
				</div>
			) : (
				<></>
			)}
		</div>
	)
}

const mapStateToProps = state => {
	return {
		messagesByTicket: state.messagesReducer.messages,
		apiCallInProgress: state.apiCallsInProgress.apiCallInProgress,
	}
}

export default connect(mapStateToProps, null)(MessageSection)
