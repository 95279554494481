import React, { Component, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

// Layout Related Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

import { toggleSidebar } from "../../../store/actions/layout";
import "./layout.scss";
import { setIsToggle } from "../../../store/actions/help";

const Layout = ({
  children,
  location,
  helpFragment,
  isSideBarToggled,
  isToggle,
  ...rest
}) => {
  const [width] = useWindowSize();
  const dispatch = useDispatch();
  const node = useRef();
  const [disappointed, setDisappointed] = useState(true);
  const [neutral, setNeutral] = useState(true);
  const [smile, setSmile] = useState(true);

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      return;
    } else {
      dispatch(setIsToggle(false));
    }
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  useEffect(() => {
    if (isSideBarToggled && localStorage.getItem('token')) {
      collapseSidebar();
    } else if (localStorage.getItem('token')) {
      dCollpaseSidebar();
    }
  }, []);
  function useWindowSize() {
    const [size, setSize] = useState([window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [size]);
    return size;
  }

  // console.log(width);

  const collapseSidebar = () => {
    let s_menu = document.querySelector(".menuarrow");
    let s_bar = document.querySelector(".sidebar");
    let s_barbg = document.querySelector(".sidebarbg");
    let logo = document.querySelector(".logo");
    let m_content = document.querySelector(".right-content");
    let ftr = document.querySelector(".ftr");

    s_menu.classList.add("actarrow");
    s_bar.classList.add("sidebarin");
    s_bar.style.width = "70px";
    s_barbg.style.width = "70px";
    logo.style.width = "70px";
    s_barbg.style.width = "70px";
    logo.style.width = "70px";
    logo.classList.add("logoin");
    m_content.classList.add("main-content-active");
    ftr.classList.add("ftr-active");
  };
  const dCollpaseSidebar = () => {
    let s_menu = document.querySelector(".menuarrow");
    let s_bar = document.querySelector(".sidebar");
    let s_barbg = document.querySelector(".sidebarbg");
    let logo = document.querySelector(".logo");
    let m_content = document.querySelector(".right-content");
    let ftr = document.querySelector(".ftr");

    s_menu.classList.remove("actarrow");
    s_bar.style.width = "300px";
    s_bar.classList.remove("sidebarin");
    s_bar.classList.remove("mobIn");
    s_bar.style.width = "300px";
    s_barbg.style.width = "300px";
    logo.style.width = "300px";
    logo.classList.remove("logoin");
    m_content.classList.remove("main-content-active");
    ftr.classList.remove("ftr-active");
  };

  const toggled = () => {
    if (!isSideBarToggled || width <= 991) {
      collapseSidebar();
      dispatch(toggleSidebar(true));
    } else {
      dCollpaseSidebar();
      dispatch(toggleSidebar(false));
    }
  };

  const handleSelect = (e) => {
    if (e.target.id === "disappointed") {
      setDisappointed(true);
      setNeutral(false);
      setSmile(false);
    } else if (e.target.id === "neutral") {
      setNeutral(true);
      setSmile(false);
      setDisappointed(false);
    } else if (e.target.id === "smile") {
      setDisappointed(false);
      setNeutral(false);
      setSmile(true);
    }
  };

  return (
    <React.Fragment>
      <div className="wrapper">
        {/* Header */}
        {localStorage.getItem('token') === null
          || localStorage.getItem('token') === undefined
          ? <></>
          : <Header toggled={toggled} />
        }
        <div className={localStorage.getItem('token') ? "main-content d-block" : "d-block"}>
          {/* Side Navbar */}
          {localStorage.getItem('token') === null
            || localStorage.getItem('token') === undefined
            ? <></>
            : <Sidebar />
          }
          {/* <!-- Right Content Start Here --> */}
          <div
            className={localStorage.getItem('token') ? "right-content" : ""}
            style={{ background: "#F3FDFF", minHeight: localStorage.getItem('token') ? "87vh" : "100vh", width: localStorage.getItem('token') === null || localStorage.getItem('token') === undefined ? "100vw" : "" }}
          >
            <div className="pb-3">{children}</div>
          </div>
          <div className="d-block">
            <div className={localStorage.getItem('token') ? "ftr" : "ftr-noauth"} style={{ background: "#F3FDFF" }}>
              <Footer />
            </div>
          </div>
        </div>
        {/* <!-- main content end here --> */}
        {/* Footer here */}

        {/* Help Fragment */}

        <div className="container email-view" ref={node}>
          <div
            style={{
              backgroundColor: "#0994F8",
              height: "50px",
              paddingTop: "10px",
            }}
          >
            <span
              className="float left ml-4"
              style={{ color: "white", fontSize: "20px" }}
            >
              <i
                className="far fa-question-circle mr-3"
                style={{
                  color: "white",
                  fontSize: "25px",
                  fontWeight: "lighter",
                }}
              ></i>
              Help
            </span>
            <span
              id="closeBtn"
              onClick={() => dispatch(setIsToggle(false))}
              className="float-right mr-4"
            >
              <i
                className="fas fa-times"
                style={{
                  color: "white",
                  fontSize: "25px",
                  fontWeight: "lighter",
                }}
              ></i>
            </span>
          </div>
          <div className="main-container mt-n5">
            <div dangerouslySetInnerHTML={{ __html: helpFragment }} />
            <div className="help-footer text-center text-muted">
              <span> Did this answer your question?</span>
              <div className="mt-2">
                <button
                  className={disappointed ? "face-select" : "face"}
                  onClick={handleSelect}
                  id="disappointed"
                  style={{
                    filter: disappointed ? "grayscale(0%)" : "grayscale(100%)",
                  }}
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Disappointed"
                >
                  😞
                </button>
                <button
                  className={neutral ? "face-select" : "face"}
                  onClick={handleSelect}
                  id="neutral"
                  style={{
                    filter: neutral ? "grayscale(0%)" : "grayscale(100%)",
                  }}
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Neutral Face"
                >
                  😐
                </button>
                <button
                  className={smile ? "face-select" : "face"}
                  onClick={handleSelect}
                  id="smile"
                  style={{
                    filter: smile ? "grayscale(0%)" : "grayscale(100%)",
                  }}
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Happy"
                >
                  😃
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Help Fragment END */}
      </div>
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    isSideBarToggled: state.layoutReducer.isSideBarToggled,
    helpFragment: state.helpReducer.helpFragment,
    isToggle: state.helpReducer.isToggle,
  };
}

export default connect(mapStateToProps, null)(withRouter(Layout));
