import React, { useState, useEffect } from "react"
import HelpComponent from "../../components/Help/Help"
import Progressbar from "react-js-progressbar"
import FadeLoader from "react-spinners/FadeLoader"
import { useParams } from "react-router-dom"
import ProvidersResults from "./ProvidersResults"
import ProgressBarHorizontal from "./ProgressBarHorizontal"
import "./potentialInboxing.scss"
import EmailBodyInsight from "./EmailBodyInsight"
import SubjectHeadlineInsight from "./SubjectHeadlineInsight"
import OverallResult from "./OverallResult"
import getSliceColor from "./getColor"
import analyzerService from "../../api-services/analyzerService"

const Analyzer = (props) => {
  let { id } = useParams()

  const [email, setEmail] = useState("")
  const [report, setReport] = useState([])

  useEffect(() => {
    if (window.location.search !== "")
      localStorage.setItem(
        "noAuthAnalyzerReport",
        JSON.stringify(
          JSON.parse(decodeURIComponent(window.location.search.substring(1)))
            .str
        )
      )
  }, [])

  useEffect(async () => {
    console.log(props)
    if (props.location.state && props.location.state !== undefined) {
      if (props.location.state.tempObj.emailData !== undefined) {
        const response = await analyzerService.analyzeEmail(
          props.location.state.tempObj.emailData
        )

        setReport(
          response.data === null || response.data === undefined
            ? response
            : response.data
        )
      } else {
        setReport(JSON.parse(props.location.state.tempObj))
        setEmail(props.location.state.email)
      }
    } else if (localStorage.getItem("noAuthAnalyzerReport")) {
      setReport(JSON.parse(localStorage.getItem("noAuthAnalyzerReport")))
    } else if (localStorage.getItem("analyzer_report")) {
      JSON.parse(localStorage.getItem("analyzer_report")).map((acc) => {
        if (acc.id == id) {
          setReport(JSON.parse(acc.report))
          setEmail(acc.from_email)
        }
      })
    }
  }, [props])

  return (
    <>
      <div className='container-fluid bg-white my-1 right-header'>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='col-12 my-4 d-flex align-items-center'>
            <span
              className='d-flex align-items-center mr-5'
              style={{ fontSize: "15px" }}
            >
              <div>
                <img
                  className='left_arrow'
                  style={{
                    width: "20px",
                    height: "15px",
                    cursor: "pointer",
                    marginTop: "-2px",
                  }}
                  src='/assets/img/caret-left-icon.svg'
                  onClick={() => props.history.push("/email-inboxing-grader")}
                />
                &nbsp;&nbsp;&nbsp;
                <b> Email Inboxing Grader:</b>&nbsp;&nbsp; {email}
              </div>
              {localStorage.getItem("noAuthAnalyzerReport") ? (
                <></>
              ) : (
                <b>
                  &nbsp;
                  <span>
                    <HelpComponent
                      filePath={"/help-fragments/2-domainHelp.html"}
                    />
                  </span>
                </b>
              )}
            </span>
          </div>
        </div>
      </div>
      <div className='container-fluid'>
        <div className='row header-bar mt-4 p-3 mr-0 bg-white'>
          <div className='col-3 pl-0'>
            <div className='card flex-row py-3 analyzer-card'>
              <div className='card-body p-0' style={{ display: "table" }}>
                <div className='text-center'>
                  <Progressbar
                    pathShadow='none'
                    input={
                      report.length == 0
                        ? 0
                        : report.summary !== undefined
                        ? report.summary.details[0].percentage
                        : ""
                    }
                    pathWidth={30}
                    pathColor={getSliceColor(
                      report.length == 0
                        ? 0
                        : report.summary !== undefined
                        ? report.summary.details[0].percentage
                        : ""
                    )}
                    trailWidth={50}
                    trailColor='#F2F2F2'
                    animation={{ ease: "linear" }}
                    size={100}
                    pathLinecap={"round"}
                    textStyle={{
                      fill: getSliceColor(
                        report.length == 0
                          ? 0
                          : report.summary !== undefined
                          ? report.summary.details[0].percentage
                          : ""
                      ),
                      fontWeight: "800",
                      fontSize: "44px",
                    }}
                    clockwise={false}
                  ></Progressbar>
                </div>
                <div className='overallResult px-4 mb-4'>
                  <ProgressBarHorizontal
                    report={
                      report.length == 0
                        ? null
                        : report.summary !== undefined
                        ? report.summary.details[0]
                        : ""
                    }
                  />
                  <ProgressBarHorizontal
                    report={
                      report.length == 0
                        ? null
                        : report.summary !== undefined
                        ? report.summary.details[1]
                        : ""
                    }
                  />
                  <ProgressBarHorizontal
                    report={
                      report.length == 0
                        ? null
                        : report.summary !== undefined
                        ? report.summary.details[2]
                        : ""
                    }
                  />
                  <ProgressBarHorizontal
                    report={
                      report.length == 0
                        ? null
                        : report.summary !== undefined
                        ? report.summary.details[3]
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='col-9'>
            {report.length == 0 ? (
              <div className='text-center' style={{ marginTop: "150px" }}>
                <FadeLoader
                  loading={report.length === 0 ? true : false}
                  color={"#0092FB"}
                  height={20}
                  width={5}
                />
              </div>
            ) : (
              <>
                <div id='plan-card' className='px-4 bg-light rounded'>
                  <div className='d-flex p-2 my-3'>
                    <div className='icon-bg d-flex'>
                      <img
                        src='/assets/img/analyzer-overall-result.png'
                        alt=''
                      />
                    </div>
                    <div className='ml-3 mt-3'>
                      <b>Overall % of Inboxing On The Following Providers</b>
                    </div>
                  </div>
                  <OverallResult
                    percentage={
                      report.potentialInBoxing !== undefined
                        ? report.potentialInBoxing.overallResult.percentage
                        : ""
                    }
                  />
                  <ProvidersResults
                    potentialInboxingReport={
                      report.potentialInBoxing !== undefined
                        ? report.potentialInBoxing.overallResult.details
                        : ""
                    }
                  />
                </div>
                <div id='plan-card' className='px-3 py-1 mt-3 bg-light rounded'>
                  <SubjectHeadlineInsight
                    subjectHeadLineInsight={
                      report.contentInsight !== undefined
                        ? report.contentInsight.subjectHeadlineInsight
                        : ""
                    }
                  />
                </div>
                <div id='plan-card' className='px-3 py-1 mt-3 bg-light rounded'>
                  <EmailBodyInsight
                    bodyStructureinsight={
                      report.contentInsight !== undefined
                        ? report.contentInsight.bodyStructureInsight
                            .overallResult
                        : ""
                    }
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Analyzer
