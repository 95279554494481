import React, { useState, useEffect } from "react"
import moment from "moment"
import DragableChart from "../warmupScheduleStats/DragableChart"
import HelpComponent from "../../../components/Help/Help"
import "./warmupOptions.scss"
import WarmupSuggestionModal from "../warmupSuggestionModal/WarmupSuggestionModal"

const WarmupOptionsContent = (props) => {
  const {
    errors,
    period,
    isEdit,
    inboxForm,
    setInboxForm,
    campaignData,
    isReschedule,
    handleFormChange,
  } = props
  const [customActive, setCustomActive] = useState(true)
  const [randomActive, setRandomActive] = useState(false)

  const handleChart = (e) => {
    if (e.target.id === "random_chart") {
      setRandomActive(true)
      setCustomActive(false)
    } else {
      setRandomActive(false)
      setCustomActive(true)
    }
  }

  useEffect(() => {}, [])

  let maxDate = isReschedule
    ? new Date(inboxForm.end_date)
    : new Date(inboxForm.start_date)
  let maxDays = moment(maxDate, "YYYY-MM-DD")
    .add(45, "days")
    .format("YYYY-MM-DD")
  return (
    <div className='col-sm-12'>
      <div className='row'>
        <div className='col-sm-6'>
          <b>
            Create Your Inbox Campaign{" "}
            <span>
              <HelpComponent />
            </span>
          </b>
        </div>
      </div>
      <div className='row mt-4'>
        <div
          onClick={handleChart}
          className='col-lg-6 col-md-6 col-sm-12'
          style={{ pointerEvents: isReschedule || isEdit ? "none" : "initial" }}
        >
          <div
            className={`card ${
              randomActive
                ? " warmup-option-card-selected"
                : "warmup-option-card"
            }`}
            id='random_chart'
          >
            <img
              id='random_chart'
              src='/assets/img/random_icon.png'
              className='card-img-top'
              alt='...'
              style={{ width: "50px", height: "50px" }}
            />
            <div className='card-body' id='random_chart'>
              <h5 className='card-title' id='random_chart'>
                Randomised
              </h5>
              <p
                className='card-text'
                style={{ color: "#7E7E7E", fontSize: "14px" }}
                id='random_chart'
              >
                For Experimentation
              </p>
            </div>
          </div>
        </div>
        <div
          onClick={handleChart}
          className=' col-lg-6 col-md-6 col-sm-12'
          style={{ pointerEvents: isReschedule || isEdit ? "none" : "initial" }}
        >
          <div
            className={`card ${
              customActive
                ? " warmup-option-card-selected"
                : "warmup-option-card"
            }`}
            id='custom_chart'
          >
            <img
              id='custom_chart'
              src='/assets/img/custom_icon.png'
              className='card-img-top'
              alt='...'
              style={{ width: "50px", height: "50px" }}
            />
            <div className='card-body' id='custom_chart'>
              <h5 className='card-title' id='custom_chart'>
                Custom
              </h5>
              <p
                className='card-text'
                style={{ color: "#7E7E7E", fontSize: "14px" }}
                id='custom_chart'
              >
                For cold email experts
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col-sm-6'>
          <b>
            Schedule Your Warm-Up
            <a data-toggle='modal' href='#warmupSuggestionModal'>
              <i
                className='far fa-info-circle ml-2'
                style={{ cursor: "pointer", color: "#229FF9" }}
              ></i>
            </a>
          </b>
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col-sm-3'>
          <label htmlFor='warmupName' className='form-label'>
            Start Date <span className='text-danger'>*</span>
          </label>
          <input
            // disabled={isReschedule}
            className='form-control'
            id='inputWithoutBackground'
            type='date'
            // value={inboxForm.start_date}
            defaultValue={inboxForm.start_date}
            name='start_date'
            onChange={handleFormChange}
            min={
              isReschedule
                ? moment(inboxForm.start_date).format("YYYY-MM-DD")
                : moment(new Date()).format("YYYY-MM-DD")
            }
            max={moment(
              inboxForm.end_date === "" ? "" : inboxForm.end_date
            ).format("YYYY-MM-DD")}
            required
          />
          {errors.hasOwnProperty("start_date")
            ? errors.start_date.map((e) => (
                <div className='text-danger mt-2 ml-1'> {e} </div>
              ))
            : ""}
        </div>
        <div className='col-sm-3'>
          <label htmlFor='warmupName' className='form-label'>
            End Date <span className='text-danger'>*</span>
          </label>
          <input
            required
            type='date'
            name='end_date'
            // disabled = {isEdit}
            className='form-control'
            id='inputWithoutBackground'
            onChange={handleFormChange}
            // value={inboxForm.end_date}
            defaultValue={inboxForm.end_date}
            max={isReschedule ? "" : maxDays}
            min={
              isReschedule
                ? moment(inboxForm.end_date).format("YYYY-MM-DD")
                : moment().add(10, "days").format("YYYY-MM-DD")
            }
          />
          {errors.hasOwnProperty("end_date")
            ? errors.end_date.map((e) => (
                <div className='text-danger mt-2 ml-1'> {e} </div>
              ))
            : ""}
          <small className='text-muted'>
            45 days minimum recommended{" "}
            <span style={{ color: "#46AEFA" }}>(always Inbox)</span>
          </small>
        </div>
        {/* </div> */}
        {/* per day sent & reply rate */}
        {/* <div className="row mt-4"> */}
        <div className='col-sm-3'>
          <label htmlFor='warmupName' className='form-label'>
            Maximum emails sent/day <span className='text-danger'>*</span>
          </label>
          <input
            min='0'
            max='60'
            required
            type='number'
            // disabled={isEdit}
            className='form-control'
            name='emails_sent_per_day'
            id='inputWithoutBackground'
            onChange={handleFormChange}
            value={inboxForm.emails_sent_per_day}
            defaultValue={inboxForm.emails_sent_per_day}
          />
          {errors.hasOwnProperty("emails_sent_per_day")
            ? errors.emails_sent_per_day.map((e) => (
                <div className='text-danger mt-2 ml-1'> {e} </div>
              ))
            : ""}
          <small className='text-muted'>
            40 recommended, 60 max{" "}
            <span style={{ color: "#46AEFA" }}>(sending limit)</span>
          </small>
        </div>

        <div className='col-sm-3'>
          <label htmlFor='warmupName' className='form-label'>
            Reply rate/day, % <span className='text-danger'>*</span>
          </label>
          <input
            min='0'
            max='50'
            required
            type='number'
            className='form-control'
            name='reply_rate_per_day'
            id='inputWithoutBackground'
            onChange={handleFormChange}
            value={inboxForm.reply_rate_per_day}
            defaultValue={inboxForm.reply_rate_per_day}
          />
          {errors.hasOwnProperty("reply_rate_per_day")
            ? errors.reply_rate_per_day.map((e) => (
                <div className='text-danger mt-2 ml-1'> {e} </div>
              ))
            : ""}
          <small className='text-muted'>
            30% recommended, 50% max{" "}
            <span style={{ color: "#46AEFA" }}>(reply rate limit)</span>
          </small>
        </div>
      </div>
      <div className='mt-2'>
        {period.endDate === "" ||
        period.startDate === "" ||
        period.endDate === undefined ||
        period.startDate === undefined ||
        inboxForm.emails_sent_per_day === 0 ? (
          <span> </span>
        ) : (
          <DragableChart
            period={period}
            isEdit={isEdit}
            inboxForm={inboxForm}
            setInboxForm={setInboxForm}
            campaignData={campaignData}
            randomActive={randomActive}
            customActive={customActive}
          />
        )}
      </div>
      <WarmupSuggestionModal />
    </div>
  )
}

export default WarmupOptionsContent
