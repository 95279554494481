import { SETMESSAGES } from "../types/index";

const initialState = {
  messages: []
};

const messagesReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SETMESSAGES:
      return {
        ...state,
        messages: payload
      }

    default: {
      return state
    }
  }
};

export default messagesReducer
