import axios from "axios"
import "../Sequences.scss"
import { ImDownload3 } from "react-icons/im"
import { Link, useParams } from "react-router-dom"
import React, { useState, useEffect } from "react"
import FadeLoader from "react-spinners/FadeLoader"
import SequencesService from "../../../api-services/sequencesService"

const SequenceDetail = (props) => {
  const { id } = useParams()

  const [loading, setLoading] = useState(false)
  const [emailData, setEmailData] = useState("")
  const [emailContent, setEmailContent] = useState("")
  const [sequenceData, setSequenceData] = useState({})
  const [emailContentId, setEmailContentId] = useState("")
  const [loadingEmailContent, setLoadingEmailContent] = useState(false)

  const fetchSequenceData = async () => {
    setLoading(true)

    const response = await SequencesService.getSequenceDetail(id)

    if (response.success) {
      setLoading(false)
      setSequenceData(response.sequence)
    } else {
      setLoading(false)
    }
  }

  const getEmailContent = async (e) => {
    setLoadingEmailContent(true)

    setEmailContentId(e.target.dataset.id)

    const response = await SequencesService.getEmailContent(e.target.dataset.id)

    if (response.success) {
      setLoadingEmailContent(false)
      const element = document.getElementById("email-content")
      element.innerHTML = response.email.email_content.body
      setEmailData(response.email)
      setEmailContent(response.email.email_content.body)
    } else {
      setLoadingEmailContent(false)
    }
  }

  const downloadPDF = async () => {
    // const response = await SequencesService.downloadPDF(emailContentId)

    // if (response.success === undefined) {
    // const file = response

    const link = document.createElement("a")
    link.href = `${process.env.REACT_APP_INBOX_BETTER_API_URL}/email-content-pdf/${emailContentId}`
    link.setAttribute("download", "file")
    document.body.appendChild(link)
    link.click()
    // setDownloading(false)
    // }
  }

  const downloadImage = async () => {
    const response = await SequencesService.downloadImage(emailContentId)

    if (response.success === undefined) {
      const file = URL.createObjectURL(response)

      const link = document.createElement("a")
      link.href = file
      link.setAttribute("download", "file")
      document.body.appendChild(link)
      link.click()
      // setDownloading(false)
    }
  }

  useEffect(() => {
    fetchSequenceData()
  }, [])

  return (
    <div className='container-fluid'>
      {loading ? (
        <div className='text-center pb-5 mb-5'>
          <FadeLoader
            width={5}
            height={20}
            loading={loading}
            color={"#0092FB"}
          />
        </div>
      ) : Object.keys(sequenceData).length === 0 ? (
        <div>
          <p>No Data Found</p>
        </div>
      ) : (
        <>
          <div className='right_header row mt-1'>
            <div className='col-12 col-xl-7 col-lg-7 col-md-7 col-sm-12 mt-4'>
              {/* <div className="row"> */}
              <img
                className='left_arrow'
                style={{
                  width: "15px",
                  height: "15px",
                  cursor: "pointer",
                  marginLeft: "30px",
                  marginRight: "10px",
                }}
                src='/assets/img/caret-left-icon.svg'
                onClick={() => props.history.push(`/email-sequences`)}
              />
              <nav
                aria-label='breadcrumb'
                style={{ marginLeft: "40px", marginTop: "-31px" }}
              >
                <ol className='breadcrumb' style={{ background: "white" }}>
                  <li className='breadcrumb-item'>
                    <a href='/warm-up-emails' style={{ fontSize: "15px" }}>
                      Home
                    </a>
                  </li>
                  <li
                    className='breadcrumb-item active'
                    aria-current='page'
                    style={{ fontSize: "15px", color: "black" }}
                  >
                    <b>{sequenceData.brand.name}</b>&nbsp;
                    <span>({sequenceData.name})</span>
                  </li>
                </ol>
              </nav>
              {/* </div> */}
            </div>
          </div>
          <div className='row my-2'>
            <div className='col-12 mb-4'>
              <div className='card'>
                <div className='card-header text-success inbox-item-name'>
                  <h5>
                    <b>{sequenceData.name}</b>
                  </h5>
                </div>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-6'>
                      <div className='row mb-2'>
                        <div className='col-4'>
                          <b className='text-muted'>Name:</b>
                        </div>
                        <div className='col-8'>{sequenceData.name}</div>
                      </div>
                      <div className='row mb-2'>
                        <div className='col-4'>
                          <b className='text-muted'>Brand:</b>
                        </div>
                        <div className='col-8'>
                          {sequenceData.brand !== undefined
                            ? sequenceData.brand.name
                            : ""}
                        </div>
                      </div>
                      <div className='row mb-2'>
                        <div className='col-4'>
                          <b className='text-muted'>Tags:</b>
                        </div>
                        <div className='col-8'>
                          {sequenceData.tags !== undefined &&
                          sequenceData.tags.length !== 0 ? (
                            sequenceData.tags.map((tag, i) => (
                              <p key={i}>{tag.name}</p>
                            ))
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='col-6 text-center'>
                      <Link
                        style={{
                          pointerEvents: emailData === "" ? "none" : "initial",
                        }}
                        className='btn btn-lg btn-warning text-white'
                        to={{
                          pathname: `/email-inboxing-grader/analyzer/${emailContentId}`,
                          state: {
                            tempObj: {
                              emailData,
                            },
                          },
                        }}
                      >
                        View Inboxing Report
                      </Link>
                      <p className='mt-2'>
                        To View Email Inboxing Report Select One of the Email
                        From The Sequence
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row mb-2'>
            <div className='col-4'>
              <div className='card'>
                <div className='card-header'>
                  <b className='text-muted'>Sequence Emails</b> | <a>Filter</a>
                </div>
                <div className='card-body'>
                  <div
                    className='list-group'
                    // style={{
                    //   height: "30vh",
                    //   overflowY: "auto",
                    //   padding: "2%",
                    // }}
                  >
                    {Object.keys(sequenceData.updatedEmails) !== undefined &&
                    Object.keys(sequenceData.updatedEmails).length !== 0 ? (
                      Object.keys(sequenceData.updatedEmails).map(
                        (updatedEmailKey, i) => (
                          <div>
                            <b className='text-primary'>{updatedEmailKey}</b>{" "}
                            <em className='text-primary'>
                              (
                              {
                                sequenceData.updatedEmails[updatedEmailKey]
                                  .length
                              }
                              )
                            </em>
                            <>
                              {sequenceData.updatedEmails[updatedEmailKey].map(
                                (updatedEmail, i) => (
                                  <a
                                    style={{ cursor: "pointer" }}
                                    data-id={updatedEmail.email_id}
                                    onClick={getEmailContent}
                                    className='list-group-item list-group-item-action mt-2'
                                  >
                                    {updatedEmail.title}
                                  </a>
                                )
                              )}
                            </>
                          </div>
                        )
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='col-8'>
              <div className='card'>
                <div className='card-header'>
                  <b className='text-muted'>Email Content</b>
                  <a
                    className='float-right'
                    onClick={async () => {
                      axios({
                        url: `${process.env.REACT_APP_INBOX_BETTER_API_URL}/email-content-pdf/${emailContentId}`,
                        method: "GET",
                        headers: {
                          Authorization: `Bearer ${
                            localStorage.getItem("token") || ""
                          }`,
                        },
                        responseType: "blob",
                      }).then((response) => {
                        const url = window.URL.createObjectURL(
                          new Blob([response.data])
                        )
                        const link = document.createElement("a")
                        link.href = url
                        link.setAttribute("download", "file.pdf")
                        document.body.appendChild(link)
                        link.click()
                      })
                    }}
                    style={{
                      background: "none",
                      textTransform: "none",
                      cursor: emailContentId === "" ? "default" : "pointer",
                      pointerEvents: emailContentId === "" ? "none" : "initial",
                    }}
                  >
                    <ImDownload3 size={19} /> PDF
                  </a>
                  <a
                    className='float-right mr-4'
                    onClick={async () => {
                      axios({
                        url: `${process.env.REACT_APP_INBOX_BETTER_API_URL}/email-content-image/${emailContentId}`,
                        method: "GET",
                        headers: {
                          Authorization: `Bearer ${
                            localStorage.getItem("token") || ""
                          }`,
                        },
                        responseType: "blob",
                      }).then((response) => {
                        const url = window.URL.createObjectURL(
                          new Blob([response.data])
                        )
                        const link = document.createElement("a")
                        link.href = url
                        link.setAttribute("download", "file.png")
                        document.body.appendChild(link)
                        link.click()
                      })
                    }}
                    style={{
                      background: "none",
                      textTransform: "none",
                      cursor: emailContentId === "" ? "default" : "pointer",
                      pointerEvents: emailContentId === "" ? "none" : "initial",
                    }}
                  >
                    <ImDownload3 size={19} /> Image
                  </a>
                </div>
                <div className='card-body text-center' id='email-content'></div>
                {emailContent === "" ? (
                  <div className='text-center'>
                    <img
                      width='180px'
                      src='https://app.sequences.ai/public/img/view-email.gif'
                      alt='to view content click on email'
                    />
                    <p>
                      To View Email Content Click on One of The Sequence Email
                      on Left
                    </p>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default SequenceDetail
