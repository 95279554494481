import React, { useState } from "react"
import { IconContext } from "react-icons"
import { FiChevronDown, FiChevronUp } from "react-icons/fi"
import HelpComponent from "../../components/Help/Help"
import "./style.scss"

const Accordion = (props) => {
  const index = ""
  const { test } = props
  const [passTest, setPassTest] = useState(
    test.filter((item) => item.color === "green")
  )
  const [clicked, setClicked] = useState(false)
  const [failTest, setFailTest] = useState(
    test.filter(
      (item) =>
        item.color === "red" ||
        item.color === "yellow" ||
        item.color === "block"
    )
  )

  const toggle = (index) => {
    if (clicked === index) {
      return setClicked(null)
    }
    setClicked(index)
  }

  return (
    <>
      <IconContext.Provider value={{ color: "#3A3974", size: "25px" }}>
        <div className='AccordionSection'>
          <div className='AccContainer'>
            <div className='Wrap d-flex'>
              <div className='d-flex'>
                <div onClick={() => toggle(index)} key={index}>
                  <div className='accordion-icon'>
                    <img
                      src={props.path}
                      alt=''
                      height={props.name === "Blacklisted Url Check" && "35px"}
                      width={props.name === "Blacklisted Url Check" && "33px"}
                      style={{
                        paddingLeft:
                          props.name === "Domain age Check"
                            ? "6px"
                            : props.name === "DMARC Check"
                            ? "3px"
                            : props.name === "Blacklisted Url Check"
                            ? "0px"
                            : "5px",
                        paddingTop:
                          props.name === "Blacklisted Url Check"
                            ? "5px"
                            : "5px",
                      }}
                    />
                  </div>
                  <p>
                    <b>{props.name}</b>
                  </p>
                </div>
                &nbsp;&nbsp;
                <div className='mt-3'>
                  <b style={{ fontSize: "" }}>
                    {props.name === "Domain Blacklist Check" ? (
                      <span>
                        <HelpComponent
                          filePath={"/help-fragments/6-DomainBlacklist.html"}
                        />
                      </span>
                    ) : props.name === "Spam Assasin Check" ? (
                      <span>
                        <HelpComponent
                          filePath={"/help-fragments/7-SpamAssasin.html"}
                        />
                      </span>
                    ) : props.name === "SPF Check" ? (
                      <span>
                        <HelpComponent
                          filePath={"/help-fragments/8-Spf.html"}
                        />
                      </span>
                    ) : props.name === "DKIM Check" ? (
                      <span>
                        <HelpComponent
                          filePath={"/help-fragments/9-Dkim.html"}
                        />
                      </span>
                    ) : props.name === "DMARC Check" ? (
                      <span>
                        <HelpComponent
                          filePath={"/help-fragments/10-Dmarc.html"}
                        />
                      </span>
                    ) : props.name === "Reverse DNS Check" ? (
                      <HelpComponent
                        filePath={"/help-fragments/12-ReverseDns.html"}
                      />
                    ) : props.name === "Short URLs Check" ? (
                      <HelpComponent
                        filePath={"/help-fragments/13-ShortUrl.html"}
                      />
                    ) : props.name === "Broken Links Check" ? (
                      <HelpComponent
                        filePath={"/help-fragments/14-BrokenLinks.html"}
                      />
                    ) : props.name === "Domain Suffix Check" ? (
                      <HelpComponent
                        filePath={"/help-fragments/16-DomainSuffix.html"}
                      />
                    ) : props.name === "IP Blacklist Check" ? (
                      <HelpComponent
                        filePath={"/help-fragments/15-IpBlacklist.html"}
                      />
                    ) : props.name === "Subject Line Check" ? (
                      <HelpComponent
                        filePath={"/help-fragments/17-SubjectLine.html"}
                      />
                    ) : (
                      <span>
                        <HelpComponent
                          filePath={"/help-fragments/11-DomainAge.html"}
                        />
                      </span>
                    )}
                  </b>
                </div>
              </div>
              <div
                className='right-part flex-grow-1'
                onClick={() => toggle(index)}
                key={index}
              >
                <div className='accordion-right d-flex justify-content-end mt-3'>
                  <button
                    style={{
                      outline: "none",
                      backgroundColor: props.StatusColor,
                    }}
                  >
                    {props.status}
                  </button>
                  <b>
                    {clicked === index ? <FiChevronUp /> : <FiChevronDown />}
                  </b>
                </div>
              </div>
            </div>
            {clicked === index ? (
              <div className='Dropdown'>
                <div
                  className='content'
                  style={{ backgroundColor: props.color }}
                >
                  <div
                    className='bold-content'
                    style={{
                      color:
                        props.StatusColor === "green"
                          ? "green"
                          : props.StatusColor === "red"
                          ? "red"
                          : "#ffffff",
                    }}
                  >
                    {props.StatusColor === "green" ? (
                      <>
                        <i
                          className='far fa-smile mt-1'
                          style={{
                            fontSize: "25px",
                            color:
                              props.StatusColor === "green"
                                ? "green"
                                : "#ffffff",
                          }}
                        ></i>
                        <span className='bold-content ml-2'>Test Passed-</span>
                      </>
                    ) : (
                      <>
                        <i
                          className='far fa-frown mt-1'
                          style={{
                            fontSize: "25px",
                            color:
                              props.StatusColor === "red" ? "red" : "#ffffff",
                          }}
                        ></i>
                        <span className='bold-content ml-2'>Test Failed-</span>
                      </>
                    )}
                    <span>{props.bold_content}</span>
                  </div>
                  {/* <div className="simple-content">{props.simple_content}</div> */}
                </div>
                <div>
                  <div className='headingDns mx-4 my-1'>
                    Why is this important?
                  </div>
                  <div className='explaination'>{props.explanation}</div>
                  {passTest.length !== 0 ? (
                    <div className='headingDns mx-4 my-1'>Passing Tests:</div>
                  ) : (
                    <></>
                  )}
                  {passTest.length !== 0 ? (
                    passTest.map((item) => (
                      <>
                        <div
                          className='test-name'
                          style={{
                            backgroundColor:
                              item.color === "yellow" ? "#FFBB00" : item.color,
                          }}
                        >
                          <i className='fas fa-check'></i>
                          <span>&nbsp;&nbsp;&nbsp;</span>
                          {item.test_name}
                        </div>
                        <div className='test-description'>
                          {item.description}
                        </div>
                      </>
                    ))
                  ) : (
                    <></>
                  )}
                  {failTest.length !== 0 ? (
                    <div className='headingDns mx-4 my-1'>
                      Problems you can fix:
                    </div>
                  ) : (
                    <></>
                  )}
                  {failTest.length !== 0 ? (
                    failTest.map((item) => (
                      <>
                        <div
                          className='test-name'
                          style={{
                            backgroundColor:
                              item.color === "yellow" ? "#FFBB00" : "red",
                          }}
                        >
                          <spam>
                            (
                            {item.show_score === "block"
                              ? ["-1", "-2", "-3"][
                                  Math.floor(Math.random() * 3)
                                ]
                              : item.show_score}{" "}
                            points)
                          </spam>
                          <span>&nbsp;&nbsp;&nbsp;</span>
                          {item.test_name === "block"
                            ? "Blocked"
                            : item.test_name}
                        </div>
                        <div className='test-description'>
                          {item.description === "block"
                            ? "The DMARC DNS record does not start with a valid version tag"
                            : item.description}
                        </div>
                      </>
                    ))
                  ) : (
                    <></>
                  )}

                  {/* {test.map((item) => (
                    <>
                    
                      <div
                        className="test-name"
                        style={{ backgroundColor: item.color === "yellow" ? "#FFBB00" : item.color }}
                      >
                        {item.color === "green" ? (
                          <>
                            <i className="fas fa-check"></i>
                            <span>&nbsp;&nbsp;&nbsp;</span>
                            {item.test_name}
                          </>
                        ) : (
                          <>
                            <spam>({item.show_score} points)</spam>
                            <span>&nbsp;&nbsp;&nbsp;</span>
                            {item.test_name}
                          </>
                        )}
                      </div>
                      
                    </>
                  ))} */}
                </div>
                {props.name === "Domain age Check" ? (
                  <>
                    <div className='mx-4'>
                      <div className='headingDns'>About your domain:</div>
                      <h6 className='text-sm-left px-5 my-5'>
                        <span style={{ fontSize: "12px", color: "#0994F8" }}>
                          &#9632;
                        </span>
                        &nbsp;&nbsp;&nbsp; Your Domain name
                        is:&nbsp;&nbsp;&nbsp;
                        <b className='fs-1 fw-bolder'>
                          {props.domain_age.domain_name}
                        </b>
                      </h6>
                      <h6 className='text-sm-left px-5 my-5'>
                        <span style={{ fontSize: "12px", color: "#0994F8" }}>
                          &#9632;
                        </span>
                        &nbsp;&nbsp;&nbsp; Your Domain creation date
                        is:&nbsp;&nbsp;&nbsp;
                        <b className='fs-1 fw-bolder'>
                          {props.domain_age.domain_creation_date}
                        </b>
                      </h6>
                      <h6 className='text-sm-left px-5 my-5'>
                        <span style={{ fontSize: "12px", color: "#0994F8" }}>
                          &#9632;
                        </span>
                        &nbsp;&nbsp;&nbsp; Your Domain age in
                        years:&nbsp;&nbsp;&nbsp;
                        <b className='fs-1 fw-bolder'>
                          {props.domain_age.domain_age_in_years}&nbsp;&nbsp;
                          <span>
                            {props.domain_age.domain_age_in_years === 1
                              ? "year"
                              : "years"}
                          </span>
                        </b>
                      </h6>
                      <h6 className='text-sm-left px-5 my-5'>
                        <span style={{ fontSize: "12px", color: "#0994F8" }}>
                          &#9632;
                        </span>
                        &nbsp;&nbsp;&nbsp; Your Domain age in
                        days:&nbsp;&nbsp;&nbsp;
                        <b className='fs-1 fw-bolder'>
                          {props.domain_age.domain_age_in_days} days
                        </b>
                      </h6>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {props.name === "Spam Assasin Check" ? (
                  <>
                    <div className='headingDns mx-4'>Spam Score:</div>
                    <h6 className='text-sm-left mx-5 my-4'>
                      <span style={{ fontSize: "12px", color: "#0994F8" }}>
                        &#9632;
                      </span>
                      &nbsp;&nbsp;&nbsp; Your spam assasin score is:&nbsp;
                      <b className='fs-1 fw-bolder'>{props.score}</b>
                    </h6>
                  </>
                ) : (
                  ""
                )}
                {props.name === "Domain Blacklist Check" ||
                props.name === "IP Blacklist Check" ? (
                  <div className='container-fluid mx-3 my-3'>
                    <div className='row'>
                      {props.detected_by.map((item) => (
                        <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3 d-flex justify-content-between pl-4 pt-2 pb-2 pr-2 detected-by truncate'>
                          <div>
                            <p>
                              {item.blacklisted === true ? (
                                <i className='far fa-times-circle'></i>
                              ) : (
                                <i className='far fa-check-circle'></i>
                              )}
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              {item.provider}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : null}
          </div>
        </div>
      </IconContext.Provider>
    </>
  )
}

export default Accordion
