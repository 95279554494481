function ValidateName(inputText) {
  var mailformat = /([A-Za-z])+( [A-Za-z]+)/;
  // console.log(inputText.slice(0,3))
  if (inputText.match(mailformat)) {
    return true;
  } else {
    return false;
  }
}

export default ValidateName;
