import InputField from './InputFields/InputField'
import FadeLoader from 'react-spinners/FadeLoader'
import React, { useState, useEffect } from 'react'
import HelpComponent from '../../../components/Help/Help'
import warmupService from '../../../api-services/warmupService'
import moment from 'moment'

const ConnectInboxContent = props => {
	const {
		error,
		isEdit,
		errors,
		setError,
		setErrors,
		inboxForm,
		checkSmtp,
		apiActive,
		smtpActive,
		setInboxForm,
		emailsToCopy,
		campaignData,
		setApiActive,
		setSmtpActive,
		aweberAuthUrl,
		handleFormChange,
		aweberDataReceived,
		mailchimpApiCheck,
		aweberListIdArray,
		checkingConnection,
		setHyperMailApiCheck,
		handleConnectionCheck,
		sendGridFromFieldArray,
		sendInBlueFromFieldArray,
		getResponseFromFieldArray,
		email_account_provider_id,
	} = props
	const [loading, setLoading] = useState(false)
	const [hypermailProfiles, setHypermailProfiles] = useState({})
	const [replyEmailDefault, setReplyEmailDefault] = useState(null)

	const handleSelector = e => {
		if (email_account_provider_id !== 12)
			if (e.target.id === 'api-key') {
				props.setInboxForm(prevInboxForm => ({
					...prevInboxForm,
					['mailgun_connection']: 'api',
				}))
				setApiActive(true)
				setSmtpActive(false)
			} else {
				props.setInboxForm(prevInboxForm => ({
					...prevInboxForm,
					['mailgun_connection']: 'smtp',
				}))
				setApiActive(false)
				setSmtpActive(true)
			}
		if (email_account_provider_id === 12) {
			if (e.target.id === 'api-key') {
				props.setInboxForm(prevInboxForm => ({
					...prevInboxForm,
					['amazon_connection']: 'api',
				}))
				setApiActive(true)
				setSmtpActive(false)
			} else {
				props.setInboxForm(prevInboxForm => ({
					...prevInboxForm,
					['amazon_connection']: 'smtp',
				}))
				setApiActive(false)
				setSmtpActive(true)
			}
		}
	}

	const handleSubmit = async () => {
		setLoading(true)
		try {
			const response = await warmupService.getProfileAndWhois({
				api_key: inboxForm.api_key,
			})
			setHypermailProfiles(response)
			props.setInboxForm(prevInboxForm => ({
				...prevInboxForm,
				['whois_id']: response.whois.whois.id,
			}))
			setLoading(false)
			setHyperMailApiCheck(false)
		} catch (e) {
			if (e.response.status == 422) {
				setErrors(e.response.data.errors)
			} else {
				setError(e.response.data.errors)
			}
			setLoading(false)
		}
	}

	const handleProfilesName = e => {
		props.setInboxForm(prevInboxForm => ({
			...prevInboxForm,
			['from_email']: e.target.value,
		}))
		hypermailProfiles.profiles.profile.map(item => {
			if (item.domain === e.target.value) {
				props.setInboxForm(prevInboxForm => ({
					...prevInboxForm,
					['profile_id']: item.id,
				}))
			}
		})
	}

	useEffect(() => {
		if (Object.keys(hypermailProfiles).length !== 0) {
			props.setInboxForm(prevInboxForm => ({
				...prevInboxForm,
				['from_email']: hypermailProfiles.profiles.profile[0].domain,
			}))
			props.setInboxForm(prevInboxForm => ({
				...prevInboxForm,
				['profile_id']: hypermailProfiles.profiles.profile[0].id,
			}))
		}
	}, [hypermailProfiles])

	useEffect(() => {
		if (email_account_provider_id == 17) {
			props.setInboxForm(prevInboxForm => ({
				...prevInboxForm,
				['reply_email']: inboxForm.from_name + '@' + inboxForm.from_email,
			}))
		} else {
			props.setInboxForm(prevInboxForm => ({
				...prevInboxForm,
				['reply_email']: inboxForm.from_email,
			}))
		}
	}, [inboxForm.from_name, inboxForm.from_email])

	let maxDate = new Date(inboxForm.start_date)
	let maxDays = moment(maxDate, 'YYYY-MM-DD')
		.add(45, 'days')
		.format('YYYY-MM-DD')

	return (
		<div className='col-sm-12'>
			{email_account_provider_id === 19 ? (
				aweberAuthUrl === '' ? (
					<></>
				) : (
					<div className='row my-4'>
						<div className='col-sm-6'>
							<label htmlFor='warmupName' className='form-label'>
								Authorization Token <span className='text-danger'>*</span>
							</label>
							<input
								required
								type='text'
								name='code'
								className='form-control'
								// id='inputWithoutBackground'
								onChange={handleFormChange}
								defaultValue={inboxForm.code}
								placeholder='Paste your token generated by Aweber'
							/>
							{errors.hasOwnProperty('code')
								? errors.code.map((e, i) => (
										<div key={i} className='text-danger ml-1'>
											{' '}
											<b> {e}</b>{' '}
										</div>
								  ))
								: ''}
						</div>
						{aweberListIdArray.length !== 0 && aweberDataReceived ? (
							<>
								<div className='col-sm-6'>
									<label htmlFor='warmupName' className='form-label'>
										Campaign Name <span className='text-danger'>*</span>
									</label>
									<input
										required
										type='text'
										name='campaign_name'
										className='form-control'
										// id='inputWithoutBackground'
										onChange={handleFormChange}
										defaultValue={inboxForm.campaign_name}
									/>
									{errors.hasOwnProperty('campaign_name')
										? errors.campaign_name.map((e, i) => (
												<div key={i} className='text-danger ml-1'>
													{' '}
													<b> {e}</b>{' '}
												</div>
										  ))
										: ''}
								</div>

								<div className='col-sm-6 mt-2'>
									<label htmlFor='list_id' className='form-label'>
										List Id <span className='text-danger'>*</span>
									</label>
									<select
										required
										name='list_id'
										className='form-control'
										// id='inputWithoutBackground'
										onChange={handleFormChange}
									>
										<option>Select an Item</option>
										{aweberListIdArray.map((singleItem, i) => (
											<option key={i} value={singleItem.id}>
												{singleItem.name}
											</option>
										))}
									</select>
									{/* <input
								required
								type='text'
								name='list_id'
								className='form-control'
								id='inputWithoutBackground'
								onChange={handleFormChange}
								defaultValue={inboxForm.listId}
							/> */}
									{errors.hasOwnProperty('code')
										? errors.code.map((e, i) => (
												<div key={i} className='text-danger ml-1'>
													{' '}
													<b> {e}</b>{' '}
												</div>
										  ))
										: ''}
								</div>
								<div className='d-flex flex-column col-sm-6 mt-2 form-check'>
									<div className='d-flex flex-row flex-grow-1 align-items-center'>
										<label htmlFor='is_aweber_pro' className='mr-2 mb-0'>
											Are you on AWeber Pro?
										</label>
										<input
											type='checkbox'
											className='mr-5'
											id='is_aweber_pro'
											name='is_aweber_pro'
											checked={inboxForm.is_aweber_pro}
											onChange={() => {
												setInboxForm({
													...inboxForm,
													is_aweber_pro: !inboxForm.is_aweber_pro,
												})
											}}
										/>
										<button
											className='btn btn-sm c-btn'
											disabled={inboxForm.is_aweber_pro}
											onClick={() =>
												navigator.clipboard.writeText(emailsToCopy)
											}
										>
											<i className='fal fa-copy mr-2'></i> Copy Warm-up Emails
										</button>
									</div>
									<div className='d-flex'>
										{inboxForm.is_aweber_pro ? (
											<h6 className='text-success mb-0 align-self-end'>
												<em>
													<b>Emails copied to clipboard!!</b>
												</em>
											</h6>
										) : (
											<h6 className='text-success mb-0 align-self-end'>
												&nbsp;
											</h6>
										)}
									</div>
								</div>
							</>
						) : (
							<></>
						)}
						<div className='d-flex align-items-center col-sm-6 mt-2 form-check'></div>
					</div>
				)
			) : (
				<div className='row my-4'>
					<div className='col-sm-6'>
						<label htmlFor='warmupName' className='form-label'>
							Campaign Name <span className='text-danger'>*</span>
						</label>
						<input
							required
							type='text'
							name='campaign_name'
							className='form-control'
							// id='inputWithoutBackground'
							onChange={handleFormChange}
							defaultValue={inboxForm.campaign_name}
						/>
						{errors.hasOwnProperty('campaign_name')
							? errors.campaign_name.map(e => (
									<div className='text-danger ml-1'>
										{' '}
										<b> {e}</b>{' '}
									</div>
							  ))
							: ''}
					</div>
				</div>
			)}
			<div className='row'>
				<div className='col-sm-6'>
					<b style={{ color: '#3073A2' }}>
						Login to your{' '}
						<b style={{ color: '#0994F8' }}>
							{email_account_provider_id === 18
								? 'ActiveCampaign Warm-up Campaigns'
								: email_account_provider_id === 17
								? 'Hypermail Warm-up Campaigns'
								: email_account_provider_id === 16
								? 'Mailchimp Warm-up Campaigns'
								: email_account_provider_id === 15
								? 'klaviyo Warm-up Campaigns'
								: email_account_provider_id === 13
								? 'Mail.com Inbox'
								: email_account_provider_id === 14
								? 'Mandrill Inbox'
								: email_account_provider_id === 9
								? 'Sendgrid Inbox'
								: email_account_provider_id === 5
								? 'iCloud Inbox'
								: email_account_provider_id === 7
								? 'Zoho Inbox'
								: email_account_provider_id === 8
								? 'Yandex Inbox'
								: email_account_provider_id === 6
								? 'Aol Inbox'
								: email_account_provider_id === 10
								? 'SendinBlue Inbox'
								: email_account_provider_id === 4
								? 'Yahoo Inbox'
								: email_account_provider_id === 12
								? 'Amazon SES Inbox'
								: email_account_provider_id === 3
								? 'Outlook Inbox'
								: email_account_provider_id === 11
								? 'Mailgun Inbox'
								: email_account_provider_id === 2
								? 'Gmail Inbox'
								: email_account_provider_id === 19
								? 'AWeber Inbox'
								: email_account_provider_id === 20
								? 'GetResponse Inbox'
								: 'Add with SMTP'}{' '}
							<span>
								<HelpComponent />
							</span>
						</b>
					</b>
				</div>
			</div>
			{email_account_provider_id === 12 ||
			email_account_provider_id === 11 ||
			email_account_provider_id === 10 ||
			email_account_provider_id === 9 ? (
				<>
					<div className='row mt-4'>
						<div
							className='col-lg-6 col-md-6 col-sm-12'
							onClick={handleSelector}
						>
							<div
								className={
									apiActive
										? ' card warmup-option-card-selected'
										: 'card warmup-option-card'
								}
								id='api-key'
							>
								<img
									alt='...'
									id='api-key'
									className='card-img-top mb-3'
									src='/assets/img/api-icon.svg'
									style={{ width: '80px', height: '80px' }}
								/>
								{/* <div className="card-body" id="api-key"> */}
								{/* <h5 className="card-title" id="api-key">
                    API key
                  </h5> */}
								{/* <p
                className="card-text"
                style={{ color: "#7E7E7E", fontSize: "14px" }}
                id="random_chart"
              >
                Recommended
              </p> */}
								{/* </div> */}
							</div>
						</div>
						<div
							onClick={handleSelector}
							className=' col-lg-6 col-md-6 col-sm-12'
						>
							<div
								className={
									smtpActive
										? 'card warmup-option-card-selected'
										: 'card warmup-option-card'
								}
								id='smtp'
							>
								<img
									id='smtp'
									alt='...'
									className='card-img-top mb-3'
									src='/assets/img/smtp-icon.svg'
									style={{ width: '80px', height: '80px' }}
								/>
								{/* <div className="card-body" id="smtp"> */}
								{/* <h5 className="card-title" id="smtp">
                    SMTP
                  </h5> */}
								{/* <p
                className="card-text"
                style={{ color: "#7E7E7E", fontSize: "14px" }}
                id="custom_chart"
              >
              </p> */}
								{/* </div> */}
							</div>
						</div>
					</div>
				</>
			) : (
				''
			)}
			{email_account_provider_id === 20 ? (
				<>
					<div className='row mt-4'>
						<InputField
							type={'text'}
							errors={errors}
							name={'api_key'}
							label_name={'API Key'}
							error_name={errors.api_key}
							on_change={handleFormChange}
							default_value={inboxForm.api_key}
							required={isEdit || smtpActive ? false : true}
						/>
						<div
							className={`col-6 ${
								getResponseFromFieldArray.length === 0 ? 'd-none' : ''
							}`}
						>
							<label className='form-label'>
								From Field<span className='text-danger'>*</span>
							</label>
							<select
								name='from_field_id'
								className='form-control'
								onChange={handleFormChange}
								value={inboxForm.from_field_id}
							>
								{getResponseFromFieldArray.length === 0 ? (
									<option>Loading</option>
								) : (
									<>
										<option value=''>Select From Field Id</option>
										{getResponseFromFieldArray.map((fromField, i) => (
											<option key={i} value={fromField.fromFieldId}>
												{fromField.email}
											</option>
										))}
									</>
								)}
							</select>
							{errors.hasOwnProperty('from_field_id')
								? errors.from_field_id.map((e, i) => (
										<div key={i} className='text-danger ml-1'>
											{' '}
											<b> {e}</b>{' '}
										</div>
								  ))
								: ''}
						</div>
						{/* <InputField
							type={'text'}
							errors={errors}
							name={'api_key'}
							label_name={'API Key'}
							error_name={errors.api_key}
							on_change={handleFormChange}
							default_value={inboxForm.api_key}
							required={isEdit || smtpActive ? false : true}
						/> */}
					</div>
					<div
						className={`row mt-4 ${
							getResponseFromFieldArray.length === 0 ? 'd-none' : ''
						}`}
					>
						<InputField
							type={'email'}
							errors={errors}
							name={'from_email'}
							label_name={'From Email'}
							on_change={handleFormChange}
							error_name={errors.from_email}
							required={isEdit ? false : true}
							default_value={inboxForm.from_email}
						/>
						<div className='col-sm-6'>
							<label className='form-label'>
								Reply Email<span className='text-danger'>*</span>
							</label>
							<input
								type='email'
								name='reply_email'
								className='form-control'
								onChange={handleFormChange}
								// id='inputWithoutBackground'
								value={inboxForm.reply_email}
								required={isEdit || smtpActive ? false : true}
							/>
							{errors.hasOwnProperty('reply_email')
								? errors.reply_email.map((e, i) => (
										<div key={i} className='text-danger ml-1'>
											{' '}
											<b> {e}</b>{' '}
										</div>
								  ))
								: ''}
						</div>
					</div>
					<div
						className={`row mt-4 mr-2 ${
							getResponseFromFieldArray.length === 0 ? 'd-none' : 'd-flex'
						}`}
					>
						<InputField
							type={'date'}
							errors={errors}
							disabled={isEdit}
							class_name={'col'}
							name={'start_date'}
							max={inboxForm.end_date}
							label_name={'Start date'}
							on_change={handleFormChange}
							error_name={errors.start_date}
							default_value={inboxForm.start_date}
							min={new Date().toISOString().split('T')[0]}
							required={isEdit || smtpActive ? false : true}
						/>
						<InputField
							type={'date'}
							errors={errors}
							name={'end_date'}
							disabled={isEdit}
							class_name={'col'}
							label_name={'End date'}
							min={inboxForm.start_date}
							on_change={handleFormChange}
							error_name={errors.end_date}
							default_value={inboxForm.end_date}
							required={isEdit || smtpActive ? false : true}
						/>
						<InputField
							errors={errors}
							type={'number'}
							disabled={isEdit}
							class_name={'col'}
							name={'reply_rate_per_day'}
							on_change={handleFormChange}
							label_name={'Reply rate per day'}
							error_name={errors.reply_rate_per_day}
							default_value={inboxForm.reply_rate_per_day}
							required={isEdit || smtpActive ? false : true}
						/>
					</div>
				</>
			) : email_account_provider_id === 1 ||
			  email_account_provider_id === 2 ||
			  email_account_provider_id === 3 ||
			  email_account_provider_id === 4 ||
			  email_account_provider_id === 5 ||
			  email_account_provider_id === 6 ||
			  email_account_provider_id === 7 ||
			  email_account_provider_id === 8 ||
			  (email_account_provider_id === 9 && smtpActive) ||
			  (email_account_provider_id === 10 && smtpActive) ||
			  (email_account_provider_id === 11 && smtpActive) ||
			  (email_account_provider_id === 12 && smtpActive) ||
			  email_account_provider_id === 13 ||
			  email_account_provider_id === 14 ? (
				<>
					<div className='row mt-4'>
						<InputField
							type={'text'}
							errors={errors}
							name={'username'}
							on_change={handleFormChange}
							error_name={errors.username}
							default_value={inboxForm.username}
							label_name={'SMTP Username / Email '}
							required={isEdit || apiActive ? false : true}
						/>
						<InputField
							errors={errors}
							type={'password'}
							name={'password'}
							on_change={handleFormChange}
							error_name={errors.password}
							label_name={'SMTP Password '}
							default_value={inboxForm.password}
							required={isEdit || apiActive ? false : true}
						/>
					</div>
				</>
			) : (
				''
			)}

			{email_account_provider_id === 19 && aweberAuthUrl === '' ? (
				<>
					<div className='row mt-4'>
						<InputField
							type={'text'}
							errors={errors}
							name={'client_id'}
							label_name={'Client ID'}
							on_change={handleFormChange}
							error_name={errors.client_id}
							default_value={inboxForm.client_id}
							required={isEdit || smtpActive ? false : true}
						/>
						<InputField
							type={'text'}
							errors={errors}
							name={'client_secret'}
							label_name={'Client Secret'}
							on_change={handleFormChange}
							error_name={errors.client_secret}
							default_value={inboxForm.client_secret}
							required={isEdit || smtpActive ? false : true}
						/>
					</div>
				</>
			) : (
				<></>
			)}

			{email_account_provider_id !== 19 ||
			(inboxForm.code === '' && !aweberDataReceived) ? (
				<></>
			) : (
				<>
					<div className='row mt-4'>
						<InputField
							type={'email'}
							errors={errors}
							name={'from_email'}
							label_name={'From Email'}
							on_change={handleFormChange}
							error_name={errors.from_email}
							required={isEdit ? false : true}
							default_value={inboxForm.from_email}
						/>
						<div className='col-sm-6'>
							<label className='form-label'>Reply Email</label>
							<input
								type='email'
								name='reply_email'
								className='form-control'
								// id='inputWithoutBackground'
								onChange={handleFormChange}
								value={inboxForm.reply_email}
								required={isEdit || smtpActive ? false : true}
							/>
							{errors.hasOwnProperty('reply_email')
								? errors.reply_email.map((e, i) => (
										<div key={i} className='text-danger ml-1'>
											{' '}
											<b> {e}</b>{' '}
										</div>
								  ))
								: ''}
						</div>
					</div>
					<div className='row mt-4 mr-2 d-flex'>
						<InputField
							type={'date'}
							errors={errors}
							disabled={isEdit}
							class_name={'col'}
							name={'start_date'}
							label_name={'Start date'}
							on_change={handleFormChange}
							error_name={errors.start_date}
							default_value={inboxForm.start_date}
							min={moment(new Date()).format('YYYY-MM-DD')}
							max={moment(
								inboxForm.end_date === '' ? '' : inboxForm.end_date
							).format('YYYY-MM-DD')}
							required={isEdit || smtpActive ? false : true}
						/>
						<InputField
							type={'date'}
							errors={errors}
							disabled={isEdit}
							name={'end_date'}
							class_name={'col'}
							label_name={'End date'}
							on_change={handleFormChange}
							error_name={errors.end_date}
							default_value={inboxForm.end_date}
							required={isEdit || smtpActive ? false : true}
						/>
						<InputField
							errors={errors}
							type={'number'}
							disabled={isEdit}
							class_name={'col'}
							name={'reply_rate_per_day'}
							on_change={handleFormChange}
							label_name={'Reply rate per day'}
							error_name={errors.reply_rate_per_day}
							default_value={inboxForm.reply_rate_per_day}
							required={isEdit || smtpActive ? false : true}
						/>
					</div>
				</>
			)}
			{email_account_provider_id === 18 ? (
				<>
					<div className='row mt-4'>
						<InputField
							type={'text'}
							errors={errors}
							name={'api_key'}
							label_name={'API Key'}
							error_name={errors.api_key}
							on_change={handleFormChange}
							default_value={inboxForm.api_key}
							required={isEdit || smtpActive ? false : true}
						/>
						<InputField
							type={'text'}
							errors={errors}
							name={'api_url'}
							label_name={'API URL'}
							error_name={errors.api_url}
							on_change={handleFormChange}
							default_value={inboxForm.api_url}
							required={isEdit || smtpActive ? false : true}
						/>
					</div>
					<div className='row mt-4'>
						<InputField
							type={'email'}
							errors={errors}
							name={'from_email'}
							label_name={'From Email'}
							on_change={handleFormChange}
							error_name={errors.from_email}
							required={isEdit ? false : true}
							default_value={inboxForm.from_email}
						/>
						<div className='col-sm-6'>
							<label className='form-label'>Reply Email</label>
							<input
								type='email'
								name='reply_email'
								className='form-control'
								// id='inputWithoutBackground'
								onChange={handleFormChange}
								value={inboxForm.reply_email}
								required={isEdit || smtpActive ? false : true}
							/>
							{errors.hasOwnProperty('reply_email')
								? errors.reply_email.map((e, i) => (
										<div key={i} className='text-danger ml-1'>
											{' '}
											<b> {e}</b>{' '}
										</div>
								  ))
								: ''}
						</div>
					</div>
					<div className='row mt-4 mr-2 d-flex'>
						<InputField
							type={'date'}
							errors={errors}
							disabled={isEdit}
							class_name={'col'}
							name={'start_date'}
							label_name={'Start date'}
							on_change={handleFormChange}
							error_name={errors.start_date}
							default_value={inboxForm.start_date}
							required={isEdit || smtpActive ? false : true}
						/>
						<InputField
							type={'date'}
							errors={errors}
							name={'end_date'}
							disabled={isEdit}
							class_name={'col'}
							label_name={'End date'}
							on_change={handleFormChange}
							error_name={errors.end_date}
							default_value={inboxForm.end_date}
							required={isEdit || smtpActive ? false : true}
						/>
						<InputField
							errors={errors}
							type={'number'}
							disabled={isEdit}
							class_name={'col'}
							name={'reply_rate_per_day'}
							on_change={handleFormChange}
							label_name={'Reply rate per day'}
							error_name={errors.reply_rate_per_day}
							default_value={inboxForm.reply_rate_per_day}
							required={isEdit || smtpActive ? false : true}
						/>
					</div>
				</>
			) : (
				''
			)}
			{email_account_provider_id === 17 ? (
				<>
					<div className='row mt-4'>
						<InputField
							type={'text'}
							errors={errors}
							name={'api_key'}
							label_name={'API Key'}
							error_name={errors.api_key}
							on_change={handleFormChange}
							default_value={inboxForm.api_key}
							required={isEdit || smtpActive ? false : true}
						/>
						<div className='col-2 form-button'>
							<button
								disabled={loading}
								onClick={handleSubmit}
								className='api-submit-btn'
							>
								Submit
							</button>
						</div>
					</div>
					{Object.keys(hypermailProfiles).length !== 0 && !loading ? (
						<>
							<div className='row mt-4 mr-2'>
								<div className='col'>
									<label className='form-label'>Company Name</label>
									<br />
									<div className='names-output mt-1 text-muted'>
										{hypermailProfiles.whois.whois.company}
									</div>
								</div>
								<div className='col'>
									<label className='form-label'>First Name</label>
									<br />
									<div className='names-output mt-1 text-muted'>
										{hypermailProfiles.whois.whois.firstname}
									</div>
								</div>
								<div className='col'>
									<label className='form-label'>Last Name</label>
									<br />
									<div className='names-output mt-1 text-muted'>
										{hypermailProfiles.whois.whois.lastname}
									</div>
								</div>
							</div>
							<div className='row mt-4 p-0 mr-2 d-flex'>
								<div className='col-sm-8'>
									<div className='row'>
										<div className='col p-0'>
											<InputField
												type={'text'}
												errors={errors}
												name={'from_name'}
												class_name={'col'}
												label_name={'From Name'}
												on_change={handleFormChange}
												error_name={errors.from_name}
												default_value={inboxForm.from_name}
												required={isEdit || smtpActive ? false : true}
											/>
										</div>
										<div style={{ marginTop: '32px', fontSize: '20px' }}>
											<b>@</b>
										</div>
										<div className='col '>
											<label htmlFor='smtpPort' className='form-label'>
												From Email
											</label>
											<select
												type='text'
												name='from_email'
												className='form-control'
												// id='inputWithoutBackground'
												onChange={handleProfilesName}
												required={isEdit ? false : true}
												defaultValue={inboxForm.from_email}
											>
												{hypermailProfiles.profiles.profile.map((item, i) => (
													<option key={i} value={item.domain}>
														{item.domain}
													</option>
												))}
											</select>
											{errors.hasOwnProperty('from_email')
												? errors.from_email.map((e, i) => (
														<div key={i} className='text-danger ml-1'>
															{' '}
															<b> {e}</b>{' '}
														</div>
												  ))
												: ''}
										</div>
									</div>
								</div>
								<div className='col'>
									<label className='form-label'>Reply Email</label>
									<input
										type='email'
										name='reply_email'
										className='form-control'
										// id='inputWithoutBackground'
										onChange={handleFormChange}
										value={inboxForm.reply_email}
										required={isEdit || smtpActive ? false : true}
									/>
									{errors.hasOwnProperty('reply_email')
										? errors.reply_email.map((e, i) => (
												<div key={i} className='text-danger ml-1'>
													{' '}
													<b> {e}</b>{' '}
												</div>
										  ))
										: ''}
								</div>
							</div>
							<div className='row mt-4 mr-2 d-flex'>
								<InputField
									type={'date'}
									errors={errors}
									disabled={isEdit}
									class_name={'col'}
									name={'start_date'}
									label_name={'Start date'}
									on_change={handleFormChange}
									error_name={errors.start_date}
									default_value={inboxForm.start_date}
									required={isEdit || smtpActive ? false : true}
								/>
								<InputField
									type={'date'}
									errors={errors}
									disabled={isEdit}
									name={'end_date'}
									class_name={'col'}
									label_name={'End date'}
									error_name={errors.end_date}
									on_change={handleFormChange}
									default_value={inboxForm.end_date}
									required={isEdit || smtpActive ? false : true}
								/>
								<InputField
									type={'number'}
									errors={errors}
									disabled={isEdit}
									class_name={'col'}
									name={'reply_rate_per_day'}
									on_change={handleFormChange}
									label_name={'Reply rate per day'}
									error_name={errors.reply_rate_per_day}
									default_value={inboxForm.reply_rate_per_day}
									required={isEdit || smtpActive ? false : true}
								/>
							</div>
						</>
					) : (
						<div className='text-center' style={{ marginTop: '150px' }}>
							<FadeLoader
								width={5}
								height={20}
								loading={loading}
								color={'#0092FB'}
							/>
						</div>
					)}
				</>
			) : (
				''
			)}

			{(apiActive && email_account_provider_id === 12) ||
			(apiActive && email_account_provider_id === 12 && isEdit) ? (
				<>
					<div className='row mt-3'>
						<InputField
							type={'text'}
							errors={errors}
							name={'api_key'}
							label_name={'API Key'}
							error_name={errors.api_key}
							on_change={handleFormChange}
							default_value={inboxForm.api_key}
							required={isEdit || smtpActive ? false : true}
						/>
						<InputField
							type={'text'}
							errors={errors}
							name={'secret_key'}
							label_name={'Secret Key'}
							on_change={handleFormChange}
							error_name={errors.secret_key}
							default_value={inboxForm.secret_key}
							required={isEdit || smtpActive ? false : true}
						/>
					</div>
					<div className='row mt-3'>
						<InputField
							type={'email'}
							errors={errors}
							name={'from_email'}
							label_name={'From Email'}
							on_change={handleFormChange}
							error_name={errors.from_email}
							required={isEdit ? false : true}
							default_value={inboxForm.from_email}
						/>
						<InputField
							type={'email'}
							errors={errors}
							name={'region'}
							disabled={isEdit}
							label_name={'Region'}
							error_name={errors.region}
							on_change={handleFormChange}
							required={isEdit ? false : true}
							default_value={inboxForm.region}
						/>
					</div>
				</>
			) : (apiActive && email_account_provider_id === 11) ||
			  (apiActive && email_account_provider_id === 11 && isEdit) ? (
				<>
					<div className='row mt-3'>
						<InputField
							type={'text'}
							errors={errors}
							name={'api_key'}
							label_name={'API Key'}
							error_name={errors.api_key}
							on_change={handleFormChange}
							default_value={inboxForm.api_key}
							required={isEdit || smtpActive ? false : true}
						/>
						<InputField
							type={'text'}
							errors={errors}
							name={'api_host_name'}
							label_name={'API Host Name'}
							on_change={handleFormChange}
							error_name={errors.api_host_name}
							default_value={inboxForm.api_host_name}
							required={isEdit || smtpActive ? false : true}
						/>
					</div>
					<div className='row mt-3'>
						<InputField
							type={'text'}
							errors={errors}
							name={'domain'}
							error_name={errors.domain}
							label_name={'Domain Name'}
							on_change={handleFormChange}
							default_value={inboxForm.domain}
							required={isEdit || smtpActive ? false : true}
						/>
						<InputField
							type={'email'}
							errors={errors}
							name={'from_email'}
							label_name={'From Email'}
							on_change={handleFormChange}
							error_name={errors.from_email}
							required={isEdit ? false : true}
							default_value={inboxForm.from_email}
						/>
					</div>
				</>
			) : (apiActive && email_account_provider_id === 10) ||
			  (apiActive && email_account_provider_id === 10 && isEdit) ? (
				<>
					<div className='row mt-4'>
						<InputField
							type={'text'}
							errors={errors}
							name={'api_key'}
							label_name={'API Key'}
							error_name={errors.api_key}
							on_change={handleFormChange}
							default_value={inboxForm.api_key}
							required={isEdit || smtpActive ? false : true}
						/>
						<div
							className={`col-6 ${
								sendInBlueFromFieldArray.length === 0 ? 'd-none' : ''
							}`}
						>
							<label className='form-label'>
								From Field<span className='text-danger'>*</span>
							</label>
							<select
								name='from_email'
								className='form-control'
								onChange={handleFormChange}
								value={inboxForm.from_email}
							>
								{sendInBlueFromFieldArray.length === 0 ? (
									<option>Loading</option>
								) : (
									<>
										<option value=''>Select From Field Id</option>
										{sendInBlueFromFieldArray.map((fromField, i) => (
											<option key={i} value={fromField.email}>
												{fromField.email}
											</option>
										))}
									</>
								)}
							</select>
							{errors.hasOwnProperty('from_email')
								? errors.from_email.map((e, i) => (
										<div key={i} className='text-danger ml-1'>
											{' '}
											<b> {e}</b>{' '}
										</div>
								  ))
								: ''}
						</div>

						{/* <InputField
							type={'email'}
							errors={errors}
							name={'from_email'}
							label_name={'From Email'}
							on_change={handleFormChange}
							error_name={errors.from_email}
							required={isEdit ? false : true}
							default_value={inboxForm.from_email}
						/> */}
					</div>
					<div className='row mt-4'>
						<div className='col-sm-6'>
							<label className='form-label'>Reply Email</label>
							<input
								type='email'
								name='reply_email'
								className='form-control'
								// id='inputWithoutBackground'
								onChange={handleFormChange}
								value={inboxForm.reply_email}
								required={isEdit || smtpActive ? false : true}
							/>
							{errors.hasOwnProperty('reply_email')
								? errors.reply_email.map((e, i) => (
										<div key={i} className='text-danger ml-1'>
											{' '}
											<b> {e}</b>{' '}
										</div>
								  ))
								: ''}
						</div>
					</div>
					<div className='row mt-4 mr-2 d-flex'>
						<InputField
							type={'date'}
							errors={errors}
							disabled={isEdit}
							class_name={'col'}
							name={'start_date'}
							label_name={'Start date'}
							on_change={handleFormChange}
							error_name={errors.start_date}
							default_value={inboxForm.start_date}
							required={isEdit || smtpActive ? false : true}
							min={moment(new Date()).format('YYYY-MM-DD')}
							max={moment(
								inboxForm.end_date === '' ? '' : inboxForm.end_date
							).format('YYYY-MM-DD')}
						/>
						<InputField
							max={maxDays}
							type={'date'}
							errors={errors}
							name={'end_date'}
							disabled={isEdit}
							class_name={'col'}
							label_name={'End date'}
							on_change={handleFormChange}
							error_name={errors.end_date}
							default_value={inboxForm.end_date}
							required={isEdit || smtpActive ? false : true}
							min={moment().add(10, 'days').format('YYYY-MM-DD')}
						/>
						<InputField
							errors={errors}
							type={'number'}
							disabled={isEdit}
							class_name={'col'}
							name={'reply_rate_per_day'}
							on_change={handleFormChange}
							label_name={'Reply rate per day'}
							error_name={errors.reply_rate_per_day}
							default_value={inboxForm.reply_rate_per_day}
							required={isEdit || smtpActive ? false : true}
						/>
					</div>
				</>
			) : (apiActive && email_account_provider_id === 9) ||
			  (apiActive && email_account_provider_id === 9 && isEdit) ? (
				<>
					<div className='row mt-4'>
						<InputField
							type={'text'}
							errors={errors}
							name={'api_key'}
							label_name={'API Key'}
							error_name={errors.api_key}
							on_change={handleFormChange}
							default_value={inboxForm.api_key}
							required={isEdit || smtpActive ? false : true}
						/>
						<div
							className={`col-6 ${
								sendGridFromFieldArray.length === 0 ? 'd-none' : ''
							}`}
						>
							<label className='form-label'>
								From Field<span className='text-danger'>*</span>
							</label>
							<select
								name='sender_id'
								className='form-control'
								onChange={handleFormChange}
								value={inboxForm.sender_id}
							>
								{sendGridFromFieldArray.length === 0 ? (
									<option>Loading</option>
								) : (
									<>
										<option value=''>Select From Field Id</option>
										{sendGridFromFieldArray.map((fromField, i) => (
											<option key={i} value={fromField.sender_id}>
												{fromField.sender_id}
											</option>
										))}
									</>
								)}
							</select>
							{errors.hasOwnProperty('sender_id')
								? errors.sender_id.map((e, i) => (
										<div key={i} className='text-danger ml-1'>
											{' '}
											<b> {e}</b>{' '}
										</div>
								  ))
								: ''}
						</div>
					</div>

					<div className='row mt-4'>
						<div
							className={`${inboxForm.from_email === '' ? 'd-none' : ''} col-6`}
						>
							<label className='form-label'>From Email</label>
							<input
								type='email'
								name='from_email'
								className='form-control'
								disabled
								// id='inputWithoutBackground'
								onChange={handleFormChange}
								value={inboxForm.from_email}
								required={isEdit || smtpActive ? false : true}
							/>
							{errors.hasOwnProperty('from_email')
								? errors.from_email.map((e, i) => (
										<div key={i} className='text-danger ml-1'>
											<b> {e}</b>
										</div>
								  ))
								: ''}
						</div>
						<div className='col-sm-6'>
							<label className='form-label'>Reply Email</label>
							<input
								type='email'
								name='reply_email'
								className='form-control'
								// id='inputWithoutBackground'
								onChange={handleFormChange}
								value={inboxForm.reply_email}
								required={isEdit || smtpActive ? false : true}
							/>
							{errors.hasOwnProperty('reply_email')
								? errors.reply_email.map((e, i) => (
										<div key={i} className='text-danger ml-1'>
											{' '}
											<b> {e}</b>{' '}
										</div>
								  ))
								: ''}
						</div>
					</div>
					<div className='row mt-4 mr-2 d-flex'>
						<InputField
							type={'date'}
							errors={errors}
							disabled={isEdit}
							class_name={'col'}
							name={'start_date'}
							label_name={'Start date'}
							on_change={handleFormChange}
							error_name={errors.start_date}
							default_value={inboxForm.start_date}
							required={isEdit || smtpActive ? false : true}
						/>
						<InputField
							type={'date'}
							errors={errors}
							disabled={isEdit}
							name={'end_date'}
							class_name={'col'}
							label_name={'End date'}
							on_change={handleFormChange}
							error_name={errors.end_date}
							default_value={inboxForm.end_date}
							required={isEdit || smtpActive ? false : true}
						/>
						<InputField
							errors={errors}
							type={'number'}
							disabled={isEdit}
							class_name={'col'}
							name={'reply_rate_per_day'}
							on_change={handleFormChange}
							label_name={'Reply rate per day'}
							error_name={errors.reply_rate_per_day}
							default_value={inboxForm.reply_rate_per_day}
							required={isEdit || smtpActive ? false : true}
						/>
					</div>
				</>
			) : (
				''
			)}

			{email_account_provider_id === 15 || email_account_provider_id === 16 ? (
				<>
					<div className='row mt-4'>
						<InputField
							type={'text'}
							errors={errors}
							name={'api_key'}
							label_name={'API Key'}
							error_name={errors.api_key}
							on_change={handleFormChange}
							default_value={inboxForm.api_key}
							required={isEdit || smtpActive ? false : true}
						/>

						{/* <div
							className={`${
								email_account_provider_id === 16 && mailchimpApiCheck
									? 'col-12'
									: 'd-none'
							}`}
						>
							
						</div> */}
					</div>
					<div
						className={`row mt-4 ${
							(email_account_provider_id === 16 && mailchimpApiCheck) ||
							email_account_provider_id === 15
								? ''
								: 'd-none'
						}`}
					>
						{(email_account_provider_id === 16 && mailchimpApiCheck) ||
						email_account_provider_id === 15 ? (
							<InputField
								type={'email'}
								errors={errors}
								name={'from_email'}
								label_name={'From Email'}
								on_change={handleFormChange}
								error_name={errors.from_email}
								required={isEdit ? false : true}
								default_value={inboxForm.from_email}
							/>
						) : (
							<></>
						)}
						<div className='col-sm-6'>
							<label className='form-label'>Reply Email</label>
							<input
								type='email'
								name='reply_email'
								className='form-control'
								onChange={handleFormChange}
								// id='inputWithoutBackground'
								value={inboxForm.reply_email}
								required={isEdit || smtpActive ? false : true}
							/>
							{errors.hasOwnProperty('reply_email')
								? errors.reply_email.map((e, i) => (
										<div key={i} className='text-danger ml-1'>
											{' '}
											<b> {e}</b>{' '}
										</div>
								  ))
								: ''}
						</div>
					</div>
					<div
						className={`row mt-4 mr-2 ${
							(email_account_provider_id === 16 && mailchimpApiCheck) ||
							email_account_provider_id === 15
								? 'd-flex'
								: 'd-none'
						}`}
					>
						<InputField
							type={'date'}
							errors={errors}
							disabled={isEdit}
							class_name={'col'}
							name={'start_date'}
							label_name={'Start date'}
							on_change={handleFormChange}
							error_name={errors.start_date}
							default_value={inboxForm.start_date}
							required={isEdit || smtpActive ? false : true}
						/>
						<InputField
							type={'date'}
							errors={errors}
							name={'end_date'}
							disabled={isEdit}
							class_name={'col'}
							label_name={'End date'}
							on_change={handleFormChange}
							error_name={errors.end_date}
							default_value={inboxForm.end_date}
							required={isEdit || smtpActive ? false : true}
						/>
						<InputField
							errors={errors}
							type={'number'}
							disabled={isEdit}
							class_name={'col'}
							name={'reply_rate_per_day'}
							on_change={handleFormChange}
							label_name={'Reply rate per day'}
							error_name={errors.reply_rate_per_day}
							default_value={inboxForm.reply_rate_per_day}
							required={isEdit || smtpActive ? false : true}
						/>
					</div>
				</>
			) : (
				// ) : email_account_provider_id === 1 ||
				//   email_account_provider_id === 2 ||
				//   email_account_provider_id === 3 ||
				//   email_account_provider_id === 4 ||
				//   email_account_provider_id === 5 ||
				//   email_account_provider_id === 6 ||
				//   email_account_provider_id === 7 ||
				//   email_account_provider_id === 8 ||
				//   (email_account_provider_id === 9 && smtpActive) ||
				//   (email_account_provider_id === 10 && smtpActive) ||
				//   (email_account_provider_id === 11 && smtpActive) ||
				//   (email_account_provider_id === 12 && smtpActive) ||
				//   email_account_provider_id === 13 ||
				//   email_account_provider_id === 14 ? (
				// 	<>
				// 		<div className='row mt-4'>
				// 			<InputField
				// 				type={'text'}
				// 				errors={errors}
				// 				name={'username'}
				// 				on_change={handleFormChange}
				// 				error_name={errors.username}
				// 				default_value={inboxForm.username}
				// 				label_name={'SMTP Username / Email *'}
				// 				required={isEdit || apiActive ? false : true}
				// 			/>
				// 			<InputField
				// 				errors={errors}
				// 				type={'password'}
				// 				name={'password'}
				// 				on_change={handleFormChange}
				// 				error_name={errors.password}
				// 				label_name={'SMTP Password *'}
				// 				default_value={inboxForm.password}
				// 				required={isEdit || apiActive ? false : true}
				// 			/>
				// 		</div>
				// 	</>
				''
			)}
			{/* smtp host, port & encryption */}
			{email_account_provider_id === 1 ? (
				<>
					<div className='row mt-4'>
						<InputField
							type={'text'}
							errors={errors}
							name={'host'}
							error_name={errors.host}
							label_name={'SMTP Host'}
							on_change={handleFormChange}
							default_value={inboxForm.host}
							required={isEdit ? false : true}
						/>

						<InputField
							type={'text'}
							name={'port'}
							errors={errors}
							error_name={errors.port}
							label_name={'SMTP Port'}
							on_change={handleFormChange}
							default_value={inboxForm.port}
							required={isEdit ? false : true}
						/>
					</div>
					<div className='row mt-4'>
						<div className='col-sm-6'>
							<label htmlFor='smtpPort' className='form-label'>
								SMTP Encryption <span className='text-danger'>*</span>
							</label>
							<select
								name='encryption'
								className='form-control'
								// id='inputWithoutBackground'
								onChange={handleFormChange}
								required={isEdit ? false : true}
								defaultValue={inboxForm.encryption}
							>
								<option defaultValue=''>Select SMTP Encryption</option>
								<option defaultValue='ssl'>SSL</option>
								<option defaultValue='tls'>TLS</option>
							</select>
							{errors.hasOwnProperty('encryption')
								? errors.encryption.map((e, i) => (
										<div key={i} className='text-danger ml-1'>
											{' '}
											<b> {e}</b>{' '}
										</div>
								  ))
								: ''}
						</div>
						<InputField
							type={'email'}
							errors={errors}
							name={'from_email'}
							label_name={'From Email'}
							on_change={handleFormChange}
							error_name={errors.from_email}
							required={isEdit ? false : true}
							default_value={inboxForm.from_email}
						/>
					</div>
				</>
			) : (email_account_provider_id === 12 && smtpActive) ||
			  (email_account_provider_id === 9 && smtpActive) ||
			  email_account_provider_id === 14 ? (
				<>
					<div
						className={
							email_account_provider_id === 11 ? 'row mt-0' : 'row mt-4'
						}
					>
						<InputField
							type={'email'}
							errors={errors}
							name={'from_email'}
							label_name={'From Email'}
							on_change={handleFormChange}
							error_name={errors.from_email}
							required={isEdit ? false : true}
							default_value={inboxForm.from_email}
						/>
					</div>
				</>
			) : (
				<></>
			)}
			{email_account_provider_id === 15 ||
			email_account_provider_id === 16 ||
			email_account_provider_id === 17 ||
			email_account_provider_id === 18 ||
			email_account_provider_id === 20 ||
			(email_account_provider_id === 9 && apiActive) ||
			(email_account_provider_id === 10 && apiActive) ? (
				''
			) : (
				<>
					{aweberAuthUrl !== '' ? (
						<></>
					) : (
						<div className='row mt-4 float-left'>
							<div className='col-sm-6 '>
								<button
									className='con-check-bn'
									disabled={checkingConnection}
									onClick={handleConnectionCheck}
								>
									{checkingConnection ? (
										<div
											className='spinner-border text-white'
											role='status'
										></div>
									) : (
										<span>
											{inboxForm.email_account_provider_id === 19
												? 'Validate Credentials'
												: 'Check Connection'}
										</span>
									)}
								</button>
								&nbsp;&nbsp;&nbsp;&nbsp;
							</div>
						</div>
					)}
					<div className='float-left' style={{ marginTop: '40px' }}>
						{checkSmtp === 'true' ? (
							<i
								className='fas fa-check-circle'
								style={{ color: 'green', fontSize: '20px' }}
							></i>
						) : checkSmtp === 'false' ? (
							<div className=' text-danger mt-2 ml-1'>
								{' '}
								Your SMTP connection is not working.{' '}
							</div>
						) : (
							''
						)}
					</div>
					{aweberAuthUrl === '' || aweberDataReceived ? (
						<></>
					) : (
						<div className='row mt-4 ml-1 text-center'>
							<a
								target='_blank'
								href={aweberAuthUrl}
								className='con-check-bn'
								style={{ paddingTop: '12px' }}
							>
								<span style={{ color: '#ffffff' }}>
									Authenticate With Aweber
								</span>
							</a>
						</div>
					)}
					<br />
					<br />
					<br />
					{/* (<> <br/> <br/> <br/><br/> <br/> <br/><i className="fas fa-check-circle" style={{color:"#0994F8" , fontSize:"248px"}}></i> </>) */}
					{/* <div className="">
        {checkSmtp === "true" ? (
          ""
        ) : checkSmtp === "false" ? (
          <div className=" text-danger mt-2 ml-1">
            {" "}
            Your SMTP connection is not working.{" "}
          </div>
        ) : (
          ""
        )}
      </div> */}
					{/* <div className="mt-5 text-muted">
            <ul style={{ lineHeight: "50px", fontSize: "15px" }}>
              <li>Enter your SMTP Username &amp; Password </li>
              <li>
                Click on the <b>&nbsp;&nbsp;Check Connection&nbsp;&nbsp;</b>{" "}
                button
              </li>
              <li>
                If the SMTP connection is working then click on the{" "}
                <b>&nbsp;&nbsp;Next&nbsp;&nbsp;</b> Button
              </li>
            </ul>
          </div> */}
				</>
			)}
		</div>
	)
}

export default ConnectInboxContent
