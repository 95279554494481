import moment from "moment"
import "./WampUpEmails.scss"
import { Modal } from "bootstrap"
import { toast } from "react-toastify"
import { Link } from "react-router-dom"
import "../../components/Filter/filter.scss"
import Pagination from "react-js-pagination"
import StatsModal from "./StatsModal/StatsModal"
import FadeLoader from "react-spinners/FadeLoader"
import Filter from "../../components/Filter/Filter"
import NewInboxModal from "./NewInboxModal/NewInboxModal"
import React, { useEffect, useState, useRef } from "react"
import warmupService from "../../api-services/warmupService"
import FeatureService from "../../api-services/featuresService"
import { ReactComponent as PausedIcon } from "../../components/Assets/icons/paused-icon.svg"
import { ReactComponent as ActiveIcon } from "../../components/Assets/icons/active-icon.svg"
import { Header } from "../../atoms/Header"
import { MarketingCampaigns } from "../../atoms/MarketingCampaigns"
import { DropDown } from "../../atoms/DropDown"
import { DeleteModal } from "../../atoms/DeleteModal"
import { GetStarted } from "../../atoms/GetStarted"
import Table from "../../atoms/Table"
import { Layout } from "../../atoms/Layout"
import { NotFound } from "../../atoms/NotFound"

const tableHeader = [
  {
    th: "Inbox",
    sort: true,
  },
  {
    th: "Sent",
    sort: true,
  },
  {
    th: "Inboxed",
    sort: true,
  },
  {
    th: "Interactions",
    sort: true,
  },
  {
    th: "Spam Rate",
    sort: true,
  },
  {
    th: "Start Date",
    sort: true,
  },
  {
    th: "End Date",
    sort: true,
  },
  {
    th: "Status",
    sort: true,
  },
  {
    th: "Action",
    sort: false,
  },
]
const WarmUpEmails = () => {
  const ref = useRef(null)

  const [id, setId] = useState(0)
  const [campaign, setCampaign] = useState(0)
  const [noData, setNoData] = useState(false)
  const [metaData, setMetaData] = useState({})
  const [loading, setLoading] = useState(false)
  const [activePage, setActivePage] = useState(1)
  const [searchInput, setSearchInput] = useState("")
  const [warmupAccounts, setWarmupAccounts] = useState([])
  const [marketingCapmaignName, setMarketingCapmaignName] = useState("")
  const [warmupCount, setWarmupCount] = useState({
    max_allowed_count: 0,
    consumed_count: 0,
  })

  const modalInstanceAdd = document.getElementById("exampleModal")
  const modalInstanceStats = document.getElementById("statsModal")
  const modalInstanceDel = document.getElementById("exampleModalCenterDlt")

  const warmupMessage = `Warming Up Recommendations:\nIt\'s Highly Recommend That You Set A 3 Month Warming Up Start & End Date the reason is,\nBy setting up 3-month warming up campaign you will start to develop trust with the different Email Service Providers with this length of a campaign not only will show consistency with your domain and emails but will greatly improve your inboxing %.`
  const extractedData = React.useMemo(() => {
    return warmupAccounts.map((warmupAccount) => {
      const tableCells = [
        {
          cellData: (
            <Link
              to={{
                pathname: `/warm-up-emails/stats/${warmupAccount.id}`,
                state: {
                  warmupAccountData: warmupAccount,
                  campaignStatus: warmupAccount.is_active,
                },
              }}
            >
              {warmupAccount.campaign_name}
            </Link>
          ),
        },
        {
          cellData:
            warmupAccount.stats !== undefined
              ? warmupAccount.stats.emails_sent != null
                ? warmupAccount.stats.emails_sent
                : 0
              : "loading",
        },
        {
          cellData:
            warmupAccount.stats !== undefined
              ? warmupAccount.stats.total_inboxed != null
                ? warmupAccount.stats.total_inboxed
                : 0
              : "loading",
        },
        {
          cellData:
            warmupAccount.stats !== undefined
              ? warmupAccount.stats.interactions != null
                ? warmupAccount.stats.interactions
                : 0
              : "loading",
        },
        {
          cellData:
            warmupAccount.stats !== undefined
              ? warmupAccount.stats.spam_rate != null
                ? warmupAccount.stats.spam_rate
                : 0
              : "loading",
        },
        {
          cellData:
            warmupAccount !== undefined
              ? moment(warmupAccount.start_date).format("MMM D, YYYY")
              : "loading",
        },
        {
          cellData:
            warmupAccount !== undefined
              ? moment(warmupAccount.end_date).format("MMM D, YYYY")
              : "loading",
        },
        {
          cellData: (
            <>
              {warmupAccount.is_completed ? (
                <ActiveIcon className='mr-1' />
              ) : (
                <PausedIcon className='mr-1' />
              )}
              {warmupAccount.is_completed
                ? "Completed"
                : !warmupAccount.is_active && !warmupAccount.is_completed
                  ? "Paused"
                  : "Running"}
            </>
          ),
        },
        {
          cellData: (
            <DropDown
              pathName={`/warm-up-emails/stats/${warmupAccount.id}`}
              tableDetail={warmupAccount}
              state={{ warmupAccountData: warmupAccount }}
              handleClick={() => {
                setId(warmupAccount.id)
                openModal(modalInstanceDel)
              }}
              deleteTest={"deleteCampaign-"}
            >
              <a
                className='dropdown-item'
                style={{
                  cursor: "pointer",
                }}
              >
                {warmupAccount.getResponseWcCampaign !== null ? (
                  <MarketingCampaigns
                    handleId={() => handleId(warmupAccount)}
                  />
                ) : warmupAccount.KlaviyoWcCampagin !== null ? (
                  <MarketingCampaigns
                    handleId={() => handleId(warmupAccount)}
                  />
                ) : warmupAccount.mailchimpWcCampagin !== null ? (
                  <MarketingCampaigns
                    handleId={() => handleId(warmupAccount)}
                  />
                ) : warmupAccount.sendinBlueWcCampagin !== null ? (
                  <MarketingCampaigns
                    handleId={() => handleId(warmupAccount)}
                  />
                ) : warmupAccount.hyperMailWcCampagin !== null ? (
                  <MarketingCampaigns
                    handleId={() => handleId(warmupAccount)}
                  />
                ) : warmupAccount.sendGridWcCampagin !== null ? (
                  <MarketingCampaigns
                    handleId={() => handleId(warmupAccount)}
                  />
                ) : warmupAccount.activeWcCampaign !== null ? (
                  <MarketingCampaigns
                    handleId={() => handleId(warmupAccount)}
                  />
                ) : warmupAccount.aweberWcCampaign !== null ? (
                  <MarketingCampaigns
                    handleId={() => handleId(warmupAccount)}
                  />
                ) : (
                  ""
                )}
              </a>
            </DropDown>
          ),
        },
      ]

      return tableCells
    })
  })

  const getWarmupAccounts = async () => {
    setLoading(true)
    const response = await warmupService.getWarmupAccounts(
      `/warmup-campaigns?page=${activePage}`
    )
    setWarmupAccounts(response.data)
    setMetaData(response.meta)
    setLoading(false)
  }

  const handlePageChange = async (pageNumber) => {
    setLoading(true)
    const response = await warmupService.getWarmupAccounts(
      `/warmup-campaigns?page=${pageNumber}`
    )
    setWarmupAccounts(response.data)
    setMetaData(response.meta)
    setActivePage(pageNumber)
    if (response.length !== 0) {
      setLoading(false)
    }
  }

  const closeModal = (modalInstance) => {
    var myModal = Modal.getInstance(modalInstance)

    myModal.hide()
  }
  const openModal = (modalInstance) => {
    var myModal = Modal.getOrCreateInstance(modalInstance)

    myModal.show()
  }

  const notify = (response) => {
    response === 422
      ? toast.error("Invalid or empty fields.")
      : response.success === true
        ? toast.info(response.message)
        : toast.error(response.message)
  }

  const handleDelete = async (tempId) => {
    ref.current.continuousStart()
    try {
      const response = await warmupService.deleteWarmupCampaign(tempId)
      if (response.success) {
        notify(response)
        closeModal(modalInstanceDel)
      }
      ref.current.complete()
      getWarmupAccounts()
      getFeatureCount()
    } catch (e) {
      if (ref.current) ref.current.complete()
    }
  }
  const handleId = (warmupAccount) => {
    if (warmupAccount.getResponseWcCampaign !== null) {
      setMarketingCapmaignName("getResponseWcCampaign")
      openModal(modalInstanceStats)
    } else if (warmupAccount.KlaviyoWcCampagin !== null) {
      setMarketingCapmaignName("KlaviyoWcCampagin")
      openModal(modalInstanceStats)
    } else if (warmupAccount.mailchimpWcCampagin !== null) {
      setMarketingCapmaignName("mailchimpWcCampagin")
      openModal(modalInstanceStats)
    } else if (warmupAccount.sendinBlueWcCampagin !== null) {
      setMarketingCapmaignName("sendinBlueWcCampagin")
      openModal(modalInstanceStats)
    } else if (warmupAccount.hyperMailWcCampagin !== null) {
      setMarketingCapmaignName("hyperMailWcCampagin")
      openModal(modalInstanceStats)
    } else if (warmupAccount.sendGridWcCampagin !== null) {
      setMarketingCapmaignName("sendGridWcCampagin")
      openModal(modalInstanceStats)
    } else if (warmupAccount.activeWcCampaign !== null) {
      setMarketingCapmaignName("activeWcCampaign")
      openModal(modalInstanceStats)
    } else if (warmupAccount.aweberWcCampaign !== null) {
      setMarketingCapmaignName("aweberWcCampaign")
      openModal(modalInstanceStats)
    }
    setCampaign(warmupAccount)
  }

  const handleSearch = async () => {
    try {
      setLoading(true)
      const response = await warmupService.getWarmupAccounts(
        `/warmup-campaigns?campaignname=${searchInput}`
      )
      if (response.data.length !== 0) {
        setWarmupAccounts(response.data)
        setMetaData(response.meta)
        setNoData(false)
      } else {
        setNoData(true)
      }
      setLoading(false)
      // let element = document.getElementById('multiCollapseExample1')
      // element.classList.remove('show')
    } catch (e) {
      // Log & handle error
    }
  }

  const handleClearFilter = () => {
    setSearchInput("")
    setNoData(false)
    getWarmupAccounts()
    // let element = document.getElementById('multiCollapseExample1')
    // element.classList.remove('show')
  }

  const getFeatureCount = async () => {
    try {
      let tempObj = {}
      const response = await FeatureService.getFeatureConsumptionCount({
        warmup: 1,
        domainHealth: "",
        spamchecker: "",
        emailgrader: "",
        sequences: "",
      })

      tempObj.consumed_count = response.consumed_count
      tempObj.max_allowed_count = response.max_allowed_count

      setWarmupCount(tempObj)
    } catch (error) {
      toast.error("Something went wrong")
    }
  }

  useEffect(() => {
    localStorage.setItem("warmupAccount", JSON.stringify(warmupAccounts))
  }, [warmupAccounts])

  useEffect(() => {
    getWarmupAccounts()
    getFeatureCount()
  }, [])

  return (
    <Layout innerRef={ref}>
      <Header
        iconPath={"/assets/img/warmup-icon.png"}
        iconAltText={"warm-up"}
        headline={"Warm-up Emails"}
        helpPath={"3-warmupHelp.html"}
        headerContent={"Manage Inboxes you want to warm-up"}
        maxAllowCount={warmupCount.max_allowed_count}
        consumedCount={warmupCount.consumed_count}
        buttonName={"Add New Inbox"}
        openModal={() => openModal(modalInstanceAdd)}
        filter={
          <Filter
            status='warm-up'
            searchInput={searchInput}
            handleSearch={handleSearch}
            setSearchInput={setSearchInput}
            handleClearFilter={handleClearFilter}
          />
        }
      />
      <div className='row mt-5'>
        <div className='col-sm-12' style={{ overflowY: "hidden" }}>
          {noData ? (
            <NotFound dataNotFound={"No Warm-up Found"} />
          ) : loading ? (
            <div className='text-center pb-5 mb-5'>
              <FadeLoader
                width={5}
                height={20}
                color={"#0092FB"}
                loading={loading}
              />
            </div>
          ) : warmupAccounts.length === 0 ? (
            <GetStarted
              dataTarget={"#exampleModal"}
              helpPath={"3-warmupHelp.html"}
              title={"InboxBetter to never land in spam anymore"}
              addFirst={
                <>
                  Add your first inbox to start to warm-up your email account
                  &nbsp;
                </>
              }
            />
          ) : (
            <>
              <Table
                tableHeader={tableHeader}
                extractedData={extractedData}
                className={"inbox-item-name"}
              />
              <div className='float-right'>
                <Pagination
                  itemClass='page-item'
                  linkClass='page-link'
                  pageRangeDisplayed={4}
                  activePage={activePage}
                  totalItemsCount={metaData.total}
                  itemsCountPerPage={metaData.per_page}
                  onChange={(pageNumber) => handlePageChange(pageNumber)}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <DeleteModal
        modalCenterDlt={"exampleModalCenterDlt"}
        modalCenterTitle={"exampleModalCenterTitle"}
        confirmation={
          " Do you really want to delete this inbox from your account?"
        }
        close={() => closeModal(modalInstanceDel)}
        DeleteItem={() =>
          handleDelete(
            parseInt(
              document
                .getElementById("deleteCampaign-" + id)
                .getAttribute("data-id")
            )
          )
        }
      />
      <NewInboxModal
        closeModal={closeModal}
        getFeatureCount={getFeatureCount}
        modalInstanceAdd={modalInstanceAdd}
        getWarmupAccounts={getWarmupAccounts}
      />
      <StatsModal
        campaign={campaign}
        closeModal={closeModal}
        warmupAccounts={warmupAccounts}
        modalInstanceStats={modalInstanceStats}
        marketingCapmaignName={marketingCapmaignName}
      />
    </Layout>
  )
}

export default WarmUpEmails
