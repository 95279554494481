function isDate(dateArg) {
  var t =
    dateArg instanceof Date
      ? dateArg
      : new Date(dateArg.replace(/-/g, "/").replace(/T.+/, ""));
  return !isNaN(t.valueOf());
}

function isValidRange(minDate, maxDate) {
  return (
    new Date(minDate.replace(/-/g, "/").replace(/T.+/, "")) <=
    new Date(maxDate.replace(/-/g, "/").replace(/T.+/, ""))
  );
}

const dateBetween = (startDt, endDt) => {
  var error =
    isDate(endDt) && isDate(startDt) && isValidRange(startDt, endDt)
      ? false
      : true;
  var between = [];
  if (error) {
    // console.log("error occured!!!... Please Enter Valid Dates");
  } else {
    var currentDate = new Date(startDt.replace(/-/g, "/").replace(/T.+/, "")),
      end = new Date(endDt.replace(/-/g, "/").replace(/T.+/, ""));
    while (currentDate <= end) {
      between.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
  }
  return between;
};

function getFullDates(s, e) {
  let b = dateBetween(s, e);
  let fDates = [...Object.values(b)];
  if (fDates.length) {
    let fullDates = fDates.map((date) => {
      const month = parseInt(date.getMonth()) + 1;
      return date.getFullYear() + "-" + month + "-" + date.getDate();
    });
    return fullDates;
  }
}
export default getFullDates;
