import React, { useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
function LineChart(props) {
  /* Chart code */
  // Themes begin
  let id = props.id;
  let chartData = props.chartData;
  // let color = "";

  // if (id === "chart1") {
  //   color = "#0994F8";
  // } else {
  //   color = "#960000";
  // }

  // console.log(chartData, "data in chart");
  // Themes end
  useLayoutEffect(() => {
    // Create chart instance
    let chart = am4core.create(id, am4charts.XYChart);

    // Add data
    chart.data = chartData;
    // chart.data = [
    //   {
    //     date: "Aug 27",
    //     inbox: 10,
    //     color: color,
    //   },
    //   {
    //     date: "Aug 28",
    //     inbox: 10,
    //   },
    //   {
    //     date: "Aug 29",
    //     inbox: 20,
    //   },
    //   {
    //     date: "Aug 30",
    //     inbox: 30,
    //   },
    //   {
    //     date: "Aug 31",
    //     inbox: 50,
    //   },
    //   {
    //     date: "Sep 01",
    //     inbox: 30,
    //   },
    //   {
    //     date: "Sep 02",
    //     inbox: 10,
    //   },
    //   {
    //     date: "Sep 03",
    //     inbox: 20,
    //   },
    //   {
    //     date: "Sep 04",
    //     inbox: 30,
    //   },
    //   {
    //     date: "Sep 05",
    //     inbox: 40,
    //   },
    //   {
    //     date: "Sep 06",
    //     inbox: 10,
    //   },
    // ];

    // Create category axis
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "formatted_date";
    categoryAxis.renderer.opposite = false;

    // Create value axis
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inversed = false;
    // valueAxis.title.text = "Place taken";
    valueAxis.renderer.minLabelPosition = 0.01;

    // Create series
    let series1 = chart.series.push(new am4charts.LineSeries());
    series1.dataFields.valueY =
      id === "chart1" ? "total_inboxed" : "total_spamed";
    series1.dataFields.categoryX = "formatted_date";
    series1.name = id === "chart1" ? "Inboxed" : "Spamed";
    var bullet = series1.bullets.push(new am4charts.CircleBullet());
    series1.tooltipText = "Place taken by {name} in {categoryX}: {valueY}";
    series1.legendSettings.valueText = "{valueY}";
    series1.visible = false;
    series1.strokeWidth = 3;
    bullet.circle.fill = am4core.color("white");
    bullet.stroke =
      id === "chart1" ? am4core.color("#0994F8") : am4core.color("#960000");
    bullet.circle.radius = 6;
    bullet.strokeWidth = 3;
    bullet.tooltipText =
      "{name} on {categoryX}:\n[bold font-size: 20]{valueY}[/]";
    series1.stroke =
      id === "chart1" ? am4core.color("#0994F8") : am4core.color("#960000");

    let hs1 = series1.segments.template.states.create("hover");
    hs1.properties.strokeWidth = 5;
    series1.segments.template.strokeWidth = 1;

    // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.itemContainers.template.events.on("over", function (event) {
      let segments = event.target.dataItem.dataContext.segments;
      segments.each(function (segment) {
        segment.isHover = true;
      });
    });

    chart.legend.itemContainers.template.events.on("out", function (event) {
      let segments = event.target.dataItem.dataContext.segments;
      segments.each(function (segment) {
        segment.isHover = false;
      });
    });
    return () => {
      chart.dispose();
    };
  }, [props]);

  return <div id={id} style={{ width: "100%", height: "500px" }}></div>;
}

export default LineChart;
