import React, { useEffect, useState, useRef } from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams, Link, Redirect } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';

import AuthService from '../../api-services/authService';

const VerifyEmail = ({ history, location, sUser }) => {
  const ref = useRef(null);

  const { user } = useParams();
  const [isVerified, setIsVerified] = useState(false);

  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const notify = () => toast('Email has been verified.');

  const alreadyEmailVerifyNotify = () => toast('Email has already been verified.');


  useEffect(async () => {
    if (sUser.email_verified_at != null && sUser.email_verified_at != false) {
      history.push('/login');
    } else {
      setLoading(true);
      ref.current.continuousStart();
      try {
        const queryParms = queryString.parse(location.search);
        await AuthService.verifyEmailRequset(`${user}${location.search}`);
        notify();
        setIsVerified(true);
        setTimeout(() => {
          history.push('/login');
      }, 3000);
      } catch (e) {
        if (e.response.status == 422) {
          const errors = e.response.data.errors;
          setErrors(errors);
          alreadyEmailVerifyNotify();
          setIsVerified(true);
        } else {
          setError(e);
        }
      } finally {
        setLoading(false);
        if (ref.current) ref.current.complete();
      }
    }
  }, []);

  return (
    <React.Fragment>
      <div class="container py-4">
        <LoadingBar height={5} color="#5D95EA" ref={ref} />
        <header class="pb-3 mb-4 border-bottom">
          <a
            href="/"
            class="d-flex align-items-center text-dark text-decoration-none"
          >
            <span class="fs-4">Inbox Better</span>
          </a>
        </header>
        <div class="p-4 mb-4 bg-light  rounded-3">
          <div class="container-fluid py-5">
            <p class="col-md-12 fs-6">
              Please wait we are verifing your email address...
            </p>

            {isVerified ? (
              <p>
                <strong class="text-success">Successfully Verified</strong>
              </p>
            ) : (
              ''
            )}

            <Link to="/login" class="btn btn-sm btn-primary" type="button">
              Click here to Login Now
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    sUser: state.authReducer.user,
  };
}

export default connect(mapStateToProps, null)(VerifyEmail);
