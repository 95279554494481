import React from "react";
import { useState, useRef } from "react";
import LoadingBar from "react-top-loading-bar";
import { toast } from "react-toastify";
import affiliateService from "../../api-services/affiliateService";
import { useDispatch } from "react-redux";
import { LOGIN } from "../../store/types";

const AffiliateRequestModal = ({ user }) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const notify = (response) => {
    response === 422
      ? toast.error("Something went wrong..!!")
      : response.success === true ||
        response.message === "yours Affiliate request has been submitted"
      ? toast.info(response.message)
      : toast.error(response.message);
  };

  const handleRequest = async (id) => {
    setLoading(true);
    ref.current.continuousStart();
    try {
      const response = await affiliateService.requestAffiliate(id);
      dispatch({
        type: LOGIN,
        payload: {
          user: response.user,
          token: localStorage.getItem("token") || "",
          expiresIn: localStorage.getItem("expiresIn") || "",
          isLoggedIn: !!localStorage.getItem("token"),
        },
      });
      localStorage.setItem("user", JSON.stringify(response.user));
      notify(response);

      try {
        const modal = document.getElementById("exampleModal");
        document.body.classList.remove("modal-open");
        document.body.removeAttribute("style");
        let header = document.getElementsByClassName("header");
        header[0].removeAttribute("style");
        modal.classList.remove("show");
        modal.setAttribute("aria-hidden", "true");
        modal.style.display = "none";
        modal.setAttribute("aria-modal", "false");
        modal.setAttribute("data-bs-dismiss", "modal");
        const modalBackdrops =
          document.getElementsByClassName("modal-backdrop");
        modalBackdrops[0].classList.remove("show");
        document.body.removeChild(modalBackdrops[0]);
      } catch (e) {
        // console.log(e);
      }
    } catch (e) {
      // console.log(e);

      if (e.response.status == 422) {
        notify(e.response.data);
      } else {
        notify(e.response.data);
      }
    } finally {
      setLoading(false);
      if (ref.current) ref.current.complete();
    }
  };
  return (
    <>
      <LoadingBar height={5} color="#5D95EA" ref={ref} />
      <div
        class="modal fade"
        id="affiliateRequestModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header-dns">
              <h5 className="modal-title-dns" id="exampleModalLongTitle">
                &nbsp;&nbsp;&nbsp;&nbsp;
                <img
                  src="/assets/img/shld.png"
                  alt=""
                  style={{ color: "#0994F8" }}
                />
                &nbsp;&nbsp;&nbsp; Request for Affiliate
              </h5>
              <button
                type="button"
                className="modal-close"
                data-dismiss="modal"
                aria-label="Close"
                style={{ outline: "none" }}
              >
                <img src="/assets/img/cross.png" alt="" />
              </button>
            </div>
            <div className="modal-body-dns">
              <h5>Are you sure you want to an affiliate user.?</h5>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary modal-button bg-white border text-dark"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary mr-3 modal-button "
                onClick={() => handleRequest(user.id)}
                disabled={loading}
                data-dismiss="modal"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AffiliateRequestModal;
