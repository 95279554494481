import React from "react"

export const MarketingCampaigns = ({ handleId }) => {
  return (
    <span
      style={{
        color: "#38b000",
        cursor: "pointer",
      }}
      onClick={handleId}
      className='d-flex align-items-center justify-content-center'
    >
      <i aria-hidden='true' className='fa fa-eye mx-3'></i>
      <span style={{ color: "#38b000" }} className='flex-fill text-center'>
        Marketing Campaigns
      </span>
    </span>
  )
}
