import axios from "axios";
import store from "../store"
import { toast } from "react-toastify"
import { logout } from "../store/actions/auth"

const API = axios.create({
  baseURL: process.env.REACT_APP_INBOX_BETTER_API_URL,
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
  },
});

API.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    console.log(err.status)
    if (err.response.status === 401) {
      if (err.response.data.message !== undefined) {
        window.location = "/login/deactivated";
      }
      // store.dispatch(logout());

      localStorage.clear()
    }

    if (err.response.status === 403) {
      if (err.response.data.message !== undefined) {
        window.location = "/login/blocked";
      }
      // store.dispatch(logout());

      localStorage.clear()
    }


    // if (err.status === 500) {
    //   toast.error("Invalid Token Entered...", {
    //     autoClose: 2500,
    //     closeOnClick: true,
    //     position: "top-right",
    //     hideProgressBar: false,
    //   })
    // }

    if (err.status !== 401) {
      throw err;
    }
  }
);

export default API;
