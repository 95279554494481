import React, { useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import HelpComponent from '../../../components/Help/Help'
import { ReactComponent as EyeIcon } from '../../../components/Assets/icons/eye-icon.svg'
import { ReactComponent as EyeSlashIcon } from '../../../components/Assets/icons/eye-slash-icon.svg'

export const AddEmailModal = ({
	errors,
	loading,
	formData,
	selectAll,
	emailList,
	handleCopy,
	closeModal,
	emailCount,
	handleSelect,
	handleAddEmail,
	editorStateExp,
	handelSelectAll,
	handleAddEmailChange,
	onEditorStateChangeExp,
}) => {
	const [showPassword, setShowPassword] = useState(false)

	return (
		<div
			tabIndex='-1'
			role='dialog'
			aria-hidden='true'
			className='modal fade'
			id='fromEmailModalCenter'
			aria-labelledby='exampleModalCenterTitle'
		>
			<div
				role='document'
				style={{ maxWidth: '818px' }}
				className='modal-dialog modal-dialog-centered'
			>
				<div className='modal-content from-email-modal'>
					<div className='modal-header-dns'>
						<h5 className='modal-title-dns' id='exampleModalLongTitle'>
							&nbsp;&nbsp;&nbsp;&nbsp;
							<img
								alt=''
								style={{ color: '#0994F8' }}
								src='/assets/img/from-email-modal.png'
							/>
							&nbsp;&nbsp;&nbsp; From Email
						</h5>
						<button
							type='button'
							aria-label='Close'
							onClick={closeModal}
							className='modal-close'
							style={{ outline: 'none' }}
						>
							<img src='/assets/img/cross.png' alt='' />
						</button>
					</div>
					<div className='modal-body-dns'>
						<div className='row modal-bar py-2 mb-3'>
							<div className='col'>
								<b>Step 1:</b>
								<span>
									&nbsp;Enter the email address which you want to use to send
									emails &nbsp;
									<b>
										<HelpComponent
											filePath={'/help-fragments/1-dns-checker.html'}
										/>
									</b>
								</span>
							</div>
						</div>
						<div data-tour='step-7'>
							<div className='mb-3'>
								<input
									required
									type='email'
									name='from_email'
									placeholder='Sender Email'
									value={formData.from_email}
									className='form-control '
									onChange={handleAddEmailChange}
								/>
								{errors.hasOwnProperty('from_email')
									? errors.from_email.map(e => (
											<div className='text-danger ml-1'>
												<b> {e}</b>{' '}
											</div>
									  ))
									: ''}
							</div>
							<div className='d-flex flex-column'>
								<div className='d-flex flex-column justify-content-center align-items-center'>
									<hr />
									<h5>OR</h5>
									<hr />
								</div>
							</div>
							<div className='row modal-bar py-2 mb-3'>
								<div className='col'>
									<b>Step 1:</b>
									<span>
										&nbsp;Enter the email address which you want to use to send
										emails &nbsp;
										<b>
											<HelpComponent
												filePath={'/help-fragments/1-dns-checker.html'}
											/>
										</b>
									</span>
								</div>
							</div>
							<div className='mb-3'>
								<input
									required
									type='email'
									name='from_email'
									placeholder='Sender Email'
									value={formData.from_email}
									className='form-control '
									onChange={handleAddEmailChange}
								/>
								{errors.hasOwnProperty('from_email')
									? errors.from_email.map(e => (
											<div className='text-danger ml-1'>
												<b> {e}</b>{' '}
											</div>
									  ))
									: ''}
							</div>
							<div className='input-group mb-3'>
								<input
									required
									name='password'
									placeholder='Password'
									className='form-control'
									value={formData.password}
									onChange={handleAddEmailChange}
									type={showPassword ? 'text' : 'password'}
								/>
								<span
									id='basic-addon2'
									className='input-group-text'
									style={{ cursor: 'pointer' }}
									onClick={() => setShowPassword(!showPassword)}
								>
									{showPassword ? <EyeSlashIcon /> : <EyeIcon />}
								</span>
							</div>
							<input
								required
								type='text'
								name='subject_line'
								placeholder='Subject Line'
								className='form-control mb-3'
								value={formData.subject_line}
								onChange={handleAddEmailChange}
							/>
							{/* {errors.hasOwnProperty('subjectLine')
								? errors.subjectLine.map(e => (
										<div className='text-danger mt-2 ml-1'> {e} </div>
								  ))
								: ''} */}
							<div>
								<Editor
									toolbarOnFocus
									toolbar={{
										options: [
											'list',
											'emoji',
											'image',
											'remove',
											'inline',
											'history',
											'fontSize',
											'blockType',
											'textAlign',
											'fontFamily',
										],
									}}
									name='email_body'
									spellCheck={true}
									editorState={editorStateExp}
									placeholder='Email Body...'
									wrapperClassName='wrapperStyle'
									editorStyle={{
										height: '230px',
									}}
									editorClassName='demo-editor bg-white'
									onEditorStateChange={onEditorStateChangeExp}
								/>
							</div>
						</div>
						{/* <div className='mt-3'>
							<input
								required
								type='email'
								name='from_email'
								data-tour='step-7'
								style={{
									height: '40px',
									fontSize: '14px',
									fontStyle: 'normal',
									textDecoration: 'none',
									fontFamily: 'Eudoxus Sans',
								}}
								onChange={handleAddEmailChange}
								className='modal-input border w-100'
								placeholder='&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Enter from email address'
							/>
							{errors.hasOwnProperty('from_email') ? (
								<span className='text-danger mt-2 ml-1'>
									{' '}
									{errors['from_email']}{' '}
								</span>
							) : (
								''
							)}
						</div> */}
						<div className='row d-flex justify-content-between my-2 modal-bar'>
							<div className='col my-2'>
								<b style={{ fontSize: '14px' }}>Step 2:</b>
								<span>
									&nbsp;&nbsp;Select Providers, you can select multiple
									providers
								</span>
								&nbsp;&nbsp;
							</div>
							<div className='col-4 d-flex justify-content-end p-0'>
								<div className=''>
									<button
										onClick={handleCopy}
										className='btn btn-primary c-btn'
										style={{ fontSize: '14px', marginRight: '2px' }}
									>
										<i className='fas fa-copy'></i>
										&nbsp;&nbsp;&nbsp;&nbsp; Copy Emails to clipboard
									</button>
								</div>
							</div>
						</div>
						<div className='row d-flex justify-content-between px-1'>
							<div className='col-6'>
								<b>
									{emailCount > 0 ? (
										<span style={{ color: 'green' }}>
											({emailCount}{' '}
											<span> Emails added and copied to clipboard..!!</span>)
										</span>
									) : errors.hasOwnProperty('to_email') ? (
										errors.to_email.map(e => (
											<div className='text-danger ml-1'>
												<b> {e}</b>{' '}
											</div>
										))
									) : (
										''
									)}
								</b>
							</div>
							<div className='col-6 d-flex justify-content-end'>
								<div className='mr-2'>
									<input
										readOnly
										type='checkbox'
										checked={selectAll}
										id='flexCheckDefault'
										onClick={handelSelectAll}
										className='form-check-input checkbox'
									/>
									<label
										htmlFor='flexCheckDefault'
										className='form-check-label'
									>
										Add all
									</label>
								</div>
							</div>
						</div>
						<div
							data-tour='step-8'
							className='row provider-select d-flex justify-content-center mt-3'
						>
							<div
								id='Gmail - Gsuit'
								className='col mx-3'
								onClick={handleSelect}
							>
								<div
									className={
										formData.inbox_providers ===
										[
											'Gmail - Gsuit',
											'Yahoo!',
											'Aol',
											// "Outlook",
											// "iCloud",
											'Mail.com',
										]
											? 'spamchecker-modal-card-selected row p-2'
											: formData.inbox_providers.includes('Gmail - Gsuit')
											? ' spamchecker-modal-card-selected row p-2'
											: 'spamchecker-modal-card row p-2'
									}
									id='Gmail - Gsuit'
								>
									<div className='col-10' id='Gmail - Gsuit'>
										<img
											alt=''
											width='35px'
											height='30px'
											id='Gmail - Gsuit'
											className='float-left'
											src='/assets/img/GmailEmail.png'
										></img>
										<div
											id='Gmail - Gsuit'
											className='float-left'
											style={{ margin: '5px 15px' }}
										>
											<b id='Gmail - Gsuit'>Gmail</b>
										</div>
									</div>
									<div
										id='Gmail - Gsuit'
										className='col-md-2 col-sm-2 col-lg-2 col-xxl-2 text-center'
									>
										<div id='Gmail - Gsuit' style={{ marginTop: '5px' }}>
											<b style={{ color: 'green' }} id='Gmail - Gsuit'>
												{emailList.length !== 0
													? emailList['Gmail - Gsuit'] === undefined
														? 0
														: emailList['Gmail - Gsuit'].length
													: 0}
											</b>
										</div>
									</div>
								</div>
							</div>
							<div id='Yahoo!' className='col mr-3' onClick={handleSelect}>
								<div
									className={
										formData.inbox_providers ===
										[
											'Gmail - Gsuit',
											'Yahoo!',
											'Aol',
											// "Outlook",
											// "iCloud",
											'Mail.com',
										]
											? 'spamchecker-modal-card-selected row p-2'
											: formData.inbox_providers.includes('Yahoo!')
											? ' spamchecker-modal-card-selected row p-2'
											: 'spamchecker-modal-card row p-2'
									}
									id='Yahoo!'
								>
									<div className='col-10' id='Yahoo!'>
										<img
											alt=''
											id='Yahoo!'
											width='35px'
											height='30px'
											className='float-left'
											src='/assets/img/yahoo-logo.png'
										></img>
										<div
											id='Yahoo!'
											className='float-left'
											style={{ margin: '5px 15px' }}
										>
											<b id='Yahoo!'>Yahoo</b>
										</div>
									</div>
									<div
										id='Yahoo!'
										className='col-md-2 col-sm-2 col-lg-2 col-xxl-2 text-center'
									>
										<div id='Yahoo!' style={{ marginTop: '5px' }}>
											<b style={{ color: 'green' }} id='Yahoo!'>
												{emailList.length !== 0
													? emailList['Yahoo!'] === undefined
														? 0
														: emailList['Yahoo!'].length
													: 0}
											</b>
										</div>
									</div>
								</div>
							</div>
							{/* <div
              className="col mr-3"
              id="Outlook"
              onClick={selectAll ? undefined : handleSelect}
            >
              <div
                className={
                  formData.inbox_providers ===
                  [
                    "Gmail - Gsuit",
                    "Yahoo!",
                    "Aol",
                    "Outlook",
                    "iCloud",
                    "Mail.com",
                  ]
                    ? "spamchecker-modal-card-selected row  p-2"
                    : formData.inbox_providers.includes("Outlook")
                    ? " spamchecker-modal-card-selected row  p-2"
                    : "spamchecker-modal-card row  p-2"
                }
                id="Outlook"
              >
                <div className="col-10" id="Outlook">
                  <img
                    className="float-left"
                    src="/assets/img/outlook.svg"
                    alt=""
                    width="35px"
                    height="30px"
                    id="Outlook"
                  ></img>
                  <div
                    className="float-left"
                    style={{ margin: "5px 15px" }}
                    id="Outlook"
                  >
                    <b id="Outlook">Outlook</b>
                  </div>
                </div>
                <div
                  className="col-md-2 col-sm-2 col-lg-2 col-xxl-2 text-center"
                  id="Outlook"
                >
                  <div id="Outlook" style={{ marginTop: "5px" }}>
                    <b style={{ color: "green" }} id="Outlook">
                      {emailList.length !== 0
                        ? emailList["Outlook"] === undefined
                          ? 0
                          : emailList["Outlook"].length
                        : 0}
                    </b>
                  </div>
                </div>
              </div>
            </div> */}
						</div>
						<div
							data-tour='step-9'
							className='row provider-select d-flex justify-content-center mt-3'
						>
							<div id='Aol' className='col mx-3' onClick={handleSelect}>
								<div
									className={
										formData.inbox_providers ===
										[
											'Gmail - Gsuit',
											'Yahoo!',
											'Aol',
											// "Outlook",
											// "iCloud",
											'Mail.com',
										]
											? 'spamchecker-modal-card-selected row  p-2'
											: formData.inbox_providers.includes('Aol')
											? ' spamchecker-modal-card-selected row  p-2'
											: 'spamchecker-modal-card row  p-2'
									}
									id='Aol'
								>
									<div className='col-10' id='Aol'>
										<img
											alt=''
											id='Aol'
											width='35px'
											height='30px'
											className='float-left'
											src='/assets/img/aol.svg'
										></img>
										<div
											id='Aol'
											className='float-left'
											style={{ margin: '5px 15px' }}
										>
											<b id='Aol'>Aol</b>
										</div>
									</div>
									<div
										id='Aol'
										className='col-md-2 col-sm-2 col-lg-2 col-xxl-2 text-center'
									>
										<div id='Aol' style={{ marginTop: '5px' }}>
											<b style={{ color: 'green' }} id='Aol'>
												{emailList.length !== 0
													? emailList['Aol'] === undefined
														? 0
														: emailList['Aol'].length
													: 0}
											</b>
										</div>
									</div>
								</div>
							</div>
							{/* <div
              className="col mr-3"
              id="iCloud"
              onClick={selectAll ? undefined : handleSelect}
            >
              <div
                className={
                  formData.inbox_providers ===
                  [
                    "Gmail - Gsuit",
                    "Yahoo!",
                    "Aol",
                    "Outlook",
                    "iCloud",
                    "Mail.com",
                  ]
                    ? "spamchecker-modal-card-selected row p-2"
                    : formData.inbox_providers.includes("iCloud")
                    ? " spamchecker-modal-card-selected row  p-2"
                    : "spamchecker-modal-card row  p-2"
                }
                id="iCloud"
              >
                <div className="col-10" id="iCloud">
                  <img
                    className="float-left"
                    src="/assets/img/ftr-icloud.png"
                    alt=""
                    width="55px"
                    height="30px"
                    id="iCloud"
                    style={{ marginLeft: "-13px" }}
                  ></img>
                  <div
                    className="float-left"
                    style={{ margin: "5px 15px" }}
                    id="iCloud"
                  >
                    <b id="iCloud">ICloud</b>
                  </div>
                </div>
                <div
                  className="col-md-2 col-sm-2 col-lg-2 col-xxl-2 text-center"
                  id="iCloud"
                >
                  <div id="iCloud" style={{ marginTop: "5px" }}>
                    <b style={{ color: "green" }} id="iCloud">
                      {emailList.length !== 0
                        ? emailList["iCloud"] === undefined
                          ? 0
                          : emailList["iCloud"].length
                        : 0}
                    </b>
                  </div>
                </div>
              </div>
            </div> */}
							<div id='Mail.com' className='col mr-3' onClick={handleSelect}>
								<div
									className={
										formData.inbox_providers ===
										[
											'Gmail - Gsuit',
											'Yahoo!',
											'Aol',
											// "Outlook",
											// "iCloud",
											'Mail.com',
										]
											? 'spamchecker-modal-card-selected row  p-2'
											: formData.inbox_providers.includes('Mail.com')
											? ' spamchecker-modal-card-selected row  p-2'
											: 'spamchecker-modal-card row  p-2'
									}
									id='Mail.com'
								>
									<div className='col-10' id='Mail.com'>
										<img
											alt=''
											width='35px'
											height='30px'
											id='Mail.com'
											className='float-left'
											src='/assets/img/mail.com-icon.png'
										></img>
										<div
											id='Mail.com'
											className='float-left'
											style={{ margin: '5px 15px' }}
										>
											<b id='Mail.com'>Mail.com</b>
										</div>
									</div>
									<div
										id='Mail.com'
										className='col-md-2 col-sm-2 col-lg-2 col-xxl-2 text-center'
									>
										<div id='Mail.com' style={{ marginTop: '5px' }}>
											<b style={{ color: 'green' }} id='Mail.com'>
												{emailList.length !== 0
													? emailList['Mail.com'] === undefined
														? 0
														: emailList['Mail.com'].length
													: 0}
											</b>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* <div className='text-danger mt-2 ml-1'>
                            {fromEmailErr ? '' : 'Please Enter Valid Email'}
                        </div> */}
						{errors.hasOwnProperty('inbox_providers') ? (
							<span className='text-danger mt-2 ml-1'>
								{' '}
								{errors['inbox_providers']}{' '}
							</span>
						) : (
							''
						)}
					</div>
					<div className='modal-footer'>
						<button
							type='button'
							onClick={closeModal}
							className='btn btn-danger c-btn-normal mr-2'
						>
							Cancel
						</button>
						<button
							type='button'
							data-tour='step-10'
							onClick={handleAddEmail}
							className='btn btn-primary c-btn'
							disabled={
								loading ||
								formData.from_email === '' ||
								formData.inbox_providers.length === 0
							}
						>
							Add
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}
