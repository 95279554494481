import { TOGGLE_MENU, TOGGLE_OFFCANVAS_DATA, TOUR_COMPLETE } from '../types/index'

const initialState = {
  offCanvasDataFlag: 'help',
  tourCompletedFlag: localStorage.getItem('tourComplete') || false,
  isSideBarToggled: JSON.parse(localStorage.getItem('isSideBarToggled')) || false,
}

const layoutReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case TOGGLE_MENU:
      localStorage.setItem('isSideBarToggled', payload.isSideBarToggled)
      return {
        ...state,
        isSideBarToggled: payload.isSideBarToggled
      }

    case TOGGLE_OFFCANVAS_DATA:
      localStorage.setItem('offCanvasData', payload.offCanvasDataFlag)
      return {
        ...state,
        offCanvasDataFlag: payload.offCanvasDataFlag
      }

    case TOUR_COMPLETE:
      localStorage.setItem('tourComplete', payload.tourCompletedFlag)
      return {
        ...state,
        tourCompletedFlag: payload.tourCompletedFlag
      }

    default: {
      return state
    }
  }
}

export default layoutReducer