import React, { useState } from 'react'
import FadeLoader from 'react-spinners/FadeLoader'

const RefferalLink = ({ affiliateLink, loading, user }) => {
	const [copy, setCopy] = useState(false)

	function myFunction() {
		var copyText = document.getElementById('myInput')
		copyText.select()
		copyText.setSelectionRange(0, 99999)
		navigator.clipboard.writeText(copyText.value)
		setCopy(true)
	}

	return (
		<>
			{loading ? (
				''
			) : (
				<div className='d-flex align-items-center p-3'>
					<div className='flex-grow-1'>
						<div className='text-muted'>Your refferal link</div>
						<div className='input-group flex-nowrap'>
							<input
								readOnly
								type='text'
								// placeholder="    https://example.com/?addition=authorityboard=berryhttps://example.com/?addition=authorityboard=berry"
								value={
									Object.keys(affiliateLink).length == 0
										? user.affiliate_status == 1
											? 'Something went wrong'
											: 'You are not affiliate user.'
										: affiliateLink
								}
								className='modal-input border input-filed'
								id='myInput'
								style={{
									padding: '15px',
									fontSize: '14px',
									fontStyle: 'normal',
									textDecoration: 'none',
									fontFamily: 'Eudoxus Sans',
								}}
							/>
							<span
								id='addon-wrapping'
								onClick={myFunction}
								className='input-group-text'
								style={{
									height: '45px',
									cursor: 'pointer',
									pointerEvents:
										Object.keys(affiliateLink).length === 0
											? 'none'
											: 'initial',
								}}
							>
								{copy ? (
									<i className='fal fa-check'></i>
								) : (
									<i className='fal fa-clone'></i>
								)}
							</span>
						</div>
					</div>
					{/* <button
						onClick={myFunction}
						style={{ width: '110px' }}
						className='btn btn-primary submit-btn btn-sm align-self-end'
						disabled={Object.keys(affiliateLink).length == 0 ? true : false}
					>
						{copy ? 'Copied' : 'Copy'}
					</button> */}
				</div>
			)}
		</>
	)
}

export default RefferalLink
