import React from "react"
import "./potentialInboxing.scss"
import ProvidersCircularProgress from "./ProvidersCircularProgress"

const ProvidersResults = ({ potentialInboxingReport }) => {
  return (
    <>
      {/* <div className='d-flex p-2 my-3'>
				<div className='icon-bg d-flex'>
					<img src='/assets/img/analyzer-overall-result.png' alt='' />
				</div>
				<div className='ml-3 mt-3'>
					<b>Service Providers</b>
				</div>
			</div> */}
      <div className='row mb-4'>
        {potentialInboxingReport !== undefined ? (
          potentialInboxingReport.map((item, i) => (
            <ProvidersCircularProgress
              key={i}
              name={item.name}
              detail={item.info}
              percentage={item.percentage}
            />
          ))
        ) : (
          <></>
        )}
      </div>
      <div className='row mb-3'>
        <div className='col-12'>
          <div className='analyzer-content'>
            <p>
              <b> The Potenial Inboxing % is based on Numerous Factors. </b>
            </p>
            <ol type='1'>
              <li>
                The Amount of Spam/Trigger Words within the subject line & body
              </li>
              <li>Image To Text Ratio %</li>
              <li>
                Overall Email Body Content + Word Count & Paragraph Structure
              </li>
              <li>
                Other Proprietary Factors (Links / ISP Restriction / Domain & IP
                Reputation etc...)
              </li>
            </ol>
            <div className='text-center'>
              <b>
                These metrics are estimations and should ONLY be used as a
                guideline to help you better optimize your eCommerce emailing.
              </b>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProvidersResults
