import { Modal } from 'bootstrap'
import { toast } from 'react-toastify'
import React, { useEffect, useState } from 'react'
import PlanService from '../../../api-services/plan.service'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'

const CheckoutForm = ({
	priceId,
	packageId,
	fetchPlans,
	customerId,
	subscriptionId,
	setClientSecret,
	setloadingPaymentElements,
}) => {
	const stripe = useStripe()
	const elements = useElements()

	const [message, setMessage] = useState(null)
	const [isLoading, setIsLoading] = useState(false)

	const modalInstance = document.getElementById('exampleModal')

	useEffect(() => {
		if (!stripe) {
			return
		}

		const clientSecret = new URLSearchParams(window.location.search).get(
			'payment_intent_client_secret'
		)

		if (!clientSecret) {
			return
		}

		stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
			switch (paymentIntent.status) {
				case 'succeeded':
					setMessage('Payment succeeded!')
					break
				case 'processing':
					setMessage('Your payment is processing.')
					break
				case 'requires_payment_method':
					setMessage('Your payment was not successful, please try again.')
					break
				default:
					setMessage('Something went wrong.')
					break
			}
		})
	}, [stripe])

	const handleSubmit = async e => {
		e.preventDefault()

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return
		}

		setIsLoading(true)

		stripe
			.confirmPayment({
				elements,
				confirmParams: {
					// Make sure to change this to your payment completion page
				},
				redirect: 'if_required',
			})
			.then(async result => {
				const response = await PlanService.sendPaymentDetails({
					price_id: packageId === 2 ? '' : priceId,
					package_id: packageId,
					customer_id: customerId,
					subscription_id: packageId === 2 ? '' : subscriptionId,
					price: result.paymentIntent.amount,
					paymentIntent: result.paymentIntent.id,
					currency: result.paymentIntent.currency,
				})

				if (response.success) {
					var paymentElement = elements.getElement('payment')

					paymentElement.clear()
					setClientSecret('')
					localStorage.setItem('user', JSON.stringify(response.user))

					fetchPlans()

					var myModal = Modal.getInstance(modalInstance)

					myModal.hide()
				}

				setIsLoading(false)
			})
			.catch(err => {
				if (err.type === 'card_error' || err.type === 'validation_error') {
					setIsLoading(false)
					toast(err.message, {
						type: 'error',
						autoClose: 2500,
						closeOnClick: true,
						position: 'top-right',
						hideProgressBar: false,
					})
					setMessage(err.message)
				} else {
					setIsLoading(false)
					toast('An unexpected error occured.', {
						type: 'error',
						autoClose: 2500,
						closeOnClick: true,
						position: 'top-right',
						hideProgressBar: false,
					})
					setMessage('An unexpected error occured.')
				}
			})

		// This point will only be reached if there is an immediate error when
		// confirming the payment. Otherwise, your customer will be redirected to
		// your `return_url`. For some payment methods like iDEAL, your customer will
		// be redirected to an intermediate site first to authorize the payment, then
		// redirected to the `return_url`.
	}

	return (
		<form id='payment-form' onSubmit={handleSubmit}>
			<PaymentElement id='payment-element' />
			<div className='mt-3 text-center'>
				<button
					id='submit'
					type='submit'
					className='buy_button my-3 px-2'
					disabled={isLoading || !stripe || !elements}
				>
					{isLoading ? (
						<>
							<span
								className='spinner-border spinner-border-sm'
								role='status'
								aria-hidden='true'
							></span>
							<span className='sr-only'>Processing...</span>
						</>
					) : (
						<span>Pay Now</span>
					)}
				</button>
			</div>
		</form>
	)
}

export default CheckoutForm
