import './NewInboxModal.scss'

import React, { useEffect, useState, useRef } from 'react'
import ProviderContent from '../ProviderContent/ProviderContent'
import ConnectInboxContent from '../ConnectInboxContent/ConnectInboxContent'
import WarmupOptionsContent from '../WarmupOptionsContent/WarmupOptionsContent'

import { ReactComponent as EmailIcon } from '../../../components/Assets/icons/mail-icon.svg'
import { ReactComponent as PaperPlaneIcon } from '../../../components/Assets/icons/paperPlane-icon.svg'
import { ReactComponent as WarmupOptionsIcon } from '../../../components/Assets/icons/warmupOptions-icon.svg'

import moment from 'moment'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import LoadingBar from 'react-top-loading-bar'
import warmupService from '../../../api-services/warmupService'

const initialState = {
	inboxForm: {
		host: '',
		port: '',
		username: '',
		password: '',
		encryption: '',
		from_email: '',
		// warmup options
		code: '',
		domain: '',
		server: '',
		list_id: '',
		region: '',
		api_url: '',
		api_key: '',
		schedule: [],
		whois_id: '',
		client_id: '',
		sender_id: '',
		from_name: '',
		profile_id: '',
		secret_key: '',
		reply_email: '',
		campaign_name: '',
		api_host_name: '',
		client_secret: '',
		from_field_id: '',
		is_aweber_pro: false,
		reply_rate_per_day: 30,
		emails_sent_per_day: 40,
		amazon_connection: 'smtp',
		mailgun_connection: 'smtp',
		email_account_provider_id: 0,
		start_date: moment().format('YYYY-MM-DD'),
		end_date: moment().add(10, 'days').format('YYYY-MM-DD'),
	},
}

const NewInboxModal = props => {
	const {
		id,
		isEdit,
		fetchData,
		chartData,
		closeModal,
		isReschedule,
		campaignData,
		editInboxForm,
		getFeatureCount,
		modalInstanceAdd,
		getWarmupAccounts,
	} = props

	const ref = useRef(null)

	const [period, setperiod] = useState({
		startDate: moment().format('YYYY-MM-DD'),
		endDate: moment().add(10, 'days').format('YYYY-MM-DD'),
	})
	const [error, setError] = useState(null)
	const [errors, setErrors] = useState({})
	const [loading, setLoading] = useState(false)
	const [checkSmtp, setCheckSmtp] = useState('')
	const [apiActive, setApiActive] = useState(false)
	const [smtpActive, setSmtpActive] = useState(true)
	const [emailsToCopy, setEmailsToCopy] = useState([])
	const [aweberAuthUrl, setAweberAuthUrl] = useState('')
	const [tabName, setTabName] = useState('inboxProvider')
	const [aweberListIdArray, setAweberListIdArray] = useState([])
	const [hyperMailApiCheck, setHyperMailApiCheck] = useState(true)
	const [aweberDataReceived, setAweberDataReceived] = useState(false)
	const [mailchimpApiCheck, setMailchimpApiCheck] = useState(false)
	const [inboxForm, setInboxForm] = useState(initialState.inboxForm)
	const [checkingConnection, setCheckingConnection] = useState(false)
	const [sendGridFromFieldArray, setSendGridFromFieldArray] = useState([])
	const [sendInBlueFromFieldArray, setSendInBlueFromFieldArray] = useState([])
	const [getResponseFromFieldArray, setGetResponseFromFieldArray] = useState([])
	const [email_account_provider_id, setEmail_account_provider_id] = useState('')

	const notify = response => {
		// response === 422
		// 	? toast.error('Invalid or empty fields.')
		// 	:
		response.success === true ||
		response.message === 'HyperMail Warm-up Campaign Created.'
			? toast.success(response.message)
			: toast.error(response.message)
	}

	useEffect(() => {
		if (editInboxForm !== null && editInboxForm !== undefined) {
			if (Object.keys(editInboxForm).length === 0)
				setInboxForm(initialState.inboxForm)
			else setInboxForm(editInboxForm)
		}
	}, [email_account_provider_id])

	useEffect(() => {
		if (isReschedule) {
			setCheckSmtp('true')
			setTabName('warmupOptions')
		}
		if (isEdit) {
			setCheckSmtp('true')
			setTabName('connectInbox')
		}
		if ((isEdit || isReschedule) && Object.keys(campaignData).length !== 0) {
			setEmail_account_provider_id(editInboxForm.email_account_provider_id)
			setInboxForm(editInboxForm)
		}
	}, [isEdit, editInboxForm])

	const handleTabs = async (event, isOnDone, tab, checkSmtp) => {
		event.preventDefault()

		if (isOnDone && isReschedule) {
			if (
				inboxForm.end_date < moment(new Date()).format('YYYY-MM-DD') ||
				inboxForm.start_date < moment(new Date()).format('YYYY-MM-DD')
			) {
				toast.warning('Select Valid Dates from today onwards')
			} else {
				setLoading(true)
				ref.current.continuousStart()

				let tempInboxForm = {
					end_date: '',
					schedule: [],
					campaign_id: 0,
					start_date: '',
					reply_rate_per_day: 0,
					emails_sent_per_day: 0,
				}

				tempInboxForm.campaign_id = id
				tempInboxForm.schedule = chartData
				tempInboxForm.end_date = inboxForm.end_date
				tempInboxForm.start_date = inboxForm.start_date
				tempInboxForm.reply_rate_per_day = inboxForm.reply_rate_per_day
				tempInboxForm.emails_sent_per_day = inboxForm.emails_sent_per_day

				try {
					const response = await warmupService.rescheduleWarmup({
						...tempInboxForm,
					})
					notify(response)
					getWarmupAccounts()

					setInboxForm(initialState.inboxForm)

					closeModal(modalInstanceAdd)
				} catch (e) {
					if (e.response !== undefined && e.response.status === 422) {
						setErrors(e.response.data.errors)
						setTabName('connectInbox')
						notify(e.response.data)
					} else {
						setError(e.response.data.errors)
						notify(e.response.data)
					}
				} finally {
					setLoading(false)
					setInboxForm(initialState.inboxForm)

					if (ref.current) ref.current.complete()
				}
			}
		} else if (isOnDone && isEdit) {
			setLoading(true)
			ref.current.continuousStart()

			if (checkSmtp === 'true') {
				setCheckingConnection(true)
				const response = await handleConnectionCheck()
				if (response) {
					setCheckingConnection(false)

					try {
						const response = await warmupService.updateWarmupEmailCampaign(id, {
							...inboxForm,
							schedule: chartData,
							mailgun_connection:
								inboxForm.mailgun_connection === undefined
									? null
									: inboxForm.mailgun_connection,
						})
						notify(response)
						fetchData()
						setInboxForm(initialState.inboxForm)
						setErrors({})

						closeModal(modalInstanceAdd)
					} catch (e) {
						if (e.response.status === 422) {
							setErrors(e.response.data.errors)
							notify(e.response.data)
							setTabName('connectInbox')
						} else {
							setError(e.response.data.errors)
							notify(e.response.data)
						}
					} finally {
						setLoading(false)
						setInboxForm(initialState.inboxForm)

						if (ref.current) ref.current.complete()
					}
				}
			}
		} else if (isOnDone && isReschedule) {
			setLoading(true)
			ref.current.continuousStart()
			try {
				const response = await warmupService.updateWarmupEmailCampaign(id, {
					...inboxForm,
					schedule: props.chartData,
					mailgun_connection:
						inboxForm.mailgun_connection === undefined
							? null
							: inboxForm.mailgun_connection,
				})
				notify(response)
				fetchData()
				setInboxForm(initialState.inboxForm)

				closeModal(modalInstanceAdd)
			} catch (e) {
				if (e.response.status === 422) {
					setErrors(e.response.data.errors)
					notify(e.response.data)
					setTabName('connectInbox')
				} else {
					setError(e.response.data.errors)
					notify(e.response.data)
				}
			} finally {
				setLoading(false)
				setInboxForm(initialState.inboxForm)

				if (ref.current) ref.current.complete()
			}
		} else if (isOnDone && email_account_provider_id === 20) {
			setLoading(true)
			ref.current.continuousStart()

			let tempInboxForm = {
				api_key: '',
				end_date: '',
				start_date: '',
				from_email: '',
				reply_email: '',
				campaign_name: '',
				from_field_id: '',
				reply_rate_per_day: 0,
			}

			tempInboxForm.api_key = inboxForm.api_key
			tempInboxForm.start_date = inboxForm.start_date
			tempInboxForm.end_date = inboxForm.end_date
			tempInboxForm.from_email = inboxForm.from_email
			tempInboxForm.reply_email = inboxForm.reply_email
			tempInboxForm.campaign_name = inboxForm.campaign_name
			tempInboxForm.from_field_id = inboxForm.from_field_id
			tempInboxForm.reply_rate_per_day = inboxForm.reply_rate_per_day

			try {
				const response = await warmupService.addGetResponseCampaign({
					...tempInboxForm,
				})
				notify(response)
				getFeatureCount()
				getWarmupAccounts()

				setInboxForm(initialState.inboxForm)

				closeModal(modalInstanceAdd)
			} catch (e) {
				if (e.response.status === 422) {
					setErrors(e.response.data.errors)
					setTabName('connectInbox')
					// notify(e.response.data)
				} else {
					setError(e.response.data.errors)
					notify(e.response.data)
				}
			} finally {
				setLoading(false)
				// setInboxForm(initialState.inboxForm)

				if (ref.current) ref.current.complete()
			}
		} else if (isOnDone && email_account_provider_id === 19) {
			setLoading(true)
			ref.current.continuousStart()
			try {
				const response = await warmupService.addAWeberCampaignWarmup({
					...inboxForm,
				})
				notify(response)
				getFeatureCount()
				getWarmupAccounts()

				setInboxForm(initialState.inboxForm)

				closeModal(modalInstanceAdd)
			} catch (e) {
				if (e.response.status === 422) {
					setErrors(e.response.data.errors)
					setTabName('connectInbox')
					// notify(e.response.data)
				} else {
					setError(e.response.data.errors)
					notify(e.response.data)
				}
			} finally {
				setLoading(false)
				// setInboxForm(initialState.inboxForm)

				if (ref.current) ref.current.complete()
			}
		} else if (isOnDone && email_account_provider_id === 18) {
			setLoading(true)
			ref.current.continuousStart()
			try {
				const response = await warmupService.addActiveCampaignWarmup({
					...inboxForm,
				})
				notify(response)
				getFeatureCount()

				getWarmupAccounts()

				setInboxForm(initialState.inboxForm)

				closeModal(modalInstanceAdd)
			} catch (e) {
				if (e.response.status === 422) {
					setErrors(e.response.data.errors)
					setTabName('connectInbox')
					// notify(e.response.data)
				} else {
					setError(e.response.data.errors)
					notify(e.response.data)
				}
			} finally {
				setLoading(false)
				// setInboxForm(initialState.inboxForm)

				if (ref.current) ref.current.complete()
			}
		} else if (isOnDone && email_account_provider_id === 17) {
			setLoading(true)
			ref.current.continuousStart()
			try {
				const response = await warmupService.addHyperMailWarmup({
					...inboxForm,
				})
				notify(response)
				getFeatureCount()

				getWarmupAccounts()

				setInboxForm(initialState.inboxForm)

				closeModal(modalInstanceAdd)
			} catch (e) {
				if (e.response.status === 422) {
					setErrors(e.response.data.errors)
					setTabName('connectInbox')
					// notify(e.response.data)
				} else {
					setError(e.response.data.errors)
					notify(e.response.data)
				}
			} finally {
				setLoading(false)
				setInboxForm(initialState.inboxForm)

				if (ref.current) ref.current.complete()
			}
		} else if (isOnDone && email_account_provider_id === 9 && apiActive) {
			setLoading(true)
			ref.current.continuousStart()
			try {
				const response = await warmupService.addSendgridWarmup({
					...inboxForm,
				})
				notify(response)
				getFeatureCount()

				getWarmupAccounts()

				setInboxForm(initialState.inboxForm)

				closeModal(modalInstanceAdd)
			} catch (e) {
				if (e.response.status === 422) {
					setErrors(e.response.data.errors)
					setTabName('connectInbox')
					// notify(e.response.data)
				} else {
					setError(e.response.data.errors)
					notify(e.response.data)
				}
			} finally {
				setLoading(false)
				setInboxForm(initialState.inboxForm)

				if (ref.current) ref.current.complete()
			}
		} else if (isOnDone && email_account_provider_id === 10 && apiActive) {
			setLoading(true)
			ref.current.continuousStart()
			try {
				const response = await warmupService.addSendInBlueWarmup({
					...inboxForm,
				})
				notify(response)
				getFeatureCount()

				getWarmupAccounts()

				setInboxForm(initialState.inboxForm)

				closeModal(modalInstanceAdd)
			} catch (e) {
				if (e.response.status === 422) {
					setErrors(e.response.data.errors)
					setTabName('connectInbox')
					// notify(e.response.data)
				} else {
					setError(e.response.data.errors)
					notify(e.response.data)
				}
			} finally {
				setLoading(false)
				setInboxForm(initialState.inboxForm)

				if (ref.current) ref.current.complete()
			}
		} else if (isOnDone && email_account_provider_id === 16) {
			setLoading(true)
			ref.current.continuousStart()
			try {
				const response = await warmupService.addMailChimpWarmup({
					...inboxForm,
				})
				notify(response)
				getFeatureCount()

				getWarmupAccounts()

				setInboxForm(initialState.inboxForm)

				closeModal(modalInstanceAdd)
			} catch (e) {
				if (e.response.status === 422) {
					setErrors(e.response.data.errors)
					// notify(e.response.data)
					setTabName('connectInbox')
				} else {
					setError(e.response.data.errors)
					notify(e.response.data)
				}
			} finally {
				setLoading(false)
				setInboxForm(initialState.inboxForm)

				if (ref.current) ref.current.complete()
			}
		} else if (isOnDone && email_account_provider_id === 15) {
			setLoading(true)
			ref.current.continuousStart()
			try {
				const response = await warmupService.addKlaviyoWarmup({
					...inboxForm,
				})
				notify(response)
				getFeatureCount()

				getWarmupAccounts()

				setInboxForm(initialState.inboxForm)

				closeModal(modalInstanceAdd)
			} catch (e) {
				if (e.response.status === 422) {
					setErrors(e.response.data.errors)
					// notify(e.response.data)
					setTabName('connectInbox')
				} else {
					setError(e.response.data.errors)
					notify(e.response.data)
				}
			} finally {
				setLoading(false)
				setInboxForm(initialState.inboxForm)

				if (ref.current) ref.current.complete()
			}
		} else if (isOnDone && checkSmtp === 'true') {
			if (
				errors.reply_rate_per_day === undefined ||
				errors.emails_sent_per_day === undefined ||
				(errors.reply_rate_per_day.length === 0 &&
					errors.emails_sent_per_day.length === 0)
			) {
				setLoading(true)
				ref.current.continuousStart()
				try {
					const response = await warmupService.addWarmupEmailAccount({
						...inboxForm,
						schedule: props.chartData,
					})
					notify(response)
					getFeatureCount()

					getWarmupAccounts()

					setInboxForm(initialState.inboxForm)
					setTabName('inboxProvider')

					closeModal(modalInstanceAdd)
				} catch (e) {
					// console.log(e);

					if (e.response.status == 422) {
						setErrors(e.response.data.errors)
						// notify(e.response.data)
						setTabName('connectInbox')
					} else {
						setError(e.response.data.errors)
						notify(e.response.data)
					}
				} finally {
					setLoading(false)
					if (ref.current) ref.current.complete()
				}
			}
		} else {
			if (tab === 'connectInbox') {
				if (checkSmtp === 'false') {
					setCheckingConnection(true)
					const response = await handleConnectionCheck()
					if (response) {
						setCheckingConnection(false)
						setTabName('warmupOptions')
					}
				}
				if (checkSmtp === 'true') {
					setTabName('warmupOptions')
				}
			} else if (tab === 'inboxProvider') setTabName('connectInbox')
		}
	}

	const fetchAweberAccountData = async token => {
		try {
			const response = await warmupService.getAweberAccountData(token)

			if (response.success) {
				setAweberDataReceived(true)
				setAweberListIdArray(response.data.lists)
				setEmailsToCopy(response.data.warmupEmails)
			} else {
				setAweberDataReceived(false)
			}
		} catch (err) {
			if (err.response.status === 500) {
				setAweberDataReceived(false)
				setErrors({ ...errors, code: ['Invalid Token Detected'] })
			}
		}
	}

	const fetchMailchimpAccountData = async token => {
		const response = await warmupService.getMailchimpAccountData(token)

		if (response.success) {
			setMailchimpApiCheck(true)
			setErrors({
				...errors,
				api_key: [],
			})
		} else {
			setMailchimpApiCheck(false)
			setErrors({
				...errors,
				api_key: [response.message],
			})
		}
	}

	const fetchFromFields = async token => {
		try {
			const response = await warmupService.getFromFieldsGetResponse(token)

			if (response.success === false) {
				// toast.error(response.message)
				setGetResponseFromFieldArray([])
				setErrors({
					...errors,
					api_key: [response.message],
				})
			} else {
				setGetResponseFromFieldArray(response)
				setErrors({
					...errors,
					api_key: [],
				})
			}
		} catch {
			toast.error('Something went wrong')
		}
	}

	const fetchsendInBlueFromFields = async token => {
		try {
			const response = await warmupService.getFromFieldsSendInBlue(token)

			if (!response.success) {
				// toast.error(response.message)
				setSendInBlueFromFieldArray([])
				setErrors({
					...errors,
					api_key: [response.message],
				})
			} else if (response.success) {
				setSendInBlueFromFieldArray(response.data)
				setErrors({
					...errors,
					api_key: [],
				})
			} else if (response.errors.length !== 0) {
				setErrors({
					...errors,
					api_key: [response.errors['api_key']],
				})
			}
		} catch {
			toast.error('Something went wrong')
		}
	}

	const fetchSendGridFromFields = async token => {
		try {
			const response = await warmupService.getFromFieldsSendGrid(token)

			if (!response.success) {
				setSendGridFromFieldArray([])
				setErrors({
					...errors,
					api_key: [response.message],
				})
			} else if (response.success) {
				setSendGridFromFieldArray(response.data)
				setErrors({
					...errors,
					api_key: [],
				})
			} else if (response.errors.length !== 0) {
				setErrors({
					...errors,
					api_key: [response.errors['api_key']],
				})
			}
		} catch {
			toast.error('Something went wrong')
		}
	}

	const handleFormChange = event => {
		event.preventDefault()

		const { name, value } = event.target

		if (name === 'sender_id' && email_account_provider_id === 9) {
			let selectedFromField = sendGridFromFieldArray.find(
				item => item.sender_id === parseInt(value)
			)
			setInboxForm({
				...inboxForm,
				sender_id: value,
				from_email: selectedFromField.email,
			})
		} else if (name === 'api_key' && email_account_provider_id === 9) {
			fetchSendGridFromFields(value)
			setInboxForm({ ...inboxForm, [name]: value })
		} else if (name === 'api_key' && email_account_provider_id === 10) {
			fetchsendInBlueFromFields(value)
			setInboxForm({ ...inboxForm, [name]: value })
		} else if (name === 'api_key' && email_account_provider_id === 16) {
			if (value !== '') {
				fetchMailchimpAccountData(value)
			} else {
				setErrors({
					...errors,
					api_key: [],
				})
			}
			setInboxForm({ ...inboxForm, [name]: value })
		} else if (name === 'api_key' && email_account_provider_id === 20) {
			fetchFromFields('api-key ' + value)
			setInboxForm({ ...inboxForm, [name]: 'api-key ' + value })
		} else if (name === 'code') {
			fetchAweberAccountData(value)
		} else if (name === 'is_aweber_pro') {
			if (event.target.checked === true) {
				navigator.clipboard.writeText(emailsToCopy)
			}
			setInboxForm({ ...inboxForm, is_aweber_pro: !inboxForm.is_aweber_pro })
		} else if (name === 'emails_sent_per_day') {
			// let num = parseInt(value)
			if (value <= 60 && value >= 1) {
				setErrors({
					...errors,
					emails_sent_per_day: [],
				})
				setInboxForm({ ...inboxForm, [name]: value })
			} else {
				setErrors({
					...errors,
					emails_sent_per_day: ['Enter a value between 0 and 60'],
				})
			}
		} else if (name === 'reply_rate_per_day') {
			// let num1 = parseInt(value)
			if (value <= 50 && value >= 1) {
				setErrors({
					...errors,
					reply_rate_per_day: [],
				})
				setInboxForm({ ...inboxForm, [name]: value })
			} else {
				setErrors({
					...errors,
					reply_rate_per_day: ['Enter a value between 0 and 50'],
				})
			}
		} else if (name === 'start_date') {
			setperiod({ ...period, startDate: value })
			setInboxForm({ ...inboxForm, [name]: value })
		} else if (name === 'end_date') {
			setperiod({ ...period, endDate: value })
			setInboxForm({ ...inboxForm, [name]: value })
		} else {
			setInboxForm({ ...inboxForm, [name]: value })
		}
	}

	const handleConnectionCheck = async () => {
		setCheckingConnection(true)
		let tempObj = {
			host: inboxForm.host, //"s25.wpx.net",
			port: inboxForm.port, //"465",
			domain: inboxForm.domain,
			region: inboxForm.region,
			api_key: inboxForm.api_key,
			username: inboxForm.username, //"ib-domain-health@localsnip.com",
			password: inboxForm.password, //"sE%6sC/8gG:6",
			client_id: inboxForm.client_id,
			encryption: inboxForm.encryption, //"ssl"
			from_email: inboxForm.from_email,
			secret_key: inboxForm.secret_key,
			client_secret: inboxForm.client_secret,
			api_host_name: inboxForm.api_host_name,
			amazon_connection: inboxForm.amazon_connection,
			mailgun_connection: inboxForm.mailgun_connection,
			email_account_provider_id: inboxForm.email_account_provider_id,
		}
		if (inboxForm.email_account_provider_id === 19) {
			try {
				const response = await warmupService.authenticateWithAweber({
					client_secret: tempObj.client_secret,
					client_id: tempObj.client_id,
				})
				setCheckingConnection(false)
				if (response?.authorizationUrl) {
					setAweberAuthUrl(response.authorizationUrl)
				}
				// if (response.success) {
				// 	setCheckSmtp('true')
				// 	notify(response)
				// 	return true
				// } else {
				// 	setCheckSmtp('false')
				// 	notify(response)
				// }
			} catch (error) {
				if (error.response.status === 422) {
					setErrors(error.response.data.errors)
					setCheckingConnection(false)
					notify(error.response.status)
				}
			}
		} else {
			try {
				const response = await warmupService.checkSMTPConnection(tempObj)
				setCheckingConnection(false)
				if (response.success) {
					setCheckSmtp('true')
					notify(response)
					return true
				} else {
					setCheckSmtp('false')
					notify(response)
				}
			} catch (error) {
				if (error.response.status === 422) {
					setErrors(error.response.data.errors)
					setCheckingConnection(false)
					notify(error.response.status)
				}
			}
		}
	}

	const handleClick = () => {
		if (email_account_provider_id !== null && tabName === 'inboxProvider') {
			return false
		} else if (checkingConnection && tabName === 'connectInbox') {
			return false
		} else if (email_account_provider_id === 15 && tabName === 'connectInbox') {
			return false
		}
	}

	useEffect(() => {
		setInboxForm(prevInboxForm => ({
			...prevInboxForm,
			host: '',
			port: '',
			username: '',
			password: '',
			encryption: '',
			from_email: '',
			// warmup options
			code: '',
			domain: '',
			server: '',
			list_id: '',
			region: '',
			api_url: '',
			api_key: '',
			schedule: [],
			whois_id: '',
			client_id: '',
			sender_id: '',
			from_name: '',
			profile_id: '',
			secret_key: '',
			reply_email: '',
			campaign_name: '',
			api_host_name: '',
			client_secret: '',
			from_field_id: '',
			is_aweber_pro: false,
			reply_rate_per_day: 30,
			emails_sent_per_day: 40,
			amazon_connection: 'smtp',
			mailgun_connection: 'smtp',
			start_date: moment().format('YYYY-MM-DD'),
			email_account_provider_id: email_account_provider_id,
			end_date: moment().add(10, 'days').format('YYYY-MM-DD'),
		}))
	}, [email_account_provider_id])

	const [selectedProvider, setSelectedProvider] = useState('')

	useEffect(() => {
		if (selectedProvider !== '') {
			setTabName('connectInbox')
		}
	}, [selectedProvider])

	useEffect(() => {
		if (tabName === 'inboxProvider') {
			setInboxForm(initialState.inboxForm)
			setErrors({})
		}
	}, [tabName])

	return (
		<>
			<LoadingBar height={5} color='#5D95EA' ref={ref} />
			<div
				tabIndex='-1'
				id='exampleModal'
				style={{ overflowY: 'initial' }}
				aria-labelledby='exampleModalLabel'
				className='modal fade add-inbox-modal'
			>
				<div
					className='modal-dialog modal-dialog-centered'
					style={{ maxWidth: '1230px' }}
				>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title' id='exampleModalLabel'>
								{isEdit ? 'Edit Connection' : 'Add New Inbox'}
								<img
									width='30px'
									height='30px'
									className='float-right'
									style={{ cursor: 'pointer' }}
									src='/assets/img/close-icon.svg'
									onClick={() => closeModal(modalInstanceAdd)}
								/>
							</h5>
						</div>
						<div className='modal-body' style={{ height: '810px' }}>
							<div className='row text-center mt-4'>
								<div
									className='col'
									type='button'
									onClick={e => setTabName('inboxProvider')}
									style={{ pointerEvents: isReschedule ? 'none' : 'initial' }}
								>
									<EmailIcon
										style={{ marginRight: '11px' }}
										fill={
											tabName === 'inboxProvider' ||
											tabName === 'connectInbox' ||
											tabName === 'warmupOptions'
												? '#0994F8'
												: '#BDBDBD'
										}
									/>
									<b className='tabText'>Inbox Provider</b>
								</div>
								<hr
									className={
										tabName === 'connectInbox' || tabName === 'warmupOptions'
											? 'lineWithBorderColor'
											: 'line'
									}
								/>
								<div
									className='col'
									type='button'
									onClick={e => {
										if (selectedProvider === '') setTabName('inboxProvider')
										else setTabName('connectInbox')
									}}
									style={{ pointerEvents: isReschedule ? 'none' : 'initial' }}
								>
									<PaperPlaneIcon
										style={{ marginRight: '11px' }}
										fill={
											tabName === 'connectInbox' || tabName === 'warmupOptions'
												? '#0994F8'
												: '#BDBDBD'
										}
									/>
									<b className='tabText'>Connect your Inbox</b>
								</div>
								<hr
									className={
										tabName === 'warmupOptions' ? 'lineWithBorderColor' : 'line'
									}
								/>
								<div
									className='col'
									type='button'
									onClick={e => {
										if (selectedProvider === '') setTabName('inboxProvider')
										else setTabName('connectInbox')
										if (checkSmtp === 'true') {
											setTabName('warmupOptions')
										}
									}}
									style={{
										pointerEvents: isReschedule || isEdit ? 'none' : 'initial',
									}}
								>
									<WarmupOptionsIcon
										style={{ marginRight: '11px' }}
										fill={tabName === 'warmupOptions' ? '#0994F8' : '#BDBDBD'}
									/>
									<b className='tabText'>Warmup Options</b>
								</div>
							</div>

							<div className='row mt-4'>
								<div className='col'>
									<div className='row'>
										{(tabName === 'inboxProvider' && (
											<div className='col-sm-12'>
												<ProviderContent
													isEdit={isEdit}
													campaignData={campaignData}
													setEmail_account_provider_id={
														setEmail_account_provider_id
													}
													selectedProvider={selectedProvider}
													setSelectedProvider={setSelectedProvider}
													email_account_provider_id={email_account_provider_id}
												/>
											</div>
										)) ||
											(tabName === 'connectInbox' && (
												<ConnectInboxContent
													error={error}
													errors={errors}
													isEdit={isEdit}
													setError={setError}
													inboxForm={inboxForm}
													setErrors={setErrors}
													checkSmtp={checkSmtp}
													apiActive={apiActive}
													smtpActive={smtpActive}
													emailsToCopy={emailsToCopy}
													setInboxForm={setInboxForm}
													campaignData={campaignData}
													setApiActive={setApiActive}
													aweberAuthUrl={aweberAuthUrl}
													setSmtpActive={setSmtpActive}
													setEmail_account_provider_id={
														setEmail_account_provider_id
													}
													handleFormChange={handleFormChange}
													selectedProvider={selectedProvider}
													mailchimpApiCheck={mailchimpApiCheck}
													aweberListIdArray={aweberListIdArray}
													aweberDataReceived={aweberDataReceived}
													checkingConnection={checkingConnection}
													setHyperMailApiCheck={setHyperMailApiCheck}
													handleConnectionCheck={handleConnectionCheck}
													sendGridFromFieldArray={sendGridFromFieldArray}
													sendInBlueFromFieldArray={sendInBlueFromFieldArray}
													getResponseFromFieldArray={getResponseFromFieldArray}
													email_account_provider_id={email_account_provider_id}
												/>
											)) ||
											(tabName === 'warmupOptions' && checkSmtp === 'true' && (
												<WarmupOptionsContent
													period={period}
													errors={errors}
													isEdit={isEdit}
													inboxForm={inboxForm}
													checkSmtp={checkSmtp}
													isReschedule={isReschedule}
													setInboxForm={setInboxForm}
													campaignData={campaignData}
													setEmail_account_provider_id={
														setEmail_account_provider_id
													}
													handleFormChange={handleFormChange}
													selectedProvider={selectedProvider}
													email_account_provider_id={email_account_provider_id}
												/>
											))}
									</div>
								</div>
							</div>
							{/* </form> */}
						</div>
						<div className='modal-footer'>
							{tabName !== 'inboxProvider' ? (
								<button
									type='button'
									className='prev-btn'
									disabled={isReschedule}
									onClick={
										tabName === 'connectInbox'
											? () => setTabName('inboxProvider')
											: () => setTabName('connectInbox')
									}
								>
									<span style={{ color: '#CCCCCC' }}>Previous</span>
								</button>
							) : (
								''
							)}
							{isEdit ? (
								<button
									type='button'
									disabled={isEdit ? false : true}
									className='next-btn'
									onClick={e =>
										handleTabs(
											e,
											(email_account_provider_id === 1 ||
												email_account_provider_id === 2 ||
												email_account_provider_id === 3 ||
												email_account_provider_id === 4 ||
												email_account_provider_id === 5 ||
												email_account_provider_id === 6 ||
												email_account_provider_id === 7 ||
												email_account_provider_id === 8 ||
												email_account_provider_id === 9 ||
												email_account_provider_id === 10 ||
												email_account_provider_id === 11 ||
												email_account_provider_id === 12 ||
												email_account_provider_id === 13 ||
												email_account_provider_id === 14 ||
												email_account_provider_id === 15 ||
												email_account_provider_id === 16 ||
												email_account_provider_id === 17 ||
												email_account_provider_id === 18 ||
												email_account_provider_id === 19 ||
												email_account_provider_id === 20 ||
												(email_account_provider_id === 10 && apiActive) ||
												(email_account_provider_id === 9 && apiActive)) &&
												tabName === 'connectInbox'
												? true
												: tabName === 'warmupOptions'
												? true
												: false,
											tabName,
											checkSmtp === 'true' ? 'true' : 'false'
										)
									}
								>
									{email_account_provider_id === 15 ||
									email_account_provider_id === 16 ||
									email_account_provider_id === 17 ||
									email_account_provider_id === 18 ||
									email_account_provider_id === 19 ||
									email_account_provider_id === 20 ||
									(email_account_provider_id === 9 && apiActive) ||
									(email_account_provider_id === 10 && apiActive)
										? 'Done'
										: tabName === 'connectInbox'
										? isEdit
											? 'Update'
											: 'Done'
										: 'Next'}
								</button>
							) : email_account_provider_id === 15 ||
							  email_account_provider_id === 16 ||
							  email_account_provider_id === 17 ||
							  email_account_provider_id === 18 ||
							  email_account_provider_id === 19 ||
							  email_account_provider_id === 20 ||
							  (email_account_provider_id === 9 && apiActive) ||
							  (email_account_provider_id === 10 && apiActive) ? (
								<button
									type='button'
									disabled={
										email_account_provider_id === 17
											? hyperMailApiCheck
												? true
												: loading
											: loading
									}
									className='next-btn'
									onClick={e =>
										handleTabs(
											e,
											(email_account_provider_id === 15 ||
												email_account_provider_id === 16 ||
												email_account_provider_id === 17 ||
												email_account_provider_id === 18 ||
												email_account_provider_id === 19 ||
												email_account_provider_id === 20 ||
												(email_account_provider_id === 9 && apiActive) ||
												(email_account_provider_id === 10 && apiActive)) &&
												tabName === 'connectInbox'
												? true
												: tabName === 'warmupOptions'
												? true
												: false,
											tabName,
											checkSmtp === 'true' ? 'true' : 'false'
										)
									}
								>
									{email_account_provider_id === 15 ||
									email_account_provider_id === 16 ||
									email_account_provider_id === 17 ||
									email_account_provider_id === 18 ||
									email_account_provider_id === 19 ||
									email_account_provider_id === 20 ||
									(email_account_provider_id === 9 && apiActive) ||
									(email_account_provider_id === 10 && apiActive)
										? 'Done'
										: tabName === 'warmupOptions'
										? isEdit
											? 'Update'
											: 'Done'
										: 'Next'}
								</button>
							) : (
								<button
									type='button'
									disabled={
										email_account_provider_id === 0 ||
										checkingConnection ||
										loading
									}
									data-toggle={
										tabName === 'warmupOptions' &&
										localStorage.getItem('warmupSuggestion') === 'true'
											? 'modal'
											: ''
									}
									data-target={
										tabName === 'warmupOptions' &&
										localStorage.getItem('warmupSuggestion') === 'true'
											? '#warmupSuggestionModal'
											: ''
									}
									className='next-btn'
									onClick={e =>
										handleTabs(
											e,
											(email_account_provider_id === 15 ||
												email_account_provider_id === 16 ||
												email_account_provider_id === 17 ||
												email_account_provider_id === 18 ||
												email_account_provider_id === 20 ||
												(email_account_provider_id === 9 && apiActive) ||
												(email_account_provider_id === 10 && apiActive)) &&
												tabName === 'connectInbox'
												? true
												: tabName === 'warmupOptions'
												? true
												: false,
											tabName,
											checkSmtp === 'true' ? 'true' : 'false'
										)
									}
								>
									{email_account_provider_id === 15 ||
									email_account_provider_id === 16 ||
									email_account_provider_id === 17 ||
									email_account_provider_id === 18 ||
									email_account_provider_id === 20 ||
									(email_account_provider_id === 9 && apiActive) ||
									(email_account_provider_id === 10 && apiActive)
										? 'Done'
										: tabName === 'warmupOptions'
										? isEdit
											? 'Update'
											: 'Done'
										: 'Next'}
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

function mapStateToProps(state) {
	return {
		chartData: state.chartDataReducer.dragableChartData,
	}
}

export default connect(mapStateToProps, null)(NewInboxModal)
