import { CAMPAIGN_STATUS } from "../types";

const initialState = {
  isSwitchToggled:true
}

const campaignStatusReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
      case CAMPAIGN_STATUS:
          return {
              ...state,
              isSwitchToggled: payload
          }
      default: {
          return state
      }
  }

}
export default campaignStatusReducer
