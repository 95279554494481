import { Modal } from 'bootstrap'
import React, { useEffect, useState } from 'react'
import NotificationService from '../../api-services/notificationService'

const Notification = () => {
	const [message, setMessage] = useState('')
	const fetchNotificationCheck = async () => {
		const response = await NotificationService.checkSubscriptionStatus()

		if (response.success) {
			localStorage.setItem('notificationSent', true)
			setMessage(response.message)

			const notifModal = document.getElementById('gracePeriodNotifModal')
			var modalInstance = Modal.getOrCreateInstance(notifModal)
			modalInstance.show()
		} else {
			localStorage.setItem('notificationSent', true)
		}
	}

	useEffect(() => {
		if (
			parseInt(localStorage.getItem('gracePeriodCheck')) ===
				new Date().getHours() &&
			localStorage['notificationSent'] === 'false'
		)
			fetchNotificationCheck()
		else if (
			parseInt(localStorage.getItem('gracePeriodCheck')) !==
			new Date().getHours()
		) {
			localStorage.setItem('notificationSent', false)
		}
	}, [])

	return (
		<div
			tabIndex='-1'
			className='modal fade'
			aria-hidden='true'
			id='gracePeriodNotifModal'
			style={{ overflowY: 'initial' }}
			aria-labelledby='gracePeriodNotifModal'
		>
			<div className='modal-dialog modal-dialog-centered'>
				<div className='modal-content'>
					<div className='modal-header'>
						<h5 className='modal-title' id='gracePeriodNotifModal'>
							Important Notice
						</h5>
					</div>
					<div className='modal-body'>{message}</div>
				</div>
			</div>
		</div>
	)
}

export default Notification
