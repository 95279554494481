import React, { Children } from "react"
import { Link } from "react-router-dom"

export const DropDown = ({
  children,
  pathName,
  state,
  handleClick,
  tableDetail,
  className,
  deleteTest,
}) => {
  // console.log(deleteTest)
  return (
    <div className='dropdown'>
      <i
        aria-hidden='true'
        aria-haspopup='true'
        aria-expanded='false'
        data-toggle='dropdown'
        id='dropdownMenuButton'
        className='fa fa-ellipsis-v'
      />
      <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
        <div className={`${className} dropdown-item`}>
          <Link
            to={{
              pathname: pathName,
              state: state,
            }}
          >
            <span
              color='#0994F8'
              className='d-flex align-items-center justify-content-center'
            >
              <i className='fa fa-eye mx-3'></i>
              <span className='flex-fill text-center'>View</span>
            </span>
          </Link>
        </div>
        {children}
        <a
          className='dropdown-item'
          style={{
            cursor: "pointer",
          }}
        >
          <span
            data-id={tableDetail.id}
            style={{ color: "#ef233c" }}
            onClick={handleClick}
            id={deleteTest + tableDetail.id}
            className='d-flex align-items-center justify-content-center'
          >
            <i className='fas fa-trash mx-3'></i>
            <span
              style={{ color: "#ef233c" }}
              className='flex-fill text-center'
            >
              Delete
            </span>
          </span>
        </a>
      </div>
    </div>
  )
}
