import { TOGGLE_MENU, TOGGLE_OFFCANVAS_DATA, TOUR_COMPLETE } from '../types/index';

export const toggleSidebar = (isToggle) => (dispatch) => {
  dispatch({ type: TOGGLE_MENU, payload: { isSideBarToggled: isToggle } })
}

export const toggleOffCanvasData = (offcanvasDataFlag) => (dispatch) => {
  dispatch({ type: TOGGLE_OFFCANVAS_DATA, payload: { offCanvasDataFlag: offcanvasDataFlag } })
}
export const tourCompleted = (tourCompletedFlag) => (dispatch) => {
  dispatch({ type: TOUR_COMPLETE, payload: { tourCompletedFlag: tourCompletedFlag } })
}