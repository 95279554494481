import { DRAGABLE_CHART_DATA } from '../types/index';

const initialState = {
  dragableChartData: []
}
export default function chartDataReducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case DRAGABLE_CHART_DATA:
      return {
        ...state,
        dragableChartData: payload
      }
    default:
      return state
  }


}
