import React, { useLayoutEffect, useRef, useState, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
am4core.addLicense("ch-custom-attribution");

function ComboChart(props) {
  const chart = useRef(null);
  const chartData = props.data;

  useEffect(() => {}, [chartData]);

  useLayoutEffect(() => {
    let x = am4core.create("barchartdiv", am4charts.XYChart);
    let num = 0;
    if (chartData.length == 0) {
      num = 1;
    } else {
      num = Math.ceil(chartData.length / 10);
    }

    x.paddingRight = 20;
    x.data = chartData;
    var dateAxis = x.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 10;
    dateAxis.maxZoomCount = 10;
    // dateAxis.start = 0;
    // dateAxis.end = 1 / num;
    // {chartData.length <= 10 ? dateAxis.end = 1 : chartData.length <= 20 ? dateAxis.end = 0.8 : chartData.length <= 30 ? dateAxis.end = 0.40 : chartData.length <= 40 ? dateAxis.end = 0.25 : dateAxis.end = 0.125} ;
    // dateAxis.renderer.cellStartLocation = 0;
    // dateAxis.renderer.cellEndLocation = 1;
    // dateAxis.keepSelection = true;

    var valueAxis1 = x.yAxes.push(new am4charts.ValueAxis());
    valueAxis1.renderer.grid.template.disabled = true;

    // Create series
    // series1 actual sales
    var series1 = x.series.push(new am4charts.ColumnSeries());
    series1.dataFields.valueY = "total_send";
    series1.dataFields.dateX = "date";
    series1.yAxis = valueAxis1;
    series1.name = "Scheduled";
    series1.tooltipText = "{name}\n[bold font-size: 20]{valueY}[/]";
    series1.fill = am4core.color("#EDEDED");
    series1.strokeWidth = 0;
    series1.clustered = false;
    series1.toBack();
    series1.columns.template.width = 60;
    var bullet1 = series1.bullets.push(new am4charts.LabelBullet());
    bullet1.label.text = "{valueY}";
    bullet1.label.fill = am4core.color("#000");
    bullet1.locationY = 0.12;

    // series2 target sales
    var series2 = x.series.push(new am4charts.ColumnSeries());
    series2.dataFields.valueY = "total_sent";
    series2.dataFields.dateX = "date";
    series2.yAxis = valueAxis1;
    series2.name = "Sent";
    series2.tooltipText = "{name}\n[bold font-size: 20]{valueY}[/]";
    series2.strokeWidth = 0;
    series2.clustered = false;
    series2.fill = am4core.color("#0994F8");
    series2.columns.template.width = 60;

    // series3 market days all
    var series3 = x.series.push(new am4charts.ColumnSeries());
    series3.dataFields.valueY = "total_spamed";
    series3.dataFields.dateX = "date";
    series3.name = "In Spam";
    series3.tooltipText = "{name}\n[bold font-size: 20]{valueY}[/]";
    series3.fill = am4core.color("#B81212");
    series3.columns.template.width = 60;
    series3.strokeWidth = 0;

    // series4 Market Days
    var series4 = x.series.push(new am4charts.LineSeries());
    series4.dataFields.valueY = "total_replies";
    series4.dataFields.dateX = "date";
    series4.name = "Replies";
    series4.strokeWidth = 3;
    series4.tensionX = 0.7;
    series4.toFront();
    series4.tooltipText = "{name}\n[bold font-size: 20]{valueY}[/]";
    series4.fill = am4core.color("red");
    series4.stroke = am4core.color("red");
    var bullet4 = series4.bullets.push(new am4charts.CircleBullet());
    bullet4.circle.radius = 4;
    bullet4.circle.strokeWidth = 2;
    bullet4.circle.fill = am4core.color("#fff");

    // Add cursor
    x.cursor = new am4charts.XYCursor();

    // Add legend
    x.legend = new am4charts.Legend();
    x.legend.position = "bottom";

    // Add scrollbar
    if (chartData.length > 10) {
      x.scrollbarX = new am4core.Scrollbar();
      x.scrollbarX.minWidth = 40;
      x.scrollbarX.minHeight = 15;
      x.scrollbarX.marginBottom = 50;
      x.scrollbarX.parent = x.topAxesContainer;
      x.scrollbarX.startGrip.disabled = true;
      x.scrollbarX.endGrip.disabled = true;
      // x.scrollbarX.start = 0;
      // x.scrollbarX.end = 1 / num;
    }
    x.updateOnReleaseOnly = true;
    x.cursor.behavior = "none";
    x.zoomOutButton.disabled = true;
    x.swipeable = true;

    chart.current = x;

    return () => {
      x.dispose();
    };
  }, [chartData]);

  return (
    <div id="barchartdiv" style={{ width: "100%", height: "400px" }}></div>
  );
}

export default ComboChart;
