import './App.scss'
import {
  Switch,
  useHistory,
  withRouter,
} from 'react-router-dom'
import { Modal } from 'bootstrap'
import React, { useState } from 'react'
import { TourProvider } from '@reactour/tour'
import { connect, useDispatch } from 'react-redux'
import TourService from './api-services/tour.service'
import { tourCompleted } from './store/actions/layout'

// layout
import NoAuth from './components/Layouts/NoAuth'
import CustomLayout from './components/Layouts/CustomLayout'
import VerticalLayout from './components/Layouts/VerticalLayout'

// Import Routes
import AppRoute from './routes/route'
import CustomAppRoute from './routes/customRoute'
import { authProtectedRoutes, customAuthRoutes, publicRoutes } from './routes'

const steps = [
  {
    selector: '[data-tour="step-1"]',
    content: 'First check for Email Blacklist and DNS Checker.',
  },
  {
    selector: '[data-tour="step-2"]',
    content: 'Click on the "run a new test."',
  },
  {
    selector: '[data-tour="step-3"]',
    highlightedSelectors: ['.modal-button'],
    mutationObservables: ['#exampleModalCenter'],
    content: 'Enter your SMTP Credentials and recipient email.',
  },
  {
    selector: '[data-tour="step-4"]',
    content: 'Click on the next and wait for the report. Note: This usualy takes upto 5 minutes.',
  },
  {
    selector: '[data-tour="step-5"]',
    content: 'After completing Email Blacklist & DNS Checker, check for your email landing in spam or inbox.',
  },
  {
    selector: '[data-tour="step-6"]',
    content: 'Click on "add email".',
  },
  {
    selector: '[data-tour="step-7"]',
    highlightedSelectors: ['.modal-input'],
    mutationObservables: ['#fromEmailModalCenter'],
    content: 'Enter your from email address that you are going use to send email on it.',
  },
  {
    highlightedSelectors: ['[data-tour="step-8"]', '[data-tour="step-9"]'],
    content: 'Select inbox providers that you want to check for your email landing, Once you click on any provider it will copy the lisit of emails that you use as recipients while sending email.',
  },
  {
    selector: '[data-tour="step-10"]',
    content: 'Click on "Add" and wait for the report. Note: The processing will be done in chunks meaning as soon as any inbox provider received emails, you will be able to see the landing by clicking on the email you just added under spam checker listing.',
  },
  {
    selector: '[data-tour="step-11"]',
    content: 'Check your email content is optimize or not.',
  },
  {
    content: 'Click on "add email"',
    selector: '[data-tour="step-12"]',
  },
  {
    selector: '[data-tour="step-13"]',
    highlightedSelectors: ['.analyzer-form'],
    mutationObservables: ['#analyzerFormModal'],
    content: 'Choose email type, category, enter your from email, subject line and email template content. Note: Enter HTML source of your email template by clicking on the text editor "Add Source" action.',
  },
  {
    selector: '[data-tour="step-14"]',
    content: 'Click on "optimize now" and wait for the report.',
  },
  {
    selector: '[data-tour="step-15"]',
    content: 'Research Competitors and their E-mail Sequences',
  },
  {
    selector: '[data-tour="step-16"]',
    content: 'Add Competitor by clicking the button.',
  },
  {
    selector: '[data-tour="step-17"]',
    content: 'Enter Competitor\'s Name',
  },
  {
    selector: '[data-tour="step-18"]',
    content: 'Enter Competitor\'s URL',
  },
  {
    selector: '[data-tour="step-19"]',
    content: 'Add Competitor by Clicking Next.',
  }
]

const App = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [step, setStep] = useState(0)
  const [showNav, setShowNav] = useState(true)

  const modalInstanceAddGrader = document.getElementById('analyzerFormModal')
  const modalInstanceAddDNSChecker = document.getElementById('exampleModalCenter')
  const modalInstanceAddSpamChecker = document.getElementById('fromEmailModalCenter')

  const setCurrentStep = step => {
    switch (step) {
      case 0:
        history.push('/dashboard')
        break
      case 1:
        history.push('/dns-checker')
        setShowNav(false)
        break
      // case 3:
      //   history.push('/dns-checker')
      //   break
      case 4:
        var myModal = Modal.getInstance(modalInstanceAddDNSChecker)
        myModal.hide()
        history.push('/inbox-spam-checker')
        break
      case 5:
        setShowNav(false)
        break
      case 9:
        var myModal = Modal.getInstance(modalInstanceAddSpamChecker)
        myModal.hide()
        history.push('/email-inboxing-grader')
        break
      case 10:
        setShowNav(false)
        break
      case 13:
        var myModal = Modal.getInstance(modalInstanceAddGrader)
        myModal.hide()
        history.push('/track-competitor-sequences')
        break
      case 14:
        setShowNav(false)
        break
      default:
        break
    }
    setStep(step)
  }

  const completeTour = async () => {
    const response = await TourService.completeTour()

    if (response.success) dispatch(tourCompleted(true))
  }

  return (
    <TourProvider
      steps={steps}
      currentStep={step}
      showNavigation={showNav}
      setCurrentStep={setCurrentStep}
      onClickClose={({ setCurrentStep, currentStep, setIsOpen }) => {
        if (currentStep === steps.length - 1) {
          setIsOpen(false)
          completeTour()

        }
        setCurrentStep(s => (s === steps.length - 1 ? 0 : s + 1))
      }}
      prevButton={({ currentStep, stepsLength, setIsOpen, setCurrentStep }) => {
        return (
          <></>
        )
      }}
    >
      <Switch>
        {publicRoutes.map((route, idx) => (
          <AppRoute
            key={idx}
            layout={NoAuth}
            path={route.path}
            isAuthProtected={false}
            component={route.component}
          />
        ))}

        {authProtectedRoutes.map((route, idx) => (
          <AppRoute
            exact
            key={idx}
            setStep={setStep}
            path={route.path}
            isAuthProtected={true}
            setShowNav={setShowNav}
            layout={VerticalLayout}
            component={route.component}
          />
        ))}

        {customAuthRoutes.map((route, idx) => (
          <CustomAppRoute
            exact
            key={idx}
            path={route.path}
            layout={CustomLayout}
            component={route.component}
          />
        ))}
      </Switch>
    </TourProvider>
  )
}

export default connect(null, null)(withRouter(App))
