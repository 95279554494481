import "./affiliate.scss"
import Payouts from "./Payouts"
import { connect } from "react-redux"
import PaymentsInfo from "./PaymentsInfo"
import RefferalLink from "./RefferalLink"
import { useDispatch } from "react-redux"
import { LOGIN } from "../../store/types"
import { withRouter } from "react-router-dom"
import PendingRequest from "./PendingRequest"
import FadeLoader from "react-spinners/FadeLoader"
import React, { useState, useEffect } from "react"
import AffiliateDashboard from "./AffiliateDashboard"
import PerformanceDetails from "./PerformanceDetails"
import RequestForAffiliate from "./RequestForAffiliate"
import affiliateService from "../../api-services/affiliateService"
import W9Form from "./W9Form"

const AffilatePrograme = ({ history, user }) => {
  const dispatch = useDispatch()

  const [error, setError] = useState(null)
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [tabName, setTabName] = useState("affDash")
  const [myAffiliates, setMyAffiliates] = useState([])
  const [affiliateLink, setAffiliateLink] = useState("")
  const [w9FormActive, setW9FormActive] = useState(false)
  const [affDashActive, setAffDashActive] = useState(true)
  const [payoutsActive, setPayoutsActive] = useState(false)
  const [affiliateStatus, setAffiliateStatus] = useState(null)
  const [perfromaceActive, setPerfromaceActive] = useState(false)
  const [paymentInfoActive, setPaymentInfoActive] = useState(false)

  const handleUserData = async () => {
    setLoading(true);
    try {
      const response = await affiliateService.getUserData(user.id);
      dispatch({
        type: LOGIN,
        payload: {
          user: response.user,
          token: localStorage.getItem("token") || "",
          expiresIn: localStorage.getItem("expiresIn") || "",
          isLoggedIn: !!localStorage.getItem("token"),
        },
      });
      localStorage.setItem("user", JSON.stringify(response.user));
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleTab = (e) => {
    if (e.target.id === "affDash") {
      setTabName("affDash");
      setAffDashActive(true);
      setPerfromaceActive(false);
      setPaymentInfoActive(false);
      setW9FormActive(false);

      setPayoutsActive(false);
    } else if (e.target.id === "performance") {
      setTabName("performance");
      setAffDashActive(false);
      setPerfromaceActive(true);
      setPaymentInfoActive(false);
      setW9FormActive(false);

      setPayoutsActive(false);
    } else if (e.target.id === "paymentInfo") {
      setTabName("paymentInfo");
      setAffDashActive(false);
      setPerfromaceActive(false);
      setPaymentInfoActive(true);
      setW9FormActive(false);

      setPayoutsActive(false);
    } else if (e.target.id === "payouts") {
      setTabName("payouts");
      setAffDashActive(false);
      setPerfromaceActive(false);
      setPaymentInfoActive(false);
      setW9FormActive(false);

      setW9FormActive(false);

      setPayoutsActive(true);
    } else if (e.target.id === "w9form") {
      setTabName("w9form");
      setAffDashActive(false);
      setPerfromaceActive(false);
      setPaymentInfoActive(false);
      setPayoutsActive(false);
      setW9FormActive(true);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await affiliateService.getAffiliateLink();
      setAffiliateLink(response);
    } catch (e) {
      setError(e.error);
      setErrors(e.errors);
    }
    try {
      const response = await affiliateService.getMyAffiliates(user.id);
      setMyAffiliates(response);
    } catch (e) {
      setError(e.error);
      setErrors(e.errors);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    handleUserData();
  }, []);

  return (
    <>
      {loading ? (
        <div className="text-center pb-5 my-5">
          <FadeLoader
            loading={loading}
            color={"#0092FB"}
            height={20}
            width={5}
          />
        </div>
      ) : (
        <>
          {user.affiliate_status == null ? (
            <RequestForAffiliate />
          ) : user.affiliate_status == 0 ? (
            <PendingRequest />
          ) : (
            <div className="col-lg-12 col-md-12 col-sm-12 mt-4 ml-2 title-col">
              <div className="card full-width" id='plan-card'>
                <div className="card-body p-0">
                  <div className="d-flex align-items-start">
                    <div id="left-div" className="flex-shrink-0 px-0" style={{ height: '80vh' }}>
                      <div className="d-flex flex-column">
                        <h4 className="text-muted s-label">
                          Affiliate Profile
                        </h4>
                        <div
                          className={
                            affDashActive ? "s-item-active" : "s-item"
                          }
                          onClick={handleTab}
                          id="affDash"
                        >
                          <img
                            id="affDash"
                            onClick={handleTab}
                            className="info-icon"
                            src={
                              affDashActive
                                ? "/assets/img/affDash-sm-blue.png"
                                : "/assets/img/affiliate_dashboard.png"
                            }
                          />
                          <div onClick={handleTab} id="affDash">
                            <b id="affDash" onClick={handleTab}>
                              Affiliate Dashboard
                            </b>
                          </div>
                        </div>
                        <div
                          className={
                            perfromaceActive
                              ? "s-item-active"
                              : "s-item"
                          }
                          id="performance"
                          onClick={handleTab}
                        >
                          <img
                            id="performance"
                            onClick={handleTab}
                            src={
                              perfromaceActive
                                ? "/assets/img/performance-sm-blue.png"
                                : "/assets/img/performance-detail.png"
                            }
                            className="pass-icon"
                          />
                          <div id="performance" onClick={handleTab}>
                            <b id="performance" onClick={handleTab}>
                              Performance Details
                            </b>
                          </div>
                        </div>
                        {/* <h4 className="text-muted s-label">PAYMENTS</h4> */}
                        <div
                          className={
                            paymentInfoActive
                              ? "s-item-active"
                              : "s-item"
                          }
                          id="paymentInfo"
                          onClick={handleTab}
                        >
                          <img
                            id="paymentInfo"
                            onClick={handleTab}
                            src={
                              paymentInfoActive
                                ? "/assets/img/payment-sm-blue.png"
                                : "/assets/img/payment-info.png"
                            }
                            className="pass-icon"
                          />
                          <div id="paymentInfo" onClick={handleTab}>
                            <b id="paymentInfo" onClick={handleTab}>
                              Payment Info
                            </b>
                          </div>
                        </div>
                        <div
                          className={
                            payoutsActive ? "s-item-active" : "s-item"
                          }
                          id="payouts"
                          onClick={handleTab}
                        >
                          <img
                            id="payouts"
                            onClick={handleTab}
                            src={
                              payoutsActive
                                ? "/assets/img/payout-sm-blue.png"
                                : "/assets/img/payouts.png"
                            }
                            className="pass-icon"
                          />
                          <div id="payouts" onClick={handleTab}>
                            <b id="payouts" onClick={handleTab}>
                              Payouts
                            </b>
                          </div>
                        </div>
                        <div
                          className={
                            w9FormActive ? "s-item-active" : "s-item"
                          }
                          id="w9form"
                          onClick={handleTab}
                        >
                          <img
                            id="w9form"
                            onClick={handleTab}
                            src={
                              w9FormActive
                                ? "/assets/img/payout-sm-blue.png"
                                : "/assets/img/payouts.png"
                            }
                            className="pass-icon"
                          />
                          <div id="w9form" onClick={handleTab}>
                            <b id="w9form" onClick={handleTab}>
                              W9 Form
                            </b>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      {(tabName === "affDash" && (
                        <>
                          <AffiliateDashboard
                            loading={loading}
                            myAffiliates={myAffiliates}
                          />
                          <RefferalLink
                            affiliateLink={affiliateLink}
                            loading={loading}
                            user={user}
                          />
                        </>
                      )) ||
                        (tabName === "performance" && (
                          <PerformanceDetails
                            myAffiliates={myAffiliates}
                            loading={loading}
                          />
                        )) ||
                        (tabName === "paymentInfo" && <PaymentsInfo />) ||
                        (tabName === "payouts" && <Payouts />) ||
                        (tabName === "w9form" && <W9Form />)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    user: state.authReducer.user,
  };
}

export default connect(mapStateToProps, null)(withRouter(AffilatePrograme));
