import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import HelpComponent from '../../components/Help/Help'
import AffiliateRequestModal from './AffiliateRequestModal'

const RequestForAffiliate = ({ user }) => {
	return (
		<>
			<div className='card aff-inner-card mt-3' id='plan-card'>
				<div className='card-body p-0'>
					<div className='d-flex flex-row align-items-center p-3'>
						<div className='shld col-sm-2 text-center p-1'>
							<img src='/assets/img/affiliate_icon_blue.png' alt='warm-up' />
						</div>
						<div className='col-sm-7'>
							<div className='text-bold heading'>
								Affiliate Profile &nbsp;
								<span>
									<HelpComponent
										filePath={'/help-fragments/3-warmupHelp.html'}
									/>
								</span>
							</div>
							<p className='sub-heading'>
								View your shareable affiliate joining link and corresponding
								insights
							</p>
						</div>
						<div className='col text-right'>
							<button
								data-toggle='modal'
								className='btn btn-sm c-btn'
								data-target='#affiliateRequestModal'
							>
								<span style={{ fontSize: '15px' }}>Request for Affiliate</span>
							</button>
						</div>
					</div>
				</div>
			</div>

			<AffiliateRequestModal user={user} />
		</>
	)
}

function mapStateToProps(state) {
	return {
		user: state.authReducer.user,
	}
}

export default connect(mapStateToProps, null)(withRouter(RequestForAffiliate))
