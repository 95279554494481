import React from 'react'
import HelpComponent from '../components/Help/Help'
export const Header = ({
	filter,
	iconPath,
	headline,
	helpPath,
	openModal,
	buttonName,
	dataTourId,
	iconAltText,
	headerContent,
	maxAllowCount,
	consumedCount,
}) => {
	return (
		<>
			<div className='d-flex align-items-md-center align-items-lg-center flex-sm-column flex-md-column flex-lg-column flex-column'>
				<div className='d-flex flex-row align-items-center justify-content-between w-100'>
					<div className='d-flex flex-fill flex-row flex-sm-row flex-md-row flex-sm-row'>
						<div className='shld flex-grow-1 flex-shrink-0 text-center pt-1 mr-2'>
							<img
								width='30px'
								height='30px'
								src={iconPath}
								alt={iconAltText}
							/>
						</div>
						<div className='flex-shrink-1'>
							<div className='text-bold heading'>
								{headline}&nbsp;
								<span>
									<HelpComponent filePath={`/help-fragments/${helpPath}`} />
								</span>
							</div>
							<p className='sub-heading'>
								{headerContent}
								{maxAllowCount === undefined || maxAllowCount === 0 ? (
									<></>
								) : (
									<span className='ml-1' style={{ fontWeight: 'bold' }}>
										({consumedCount} / {maxAllowCount} used)
									</span>
								)}
							</p>
						</div>
					</div>
					<div className='d-flex justify-content-end text-right'>
						<div className='d-flex flex-row text-right'>
							<div className='col justify-content-end px-0'>
								<button
									onClick={openModal}
									className='btn btn-sm c-btn'
									data-tour={`step-${dataTourId}`}
								>
									<img
										src='/assets/img/plus-icon.svg'
										style={{
											fontSize: '18px',
											marginRight: '5px',
											marginBottom: '2px',
										}}
									/>
									<span style={{ fontSize: '15px' }}>{buttonName}</span>
								</button>
							</div>
							<div
								className='col justify-content-start'
								style={{ minWidth: '130px', maxWidth: '130px' }}
							>
								<div className='d-flex justify-content-end'>
									<button
										role='button'
										aria-expanded='false'
										className='filter-btn'
										data-toggle='collapse'
										data-target='#multiCollapseExample1'
										aria-controls='multiCollapseExample1'
									>
										<i id='filter-icon' className='fas fa-filter mr-2'></i>
										Filter
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='w-100'>{filter}</div>
			</div>
		</>
	)
}
