import './App.scss'
import App from './App'
import React from 'react'
import store from "./store"
import ReactDOM from 'react-dom'
import { Provider } from "react-redux"
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { BrowserRouter } from "react-router-dom"

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
      <ToastContainer />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)
