import axios from 'axios'
import { toast } from 'react-toastify'
import { Editor } from 'react-draft-wysiwyg'
import LoadingBar from 'react-top-loading-bar'
import React, { useEffect, useState, useRef } from 'react'
import analyzerService from '../../api-services/analyzerService'
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'

const initialState = {
	analyzerForm: {
		html: '',
		fromEmail: '',
		subjectLine: '',
		emailSequence: '',
		ecommerceNiche: '',
	},
}

const AnalyzerFormModal = ({
	loading,
	setStep,
	setShowNav,
	closeModal,
	setLoading,
	getAnalyzers,
	getFeatureCount,
	modalInstanceAdd,
}) => {
	const ref = useRef(null)

	const [errors, setErrors] = useState([])
	const [categories, setCategories] = useState([])
	const [emailTypes, setEmailTypes] = useState([])
	const [editorStateExp, setEditorStateExp] = useState(
		EditorState.createEmpty()
	)
	const [addingGrader, setAddingGrader] = useState(false)
	const [analyzerForm, setAnalyzerForm] = useState(initialState.analyzerForm)

	const someHtml = analyzerForm.html

	const handleSelectCategory = e => {
		categories.map(i => {
			i.name === e.target.value &&
				setAnalyzerForm({ ...analyzerForm, ecommerceNiche: i.name })
		})
	}
	const handleSelectEamiltype = e => {
		emailTypes.map(i => {
			i.name === e.target.value &&
				setAnalyzerForm({ ...analyzerForm, emailSequence: i.name })
		})
	}

	const onEditorStateChangeExp = editorStateExp => {
		setEditorStateExp(editorStateExp)
	}

	const handleFormChange = e => {
		const { name, value } = e.target
		setAnalyzerForm({ ...analyzerForm, [name]: value })
	}

	async function fetchData() {
		try {
			const category = await axios.get(
				'https://app.sequences.ai/api/system/categories'
			)
			setCategories(category.data)
			const emailtype = await axios.get(
				'https://app.sequences.ai/api/system/email-types'
			)
			setEmailTypes(emailtype.data)

			setAnalyzerForm({
				...analyzerForm,
				ecommerceNiche: category.data[0].name,
				emailSequence: emailtype.data[0].name,
			})
		} catch (e) {
			// console.log(e);
		}
	}

	const notify = response => {
		response === 422
			? toast.error('Invalid or empty fields.')
			: response.message === 'Your Grader  Inbox  Request is added.'
			? toast.success(response.message)
			: toast.error(response.message)
	}

	const handleOptimize = async event => {
		event.preventDefault()

		setAddingGrader(true)
		ref.current.continuousStart()
		try {
			const response = await analyzerService.addAnalyzer({
				...analyzerForm,
			})
			setStep(13)
			setShowNav(true)
			notify(response)
			setAnalyzerForm({
				...analyzerForm,
				html: '',
				fromEmail: '',
				subjectLine: '',
				emailSequence: 'Welcome Email',
				ecommerceNiche: 'Fitness & Weight Loss',
			})
			getFeatureCount()
			getAnalyzers()
			closeModal(modalInstanceAdd)
		} catch (e) {
			if (e.response.status === 422) {
				setErrors(e.response.data.errors)
				notify(e.response.data)
			} else {
				setErrors(e.response.data.errors)
				notify(e.response.data)
			}
		} finally {
			setAddingGrader(false)
			if (ref.current) ref.current.complete()
		}
	}

	useEffect(() => {
		const block = convertToRaw(editorStateExp.getCurrentContent()).blocks
		setAnalyzerForm({
			...analyzerForm,
			html: block
				.map(block => (!block.text.trim() && '\n') || block.text)
				.join('\n'),
		})
	}, [editorStateExp])

	useEffect(() => {
		fetchData()
	}, [])

	return (
		<>
			<LoadingBar height={5} color='#5D95EA' ref={ref} />
			<div
				tabIndex='-1'
				role='dialog'
				aria-hidden='true'
				id='analyzerFormModal'
				aria-labelledby='exampleModalLabel'
				className='modal fade add-inbox-modal'
			>
				<div
					role='document'
					style={{ maxWidth: '1230px' }}
					className='modal-dialog modal-dialog-centered'
				>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title' id='exampleModalLongTitle'>
								<img
									alt='modal-icon'
									src='/assets/img/potential-inboxing-blue.png'
								/>
								&nbsp;&nbsp;&nbsp;&nbsp; Analyzer Form
								<img
									width='30px'
									height='30px'
									onClick={() => closeModal(modalInstanceAdd)}
									className='float-right'
									style={{ cursor: 'pointer' }}
									src='/assets/img/close-icon.svg'
								/>
							</h5>
						</div>
						<div className='modal-body p-0' style={{ height: 'auto' }}>
							<div className='container-fluid '>
								<div className='main-headings text-center mb-5'>
									<span style={{ color: '#0994F8' }}>Inbox Better</span>
									<p className='form-description'>
										Optimize Your Ecommerce Email Sequences With The Help Of AI{' '}
										<br />
										Analyze &#38; Grade Your Email Flows Against 1 Million +
										Transactional B2C Emails
									</p>
								</div>
								<form className='analyzer-form' data-tour='step-13'>
									<div className='row mt-4'>
										<div className='col mx-4'>
											<label htmlFor='emailType' className='form-label'>
												<span className='text-danger'>*</span> Choose Your Email
												Type{' '}
											</label>
											<select
												required
												name='encryption'
												className='form-control'
												id='inputWithoutBackground'
												onChange={handleSelectEamiltype}
												value={analyzerForm.emailSequence}
											>
												{emailTypes.length !== 0 ? (
													emailTypes.map(item => (
														<option key={item.id} value={item.name}>
															{item.name}
														</option>
													))
												) : (
													<option value='Categories'>Select Email Types</option>
												)}
											</select>
										</div>
										<div className='col mx-4'>
											<label htmlFor='emailType' className='form-label'>
												<span className='text-danger'>*</span> Choose Your Email
												Category
											</label>
											<select
												required
												name='encryption'
												className='form-control'
												id='inputWithoutBackground'
												onChange={handleSelectCategory}
												value={analyzerForm.ecommerceNiche}
											>
												{categories.length !== 0 ? (
													categories.map(item => (
														<option key={item.id} value={item.name}>
															{item.name}
														</option>
													))
												) : (
													<option value='Categories'>
														Select Email Categories
													</option>
												)}
											</select>
										</div>
									</div>
									<div className='row mt-4'>
										<div className='col mx-4'>
											{' '}
											<label htmlFor='emailType' className='form-label'>
												<span className='text-danger'>*</span> From Email
											</label>
											<input
												required
												type='text'
												name={'fromEmail'}
												placeholder='From Email'
												className='form-control'
												id='inputWithoutBackground'
												onChange={handleFormChange}
												value={analyzerForm.fromEmail}
											/>
											{errors.hasOwnProperty('fromEmail')
												? errors.fromEmail.map(e => (
														<div className='text-danger mt-2 ml-1'> {e} </div>
												  ))
												: ''}
										</div>
										<div className='col mx-4'>
											{' '}
											<label htmlFor='youremail' className='form-label'>
												<span className='text-danger'>*</span> Subject Headline
											</label>
											<input
												required
												type='text'
												name={'subjectLine'}
												className='form-control'
												id='inputWithoutBackground'
												onChange={handleFormChange}
												value={analyzerForm.subjectLine}
											/>
											{errors.hasOwnProperty('subjectLine')
												? errors.subjectLine.map(e => (
														<div className='text-danger mt-2 ml-1'> {e} </div>
												  ))
												: ''}
										</div>
									</div>
									<div className='row mt-4 mx-2'>
										<div className='col-12 '>
											<label htmlFor='youremail' className='form-label'>
												<span className='text-danger'>*</span> Email Body
											</label>
											{/* <Editor
												toolbarOnFocus
												toolbar={{
													options: [
														'inline',
														'blockType',
														'fontSize',
														'fontFamily',
														'list',
														'textAlign',
														'emoji',
														'image',
														'remove',
														'history',
													],
												}}
												name='html'
												spellCheck={true}
												editorState={editorStateExp}
												placeholder='Explanation...'
												wrapperClassName='wrapperStyle'
												editorClassName='editorStyleExp'
												onEditorStateChange={onEditorStateChangeExp}
											/> */}
											<textarea
												rows='7'
												name='html'
												className='form-control'
												value={analyzerForm.html}
												onChange={handleFormChange}
												id='exampleFormControlTextarea1'
											></textarea>
											{errors.hasOwnProperty('html')
												? errors.html.map(e => (
														<div className='text-danger mt-2 ml-1'> {e} </div>
												  ))
												: ''}
										</div>
									</div>
									<div className='row mt-4 mx-2'>
										<div className='col-12'>
											<label htmlFor='youremail' className='form-label'>
												Email Body Preview
											</label>
											<div
												className='mb-5'
												// style={{ border: '2px solid #E5E5E5' }}
												dangerouslySetInnerHTML={{ __html: analyzerForm.html }}
											>
												{/* <textarea
													className='col-12'
													disabled
													value={analyzerForm.html}
												/> */}
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
						<div className='modal-footer row d-flex justify-content-end mx-3'>
							<div>
								<button
									type='button'
									onClick={() => {
										setAnalyzerForm({
											...analyzerForm,
											html: '',
											fromEmail: '',
											subjectLine: '',
											emailSequence: 'Welcome Email',
											ecommerceNiche: 'Fitness & Weight Loss',
										})
										closeModal(modalInstanceAdd)
									}}
									className='btn btn-danger c-btn-normal'
								>
									Cancel
								</button>
								<button
									type='button'
									data-tour='step-14'
									disabled={addingGrader}
									onClick={handleOptimize}
									className='btn c-btn ml-2'
								>
									Optimize Now
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default AnalyzerFormModal
