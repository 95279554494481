import API from "./api";

const affiliateService = {
  getAffiliateLink: () => {
    return API.get("/user_affiliate_link")
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  getMyAffiliates: (id) => {
    return API.get(`/my_affiliates/${id}`)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  getUserData: (id) => {
    return API.get(`/getuser/${id}`)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  requestAffiliate: (id) => {
    return API.post(`/user_affiliate_request/${id}`)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  submitW9Form: (data) => {
    return API.post(`/w9form`, data)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
  getPayouts: () => {
    return API.get(`/payuouts`)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
};

export default affiliateService;
