import {
	API_CALL_IN_PROGRESS,
	API_CALL_NOT_IN_PROGRESS,
} from '../../store/types'
import moment from 'moment'
import { Modal } from 'bootstrap'
import Table from '../../atoms/Table'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import uniqueString from 'unique-string'
import '../WarmUpEmails/WampUpEmails.scss'
import { Layout } from '../../atoms/Layout'
import { Header } from '../../atoms/Header'
import '../../components/Filter/filter.scss'
import Pagination from 'react-js-pagination'
import { NotFound } from '../../atoms/NotFound'
import { DropDown } from '../../atoms/DropDown'
import { connect, useDispatch } from 'react-redux'
import FadeLoader from 'react-spinners/FadeLoader'
import { AddNewTestModal } from './AddNewTestModal'
import Filter from '../../components/Filter/Filter'
import { GetStarted } from '../../atoms/GetStarted'
import { convertToRaw, EditorState } from 'draft-js'
import { DeleteModal } from '../../atoms/DeleteModal'
import HelpComponent from '../../components/Help/Help'
import React, { useState, useRef, useEffect } from 'react'
import warmupService from '../../api-services/warmupService'
import FeatureService from '../../api-services/featuresService'
import { ReactComponent as PausedIcon } from '../../components/Assets/icons/paused-icon.svg'
import { ReactComponent as ActiveIcon } from '../../components/Assets/icons/active-icon.svg'

const tableHeader = [
	{
		th: 'Inbox',
		sort: true,
	},
	{
		th: 'Spam score',
		sort: true,
	},
	{
		th: 'Things To Fix',
		sort: true,
	},
	{
		th: 'Score',
		sort: true,
	},
	{
		th: 'Created',
		sort: true,
	},
	{
		th: 'Status',
		sort: true,
	},
	{
		th: 'Actions',
		sort: false,
	},
]
const DnsChecker = ({ apiCallInProgress, setStep, setShowNav }) => {
	const ref = useRef(null)
	const dispatch = useDispatch()

	const [id, setId] = useState('')
	const [copy, setCopy] = useState(false)
	const [testId, setTestId] = useState(0)
	const [addNewTestForm, setAddNewTestForm] = useState({
		password: '',
		to_email: '',
		from_email: '',
		email_body: '',
		subject_line: '',
	})
	const [noData, setNoData] = useState(false)
	const [loading, setLoading] = useState(false)
	const [activePage, setActivePage] = useState(1)
	const [domainData, setDomainData] = useState([])
	const [searchInput, setSearchInput] = useState('')
	const [domainHealth, setDomainHealth] = useState([])
	const [editorStateExp, setEditorStateExp] = useState(
		EditorState.createEmpty()
	)
	const [dnsCheckerCount, setDnsCheckerCount] = useState({
		consumed_count: 0,
		max_allowed_count: 0,
	})
	const [addingNewTest, setAddingNewTest] = useState(false)

	const modalInstanceDel = document.getElementById('DnsModalCenterDlt')
	const modalInstanceAdd = document.getElementById('exampleModalCenter')

	const extractedData = React.useMemo(() => {
		return domainData.map(item => {
			const tableCells = [
				{
					cellData: (
						<Link
							className={item.inbox === 'Awaiting...' ? 'disabled-link' : ''}
							to={{
								pathname: `/dns-checker/domain-health/${item.id}`,
								state: {
									tempObj: item,
								},
							}}
						>
							{item.inbox}
						</Link>
					),
				},
				{
					cellData: item.score === null ? '--' : item.score,
				},
				{
					cellData:
						item.report === null
							? '--'
							: JSON.parse(item.report).health_check.things_to_fix,
				},
				{
					cellData: (
						<>
							{item.report === null
								? '--'
								: JSON.parse(item.report).health_check.all_score}
							/ 100
						</>
					),
				},
				{
					cellData: moment(item.updated_at).format('MMM D,YYYY'),
				},
				{
					cellData: (
						<>
							{item.is_pending ? (
								<PausedIcon className='mr-2' />
							) : (
								<ActiveIcon className='mr-2' />
							)}
							{item.is_pending ? 'Processing...' : 'Completed'}
						</>
					),
				},
				{
					cellData: (
						<DropDown
							pathName={`/dns-checker/domain-health/${item.id}`}
							state={{
								tempObj: item,
							}}
							tableDetail={item}
							handleClick={() => {
								setTestId(item.id)
								openModal(modalInstanceDel)
							}}
							className={item.report === null ? 'd-none' : ''}
							deleteTest={'deleteDnsTest-'}
						/>
					),
				},
			]
			return tableCells
		})
	})

	const onEditorStateChangeExp = editorStateExp => {
		setEditorStateExp(editorStateExp)
	}

	const handleOnChange = e => {
		const { name, value } = e.target

		setAddNewTestForm({ ...addNewTestForm, [name]: value })
	}

	const closeModal = modalInstance => {
		var myModal = Modal.getInstance(modalInstance)
		myModal.hide()
	}

	const openModal = modalInstance => {
		setId(uniqueString())
		var myModal = Modal.getOrCreateInstance(modalInstance)
		myModal.show()

		setTimeout(() => {
			setShowNav(true)
			setStep(2)
		}, 400)
	}

	const notify = response => {
		response.message === 'DNS checker test created.'
			? toast.success(response.message)
			: toast.error(response.message)
	}
	const string = () => {
		openModal(modalInstanceAdd)
	}

	function myFunction() {
		var copyText = document.getElementById('myInput')
		copyText.select()
		copyText.setSelectionRange(0, 99999)
		navigator.clipboard.writeText(copyText.value)
		setCopy(true)

		setStep(3)
	}

	const handleDelete = async tempId => {
		ref.current.continuousStart()
		try {
			const response = await warmupService.deleteDnsTest(tempId)
			if (response) {
				closeModal(modalInstanceDel)
				toast.info(response.message)
			}
			ref.current.complete()
			fetchData()
		} catch (e) {
			if (ref.current) ref.current.complete()
		}
	}

	const addNewTest = async e => {
		e.preventDefault()

		setAddingNewTest(true)

		fetchData()

		const response = await warmupService.postRunNewTest(addNewTestForm)

		getFeatureCount()
		fetchData()
		setStep(4)
		setAddingNewTest(false)
		closeModal(modalInstanceAdd)
		notify(response)
	}
	async function fetchData(isLoader = true) {
		isLoader ? setLoading(true) : setLoading(false)
		dispatch({ type: API_CALL_IN_PROGRESS })
		try {
			const response = await warmupService.getDomainHealths(
				`/domain-healths?email=${searchInput}&page=${
					searchInput === '' ? activePage : 1
				}`
			)

			if (response.data.length !== 0) {
				setDomainHealth(response)
				setDomainData(response.data)
				setNoData(false)
			} else {
				setNoData(true)
			}
			setLoading(false)
			dispatch({ type: API_CALL_NOT_IN_PROGRESS })
		} catch (e) {
			dispatch({ type: API_CALL_NOT_IN_PROGRESS })
			setLoading(false)
		}
	}
	const handlePageChange = async pageNumber => {
		setLoading(true)
		dispatch({ type: API_CALL_IN_PROGRESS })

		try {
			const response = await warmupService.getDomainHealths(
				`/domain-healths?email=${searchInput}&page=${pageNumber}`
			)
			setDomainHealth(response)
			setDomainData(response.data)
			setActivePage(pageNumber)
			setLoading(false)
			dispatch({ type: API_CALL_NOT_IN_PROGRESS })
		} catch (e) {
			dispatch({ type: API_CALL_NOT_IN_PROGRESS })

			setLoading(false)
		}
	}

	const handleClose = () => {
		setCopy(false)

		closeModal(modalInstanceAdd)
	}

	const handleSearch = async () => {
		try {
			setLoading(true)
			const response = await warmupService.getDomainHealths(
				`/domain-healths?email=${searchInput}`
			)
			if (response.data.length !== 0) {
				setDomainHealth(response)
				setDomainData(response.data)
			} else {
				setNoData(true)
			}
			setLoading(false)
			let element = document.getElementById('multiCollapseExample1')
			element.classList.remove('show')
		} catch (e) {
			// Log & handle error
		}
	}
	const handleClearFilter = () => {
		setSearchInput('')
		setNoData(false)
		fetchData()
		// let element = document.getElementById('multiCollapseExample1')
		// element.classList.remove('show')
	}

	const getFeatureCount = async () => {
		try {
			let tempObj = {}
			const response = await FeatureService.getFeatureConsumptionCount({
				warmup: '',
				domainHealth: 1,
				spamchecker: '',
				emailgrader: '',
				sequences: '',
			})

			tempObj.max_allowed_count = response.max_allowed_count
			tempObj.consumed_count = response.consumed_count

			setDnsCheckerCount(tempObj)
		} catch (error) {
			toast.error('Something went wrong')
		}
	}

	useEffect(() => {
		const interval = setInterval(() => {
			if (!apiCallInProgress) fetchData()
		}, 5000)

		return () => clearInterval(interval)
	}, [apiCallInProgress])

	useEffect(() => {
		fetchData()
		getFeatureCount()
		// const timer = setInterval(() => fetchData(false), 5000)
		// return () => clearInterval(timer)
	}, [])

	useEffect(() => {
		const block = convertToRaw(editorStateExp.getCurrentContent()).blocks

		if (block[0].text !== '')
			setAddNewTestForm({
				...addNewTestForm,
				email_body: block
					.map(block => (!block.text.trim() && '\n') || block.text)
					.join('\n'),
			})
	}, [editorStateExp])

	useEffect(() => {
		if (id !== '')
			setAddNewTestForm({
				...addNewTestForm,
				to_email: id + '@inboxbetter.com',
			})
	}, [id])

	useEffect(() => {
		localStorage.setItem('domain_health', JSON.stringify(domainData))
	}, [domainData])

	return (
		<Layout innerRef={ref}>
			<Header
				iconPath={'/assets/img/shld.png'}
				iconAltText={'shld'}
				headline={'Email Blacklists & DNS Checker'}
				helpPath={'1-dns-checker.html'}
				headerContent={
					<>
						Check if your IPs &amp; domains are blacklisted. <br />
						Test your DNS settings to improve deliverability.
					</>
				}
				dataTourId={2}
				maxAllowCount={dnsCheckerCount.max_allowed_count}
				consumedCount={dnsCheckerCount.consumed_count}
				buttonName={'Run a New Test'}
				openModal={string}
				filter={
					<Filter
						handleSearch={fetchData}
						handleClearFilter={handleClearFilter}
						setSearchInput={setSearchInput}
						searchInput={searchInput}
						status={'dns'}
					/>
				}
			/>
			{/* <div className="row my-3">
                <div className="col-sm-3 form-group">
                  <label htmlFor="protocol" className="label-search">
                    Enter email address
                  </label>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Email address"
                      aria-label="Recipient's username"
                      aria-describedby="button-addon2"
                      onChange={searchEmailChange}
                    />
                  </div>
                </div>
                <div className="col search_btn">
                  <button
                    class="btn c-btn"
                    type="button"
                    id="button-addon2"
                    onClick={handleSearchEmail}
                  >
                    Search
                  </button>
                </div>
              </div> */}
			<div className='row mt-5'>
				<div className='col-sm-12'>
					{noData ? (
						<NotFound dataNotFound={'No Test Found'} />
					) : loading && domainData.length === 0 ? (
						<div style={{ marginLeft: '50%', height: '30px' }}>
							<FadeLoader
								width={5}
								height={20}
								loading={loading}
								color={'#0092FB'}
							/>
						</div>
					) : domainData.length === 0 ? (
						<GetStarted
							dataTarget={'#exampleModalCenter'}
							helpPath={'1-dns-checker.html'}
							title={<>Email Blacklists &amp; DNS Checker</>}
							addFirst={
								<>
									Run a new test to check if your domain &amp; sending IP are{' '}
									<br />
									blacklisted. Have a look at your DNS settings to fix all your
									<br /> deliverability issues.
								</>
							}
						/>
					) : (
						<>
							<Table
								extractedData={extractedData}
								tableHeader={tableHeader}
								className={'inbox-item-name'}
							/>
							<div className='d-flex justify-content-end mr-4'>
								<Pagination
									itemClass='page-item'
									linkClass='page-link'
									activePage={domainHealth.current_page}
									itemsCountPerPage={domainHealth.per_page}
									totalItemsCount={domainHealth.total}
									pageRangeDisplayed={4}
									onChange={pageNumber => handlePageChange(pageNumber)}
								/>
							</div>
						</>
					)}
				</div>
			</div>
			<AddNewTestModal
				id={id}
				copy={copy}
				nextClick={addNewTest}
				loading={addingNewTest}
				copiedClick={myFunction}
				closeClick={handleClose}
				editorStateExp={editorStateExp}
				handleOnChange={handleOnChange}
				addNewTestForm={addNewTestForm}
				headline={' Email Blacklists & DNS Checker'}
				onEditorStateChangeExp={onEditorStateChangeExp}
				sentInstruction={'Enter your Credentials to start the test'}
				copyInstruction={
					' First copy email below and must send email, then click on Next.'
				}
				help={<HelpComponent filePath={'/help-fragments/1-dns-checker.html'} />}
			/>
			<DeleteModal
				modalCenterDlt={'DnsModalCenterDlt'}
				modalCenterTitle={'exampleModalCenterTitle'}
				confirmation={
					' Do you really want to delete this test from your account?'
				}
				close={() => closeModal(modalInstanceDel)}
				DeleteItem={() =>
					handleDelete(
						parseInt(
							document
								.getElementById('deleteDnsTest-' + testId)
								.getAttribute('data-id')
						)
					)
				}
			/>
		</Layout>
	)
}

const mapStateToProps = state => {
	return {
		apiCallInProgress: state.apiCallsInProgress.apiCallInProgress,
	}
}

export default connect(mapStateToProps, null)(DnsChecker)
