import './StatsModal.scss'
import BarChart from './BarChart'
import PieChart from '../Stats/PieChart'
import React, { useState, useEffect } from 'react'
import { Modal } from 'bootstrap'

const StatsModal = ({ campaign, marketingCapmaignName }) => {
	const [selectedId, setSelectedId] = useState()
	const [spamAlert, setSpamAlert] = useState(true)
	const [bounceAlert, setBounceAlert] = useState(true)
	const [marketingCampaign, setMarketingCampaign] = useState([])

	const modalInstanceStats = document.getElementById('statsModal')

	const closeModal = modalInstance => {
		var myModal = Modal.getInstance(modalInstance)

		myModal.hide()
	}
	const openModal = modalInstance => {
		var myModal = Modal.getOrCreateInstance(modalInstance)

		myModal.show()
	}

	const handleSelect = e => {
		if (
			marketingCapmaignName === 'mailchimpWcCampagin' ||
			marketingCapmaignName === 'sendinBlueWcCampagin'
		) {
			marketingCampaign.map(i => {
				if (i.title === '') {
					i.subject === e.target.value && setSelectedId(i.id)
				} else {
					i.title === e.target.value && setSelectedId(i.id)
				}
			})
		} else {
			marketingCampaign.map(
				i => i.name === e.target.value && setSelectedId(i.id)
			)
		}
	}

	const handleBounceClose = () => {
		setBounceAlert(false)
	}

	const handleSpamClose = () => {
		setSpamAlert(false)
	}

	useEffect(() => {
		if (Object.keys(campaign).length !== 0) {
			setMarketingCampaign(campaign[marketingCapmaignName].marketing_campaigns)
			if (campaign[marketingCapmaignName].marketing_campaigns.length !== 0) {
				setSelectedId(campaign[marketingCapmaignName].marketing_campaigns[0].id)
			}
		}
	}, [campaign])

	useEffect(() => {
		if (Object.keys(campaign).length !== 0) {
			setMarketingCampaign(campaign[marketingCapmaignName].marketing_campaigns)
			if (campaign[marketingCapmaignName].marketing_campaigns.length !== 0)
				setSelectedId(campaign[marketingCapmaignName].marketing_campaigns[0].id)
		}
	}, [])

	useEffect(() => {
		if (bounceAlert === false) {
			setBounceAlert(true)
		}
		if (spamAlert === false) {
			setSpamAlert(true)
		}
	}, [selectedId])

	return (
		<>
			<div
				tabIndex='-1'
				id='statsModal'
				// aria-hidden="true"
				aria-labelledby='exampleModalLabel'
				className='modal fade add-inbox-modal'
			>
				<div
					className='modal-dialog modal-dialog-centered'
					style={{ maxWidth: '1230px' }}
				>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title' id='exampleModalLabel'>
								<img src='/assets/img/warmup-icon.png' alt='modal-icon' />
								&nbsp;&nbsp;&nbsp;&nbsp; Marketing Campaigns Statistic
								<img
									width='30px'
									height='30px'
									className='float-right'
									style={{ cursor: 'pointer' }}
									src='/assets/img/close-icon.svg'
									onClick={() => closeModal(modalInstanceStats)}
								/>
							</h5>
						</div>
						<div className='modal-body p-0' style={{ height: 'auto' }}>
							<div className='container-fluid '>
								{marketingCampaign.length === 0 ? (
									<>
										<div className='text-center'>
											<img src='/assets/img/marketing-campaign.gif' />
										</div>
										<div className='text-center text-muted mb-5'>
											<h5>
												Currently you don't have any marketing campaigns..!!
											</h5>
											<br />
											<h5>
												System will sync each campaign after 15 minutes once you
												add in to your account.
											</h5>
										</div>
									</>
								) : (
									<div className='section main'>
										<div className='row mx-1 px-3 py-3'>
											<div className='col bg-white main-card'>
												<div className='row p-2'>
													<div className='icon'>
														<img src='/assets/img/card1-icon.png' alt=''></img>
													</div>
													<div className='col-md-10 col-sm-10 col-lg-8 col-xxl-8'>
														<h5 className='m-1'>
															<b>{marketingCampaign.length}</b>
														</h5>
														<p className='m-0 p-0'>Number Of Campaigns</p>
													</div>
												</div>
											</div>
										</div>
										<div className='row mx-1 mb-3 px-3'>
											<div className=' bg-white d-flex stat-dropdown'>
												<div className='title-bar-icon'>
													<img src='/assets/img/statistical-icon.png' alt='' />
												</div>
												{marketingCapmaignName === 'mailchimpWcCampagin' ||
												marketingCapmaignName === 'sendinBlueWcCampagin' ? (
													<select
														onChange={handleSelect}
														aria-label='Default select example'
														class='form-select pt-1 pb-2 pl-3 pr-4 select'
													>
														{marketingCampaign.map(acc => (
															<option id={acc.id}>
																{acc.title === '' ? acc.subject : acc.title}
															</option>
														))}
													</select>
												) : (
													<select
														onChange={handleSelect}
														aria-label='Default select example'
														class='form-select pt-1 pb-2 pl-3 pr-4 select'
													>
														{marketingCampaign.map(acc => (
															<option id={acc.id}>{acc.name}</option>
														))}
													</select>
												)}
											</div>
										</div>

										{marketingCampaign.map(
											data =>
												data.id === selectedId && (
													<>
														{bounceAlert &&
														marketingCampaign.length !== 0 &&
														selectedId !== undefined ? (
															data.stats !== null &&
															data.stats.bounce_rate > 0.2 ? (
																<div className='row mx-1 px-3 mb-0'>
																	<div
																		className='alert alert-danger alert-dismissible fade show w-100 pb-3'
																		role='alert'
																	>
																		<span>
																			"IBN Your <strong>Bounce Rate</strong> is
																			get to critical level..!!"
																			<br />
																			&nbsp;Your Campaign{' '}
																			<strong>Bounce Rate</strong> is get to
																			critical level{' '}
																			<b>
																				{marketingCampaign.length === 0 &&
																				selectedId === undefined
																					? 0
																					: marketingCampaign[selectedId - 1]
																							.stats.bounce_rate}
																				%
																			</b>
																			. You must now stop your running marketing
																			campaign and improve your email list.
																		</span>
																		<button
																			type='button'
																			aria-label='Close'
																			data-dismiss='alert'
																			onClick={handleBounceClose}
																			className='close alert-close'
																		>
																			<span aria-hidden='true'>&times;</span>
																		</button>
																	</div>
																</div>
															) : (
																''
															)
														) : (
															''
														)}
														{spamAlert &&
														marketingCampaign.length !== 0 &&
														selectedId !== undefined ? (
															data.stats !== null &&
															data.stats.spam_rate >= 0.1 ? (
																<div className='row mx-1 px-3 mb-0'>
																	<div
																		className='alert alert-danger alert-dismissible fade show w-100 pb-3'
																		role='alert'
																	>
																		<span>
																			"IBN Your <strong>Spam Rate</strong> is
																			get to critical level..!!"
																			<br />
																			&nbsp;Your Campaign{' '}
																			<strong>Spam Rate</strong> is get to
																			critical level{' '}
																			<b>
																				{marketingCampaign.length === 0 &&
																				selectedId === undefined
																					? 0
																					: marketingCampaign[selectedId - 1]
																							.stats.spam_rate}
																				%
																			</b>
																			. You must now stop your running marketing
																			campaign and improve your email list.
																		</span>
																		<button
																			type='button'
																			aria-label='Close'
																			data-dismiss='alert'
																			onClick={handleSpamClose}
																			className='close alert-close'
																		>
																			<span aria-hidden='true'>&times;</span>
																		</button>
																	</div>
																</div>
															) : (
																''
															)
														) : (
															''
														)}
														<div className='row mx-1 px-3'>
															<div className='col bg-white main-card mr-2'>
																<div className='row p-3'>
																	<div
																		className='secondary-card-icon-rate d-flex align-items-center justify-content-center'
																		style={{ backgroundColor: '#E2F4E5' }}
																	>
																		<img
																			src='/assets/img/happiness.png'
																			alt=''
																		></img>
																	</div>
																	<div className='col-md-10 col-sm-10 col-lg-8 col-xxl-8'>
																		<h5 className='my-2'>
																			<b>
																				{data.stats === null
																					? 0
																					: data.stats.unique_click_rate}
																				%
																			</b>
																		</h5>
																		<p className='my-1 p-0 text-muted'>
																			Unique click rate
																		</p>
																	</div>
																</div>
															</div>
															<div className='col bg-white main-card ml-2 mr-2'>
																<div className='row p-3'>
																	<div
																		className='secondary-card-icon-rate d-flex align-items-center justify-content-center'
																		style={{ backgroundColor: '#F4F3E2' }}
																	>
																		<img
																			src='/assets/img/happiness.png'
																			alt=''
																		></img>
																	</div>
																	<div className='col-md-10 col-sm-10 col-lg-8 col-xxl-8'>
																		<h5 className='my-2'>
																			<b>
																				{data.stats === null
																					? 0
																					: data.stats.unique_open_rate}
																				%
																			</b>
																		</h5>
																		<p className='my-1 p-0 text-muted'>
																			Unique open rate
																		</p>
																	</div>
																</div>
															</div>
															<div className='col bg-white main-card mr-2 ml-2'>
																<div className='row p-3'>
																	<div
																		className='secondary-card-icon-rate d-flex align-items-center justify-content-center'
																		style={{ backgroundColor: '#EFE2F4' }}
																	>
																		<img src='/assets/img/sad.png' alt=''></img>
																	</div>
																	<div className='col-3-md-10 col-sm-10 col-lg-8 col-xxl-8'>
																		<h5 className='my-2'>
																			<b>
																				{data.stats === null
																					? 0
																					: data.stats.bounce_rate}
																				%
																			</b>
																		</h5>
																		<p className='my-1 p-0 text-muted'>
																			Bounce Rate
																		</p>
																	</div>
																</div>
															</div>
															<div className='col bg-white main-card ml-2'>
																<div className='row p-3'>
																	<div
																		className='secondary-card-icon-rate d-flex align-items-center justify-content-center'
																		style={{ backgroundColor: '#FFEBEB' }}
																	>
																		<img
																			src='/assets/img/crying.png'
																			alt=''
																		></img>
																	</div>
																	<div className='col-md-10 col-sm-10 col-lg-8 col-xxl-8'>
																		<h5 className='my-2'>
																			<b>
																				{data.stats === null
																					? 0
																					: data.stats.spam_rate}
																				%
																			</b>
																		</h5>
																		<p className='my-1 p-0 text-muted'>
																			Spam Rate
																		</p>
																	</div>
																</div>
															</div>
														</div>
														<div className='d-flex flex-row mt-3 mx-1 px-3'>
															<div className='d-flex flex-row flex-grow-1 align-items-center bg-white main-card mr-2 p-3'>
																<div
																	className='secondary-card-icon d-flex align-items-center justify-content-center'
																	style={{ backgroundColor: '#EFE2F4' }}
																>
																	<img src='/assets/img/sad.png' alt='' />
																</div>
																<div className='px-2'>
																	<h5 className='my-2'>
																		{data.stats === null
																			? 0
																			: data.stats.total_clicked}
																	</h5>
																	<p className='my-1 p-0 text-muted'>
																		Total Clicked
																	</p>
																</div>
															</div>
															<div className='d-flex flex-row flex-grow-1 align-items-center bg-white main-card mr-2 p-3'>
																<div
																	className='secondary-card-icon d-flex align-items-center justify-content-center'
																	style={{ backgroundColor: '#EFE2F4' }}
																>
																	<img src='/assets/img/sad.png' alt='' />
																</div>
																<div className='px-2'>
																	<h5 className='my-2'>
																		{data.stats === null
																			? 0
																			: data.stats.total_opened === undefined
																			? data.stats.unique_open
																			: data.stats.total_opened}
																	</h5>
																	<p className='my-1 p-0 text-muted'>
																		Total Opened
																	</p>
																</div>
															</div>
															<div className='d-flex flex-row flex-grow-1 align-items-center bg-white main-card mr-2 p-3'>
																<div
																	className='secondary-card-icon d-flex align-items-center justify-content-center'
																	style={{ backgroundColor: '#EFE2F4' }}
																>
																	<img src='/assets/img/sad.png' alt='' />
																</div>
																<div className='px-2'>
																	<h5 className='my-2'>
																		{data.stats === null
																			? 0
																			: data.stats.total_bounced}
																	</h5>
																	<p className='my-1 p-0 text-muted'>
																		Total Bounced
																	</p>
																</div>
															</div>
															{marketingCapmaignName === 'aweberWcCampaign' ? (
																<></>
															) : (
																<div className='d-flex flex-row flex-grow-1 align-items-center bg-white main-card mr-2 p-3'>
																	<div
																		className='secondary-card-icon d-flex align-items-center justify-content-center'
																		style={{ backgroundColor: '#EFE2F4' }}
																	>
																		<img src='/assets/img/sad.png' alt='' />
																	</div>
																	<div className='px-2'>
																		<h5 className='my-2'>
																			{marketingCapmaignName ===
																			'mailchimpWcCampagin'
																				? data.stats === null
																					? 0
																					: data.stats.total_forward
																				: marketingCapmaignName ===
																				  'KlaviyoWcCampagin'
																				? data.stats === null
																					? 0
																					: data.stats.total_dropped
																				: 0}
																		</h5>
																		{marketingCapmaignName ===
																		'mailchimpWcCampagin' ? (
																			<p className='my-1 p-0 text-muted'>
																				Total Forward
																			</p>
																		) : (
																			<p className='my-1 p-0 text-muted'>
																				Total Dropped
																			</p>
																		)}
																	</div>
																</div>
															)}
															<div className='d-flex flex-row flex-grow-1 align-items-center bg-white main-card mr-2 p-3'>
																<div
																	className='secondary-card-icon d-flex align-items-center justify-content-center'
																	style={{ backgroundColor: '#EFE2F4' }}
																>
																	<img src='/assets/img/sad.png' alt='' />
																</div>
																<div className='px-2'>
																	<h5 className='my-2'>
																		{marketingCapmaignName ===
																			'mailchimpWcCampagin' ||
																		marketingCapmaignName ===
																			'sendinBlueWcCampagin' ||
																		marketingCapmaignName ===
																			'sendGridWcCampagin' ||
																		marketingCapmaignName ===
																			'getResponseWcCampaign'
																			? data.stats === null
																				? 0
																				: data.stats.total_deliveries
																			: marketingCapmaignName ===
																			  'aweberWcCampaign'
																			? data.stats === null
																				? 0
																				: data.stats.total_sent
																			: data.stats === null
																			? 0
																			: data.stats.total_received}
																	</h5>
																	{marketingCapmaignName ===
																		'aweberWcCampaign' ||
																	marketingCapmaignName ===
																		'mailchimpWcCampagin' ||
																	marketingCapmaignName ===
																		'sendinBlueWcCampagin' ||
																	marketingCapmaignName ===
																		'sendGridWcCampagin' ||
																	marketingCapmaignName ===
																		'getResponseWcCampaign' ? (
																		<p className='my-1 p-0 text-muted'>
																			Total Deliveries
																		</p>
																	) : (
																		<p className='my-1 p-0 text-muted'>
																			Total Received
																		</p>
																	)}
																</div>
															</div>
															<div className='d-flex flex-row flex-grow-1 align-items-center bg-white main-card p-3'>
																<div
																	className='secondary-card-icon d-flex align-items-center justify-content-center'
																	style={{ backgroundColor: '#EFE2F4' }}
																>
																	<img alt='' src='/assets/img/sad.png' />
																</div>
																<div className='px-2'>
																	<h5 className='my-2'>
																		{data.stats === null
																			? 0
																			: data.stats.total_spamed}
																	</h5>
																	<p className='my-1 p-0 text-muted'>
																		Total Spamed
																	</p>
																</div>
															</div>
														</div>
														<div className='row mt-3 mx-1 px-3'>
															<div className='col-12 bg-white d-flex p-2 title'>
																<div className='title-bar-icon'>
																	<img
																		alt=''
																		src='/assets/img/graphical-icon.png'
																	/>
																</div>
																<div className='title-bar-content float-left'>
																	Graphical Overview
																</div>
															</div>
														</div>
														<div className='row mt-3 mx-1 px-3'>
															<div className='col-7 bg-white mr-1 bar-chart-div'>
																<BarChart
																	click_rate={
																		data.stats === null
																			? 0
																			: data.stats.unique_click_rate
																	}
																	open_rate={
																		data.stats === null
																			? 0
																			: data.stats.unique_open_rate
																	}
																	bounce_rate={
																		data.stats === null
																			? 0
																			: data.stats.bounce_rate
																	}
																	spam_rate={
																		data.stats === null
																			? 0
																			: data.stats.spam_rate
																	}
																	height={'280px'}
																/>
															</div>
															<div className='col bg-white pie-chart-div ml-2'>
																<PieChart
																	click_rate={
																		data.stats === null
																			? 0
																			: data.stats.unique_click_rate
																	}
																	open_rate={
																		data.stats === null
																			? 0
																			: data.stats.unique_open_rate
																	}
																	bounce_rate={
																		data.stats === null
																			? 0
																			: data.stats.bounce_rate
																	}
																	spam_rate={
																		data.stats === null
																			? 0
																			: data.stats.spam_rate
																	}
																	height={'280px'}
																	id={'modal'}
																/>
															</div>
														</div>
													</>
												)
										)}
									</div>
								)}
							</div>
						</div>
						<div className='modal-footer row d-flex justify-content-end mx-3'>
							{/* <div>
                <button type="button" className="btn-campaign">
                  Previous Campaign
                </button>
              </div> */}
							<div>
								<button
									type='button'
									className='btn-campaign'
									onClick={() => closeModal(modalInstanceStats)}
								>
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default StatsModal
