import * as React from "react"
import { Timeline, TimelineEvent } from "react-event-timeline"

const WarmupTimeline = ({ entries }) => {
  return (
    <Timeline
      lineStyle={{
        width: 2,
        left: "15px",
        background: "#999",
      }}
      className='mx-0 w-100'
    >
      {entries.map((entry, i) => (
        <TimelineEvent
          key={i}
          container='card'
          createdAt={entry.landing_at}
          style={{
            boxShadow: "none",
            borderRadius: "5px",
            border:
              entry.landing_in === "inbox"
                ? "2px solid #0092FB"
                : "2px solid #E89421",
          }}
          iconStyle={{ fontSize: "18px" }}
          title={entry.landing_in.toUpperCase()}
          icon={<i className='far fa-envelope'></i>}
          titleStyle={{ fontWeight: "bold", fontSize: "18px" }}
          bubbleStyle={{
            border: "none",
            backgroundColor:
              entry.landing_in === "inbox" ? "#E6F8FC" : "#F4F3E2",
          }}
          cardHeaderStyle={{
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            backgroundColor:
              entry.landing_in === "inbox" ? "#E6F8FC" : "#F4F3E2",
            color: entry.landing_in === "inbox" ? "#0092FB" : "#E89421",
          }}
          iconColor={entry.landing_in === "inbox" ? "#0092FB" : "#E89421"}
        >
          <div
            style={{ fontSize: "15px" }}
            className='d-flex align-items-start flex-column'
          >
            <div>
              <b className='text-dark'>Landed in: </b>
              <span>
                {" "}
                {entry.email_account !== undefined
                  ? entry.email_account.username
                  : ""}
              </span>
            </div>
            <div>
              <b className='text-dark'>Actions: </b>
              <span>
                {entry.landing_in === "inbox"
                  ? "Mark as Read"
                  : "Mark as Read & Move to Inbox"}
              </span>
            </div>
          </div>
        </TimelineEvent>
      ))}
    </Timeline>
  )
}

export default WarmupTimeline
