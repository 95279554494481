import React from "react"
import DetailTable from "./DetailTable"
import FadeLoader from "react-spinners/FadeLoader"

const EmailDetails = ({
  spamReport,
  color,
  path,
  email,
  style,
  width,
  height,
}) => {
  return (
    <>
      {spamReport.length !== 0 ? (
        spamReport.landed_in.length !== 0 ? (
          spamReport.landed_in.map((item, i) =>
            item !== null ? (
              <div className='row bg-white d-flex mt-3 signle-table' key={i}>
                <div
                  className='email-icon'
                  style={{
                    backgroundColor: color,
                  }}
                >
                  <img
                    src={path}
                    alt=''
                    style={{ margin: style }}
                    width={width}
                    height={height}
                  />
                </div>
                <div className='flex-grow-2 email-bar'>
                  <span className='email-address'>{item.email}</span>
                </div>
                <DetailTable item={item} email={email} />
              </div>
            ) : (
              <></>
            )
          )
        ) : (
          <div className='text-muted text-center mt-5'>
            <h5>You haven't sent email to this provider..!!</h5>
          </div>
        )
      ) : (
        <div className='text-muted text-center mt-5'>
          <h5>You haven't sent email to this provider..!!</h5>
        </div>
      )}
    </>
  )
}
export default EmailDetails
