import './layout.scss'
import '../../chat/chat.scss'

import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import React, { useState, useEffect, useRef } from 'react'

// Layout Related Components
import Header from './Header'
import Footer from './Footer'
import Sidebar from './Sidebar'

import Notification from '../../systemWide/Notification'
import { setIsToggle } from '../../../store/actions/help'
import MessageSection from '../../messages/MessageSection'
import { toggleSidebar } from '../../../store/actions/layout'

const Layout = ({
  children,
  location,
  isToggle,
  helpFragment,
  isSideBarToggled,
  offCanvasDataFlag,
  ...rest
}) => {
  const node = useRef()
  const dispatch = useDispatch()
  const [width] = useWindowSize()

  const [smile, setSmile] = useState(true)
  const [neutral, setNeutral] = useState(true)
  const [disappointed, setDisappointed] = useState(true)

  const handleClick = e => {
    if (node.current.contains(e.target)) return
    else dispatch(setIsToggle(false))
  }

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleClick)
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  useEffect(() => {
    if (isSideBarToggled) collapseSidebar()
    else dCollpaseSidebar()
  }, [])

  function useWindowSize() {
    const [size, setSize] = useState([window.innerWidth])
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerWidth])
      }
      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, [size])
    return size
  }

  const collapseSidebar = () => {
    let ftr = document.querySelector('.ftr')
    let logo = document.querySelector('.logo')
    let s_bar = document.querySelector('.sidebar')
    let s_menu = document.querySelector('.menuarrow')
    let s_barbg = document.querySelector('.sidebarbg')
    let m_content = document.querySelector('.right-content')

    s_menu.classList.add('actarrow')
    s_bar.classList.add('sidebarin')
    s_bar.style.width = '70px'
    s_barbg.style.width = '70px'
    logo.style.width = '70px'
    s_barbg.style.width = '70px'
    logo.style.width = '70px'
    logo.classList.add('logoin')
    m_content.classList.add('main-content-active')
    ftr.classList.add('ftr-active')
  }
  const dCollpaseSidebar = () => {
    let s_menu = document.querySelector('.menuarrow')
    let s_bar = document.querySelector('.sidebar')
    let s_barbg = document.querySelector('.sidebarbg')
    let logo = document.querySelector('.logo')
    let m_content = document.querySelector('.right-content')
    let ftr = document.querySelector('.ftr')

    s_menu.classList.remove('actarrow')
    s_bar.style.width = '300px'
    s_bar.classList.remove('sidebarin')
    s_bar.classList.remove('mobIn')
    s_bar.style.width = '300px'
    s_barbg.style.width = '300px'
    logo.style.width = '300px'
    logo.classList.remove('logoin')
    m_content.classList.remove('main-content-active')
    ftr.classList.remove('ftr-active')
  }

  const toggled = () => {
    if (!isSideBarToggled || width <= 991) {
      collapseSidebar()
      dispatch(toggleSidebar(true))
    } else {
      dCollpaseSidebar()
      dispatch(toggleSidebar(false))
    }
  }

  const handleSelect = e => {
    if (e.target.id === 'disappointed') {
      setDisappointed(true)
      setNeutral(false)
      setSmile(false)
    } else if (e.target.id === 'neutral') {
      setNeutral(true)
      setSmile(false)
      setDisappointed(false)
    } else if (e.target.id === 'smile') {
      setDisappointed(false)
      setNeutral(false)
      setSmile(true)
    }
  }

  return (
    <>
      <div
        id='layout-wrapper'
        className='wrapper d-flex flex-column'
        style={{ transition: 'all 0.2s linear', minHeight: '90vh' }}
      >
        {JSON.parse(localStorage.getItem('user')).is_secure === 1 &&
          localStorage.getItem('warningCount') < 1 ? (
          <div
            role='alert'
            style={{ zIndex: '999999' }}
            className='alert alert-danger alert-dismissible fade show w-100 my-0 position-fixed'
          >
            <div className='text-center'>
              Warning your account is being accessed by multiple ip addresses.
              Kindly secure your account if this continues your account will be
              temporarily blocked until this issue is resolved.
              <a
                href='/settings'
                style={{ fontWeight: 'bold' }}
                className='bg-transparent text-danger'
              >
                {' '}
                Please Update your Password
              </a>
            </div>
            <button
              type='button'
              aria-label='Close'
              data-dismiss='alert'
              onClick={() => {
                let warningCount = JSON.parse(
                  localStorage.getItem('user')
                ).warningCount
                warningCount = 1

                localStorage.setItem('warningCount', warningCount)
              }}
              className='close alert-close'
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
        ) : (
          ''
        )}
        <Header toggled={toggled} />

        <div className='main-content d-block'>
          <Sidebar />
          <div
            className='right-content'
            style={{
              background: '#F3FDFF',
              minHeight: '87vh',
              transition: 'all 0.2s linear',
            }}
          >
            <div className='pb-3'>{children}</div>
          </div>
          <div className='d-block'>
            <div
              className='ftr'
              style={{ background: '#F3FDFF', transition: 'all 0.2s linear' }}
            >
              <Footer />
            </div>
          </div>
        </div>

        <Notification />

        <div
          className='email-view d-flex flex-column'
          style={{ height: '99vh' }}
          ref={node}
        >
          <div style={{ height: '20%' }}>
            <div
              style={{
                // height: '50px',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#0994F8',
              }}
            >
              <span
                className='ml-4'
                style={{ color: 'white', fontSize: '20px' }}
              >
                <i
                  className='far fa-question-circle mr-3'
                  style={{
                    color: 'white',
                    fontSize: '25px',
                    fontWeight: 'lighter',
                  }}
                ></i>
                {offCanvasDataFlag === 'help' ? 'Help' : 'Support Chat'}
              </span>
              <span
                id='closeBtn'
                onClick={() => dispatch(setIsToggle(false))}
                className='float-right mr-4'
              >
                <i
                  className='fas fa-times'
                  style={{
                    color: 'white',
                    fontSize: '25px',
                    fontWeight: 'lighter',
                  }}
                ></i>
              </span>
            </div>
            <div
              className={`${offCanvasDataFlag === 'help' ? 'main-container' : ''
                }`}
            >
              {offCanvasDataFlag === 'chat' ? (
                <MessageSection />
              ) : (
                <>
                  <div dangerouslySetInnerHTML={{ __html: helpFragment }} />
                  <div className='help-footer text-center text-muted'>
                    <span> Did this answer your question?</span>
                    <div className='mt-2'>
                      <button
                        className={disappointed ? 'face-select' : 'face'}
                        onClick={handleSelect}
                        id='disappointed'
                        style={{
                          filter: disappointed
                            ? 'grayscale(0%)'
                            : 'grayscale(100%)',
                        }}
                        data-toggle='tooltip'
                        data-placement='bottom'
                        title='Disappointed'
                      >
                        😞
                      </button>
                      <button
                        className={neutral ? 'face-select' : 'face'}
                        onClick={handleSelect}
                        id='neutral'
                        style={{
                          filter: neutral ? 'grayscale(0%)' : 'grayscale(100%)',
                        }}
                        data-toggle='tooltip'
                        data-placement='bottom'
                        title='Neutral Face'
                      >
                        😐
                      </button>
                      <button
                        className={smile ? 'face-select' : 'face'}
                        onClick={handleSelect}
                        id='smile'
                        style={{
                          filter: smile ? 'grayscale(0%)' : 'grayscale(100%)',
                        }}
                        data-toggle='tooltip'
                        data-placement='bottom'
                        title='Happy'
                      >
                        😃
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    isToggle: state.helpReducer.isToggle,
    helpFragment: state.helpReducer.helpFragment,
    isSideBarToggled: state.layoutReducer.isSideBarToggled,
    offCanvasDataFlag: state.layoutReducer.offCanvasDataFlag,
  }
}

export default connect(mapStateToProps, null)(withRouter(Layout))
