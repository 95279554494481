import { useState } from "react"
import { Modal } from "bootstrap"
import { toast } from "react-toastify"
import { Link } from "react-router-dom"
import React, { useEffect } from "react"
import FadeLoader from "react-spinners/FadeLoader"
import AddCompetitorModal from "./AddCompetitorModal"
import Filter from "../../../components/Filter/Filter"
import SequencesService from "../../../api-services/sequencesService"
import Pagination from "react-js-pagination"
import Table from "../../../atoms/Table"
import { TrackCompetitorHeader } from "./trackCompetitorHeader/TrackCompetitorHeader"

const tableHeader = [
  {
    th: "Brand Name",
    sort: false,
  },
  {
    th: "Landing Page URL",
    sort: false,
  },
  {
    th: "Status",
    sort: false,
  },
]

const TrackCompetitor = ({ setStep, setShowNav }) => {
  const [loading, setLoading] = useState(false)
  const [activePage, setActivePage] = useState(1)
  const [competitors, setCompetitors] = useState([])
  const [metaData, setMetaData] = useState({ perPage: 0, totalPages: 0 })

  const extractedData = React.useMemo(() => {
    return competitors.map((competitor) => {
      const tableCells = [
        {
          cellData: competitor.competitor_name,
        },
        {
          cellData: competitor.competitor_url,
        },
        {
          cellData: (
            <span
              className='inbox-item'
              style={{
                fontWeight: "bold",
                paddingLeft: "20px",
                color: competitor.is_Approved ? "#1cc88a" : "#a6a6a6",
              }}
            >
              {competitor.is_Approved ? "Approved" : "Pending..."}
            </span>
          ),
        },
      ]
      return tableCells
    })
  })

  const fetchTrackedCompetitors = async () => {
    setLoading(true)

    const response = await SequencesService.listTrackedCompetitors()

    if (response.success) {
      setCompetitors(response.data.data)

      let tempObj = {
        perPage: response.data.per_page,
        totalPages: response.data.total,
      }

      setMetaData(tempObj)
    } else {
      toast.error(response.message, {
        autoClose: 2500,
        closeOnClick: true,
        position: "top-right",
        hideProgressBar: false,
      })
    }
    setLoading(false)
  }

  const handlePageChange = async (pageNumber) => {
    setLoading(true)

    setActivePage(pageNumber)

    const response = await SequencesService.listTrackedCompetitors(pageNumber)

    if (response.success) {
      setCompetitors(response.data.data)

      let tempObj = {
        perPage: response.data.per_page,
        totalPages: response.data.total,
      }

      setMetaData(tempObj)
    } else {
      toast.error(response.message, {
        autoClose: 2500,
        closeOnClick: true,
        position: "top-right",
        hideProgressBar: false,
      })
    }
    setLoading(false)
  }

  const closeModal = () => {
    let modalInstanceAdd = document.getElementById("addCompetitorModal")

    var myModal = Modal.getInstance(modalInstanceAdd)

    myModal.hide()
  }

  const openModal = () => {
    let modalInstanceAdd = document.getElementById("addCompetitorModal")

    var myModal = Modal.getOrCreateInstance(modalInstanceAdd)

    myModal.show()

    if (!localStorage.getItem("tourCompleted"))
      setTimeout(() => {
        setStep(15)
        setShowNav(true)
      }, 400)
  }

  useEffect(() => {
    fetchTrackedCompetitors()
  }, [])

  return (
    <div className='container-fluid'>
      <div className='col-lg-12 col-md-12 col-sm-12 mt-4 title-col px-0'>
        <div className='card full-width' id='plan-card'>
          <div className='card-body'>
            <TrackCompetitorHeader openModal={openModal} />
            {loading ? (
              <div className='text-center pb-5 mb-5'>
                <FadeLoader
                  width={5}
                  height={20}
                  loading={loading}
                  color={"#0092FB"}
                />
              </div>
            ) : (
              <>
                {competitors.length === 0 ? (
                  <div className='text-center starter-inboxes'>
                    <p className='mt-4 sp' style={{ fontSize: "18px" }}>
                      <b>No Competitors Found...</b>
                    </p>
                  </div>
                ) : (
                  <>
                    <Table
                      tableHeader={tableHeader}
                      extractedData={extractedData}
                    />
                    <div className='row float-right'>
                      <div>
                        <Pagination
                          itemClass='page-item'
                          linkClass='page-link'
                          pageRangeDisplayed={4}
                          activePage={activePage}
                          itemsCountPerPage={metaData.perPage}
                          totalItemsCount={metaData.totalPages}
                          onChange={(pageNumber) =>
                            handlePageChange(pageNumber)
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <AddCompetitorModal
        closeModal={closeModal}
        fetchTrackedCompetitors={fetchTrackedCompetitors}
      />
    </div>
  )
}

export default TrackCompetitor
