import LoadingBar from 'react-top-loading-bar'
import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { logout } from '../../../store/actions/auth'
import moment from 'moment'

import { ReactComponent as NotifIcon } from '../../Assets/icons/notif-icon.svg'
import { ReactComponent as LogoutIcon } from '../../Assets/icons/logout-dropdown-icon.svg'
import NotificationService from '../../../api-services/notificationService'

// import { NotificationService } from '../../../api-services/notificationService'

const Header = ({ history, user, toggled }) => {
	const ref = useRef(null)

	const dispatch = useDispatch()

	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(false)
	const [notifications, setNotifications] = useState([])

	const handleLogout = async event => {
		event.preventDefault()
		setLoading(true)
		ref.current.continuousStart()
		try {
			await dispatch(logout(history))
			history.push('/login')
		} catch (e) {
			setError(e)
		} finally {
			setLoading(false)
			if (ref.current) ref.current.complete()
		}
	}

	const fetchNotifications = async () => {
		let tempArr = []
		const response = await NotificationService.list()

		if (response.success) {
			response.messages.forEach(message => {
				tempArr.push(message.global_messages[0])
			})
			setNotifications(tempArr.reverse())
		} else {
			console.log(response.message)
		}
	}

	useEffect(() => {
		fetchNotifications()
	}, [])

	return (
		<>
			<LoadingBar height={5} color='#5D95EA' ref={ref} />
			<header className='header color-white fixed-top'>
				<div className='toprow'>
					<figure className='align-items-center d-flex logo'>
						<Link to='/'>
							<img
								alt='logo'
								className='img-fluid lg-logo'
								src='/assets/img/logo-dashboard.jpg'
							/>
							<img
								alt='logo'
								src='/assets/img/sm-logo.jpg'
								className='img-fluid sm-logo'
							/>
						</Link>
					</figure>
					<div className='top-bar align-items-center d-flex justify-content-between'>
						<div className='align-items-center col-3 col-lg-5 col-md-6 col-sm-2 d-flex pl-0 header-icons'>
							<div className='backlink' onClick={toggled}>
								<a className='menuarrow' style={{ cursor: 'pointer' }}>
									<img
										src='/assets/img/arrow.jpg'
										alt='logo'
										className='img-fluid lg-logom'
									/>
								</a>
							</div>
						</div>
						<div className='col-lg-4 col-sm-9 col-md-6 col-9 userlinks'>
							<ul className='align-items-center justify-content-end d-flex list-unstyled mb-0 userlist'>
								<li className='nav-item dropdown userdropdown ntifction-dropdown'>
									<a
										href='#'
										aria-expanded='true'
										id='usernotification'
										data-toggle='dropdown'
										className='align-items-center d-flex nav-link dropdown-toggle'
									>
										<NotifIcon />
									</a>
									<div className='dropdown-menu shadow px-2 py-2 rounded'>
										<b>Notifications</b>
										<div
											className='p-1'
											style={{ maxHeight: '300px', overflowY: 'auto' }}
										>
											{notifications.length === 0 ? (
												<p className='text-center'>No Notifications</p>
											) : (
												notifications.map((notification, i) => (
													<div key={i}>
														<div
															className='dropdown-item'
															style={{
																display: 'flex',
																cursor: 'default',
																alignItems: 'end',
																flexDirection: 'row',
																transition: 'all 0.2s linear',
																justifyContent: 'space-between',
															}}
														>
															<div>
																<div
																	style={{
																		fontWeight: 'bold',
																	}}
																>
																	{notification.subject}
																</div>
																<div className='notifi-desc'>
																	{notification.message}
																</div>
															</div>
															<span className='notifi-date fontmed'>
																{moment(
																	moment(notification.created_at).format(
																		'YYYYMMDD'
																	),
																	'YYYYMMDD'
																).fromNow()}
															</span>
														</div>
														<hr className='my-0' />
													</div>
												))
											)}
										</div>
									</div>
								</li>
								<li className='nav-item dropdown prof-dropdown'>
									<a
										href='#'
										id='profilelink'
										data-toggle='dropdown'
										className='align-items-center d-flex dropdown-toggle nav-link profilelink'
										style={{ transition: 'all 1s linear' }}
									>
										<span className='username text-center'> {user.name} </span>
										{user.avatar === 'default.png' ? (
											<div
												className='col-md-3 acc-icon'
												style={{ textAlign: 'center', paddingTop: '3px' }}
											>
												<span className='text-capitalize'>
													{user.name.slice(0, 1)}
												</span>
											</div>
										) : (
											<img
												src={user.avatar}
												alt='user image'
												className='img-fluid rounded-circle img-fluid profileimg'
											/>
										)}
									</a>
									<div className='dropdown-menu shadow rounded px-0 py-2 mt-0'>
										<div className='col px-2'>
											<div className='row px-1 mx-0 d-flex align-items-center justify-content-evenly'>
												<div
													className='flex-grow-1 acc-icon'
													style={{ textAlign: 'center', paddingTop: '3px' }}
												>
													<span className='text-capitalize fw-bold'>
														{user.name !== undefined
															? user.name.slice(0, 1)
															: ''}
													</span>
												</div>
												<div className='ml-2'>
													<div className='row'>
														<div className='col'>
															<p
																className='text-capitalize mb-0'
																style={{ fontSize: '15px', fontWeight: '700' }}
															>
																{user.name}
															</p>
														</div>
													</div>
													<div className='row'>
														<div className='col'>
															<p
																className='text-muted mb-0'
																style={{ fontSize: '14px' }}
															>
																{user.email}
															</p>
														</div>
													</div>
												</div>
											</div>
											<hr />
											<div
												onClick={handleLogout}
												className='dropdown-item pr-0'
												style={{
													cursor: 'pointer',
													transition: 'all 0.2s linear',
												}}
											>
												<LogoutIcon
													className='mr-3 pl-1'
													style={{ transform: 'scale(1.5)' }}
												/>
												<b>Logout</b>
											</div>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</header>
		</>
	)
}

function mapStateToProps(state) {
	return {
		user: state.authReducer.user,
	}
}

export default connect(mapStateToProps, null)(withRouter(Header))
