import { useState } from "react"
import { toast } from "react-toastify"
import { Link } from "react-router-dom"
import React, { useEffect } from "react"
import FadeLoader from "react-spinners/FadeLoader"
import Filter from "../../../components/Filter/Filter"
import SequencesService from "../../../api-services/sequencesService"
import Table from "../../../atoms/Table"
import { UnlockedHeader } from "./unlockedHeader/UnlockedHeader"
const tableHeader = [
  {
    th: "Sequence Name",
    sort: true,
  },
  {
    th: "Viewed",
    sort: true,
  },
  {
    th: "Brand",
    sort: true,
  },
  {
    th: "Actions",
    sort: false,
  },
]
const UnlockedSequences = () => {
  const [loading, setLoading] = useState(false)
  const [sequences, setSequences] = useState([])
  const [filterByViewed, setFilterByViewed] = useState("")

  const extractedData = React.useMemo(() => {
    return sequences.map((sequence) => {
      const tableCells = [
        {
          cellData: sequence.is_unlocked ? (
            <span className='inbox-item-name '>
              <Link
                to={{
                  pathname: `/email-sequences/${sequence.id}`,
                }}
              >
                {sequence.name}
              </Link>
            </span>
          ) : (
            <span className='inbox-item'>{sequence.name}</span>
          ),
        },
        {
          cellData: (
            <span
              className='text-muted'
              style={{
                color: sequence.is_viewed ? "#1cc88a" : "#000",
                fontWeight: "bold",
              }}
            >
              {sequence.is_viewed ? "Yes" : "No"}
            </span>
          ),
        },
        {
          cellData:
            sequence.brand !== undefined ? sequence.brand.name : "Loading",
        },
        {
          cellData: (
            <>
              <Link
                to={{
                  pathname: `/email-sequences/${sequence.id}`,
                }}
              >
                <i
                  className='fa fa-eye ml-3'
                  aria-hidden='true'
                  style={{
                    color: "#1cc88a",
                    cursor: "pointer",
                  }}
                ></i>
              </Link>
              <i class='fas fa-lock-open ml-2 text-muted'></i>
            </>
          ),
        },
      ]
      return tableCells
    })
  })

  const fetchUnlockedSequences = async (viewedFlag) => {
    setLoading(true)
    let perPage = 0
    let totalPages = 0

    const response = await SequencesService.recentlyUnlockedSequences(
      viewedFlag
    )

    if (response.data !== undefined) {
      if (response.data.success) {
        setSequences(response.data.sequence)
      }
    } else {
      toast.error(response.message, {
        autoClose: 2500,
        closeOnClick: true,
        position: "top-right",
        hideProgressBar: false,
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchUnlockedSequences()
  }, [])

  return (
    <div className='container-fluid'>
      <div className='col-lg-12 col-md-12 col-sm-12 mt-4 title-col px-0'>
        <div className='card full-width' id='plan-card'>
          <div className='card-body'>
            <UnlockedHeader />
            <Filter
              // activePage={activePage}
              // setMetaData={setMetaData}
              setLoading={setLoading}
              setSequences={setSequences}
              status={"unlocked-sequences"}
              filterByViewed={filterByViewed}
              setFilterByViewed={setFilterByViewed}
              fetchSequences={fetchUnlockedSequences}
            />
            {loading ? (
              <div className='text-center pb-5 mb-5'>
                <FadeLoader
                  loading={loading}
                  color={"#0092FB"}
                  height={20}
                  width={5}
                />
              </div>
            ) : (
              <Table tableHeader={tableHeader} extractedData={extractedData} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default UnlockedSequences
