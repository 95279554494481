import {
	API_CALL_IN_PROGRESS,
	API_CALL_NOT_IN_PROGRESS,
} from '../../store/types'
import moment from 'moment'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import '../WarmUpEmails/WampUpEmails.scss'
import Pagination from 'react-js-pagination'
import '../../components/Filter/filter.scss'
import { connect, useDispatch } from 'react-redux'
import FadeLoader from 'react-spinners/FadeLoader'
import Filter from '../../components/Filter/Filter'
import AnalyzerFormModal from './AnalyzerFormModal'
import React, { useRef, useState, useEffect } from 'react'
import FeatureService from '../../api-services/featuresService'
import analyzerService from '../../api-services/analyzerService'
import { ReactComponent as PausedIcon } from '../../components/Assets/icons/paused-icon.svg'
import { ReactComponent as ActiveIcon } from '../../components/Assets/icons/active-icon.svg'
import { Modal } from 'bootstrap'
import { Header } from '../../atoms/Header'
import { DropDown } from '../../atoms/DropDown'
import Table from '../../atoms/Table'
import { GetStarted } from '../../atoms/GetStarted'
import { DeleteModal } from '../../atoms/DeleteModal'
import { Layout } from '../../atoms/Layout'
import { NotFound } from '../../atoms/NotFound'

const tableHeader = [
	{
		th: 'Email From',
		sort: true,
	},
	{
		th: 'Created at',
		sort: true,
	},
	{
		th: 'Status',
		sort: true,
	},
	{
		th: 'Actions',
		sort: false,
	},
]

const PotentialInboxing = ({ apiCallInProgress, setStep, setShowNav }) => {
	const ref = useRef(null)
	const dispatch = useDispatch()

	const [testId, setTestId] = useState(0)
	const [noData, setNoData] = useState(false)
	const [loading, setLoading] = useState(false)
	const [activePage, setActivePage] = useState(1)
	const [searchInput, setSearchInput] = useState('')
	const [perPageData, setPerPageData] = useState([])
	const [analyzerData, setAnalyzerData] = useState([])

	const [graderCount, setGraderCount] = useState({
		consumed_count: 0,
		max_allowed_count: 0,
	})

	const modalInstanceAdd = document.getElementById('analyzerFormModal')
	const modalInstanceDel = document.getElementById('AnalyzerModalCenterDlt')
	const extractedData = React.useMemo(() => {
		return analyzerData.map(item => {
			const tableCells = [
				{
					cellData: (
						<Link
							className={item.report === null ? 'disabled-link' : ''}
							to={{
								pathname: `/email-inboxing-grader/analyzer/${item.id}`,
								state: {
									tempObj: item.report,
									email: item.from_email,
								},
							}}
						>
							{item.from_email}
						</Link>
					),
				},
				{
					cellData: moment(item.created_at).format('MMM D, YYYY'),
				},
				{
					cellData: (
						<>
							{item.is_pending ? (
								<PausedIcon className='mr-2' />
							) : (
								<ActiveIcon className='mr-2' />
							)}
							{item.is_pending ? 'Processing' : 'Completed'}
						</>
					),
				},
				{
					cellData: (
						<DropDown
							pathName={`/email-inboxing-grader/analyzer/${item.id}`}
							state={{
								tempObj: item.report,
								email: item.from_email,
							}}
							tableDetail={item}
							handleClick={() => {
								setTestId(item.id)
								openModal(modalInstanceDel)
							}}
							className={item.is_pending === 1 ? 'd-none' : ''}
							deleteTest={'deleteAnalyzerTest-'}
						/>
					),
				},
			]
			return tableCells
		})
	})
	const closeModal = modalType => {
		var myModal = Modal.getInstance(modalType)

		myModal.hide()
	}
	const openModal = modalType => {
		var myModal = Modal.getOrCreateInstance(modalType)

		myModal.show()

		setTimeout(() => {
			setStep(11)
			setShowNav(true)
		}, 400)
	}

	const notify = response => {
		response === 'Your Potential Inbox  Request is added.'
			? toast.success(response.message)
			: toast.error('Something went wrong..!!')
	}

	const handleDelete = async tempId => {
		ref.current.continuousStart()

		try {
			const response = await analyzerService.deleteAnalyzerTest(tempId)
			if (response.success) {
				toast.info(response.message)
				// notify(response.message)
				closeModal(modalInstanceDel)
			}
			ref.current.complete()
			getAnalyzers()
		} catch (e) {
			if (ref.current) ref.current.complete()
		}
	}

	const getAnalyzers = async () => {
		dispatch({ type: API_CALL_IN_PROGRESS })
		setLoading(true)
		try {
			const response = await analyzerService.getAnalyzerReports(
				`/requetsresponse?email=${searchInput}&page=${
					searchInput === '' ? activePage : 1
				}`
			)
			if (response.data.length !== 0) {
				setPerPageData(response)
				setAnalyzerData(response.data)
				localStorage.setItem('analyzer_report', JSON.stringify(response))
				setNoData(false)
			} else {
				setNoData(true)
			}
			setLoading(false)
			dispatch({ type: API_CALL_NOT_IN_PROGRESS })
		} catch (e) {
			dispatch({ type: API_CALL_NOT_IN_PROGRESS })
			setLoading(false)
		}
	}

	const handlePageChange = async pageNumber => {
		setLoading(true)
		dispatch({ type: API_CALL_IN_PROGRESS })

		const response = await analyzerService.getAnalyzerReports(
			`/requetsresponse?email=${searchInput}&page=${pageNumber}`
		)
		setAnalyzerData(response.data)
		setPerPageData(response)
		setActivePage(pageNumber)

		if (response.length !== 0) {
			setLoading(false)
		}
		dispatch({ type: API_CALL_NOT_IN_PROGRESS })
	}

	// const handleSearch = async () => {
	// 	try {
	// 		setLoading(true)
	// 		const response = await analyzerService.getAnalyzerReports(
	// 			`/requetsresponse?email=${searchInput}`
	// 		)
	// 		if (response.data.length !== 0) {
	// 			setPerPageData(response)
	// 			setAnalyzerData(response.data)
	// 		} else {
	// 			setNoData(true)
	// 		}
	// 		setLoading(false)
	// 		let element = document.getElementById('multiCollapseExample1')
	// 		element.classList.remove('show')
	// 	} catch (e) {
	// 		// Log & handle error
	// 	}
	// }

	const handleClearFilter = () => {
		setSearchInput('')
		setNoData(false)
		getAnalyzers()
		// let element = document.getElementById('multiCollapseExample1')
		// element.classList.remove('show')
	}

	const getFeatureCount = async () => {
		try {
			let tempObj = {}
			const response = await FeatureService.getFeatureConsumptionCount({
				warmup: '',
				sequences: '',
				emailgrader: 1,
				spamchecker: '',
				domainHealth: '',
			})

			tempObj.consumed_count = response.consumed_count
			tempObj.max_allowed_count = response.max_allowed_count

			setGraderCount(tempObj)
		} catch (error) {
			toast.error('Something went wrong')
		}
	}

	useEffect(() => {
		localStorage.setItem('analyzer_report', JSON.stringify(analyzerData))
	}, [analyzerData])

	useEffect(() => {
		const interval = setInterval(() => {
			if (!apiCallInProgress) getAnalyzers()
		}, 5000)

		return () => clearInterval(interval)
	}, [apiCallInProgress])

	useEffect(() => {
		getAnalyzers()
		getFeatureCount()
	}, [])

	return (
		<Layout innerRef={ref}>
			<Header
				iconPath={'/assets/img/potential-inboxing-blue.png'}
				iconAltText={'grader'}
				headline={'Email Inboxing Grader'}
				helpPath={'1-dns-checker.html'}
				headerContent={
					<span className=' text-bold heading'>Ecommerce Email Grader</span>
				}
				dataTourId={12}
				maxAllowCount={graderCount.max_allowed_count}
				consumedCount={graderCount.consumed_count}
				buttonName={'Add Email'}
				openModal={() => openModal(modalInstanceAdd)}
				filter={
					<Filter
						status={'dns'}
						searchInput={searchInput}
						handleSearch={getAnalyzers}
						setSearchInput={setSearchInput}
						handleClearFilter={handleClearFilter}
					/>
				}
			/>
			{/* <div className="row my-3">
              <div className="col-sm-3 form-group">
                <label htmlFor="protocol" className="label-search">
                  Enter email address
                </label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email address"
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                    onChange={searchEmailChange}
                  />
                </div>
              </div>
              <div className="col search_btn">
                <button
                  className="btn c-btn"
                  type="button"
                  id="button-addon2"
                  onClick={handleSearchEmail}
                >
                  Search
                </button>
              </div>
            </div> */}
			<div className='row mt-4'>
				<div className='col-sm-12'>
					{noData ? (
						<NotFound dataNotFound={'No Grader Found'} />
					) : loading && analyzerData.length === 0 ? (
						<div
							style={{
								height: '30px',
								marginLeft: '50%',
								marginBottom: '20px',
							}}
						>
							<FadeLoader
								width={5}
								height={20}
								loading={true}
								color={'#0092FB'}
							/>
						</div>
					) : analyzerData.length === 0 ? (
						<GetStarted
							helpPath={'1-dns-checker.html'}
							title={'Email Inboxing Grader'}
							addFirst={
								<>
									Are Your Emails going to Inbox, Promotions or Spam? <br />
									Use our free email deliverability tester to see where your
									<br />
									emails are being delivered.
								</>
							}
						/>
					) : (
						<>
							<Table
								extractedData={extractedData}
								tableHeader={tableHeader}
								className={'inbox-item-name'}
							/>
							<div className='float-right'>
								<Pagination
									itemClass='page-item'
									linkClass='page-link'
									activePage={activePage}
									itemsCountPerPage={perPageData.per_page}
									totalItemsCount={perPageData.total}
									pageRangeDisplayed={4}
									onChange={pageNumber => handlePageChange(pageNumber)}
								/>
							</div>
						</>
					)}
				</div>
			</div>
			<AnalyzerFormModal
				setStep={setStep}
				loading={loading}
				setShowNav={setShowNav}
				closeModal={closeModal}
				setLoading={setLoading}
				getAnalyzers={getAnalyzers}
				getFeatureCount={getFeatureCount}
				modalInstanceAdd={modalInstanceAdd}
			/>
			<DeleteModal
				modalCenterDlt={'AnalyzerModalCenterDlt'}
				modalCenterTitle={'exampleModalCenterTitle'}
				confirmation={
					' Do you really want to delete this test from your account?'
				}
				close={() => closeModal(modalInstanceDel)}
				DeleteItem={() =>
					handleDelete(
						parseInt(
							document
								.getElementById('deleteAnalyzerTest-' + testId)
								.getAttribute('data-id')
						)
					)
				}
			/>
		</Layout>
	)
}

const mapStateToProps = state => {
	return {
		apiCallInProgress: state.apiCallsInProgress.apiCallInProgress,
	}
}

export default connect(mapStateToProps, null)(PotentialInboxing)
