import React from 'react'
import HelpComponent from '../../../components/Help/Help'

const UserInfo = ({
	user,
	error,
	errors,
	userInfo,
	setUserInfo,
	handleUserInfoSave,
}) => {
	return (
		<>
			<div className='card' id='plan-card'>
				<div
					className='card-header d-flex justify-content-between'
					style={{ background: '#FFFFFF' }}
				>
					<h5 className='text-capitalize text-bold heading align-self-center mb-0'>
						User Information &nbsp;
						<span>
							<HelpComponent filePath={'/help-fragments/4-userHelp.html'} />
						</span>{' '}
					</h5>
					<button onClick={handleUserInfoSave} className='btn save-btn'>
						Save
					</button>
				</div>

				<div className='card-body d-flex'>
					<div
						className='avatar d-flex flex-row mr-4'
						style={{ position: 'relative' }}
					>
						<div className='sbg d-flex align-items-center justify-content-center'>
							{user.avatar !== '' && user.avatar !== 'default.png' ? (
								<img
									alt=''
									width='105px'
									height='105px'
									className='rounded-circle'
									src={user.avatar !== '' ? user.avatar : userInfo.avatar}
								/>
							) : (
								<img src='/assets/img/default.png' alt='' />
							)}
						</div>
						<i
							className='fas fa-plus-circle text-center align-self-end'
							style={{
								left: '70%',
								color: '#0994F8',
								fontSize: '25px',
								cursor: 'pointer',
								position: 'absolute',
							}}
							data-toggle='modal'
							data-target='#cropperModal'
						></i>
					</div>
					<div className='avatar-label align-self-center'>
						<h5 className='text-capitalize text-bold heading'>Avatar</h5>
						<span className='text-muted'>Recomended Dimensions:</span>
						<span className='text-muted'> 520*520 Max file size 5MB</span>
					</div>
				</div>

				<div id='divider' className='mt-4 divider' />

				<div className='row p-5'>
					<div className='col'>
						<div className='mb-4'>
							<label htmlFor='name' className='form-label'>
								Display Name <span className='text-danger'>*</span>
							</label>
							<input
								type='text'
								name='name'
								className='form-control'
								defaultValue={user.name}
								onChange={({ target }) =>
									setUserInfo({
										...userInfo,
										name: target.value,
									})
								}
								style={{ borderColor: 'none' }}
							/>
							{errors.hasOwnProperty('name')
								? errors.name.map(e => (
										<span className='text-danger mt-2 ml-1'>
											{e} <br />
										</span>
								  ))
								: ''}
						</div>
					</div>
					<div className='col'>
						<div className='mb-4'>
							<label htmlFor='Email Address' className='form-label'>
								Email <span className='text-danger'>*</span>
							</label>
							<input
								disabled
								required
								type='email'
								name='email'
								value={user.email}
								className='form-control'
								style={{ borderColor: 'none' }}
							/>
							<small className='text-muted'>
								You are not allowed to change your email
							</small>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default UserInfo
