import * as types from "../types/index.js";

const initialState = {
  apiCallInProgress: true
}

const apiCallStatusReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case types.API_CALL_IN_PROGRESS:
      return {
        ...state,
        apiCallInProgress: true
      }

    case types.API_CALL_NOT_IN_PROGRESS:
      return {
        ...state,
        apiCallInProgress: false
      }

    default: {
      return state;
    }
  }
};

export default apiCallStatusReducer;
