import axios from 'axios';
import { LOAD_FRAGMENT, TOGGLE_HELP_PANEL } from '../types/index';

export const setHelpFragment = ( filePath ) => dispatch => {
    return axios.get(filePath)
      .then(({ data }) => {
        dispatch({ type: LOAD_FRAGMENT, payload: data })
      })
      .catch(err => {
        throw err
      })
}

export const setIsToggle = ( isToggle ) => dispatch => {
    dispatch({ type: TOGGLE_HELP_PANEL, payload: isToggle })
}