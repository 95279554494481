import React from "react"
import { ReactComponent as TrackIconBlue } from "../../../../components/Assets/icons/track-blue-icon.svg"
export const TrackCompetitorHeader = ({openModal}) => {
  return (
    <div className='d-flex align-item-center justify-content-between'>
      <div className='col'>
        <div className='row'>
          <div className='col shld text-center pt-3'>
            <TrackIconBlue />
          </div>
          <div className='col'>
            <div className='mt-2 text-bold heading'>
              Track Competitors &nbsp;
              <span>
                {/* <HelpComponent
            filePath={"/help-fragments/3-warmupHelp.html"}
          /> */}
              </span>
            </div>
            <p className='col sub-heading'>
              View and submit your competitor's email sequences.
            </p>
          </div>
        </div>
      </div>
      <div className='px-0 align-self-center text-right'>
        <button
          type='button'
          onClick={openModal}
          data-tour='step-16'
          className='btn btn-sm c-btn'
          style={{
            outline: "none",
            backgroundColor: "#0994F8",
          }}
        >
          <img
            src='/assets/img/plus-icon.svg'
            style={{
              fontSize: "18px",
              marginRight: "13px",
              marginBottom: "2px",
            }}
          />
          <span style={{ fontSize: "15px" }}>Add Competitor</span>
        </button>
      </div>
    </div>
  )
}
