import React from 'react'
import moment from 'moment'

const PerformanceTable = ({ myAffiliates }) => {
	return (
		<>
			<div className='col m-4 table-responsive'>
				<table className='table'>
					<thead>
						<tr>
							<th scope='col'>
								&nbsp;&nbsp;&nbsp;Day
								<span className='sort-controls'>
									<img className='up' src='/assets/img/up-sort.png' />
									<img className='down' src='/assets/img/down-sort.png' />
								</span>
							</th>
							<th scope='col'>
								Clicks
								<span className='sort-controls'>
									<img className='up' src='/assets/img/up-sort.png' />
									<img className='down' src='/assets/img/down-sort.png' />
								</span>
							</th>
							<th scope='col'>
								Signups
								<span className='sort-controls'>
									<img className='up' src='/assets/img/up-sort.png' />
									<img className='down' src='/assets/img/down-sort.png' />
								</span>
							</th>
							{/* <th scope="col">
                Customers
                <span className="sort-controls">
                  <img className="up" src="/assets/img/up-sort.png" />
                  <img className="down" src="/assets/img/down-sort.png" />
                </span>
              </th> */}
							<th scope='col'>
								Remaining Payout
								<span className='sort-controls'>
									<img className='up' src='/assets/img/up-sort.png' />
									<img className='down' src='/assets/img/down-sort.png' />
								</span>
							</th>
						</tr>
					</thead>
					<tbody>
						{myAffiliates['DailyTracking'].length == 0 ? (
							<tr>
								<p className='text-muted ml-4 mt-4'>No data available..!!</p>
							</tr>
						) : (
							myAffiliates['DailyTracking'].map((item, i) => (
								<tr>
									<td>
										&nbsp;&nbsp;&nbsp;{moment(item.date).format('MMM D, YYYY')}
									</td>
									<td>{item.clicks}</td>
									<td>{item.singup}</td>
									<td>{item.earned}</td>
								</tr>
							))
						)}
					</tbody>
				</table>
			</div>
		</>
	)
}

export default PerformanceTable
