import React from 'react'
import moment from 'moment'

const Footer = () => {
	return (
		<div>
			<b>
				<span style={{ color: '#0E90F0' }}>Inbox</span> Better
			</b>&nbsp;
			© {moment(Date.now()).format('YYYY')}&nbsp; All Rights Reserved.
		</div>
	)
}

export default Footer
