import { toast } from 'react-toastify'
import LoadingBar from 'react-top-loading-bar'
import { login } from '../../store/actions/auth'
import { Link, Redirect } from 'react-router-dom'
import { useDispatch, connect } from 'react-redux'
import ValidateEmail from '../../Helper/validator'
import AuthService from '../../api-services/authService'
import React, { useEffect, useState, useRef } from 'react'

const Login = ({ history, isLoggedIn }) => {
	const ref = useRef(null)
	const dispatch = useDispatch()

	const [errors, setErrors] = useState({})
	const [error, setError] = useState(null)
	const [alert, setAlert] = useState(false)
	const [loading, setLoading] = useState(false)
	const [userEmail, setUserEmail] = useState('')
	const [emailErr, setEmailErr] = useState(true)
	const [loginForm, setLoginForm] = useState({
		email: '',
		password: '',
	})
	const [blockAlert, setBlockAlert] = useState(false)

	const notify = () => toast('Email Verification Resend Successfully.')

	const handleLoginFormChange = event => {
		const { name, value } = event.target
		setLoginForm({ ...loginForm, [name]: value })
	}

	const handleUserLogin = async event => {
		event.preventDefault()
		setLoading(true)

		setEmailErr(ValidateEmail(loginForm.email))
		try {
			ref.current.continuousStart()
			await dispatch(login(loginForm, history))
		} catch (e) {
			if (e.response.status == 422) {
				ref.current.complete()
				const errors = e.response.data.errors
				setErrors(errors)
				if (errors.hasOwnProperty('verifyEmail')) {
					localStorage.setItem('user', JSON.stringify(e.response.data.user))
					history.push('/email-verification')
				}
			} else {
				setError(e)
			}
		} finally {
			setLoading(false)
		}
	}

	const handleResendEmailVerification = async event => {
		event.preventDefault()

		if (userEmail !== '') {
			setLoading(true)
			ref.current.continuousStart()
			try {
				await AuthService.resendEmailVerification(userEmail)
				notify()
			} catch (e) {
				if (e.response.status === 422) {
					const errors = e.response.data.errors
					setErrors(errors)
				} else {
					setError(e)
				}
			} finally {
				setLoading(false)
				ref.current.complete()
			}
		}
	}

	useEffect(() => {
		if (window.location.href.includes('deactivated')) {
			setAlert(true)
		} else if (window.location.href.includes('blocked')) {
			setBlockAlert(true)
		} else {
			setAlert(false)
			setBlockAlert(false)
		}
	}, [])

	return (
		<>
			{isLoggedIn && <Redirect to='/dashboard' />}

			<section className='login'>
				<LoadingBar height={5} color='#5D95EA' ref={ref} />
				<div
					id='wrapper-div'
					className='d-flex flex-lg-column flex-md-column flex-sm-column flex-column'
				>
					{alert ? (
						<div
							className='alert alert-danger alert-dismissible fade show w-100 my-0'
							role='alert'
						>
							<div className='text-center'>
								<b>Account not active:</b> We will notify you when Admin
								activate's your Account.
							</div>
							<button
								type='button'
								aria-label='Close'
								data-dismiss='alert'
								className='close alert-close'
							>
								<span aria-hidden='true'>&times;</span>
							</button>
						</div>
					) : blockAlert ? (
						<div
							role='alert'
							className='alert alert-danger alert-dismissible fade show w-100 my-0'
						>
							<div className='text-center'>
								Your Account has been <b>Blocked</b>.
							</div>
							<button
								type='button'
								aria-label='Close'
								data-dismiss='alert'
								className='close alert-close'
							>
								<span aria-hidden='true'>&times;</span>
							</button>
						</div>
					) : (
						''
					)}
					<div className='d-flex flex-fill flex-lg-row flex-md-row flex-sm-column flex-column'>
						<div className='d-flex flex-fill flex-shrink-1 flex-sm-shrink-1 flex-column align-items-center justify-content-center login-left-col'>
							<a
								style={{
									display: 'flex',
									background: 'none',
									alignItems: 'center',
									justifyContent: 'center',
								}}
								href='https://inboxbetter.comocrm.com/'
							>
								<img
									alt='main logo'
									className='img-fluid'
									src='/assets/img/logo.png'
								/>
							</a>
							<div
								className='d-sm-none d-none d-md-flex d-lg-flex align-items-center justify-content-center'
								style={{
									zIndex: '1',
									marginTop: '50px',
									position: 'relative',
								}}
							>
								<div
									style={{
										zIndex: -1,
										width: '100%',
										height: '100%',
										borderRadius: '50%',
										position: 'absolute',
										background: '#ABDCFF',
									}}
								></div>
								<img src='/assets/img/sign-in.png' className='img-fluid' />
							</div>
							<p className='login-p pb-0 mt-sm-5 mt-5'>
								Use The "Perfect Inbox" AI To Unlock Your Email
								<br />
								Deliverability, While Testing Your Email Creatives &<br />{' '}
								Avoiding The Spam Folder
							</p>
						</div>

						<div className='d-flex flex-fill flex-sm-grow-1 flex-grow-1 justify-content-center align-items-center'>
							<div className='right-main'>
								<h2 style={{ color: '#0994f8' }}>
									{' '}
									Let’s Prepare for Launch!{' '}
								</h2>
								<p className='mb-0'> Sign in to your account </p>
								<form onSubmit={handleUserLogin}>
									<div className=' d-flex flex-column form-container'>
										<div className='mb-4'>
											<label htmlFor='Email Address' className='form-label'>
												Email Address <span style={{ color: 'red' }}>*</span>
											</label>
											<input
												required
												type='email'
												name='email'
												className='form-control'
												placeholder='demo@domain.com'
												onChange={handleLoginFormChange}
											/>
											{errors.hasOwnProperty('email')
												? errors.email.map((e, i) => (
														<span key={i} className='text-danger mt-1 ml-1'>
															* {e}{' '}
														</span>
												  ))
												: ''}
										</div>

										<div className='mb-4'>
											<label htmlFor='password' className='form-label'>
												Password <span style={{ color: 'red' }}>*</span>
											</label>
											<input
												required
												type='password'
												name='password'
												placeholder='Password'
												className='form-control'
												onChange={handleLoginFormChange}
											/>
											{errors.hasOwnProperty('password')
												? errors.password.map((e, i) => (
														<span key={i} className='text-danger mt-1 ml-1'>
															* {e}{' '}
														</span>
												  ))
												: ''}
										</div>

										<div className='forget d-flex justify-content-end'>
											<Link
												to='/reset-password'
												className='text-decoration-underline'
											>
												Forgot Password?
											</Link>
										</div>

										{errors.hasOwnProperty('message')
											? errors.message.map(e => (
													<div className='mt-4'>
														<span className='text-danger ml-1'> {e} </span>
														<div>
															<button
																onClick={handleResendEmailVerification}
																className='btn p-1 btn-link'
															>
																Resend Email
															</button>
														</div>
													</div>
											  ))
											: ''}

										<div className='d-flex pt-4'>
											<button
												type='submit'
												disabled={loading}
												className='btn admin-button'
												style={{ borderRadius: '3px' }}
											>
												Sign In
											</button>
										</div>

										<div className='d-flex pt-4 inbox-better'>
											<p className='inbox-p'>
												Not a{' '}
												<span>
													<b>Inbox</b>
												</span>
												<b>Better</b> user yet?&nbsp;&nbsp;
												<Link to='/register'>
													<u>Create your account</u>{' '}
												</Link>
											</p>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

function mapStateToProps(state) {
	return {
		user: state.authReducer.user,
		isLoggedIn: state.authReducer.isLoggedIn,
	}
}

export default connect(mapStateToProps, null)(Login)
