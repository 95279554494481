import React from "react"

const SMSSequence = () => {
  return (
    <div className='container-fluid'>
      <div className='col-md-6 offset-md-3 mt-3 d-flex justify-content-center align-middle'>
        <figure>
          <img
            src='/assets/img/coming_soon.gif'
            width='550px'
            height='550px'
            alt=''
          />
        </figure>
      </div>
    </div>
  )
}

export default SMSSequence
