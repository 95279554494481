import API from "./api"

const InvoiceService = {
  list: () => {
    return API.post(`/stripe-invoice-detail?customer_id=${JSON.parse(localStorage.getItem('user')).stripePayment.customer_id}`, {})
      .then(({ data }) => {
        return data
      })
      .catch((err) => {
        throw err
      })
  }
}

export default InvoiceService
