import { useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { ReactComponent as EyeIcon } from '../../components/Assets/icons/eye-icon.svg'
import { ReactComponent as EyeSlashIcon } from '../../components/Assets/icons/eye-slash-icon.svg'

export const AddNewTestModal = ({
	id,
	help,
	errors,
	loading,
	headline,
	nextClick,
	closeClick,
	handleOnChange,
	addNewTestForm,
	editorStateExp,
	sentInstruction,
	onEditorStateChangeExp,
}) => {
	const [showPassword, setShowPassword] = useState(false)

	return (
		<div
			tabIndex='-1'
			role='dialog'
			aria-hidden='true'
			className='modal fade'
			id='exampleModalCenter'
			aria-labelledby='exampleModalCenterTitle'
		>
			<div
				role='document'
				style={{ maxWidth: '818px' }}
				className='modal-dialog modal-dialog-centered'
			>
				<div className='modal-content'>
					<div className='modal-header-dns'>
						<h5 className='modal-title-dns' id='exampleModalLongTitle'>
							&nbsp;&nbsp;&nbsp;&nbsp;
							<img
								alt=''
								src='/assets/img/shld.png'
								style={{ color: '#0994F8' }}
							/>
							&nbsp;&nbsp;&nbsp; {headline}
						</h5>
						<button
							type='button'
							aria-label='Close'
							onClick={closeClick}
							className='modal-close'
							style={{ outline: 'none' }}
						>
							<img src='/assets/img/cross.png' alt='' />
						</button>
					</div>
					<form onSubmit={nextClick}>
						<div data-tour='step-3' className='modal-body-dns'>
							<h6>Send the email by yourself</h6>
							<label>Recipient Email</label>
							<input
								required
								readOnly
								type='email'
								name='to_email'
								className='form-control mb-3'
								value={id + '@inboxbetter.com'}
							/>
							<div className='d-flex flex-column'>
								<button
									onClick={nextClick}
									className='btn btn-primary c-btn float-right'
								>
									Next
								</button>
								<div className='d-flex flex-column justify-content-center align-items-center'>
									<hr />
									<h5>OR</h5>
									<hr />
								</div>
							</div>
							<h6>
								{sentInstruction} &nbsp; <span>{help}</span>
							</h6>
							<label>Sender Email</label>
							<input
								required
								type='email'
								name='from_email'
								onChange={handleOnChange}
								className='form-control mb-3'
								value={addNewTestForm.from_email}
							/>
							<label>Sender Password</label>
							<div className='input-group mb-3'>
								<input
									required
									name='password'
									className='form-control'
									onChange={handleOnChange}
									value={addNewTestForm.password}
									type={showPassword ? 'text' : 'password'}
								/>
								<span
									id='basic-addon2'
									className='input-group-text'
									style={{ cursor: 'pointer' }}
									onClick={() => setShowPassword(!showPassword)}
								>
									{showPassword ? <EyeSlashIcon /> : <EyeIcon />}
								</span>
							</div>
							<label htmlFor='youremail' className='form-label'>
								Subject Headline
							</label>
							<input
								required
								type='text'
								name='subject_line'
								className='form-control mb-3'
								onChange={handleOnChange}
								value={addNewTestForm.subject_line}
							/>
							{/* {errors.hasOwnProperty('subjectLine')
								? errors.subjectLine.map(e => (
										<div className='text-danger mt-2 ml-1'> {e} </div>
								  ))
								: ''} */}
							<label htmlFor='youremail' className='form-label'>
								Email Body
							</label>
							<Editor
								toolbarOnFocus
								toolbar={{
									options: [
										'inline',
										'blockType',
										'fontSize',
										'fontFamily',
										'list',
										'textAlign',
										'emoji',
										'image',
										'remove',
										'history',
									],
								}}
								name='email_body'
								spellCheck={true}
								editorState={editorStateExp}
								placeholder='Explanation...'
								wrapperClassName='wrapperStyle'
								editorClassName='editorStyleExp'
								onEditorStateChange={onEditorStateChangeExp}
							/>
							{/* <textarea
								rows='7'
								name='email_body'
								onChange={handleOnChange}
								className='form-control mb-3'
								id='exampleFormControlTextarea1'
								value={addNewTestForm.email_body}
							></textarea> */}
							{/* {errors.hasOwnProperty('html')
								? errors.html.map(e => (
										<div className='text-danger mt-2 ml-1'> {e} </div>
								  ))
								: ''} */}
						</div>
						<div className='modal-footer'>
							<button
								type='button'
								onClick={closeClick}
								className='btn btn-danger c-btn-normal text-dark'
							>
								Cancel
							</button>
							<button
								type='submit'
								data-tour='step-4'
								disabled={
									loading ||
									addNewTestForm.email_body === '' ||
									addNewTestForm.password === '' ||
									addNewTestForm.from_email === '' ||
									addNewTestForm.subject_line === ''
								}
								className='btn btn-primary mr-2 c-btn'
							>
								Next
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}
