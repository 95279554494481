import { toast } from 'react-toastify'
import PayoutsTable from './PayoutsTable'
import React, { useEffect, useState } from 'react'
import FadeLoader from 'react-spinners/FadeLoader'
import HelpComponent from '../../components/Help/Help'
import affiliateService from '../../api-services/affiliateService'

const Payouts = () => {
	const [payouts, setPayouts] = useState([])
	const [loading, setLoading] = useState(false)
	const [activePage, setActivePage] = useState(1)
	const [period, setPeriod] = useState('last7Days')
	const [metaData, setMetaData] = useState({ per_page: 0, total: 0 })

	const handleTab = e => {
		if (e.target.id === 'last7Days') {
			setPeriod('last7Days')
		} else if (e.target.id === 'lastMonth') {
			setPeriod('lastMonth')
		} else if (e.target.id === 'last6Month') {
			setPeriod('last6Month')
		}
	}

	const handlePageChange = async pageNumber => {
		setLoading(true)

		let perPage = 0
		let totalPages = 0

		const response = await affiliateService.getPayouts(pageNumber)

		setActivePage(pageNumber)
		setPayouts(response.Payouts.data)
		totalPages = response.Payouts.total
		perPage = response.Payouts.per_page
		setMetaData({ totalPages: totalPages, perPage: perPage })

		setLoading(false)
	}

	const fetchPayouts = async () => {
		setLoading(true)

		const response = await affiliateService.getPayouts()

		if (response.success) {
			let tempObj = { per_page: 0, total: 0 }
			setPayouts(response.Payouts.data)
			tempObj.per_page = response.Payouts.per_page
			tempObj.total = response.Payouts.total

			setMetaData(tempObj)
			setLoading(false)
			toast.success(response.message, {
				autoClose: 2500,
				closeOnClick: true,
				position: 'top-right',
			})
		} else {
			setLoading(false)
			toast.error(response.message, {
				autoClose: 2500,
				closeOnClick: true,
				position: 'top-right',
			})
		}
	}

	useEffect(() => {
		fetchPayouts()
	}, [])

	return (
		<>
			<div className='card'>
				<div className='card-body p-0 d-flex flex-column'>
					<div className='d-flex align-items-center p-4'>
						<div className='shld flex-grow-1 mr-3 text-center p-1'>
							<img src='/assets/img/payouts-blue.png' alt='warm-up' />
						</div>
						<div className='d-flex flex-column'>
							<div className='mt-2 text-bold heading'>
								Payouts &nbsp;
								<span>
									<HelpComponent
										filePath={'/help-fragments/3-warmupHelp.html'}
									/>
								</span>
							</div>
							<p className='sub-heading flex-fill'>
								View your shareable affiliate joining link and corresponding
								insights
							</p>
						</div>
					</div>
					<div className='row d-none'>
						<div className='col d-flex justify-content-end'>
							<div className='aff_email_menu'>
								<ul>
									<li onClick={handleTab}>
										<div
											className={
												period === 'last7Days' ? 'nav_item_active' : 'nav_item'
											}
											id='last7Days'
										>
											<span
												id='last7Days'
												style={{
													color: period === 'last7Days' ? '#0994F8' : 'black',
												}}
											>
												Last 7 Days
											</span>
										</div>
									</li>
									<li onClick={handleTab}>
										<div
											className={
												period === 'lastMonth' ? 'nav_item_active' : 'nav_item'
											}
											id='lastMonth'
										>
											<span
												id='lastMonth'
												style={{
													color: period === 'lastMonth' ? '#0994F8' : 'black',
												}}
											>
												Last Month
											</span>
										</div>
									</li>
									<li onClick={handleTab}>
										<div
											className={
												period === 'last6Month' ? 'nav_item_active' : 'nav_item'
											}
											id='last6Month'
										>
											<span
												id='last6Month'
												style={{
													color: period === 'last6Month' ? '#0994F8' : 'black',
												}}
											>
												Last 6 Months
											</span>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div id='divider' className='mx-4 d-none' />

					{loading ? (
						<div className='text-center pb-5 mb-5'>
							<FadeLoader
								width={5}
								height={20}
								color={'#0092FB'}
								loading={loading}
							/>
						</div>
					) : payouts.length === 0 ? (
						<div className='text-center starter-inboxes'>
							<p className='mt-4 sp' style={{ fontSize: '18px' }}>
								<b>No Payouts Found</b>
							</p>
						</div>
					) : (
						<PayoutsTable
							payouts={payouts}
							metaData={metaData}
							activePage={activePage}
							handlePageChange={handlePageChange}
						/>
					)}
				</div>
			</div>
		</>
	)
}

export default Payouts
