import React from "react"
import LoadingBar from "react-top-loading-bar"

export const Layout = ({ children, innerRef }) => {
  return (
    <>
      <LoadingBar height={5} color='#5D95EA' ref={innerRef} />
      <div className='col-lg-12 col-md-12 col-sm-12 mt-4 title-col'>
        <div className='card full-width' id='plan-card'>
          <div className='card-body pt-3'>{children}</div>
        </div>
      </div>
    </>
  )
}
