import { useState } from "react"
import { toast } from "react-toastify"
import { Link } from "react-router-dom"
import React, { useEffect } from "react"
import FadeLoader from "react-spinners/FadeLoader"
import SequencesService from "../../../api-services/sequencesService"
import Pagination from "react-js-pagination"
import Table from "../../../atoms/Table"
import { FavoriteSequenceHeader } from "./favoriteSequenceHeader/FavoriteSequenceHeader"

const tableHeader = [
  {
    th: "Sequence Name",
    sort: true,
  },
  {
    th: "Viewed",
    sort: false,
  },
  {
    th: "Brand",
    sort: true,
  },
  {
    th: "Actions",
    sort: false,
  },
]
const FavoriteSequence = () => {
  const [loading, setLoading] = useState(false)
  const [sequences, setSequences] = useState([])
  const [activePage, setActivePage] = useState(1)
  const [filterByViewed, setFilterByViewed] = useState("")
  const [metaData, setMetaData] = useState({ per_page: 0, total: 0 })

  const extractedData = React.useMemo(() => {
    return sequences.map((sequence) => {
      const tableCells = [
        {
          cellData: sequence.is_unlocked ? (
            <span className='inbox-item-name '>
              <Link
                to={{
                  pathname: `/email-sequences/${sequence.id}`,
                }}
              >
                {sequence.name}
              </Link>
            </span>
          ) : (
            <span className='inbox-item'>{sequence.name}</span>
          ),
        },
        {
          cellData: (
            <span
              className='text-muted'
              style={{
                color: sequence.is_viewed ? "#1cc88a" : "#000",
                fontWeight: "bold",
              }}
            >
              {sequence.is_viewed ? "Yes" : "No"}
            </span>
          ),
        },
        {
          cellData:
            sequence.brand !== undefined ? sequence.brand.name : "Loading",
        },
        {
          cellData: sequence.is_unlocked ? (
            <>
              <Link
                to={{
                  pathname: `/email-sequences/${sequence.id}`,
                }}
              >
                <i
                  className='fa fa-eye ml-3'
                  aria-hidden='true'
                  style={{
                    color: "#1cc88a",
                    cursor: "pointer",
                  }}
                />
              </Link>
              <i className='fas fa-lock-open ml-2 text-muted' />
            </>
          ) : (
            <div
              onClick={() => unlockSequence(sequence.id)}
              style={{ cursor: "pointer" }}
              className='d-flex align-items-center text-muted justify-content-center'
            >
              <i className='fas fa-lock' />
              <p className='mb-0 ml-1'> Unlock</p>
            </div>
          ),
        },
      ]
      return tableCells
    })
  })

  const fetchFavoriteSequences = async () => {
    setLoading(true)

    let tempObj = {
      perPage: 0,
      totalPages: 0,
    }

    const response = await SequencesService.listFavoriteSequence(activePage)

    if (response.data !== undefined) {
      if (response.data.success) {
        setSequences(response.data.sequence.data)
        tempObj.perPage = response.data.sequence.per_page
        tempObj.totalPages = response.data.sequence.total
        setMetaData(tempObj)
      }
    } else {
      toast.error(response.message, {
        autoClose: 2500,
        closeOnClick: true,
        position: "top-right",
        hideProgressBar: false,
      })
    }
    setLoading(false)
  }

  const unlockSequence = async (id) => {
    try {
      const response = await SequencesService.unlockSequence(id)

      if (response.success) {
        toast.success(response.message, {
          autoClose: 2500,
          closeOnClick: true,
          position: "top-right",
        })
        fetchFavoriteSequences()
      } else if (!response.success) {
        toast.error(response.message, {
          autoClose: 2500,
          closeOnClick: true,
          position: "top-right",
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handlePageChange = async (pageNumber) => {
    setActivePage(pageNumber)

    setLoading(true)

    let tempObj = {
      perPage: 0,
      totalPages: 0,
    }

    const response = await SequencesService.listFavoriteSequence(pageNumber)

    if (response.data !== undefined) {
      if (response.data.success) {
        setSequences(response.data.sequence.data)
        tempObj.perPage = response.data.sequence.per_page
        tempObj.totalPages = response.data.sequence.total
        setMetaData(tempObj)
      }
    } else {
      toast.error(response.message, {
        autoClose: 2500,
        closeOnClick: true,
        position: "top-right",
        hideProgressBar: false,
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchFavoriteSequences()
  }, [])

  return (
    <div className='container-fluid'>
      <div className='col-lg-12 col-md-12 col-sm-12 mt-4 title-col px-0'>
        <div className='card full-width' id='plan-card'>
          <div className='card-body'>
            <FavoriteSequenceHeader />
            {loading ? (
              <div className='text-center pb-5 mb-5'>
                <FadeLoader
                  loading={loading}
                  color={"#0092FB"}
                  height={20}
                  width={5}
                />
              </div>
            ) : (
              <>
                {sequences.length === 0 ? (
                  <div className='text-center starter-inboxes'>
                    <p className='mt-4 sp' style={{ fontSize: "18px" }}>
                      <b>No Sequences Favorited...</b>
                    </p>
                  </div>
                ) : (
                  <>
                    <Table
                      tableHeader={tableHeader}
                      extractedData={extractedData}
                    />
                    <div className='row float-right'>
                      <Pagination
                        itemClass='page-item'
                        linkClass='page-link'
                        pageRangeDisplayed={4}
                        activePage={activePage}
                        onChange={(pageNumber) => handlePageChange(pageNumber)}
                        itemsCountPerPage={metaData.perPage}
                        totalItemsCount={metaData.totalPages}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FavoriteSequence
