import { useState } from "react"
import { toast } from "react-toastify"
import { Link } from "react-router-dom"
import React, { useEffect } from "react"
import Pagination from "react-js-pagination"
import "../../../components/Filter/filter.scss"
import FadeLoader from "react-spinners/FadeLoader"
import Filter from "../../../components/Filter/Filter"
import FeatureService from "../../../api-services/featuresService"
import SequencesService from "../../../api-services/sequencesService"
import Table from "../../../atoms/Table"
import { EmailSequenceHeader } from "./emailSequenceHeader/EmailSequenceHeader"
const tableHeader = [
  {
    th: "Sequence Name",
    sort: true,
  },
  {
    th: "View",
    sort: true,
  },
  {
    th: "Brand",
    sort: true,
  },
  {
    th: "Actions",
    sort: false,
  },
]
const EmailSequence = () => {
  const [metaData, setMetaData] = useState({})
  const [loading, setLoading] = useState(false)
  const [sequences, setSequences] = useState([])
  const [activePage, setActivePage] = useState(1)
  const [selectedBrand, setSelectedBrand] = useState("")
  const [sequencesCount, setSequencesCount] = useState({
    max_allowed_count: 0,
    consumed_count: 0,
  })
  const [filterByViewed, setFilterByViewed] = useState("")
  const [selectedCategory, setSelectedCategory] = useState("")

  const extractedData = React.useMemo(() => {
    return sequences.map((sequence) => {
      const tableCells = [
        {
          cellData: sequence.is_unlocked ? (
            <span className='inbox-item-name '>
              <Link
                to={{
                  pathname: `/email-sequences/${sequence.id}`,
                }}
              >
                {sequence.name}
              </Link>
            </span>
          ) : (
            <span className='inbox-item'>{sequence.name}</span>
          ),
        },
        {
          cellData: (
            <span
              className='text-muted'
              style={{
                color: sequence.is_viewed ? "#1cc88a" : "#000",
                fontWeight: "bold",
              }}
            >
              {sequence.is_viewed ? "Yes" : "No"}
            </span>
          ),
        },
        {
          cellData:
            sequence.brand !== undefined ? sequence.brand.name : "Loading",
        },
        {
          cellData: (
            <span className='d-flex flex-row align-items-center justify-content-center'>
              {sequence.is_fav ? (
                <i
                  style={{
                    fontSize: "17px",
                    cursor: "pointer",
                  }}
                  class='fas fa-star mr-2 text-warning'
                  onClick={() => handleUnfavoriteSequence(sequence.id)}
                />
              ) : (
                <i
                  style={{
                    fontSize: "17px",
                    cursor: "pointer",
                  }}
                  class='far fa-star mr-2 text-warning'
                  onClick={() => handleFavoriteSequence(sequence.id)}
                />
              )}

              {sequence.is_unlocked ? (
                <>
                  <Link
                    to={{
                      pathname: `/email-sequences/${sequence.id}`,
                    }}
                  >
                    <i
                      className='fa fa-eye ml-3'
                      aria-hidden='true'
                      style={{
                        color: "#1cc88a",
                        cursor: "pointer",
                      }}
                    />
                  </Link>
                  <i className='fas fa-lock-open ml-2 text-muted' />
                </>
              ) : (
                <div
                  onClick={() => unlockSequence(sequence.id)}
                  style={{ cursor: "pointer" }}
                  className='d-flex align-items-center text-muted'
                >
                  <i className='fas fa-lock'></i>{" "}
                  <p className='mb-0 ml-1'> Unlock</p>
                </div>
              )}
            </span>
          ),
        },
      ]
      return tableCells
    })
  })

  const fetchSequences = async () => {
    // setLoading(true)
    let perPage = 0
    let totalPages = 0

    const response = await SequencesService.listSequences(
      activePage,
      selectedBrand.value,
      selectedCategory.value,
      filterByViewed
    )

    if (response.data !== undefined) {
      if (response.data.success) {
        setSequences(response.data.sequence.data)
        totalPages = response.data.sequence.total
        perPage = response.data.sequence.per_page
        setMetaData({ totalPages: totalPages, perPage: perPage })
      } else {
        toast.error(response.data.message, {
          autoClose: 2500,
          closeOnClick: true,
          position: "top-right",
          hideProgressBar: false,
        })
      }
    } else {
      toast.error(response.message, {
        autoClose: 2500,
        closeOnClick: true,
        position: "top-right",
        hideProgressBar: false,
      })
    }
    setLoading(false)
  }

  const handlePageChange = async (pageNumber) => {
    setLoading(true)

    let totalPages = 0
    let perPage = 0

    const response = await SequencesService.listSequences(
      pageNumber,
      selectedBrand.value,
      selectedCategory.value,
      filterByViewed
    )

    setActivePage(pageNumber)
    setSequences(response.data.sequence.data)
    totalPages = response.data.sequence.total
    perPage = response.data.sequence.per_page
    setMetaData({ totalPages: totalPages, perPage: perPage })

    setLoading(false)
  }

  const unlockSequence = async (id) => {
    try {
      const response = await SequencesService.unlockSequence(id)

      if (response.success) {
        toast.success(response.message, {
          autoClose: 2500,
          closeOnClick: true,
          position: "top-right",
        })
        fetchSequences()
      } else if (!response.success) {
        toast.error(response.message, {
          autoClose: 2500,
          closeOnClick: true,
          position: "top-right",
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getFeatureCount = async () => {
    try {
      let tempObj = {}
      const response = await FeatureService.getFeatureConsumptionCount({
        warmup: "",
        sequences: 1,
        spamchecker: "",
        emailgrader: "",
        domainHealth: "",
      })

      tempObj.max_allowed_count = response.max_allowed_count
      tempObj.consumed_count = response.consumed_count

      setSequencesCount(tempObj)
    } catch (error) {
      toast.error("Something went wrong")
    }
  }

  const handleFavoriteSequence = async (id) => {
    // setLoading(true)
    const response = await SequencesService.favoriteSequence(id)

    if (response.success) {
      // setLoading(false)
      fetchSequences()
      toast.success(response.message, {
        autoClose: 2500,
        closeOnClick: true,
        position: "top-right",
      })
    } else {
      toast.error(response.message, {
        autoClose: 2500,
        closeOnClick: true,
        position: "top-right",
      })
    }
  }
  const handleUnfavoriteSequence = async (id) => {
    // setLoading(true)
    const response = await SequencesService.unFavoriteSequence(id)

    if (response.success) {
      // setLoading(false)
      fetchSequences()
      toast.success(response.message, {
        autoClose: 2500,
        closeOnClick: true,
        position: "top-right",
      })
    } else {
      toast.error(response.message, {
        autoClose: 2500,
        closeOnClick: true,
        position: "top-right",
      })
    }
  }

  useEffect(() => {
    let element = document.getElementById("multiCollapseExample1")

    if (sequences.length === 0) {
      if (element !== null) element.classList.remove("show")
    }
  }, [sequences])

  useEffect(() => {
    fetchSequences()
  }, [filterByViewed])

  useEffect(() => {
    setLoading(true)
    fetchSequences()
    getFeatureCount()
  }, [])

  return (
    <div className='container-fluid'>
      <div className='col-lg-12 col-md-12 col-sm-12 mt-4 title-col px-0'>
        <div className='card full-width' id='plan-card'>
          <div className='card-body'>
            <EmailSequenceHeader
              consumedCount={sequencesCount.consumed_count}
              allowedCount={sequencesCount.max_allowed_count}
            />
            {loading ? (
              <div className='text-center pb-5 mb-5'>
                <FadeLoader
                  width={5}
                  height={20}
                  color={"#0092FB"}
                  loading={loading}
                />
              </div>
            ) : (
              <>
                <Filter
                  setLoading={setLoading}
                  activePage={activePage}
                  setMetaData={setMetaData}
                  status={"email-sequences"}
                  setSequences={setSequences}
                  selectedBrand={selectedBrand}
                  filterByViewed={filterByViewed}
                  fetchSequences={fetchSequences}
                  setFilterByViewed={setFilterByViewed}
                  selectedCategory={selectedCategory}
                  setSelectedBrand={setSelectedBrand}
                  setSelectedCategory={setSelectedCategory}
                />
                {sequences.length === 0 ? (
                  <div className='text-center starter-inboxes'>
                    <p className='mt-4 sp' style={{ fontSize: "18px" }}>
                      <b>No Sequences Found</b>
                    </p>
                  </div>
                ) : (
                  <>
                    <Table
                      tableHeader={tableHeader}
                      extractedData={extractedData}
                    />
                    <div className='row float-right'>
                      <div>
                        <Pagination
                          itemClass='page-item'
                          linkClass='page-link'
                          pageRangeDisplayed={4}
                          activePage={activePage}
                          itemsCountPerPage={metaData.perPage}
                          totalItemsCount={metaData.totalPages}
                          onChange={(pageNumber) =>
                            handlePageChange(pageNumber)
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmailSequence
