import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import AuthService from '../../api-services/authService'
import { toast } from 'react-toastify'
import LoadingBar from 'react-top-loading-bar'
import { useParams, Link, Redirect } from 'react-router-dom'
import ValidateEmail from '../../Helper/validator'

const ResetPassword = ({ user, history, location }) => {
	const ref = useRef(null)
	const [email, setEmail] = useState('')
	const { signedEmail } = useParams()
	const [queryParms, setQueryParms] = useState({})

	const [error, setError] = useState(null)
	const [errors, setErrors] = useState({})
	const [loading, setLoading] = useState(false)
	const [emailErr, setEmailErr] = useState(true)

	const notify = () => toast('Password has been Reset.')

	useEffect(() => {
		let parsedQueryString = queryString.parse(location.search)
		setQueryParms(parsedQueryString)
		setEmail(parsedQueryString.email)
	}, [])

	const handleResetPasswordFormSubmit = async event => {
		event.preventDefault()

		setEmailErr(ValidateEmail(email))
		setLoading(true)
		ref.current.continuousStart()
		try {
			await AuthService.sendPasswordResetLink({ email })

			notify()

			setTimeout(() => {
				history.push('/login')
			}, 3000)
		} catch (e) {
			if (e.response.status === 422) {
				const errors = e.response.data.errors
				setErrors(errors)
			} else {
				setError(e)
			}
		} finally {
			setLoading(false)
			if (ref.current) ref.current.complete()
		}
	}

	return (
		<>
			<section className='login'>
				<LoadingBar height={5} color='#5D95EA' ref={ref} />
				<div class='w-100'>
					<div class='h-100 w-100 mx-0 position-absolute row login-row'>
						<div className='align-items-lg-center align-self-center col-12 col-lg-7 col-md-7 col-xxl-7 d-flex h-100 justify-content-center mx-auto w-100 login-left-col'>
							<div class='align-items-center d-flex flex-column h-100 justify-content-center left-main'>
								<div>
									<img src='/assets/img/logo.png' className='img-fluid' />
								</div>
								<div>
									<img src='/assets/img/sign-in.png' className='img-fluid' />
								</div>
								<p className='login-p'>
									Use The "Perfect Inbox" AI To Unlock Your Email
									Deliverability, While Testing Your Email Creatives & Avoiding
									The Spam Folder
								</p>
							</div>
						</div>

						<div class='col-lg-5 col-xxl-5 col-md-5 col-12 col-sm-5 mx-auto d-flex justify-content-center align-items-center'>
							<div class='right-main'>
								<h1 className='right-heading'> Reset Your Password </h1>
								<form onSubmit={handleResetPasswordFormSubmit}>
									<div class='form-container'>
										<div class='mb-4'>
											<label for='Email Address' class='form-label'>
												Email Address <span style={{ color: 'red' }}>*</span>
											</label>
											<input
												className='form-control'
												type='email'
												name='email'
												onChange={event => setEmail(event.target.value)}
												// placeholder="demo@domain.com"
												required
											/>
											<div className='text-danger mt-2 ml-1'>
												{emailErr ? '' : 'Please Enter Valid Email'}
											</div>
										</div>

										<div className='col-md-12 pt-4'>
											<button
												disabled={loading}
												type='submit'
												className='btn admin-button'
											>
												Reset Password
											</button>
										</div>

										<div className='col-md-12 pt-4 inbox-better'>
											<p className='inbox-p'>
												Not a{' '}
												<span>
													<b>Inbox</b>
												</span>
												<b>Better</b> user yet?&nbsp;&nbsp;
												<Link to='/register'>
													<u>Create your account</u>{' '}
												</Link>
											</p>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

function mapStateToProps(state) {
	return {
		user: state.authReducer.user,
	}
}

export default connect(mapStateToProps, null)(ResetPassword)
