import React, { useEffect } from "react"
import Accordion from "./Accordion"
import "./style.scss"
import { useState } from "react"
import FadeLoader from "react-spinners/FadeLoader"
import moment from "moment"
import HelpComponent from "../../components/Help/Help"
import { useParams } from "react-router-dom"
import noAuthServices from "../../api-services/noAuthServices"

function DomainHealth(props) {
  const [healthData, setHealthData] = useState([])
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const [rawEmail, setRawEmail] = useState(false)
  const [date, setDate] = useState("")
  let { id } = useParams()

  let fix = document.querySelector(".topnav_button_fix")
  let raw = document.querySelector(".topnav_button_raw")

  const handleClick = (name) => {
    if (name === "raw") {
      fix.classList.remove("topnav_button_active")
      raw.classList.add("topnav_button_active")
      setRawEmail(true)
    } else {
      fix.classList.add("topnav_button_active")
      raw.classList.remove("topnav_button_active")
      setRawEmail(false)
    }
  }

  const fetchnoAuthReport = async (id) => {
    const response = await noAuthServices.getDNSReport(id)

    localStorage.setItem("noAuthDNSReport", JSON.stringify(response.data.data))
  }

  useEffect(() => {
    if (window.location.search !== "")
      fetchnoAuthReport(window.location.search.substring(1))
    else setHealthData(JSON.parse(localStorage.getItem("noAuthDNSReport")))
  }, [])

  useEffect(() => {
    if (props.location.state && props.location.state !== undefined) {
      setHealthData(JSON.parse(props.location.state.tempObj.report))
      setEmail(props.location.state.tempObj.inbox)
      setDate(props.location.state.tempObj.created_at)
    } else if (localStorage.getItem("noAuthDNSReport")) {
      setHealthData(JSON.parse(localStorage.getItem("noAuthDNSReport")))
    } else if (localStorage.getItem("domain_health")) {
      JSON.parse(localStorage.getItem("domain_health")).map((acc) => {
        if (acc.id == id) {
          setHealthData(JSON.parse(acc.report))
          setEmail(acc.inbox)
          setDate(acc.created_at)
        }
      })
    }
    // },
    //   if (
    //     props.location.state.tempObj.report === null &&
    //     localStorage.getItem("domain_data")
    //   ) {
    //     setLoading(true);
    //   } else if (
    //     !typeof JSON.parse(localStorage.getItem("domain_data")) === null
    //   ) {
    //     setHealthData(localStorage.getItem("domain_data"));
    //     setLoading(false);
    //   } else {
    //     setHealthData(JSON.parse(props.location.state.tempObj.report));
    //     // localStorage.setItem("domain_data", props.location.state.tempObj.report);
    //     setLoading(false);
    //   }
  }, [props])

  return (
    <>
      <div className='container-fluid bg-white my-1 right-header'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-md-8 col-xxl-8 col-lg-8 col-12 pl-3 my-4'>
            <span style={{ fontSize: "15px" }}>
              <img
                className='left_arrow'
                style={{
                  marginLeft: "30px",
                  marginRight: "10px",
                  marginTop: "-2px",
                  cursor: "pointer",
                  height: "15px",
                  width: "15px",
                }}
                src='/assets/img/caret-left-icon.svg'
                onClick={() => props.history.push("/dns-checker")}
              />
              &nbsp;&nbsp;&nbsp;
              <b> Email Blacklist &amp; DNS Checker Results:</b>&nbsp;&nbsp;
              {email}
              <b>
                &nbsp;
                <span>
                  <HelpComponent
                    filePath={"/help-fragments/2-domainHelp.html"}
                  />
                </span>
              </b>
            </span>
          </div>
          <div className='col-md-3 col-xxl-3 col-lg-3 col-3 d-flex d-inline justify-content-end my-4'>
            <p className='pr-4'>Date: {moment(date).format("MMM D, YYYY")}</p>
            {/* <button className="btn btn btn-sm c-btn btn-right-shareReport c-btn">
              Share my report
            </button> */}
          </div>
        </div>
      </div>
      <div className='row mt-3 mx-4 mb-3'>
        <div className='col border boder-danger bg-white'>
          <div className='row p-3'>
            <div className='icon-div py-3 px-3'>
              <img
                src='/assets/img/score-meter.png'
                alt=''
                width='28px'
                height='25px'
              ></img>
            </div>
            <div className='col-md-10 col-6 col-lg-6 col-xxl-6'>
              <h5 className='score'>
                {healthData.length === 0 ? (
                  <b>Work in progress...</b>
                ) : (
                  <>
                    {healthData.health_check.all_score === undefined
                      ? 30
                      : healthData.health_check.all_score}
                    <span> / 100</span>
                  </>
                )}
              </h5>
              <p>Emails Deliverability Score</p>
            </div>
          </div>
        </div>
        <div className='col border border-danger ml-3 bg-white'>
          <div className='row p-3'>
            <div className='icon-div'>
              <img
                alt=''
                className='img-fluid'
                src='/assets/img/book.png'
                style={{ padding: "3px" }}
              />
            </div>
            <div className='ml-2'>
              <h5>
                <b>Want to increase your Deliverability?</b>
              </h5>
              <span>
                <span
                  style={{
                    color: "blue",
                    textDecoration: "underline",
                  }}
                >
                  InboxBetter tips to fix your issues
                </span>
                &nbsp;
                <span style={{ textDecoration: "none" }}>(Coming Soon)</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='topnav'>
        <button
          className='topnav_button_fix topnav_button_active mr-2 mt-1'
          onClick={() => handleClick("fix")}
        >
          <div className='topnav_icon'>
            <img
              src='/assets/img/thingstofix.png'
              alt=''
              style={{ paddingLeft: "1px", paddingTop: "8px" }}
              height='42px'
            />
          </div>
          <p
            className='float-left'
            style={{ paddingTop: "5px", marginLeft: "10px", fontSize: "18px" }}
          >
            &nbsp;&nbsp;
            {healthData.length === 0
              ? ""
              : healthData.health_check.things_to_fix}
            &nbsp;&nbsp;Things to fix
          </p>
        </button>
        <button
          className='topnav_button_raw ml-2 mt-1'
          onClick={() => handleClick("raw")}
        >
          <div className='topnav_icon'>
            <img
              src='/assets/img/raw_email.png'
              alt=''
              style={{ paddingLeft: "2px", paddingTop: "5px" }}
            />
          </div>
          <p
            className='float-left'
            style={{ paddingTop: "5px", marginLeft: "10px", fontSize: "18px" }}
          >
            Raw email
          </p>
        </button>
      </div>
      {healthData.length === 0 ? (
        <div>
          <div style={{ marginLeft: "48%", height: "30px", marginTop: "15%" }}>
            <FadeLoader
              loading={loading}
              color={"#0092FB"}
              height={20}
              width={5}
            />
          </div>
          <div className='text-center' style={{ marginTop: "30px" }}>
            <h4>Your Email is in Process.Please Wait for 15-30 seconds.</h4>
            <br />
            <h5>Thanks for your Patience.</h5>
          </div>
        </div>
      ) : rawEmail ? (
        <div className='rawEmail p-3'>
          <pre>{healthData.health_check.raw_email}</pre>
        </div>
      ) : (
        <div className='container-fluid col-md-12 col-lg-12 col-xxl-12 col-10 mt-4'>
          <div className='accordion'>
            <Accordion
              name='Domain Blacklist Check'
              status={
                healthData === 0
                  ? ""
                  : healthData.health_check.domain_blacklist
                      .is_domain_blacklisted === true
                  ? "Bad"
                  : "Good"
              }
              color={
                healthData.length === 0
                  ? ""
                  : healthData.health_check.domain_blacklist
                      .is_domain_blacklisted === false
                  ? "rgba(229,242,229)"
                  : "rgba(255, 242, 238)"
              }
              StatusColor={
                healthData.length === 0
                  ? ""
                  : healthData.health_check.domain_blacklist
                      .is_domain_blacklisted === false
                  ? "green"
                  : "red"
              }
              path='/assets/img/gg_screen.png'
              bold_content={healthData.health_check.domain_blacklist.message}
              simple_content={
                healthData.health_check.domain_blacklist.description
              }
              detected_by={
                healthData.health_check.domain_blacklist.domain_detected_by
              }
              explanation='Certain blacklists can have a severe affect on the ability to reach the inbox. We have aggregated a list of ones known to be meaningful.'
              test={[healthData.health_check.domain_blacklist]}
            />
          </div>
          <div className='accordion'>
            <Accordion
              name='Spam Assasin Check'
              status={
                healthData.length === 0
                  ? ""
                  : healthData.health_check.spam_assassin_score.color ===
                    "green"
                  ? "Good"
                  : healthData.health_check.spam_assassin_score.color ===
                    "yellow"
                  ? "Warn"
                  : "Bad"
              }
              color={
                healthData.length === 0
                  ? ""
                  : healthData.health_check.spam_assassin_score.color ===
                    "green"
                  ? "rgba(229,242,229)"
                  : healthData.health_check.spam_assassin_score.color ===
                    "yellow"
                  ? "#FFBB00"
                  : "rgba(255, 242, 238)"
              }
              StatusColor={
                healthData.length === 0
                  ? ""
                  : healthData.health_check.spam_assassin_score.color ===
                    "green"
                  ? "green"
                  : healthData.health_check.spam_assassin_score.color ===
                    "yellow"
                  ? "#FFBB00"
                  : "red"
              }
              score={
                healthData.length !== 0 &&
                healthData.health_check.spam_assassin_score.spam_assassin_key
                  .spam_assassin_score
              }
              path='/assets/img/codicon-book.png'
              bold_content={healthData.health_check.spam_assassin_score.message}
              explanation='SpamAssassin is a widely used anti-spam platform and is a valuble proxy when gauging whether or not your email will be flagged as spam by major Email Service Providers.'
              test={[
                healthData.health_check.spam_assassin_score.spam_assassin_key,
              ]}
            />
          </div>
          <div className='accordion'>
            <Accordion
              name='SPF Check'
              status={
                healthData.length === 0
                  ? ""
                  : healthData.health_check.spf.spf === true
                  ? "Good"
                  : "Bad"
              }
              color={
                healthData.length === 0
                  ? ""
                  : healthData.health_check.spf.color === "green"
                  ? "rgba(229,242,229)"
                  : healthData.health_check.spf.color === "yellow"
                  ? "#FFBB00"
                  : "rgba(255, 242, 238)"
              }
              StatusColor={
                healthData.length === 0
                  ? ""
                  : healthData.health_check.spf.color === "yellow"
                  ? "#FFBB00"
                  : healthData.health_check.spf.color
              }
              path='/assets/img/thunder.png'
              bold_content={healthData.health_check.spf.message}
              explanation='A valid SPF record will prevent spammers from sending spam from your domain.'
              test={[
                healthData.health_check.spf.spf_mechanism_key,
                healthData.health_check.spf.spf_record_key,
                healthData.health_check.spf.duplicate_spf_record_key,
                healthData.health_check.spf.spf_auth_key,
                healthData.health_check.spf.spf_ptr_mechanism_key,
              ]}
            />
          </div>
          <div className='accordion'>
            <Accordion
              name='DKIM Check'
              status={
                healthData.length === 0
                  ? ""
                  : healthData.health_check.dkim.dkim === true
                  ? "Good"
                  : "Bad"
              }
              color={
                healthData.length === 0
                  ? ""
                  : healthData.health_check.dkim.color === "green"
                  ? "rgba(229,242,229)"
                  : "rgba(255, 242, 238)"
              }
              StatusColor={
                healthData.length === 0
                  ? ""
                  : healthData.health_check.dkim.color
              }
              score=''
              path='/assets/img/clipboard.png'
              bold_content={healthData.health_check.dkim.message_key.message}
              explanation='Publishing a DKIM key will will reduce the likelihood of your messages being blocked or ending up in the spam folder. It will also prevent spoofing by adding a digital signature to your email headers.'
              test={[
                healthData.health_check.dkim.dkim_signature_key,
                healthData.health_check.dkim.dkim_validity_key,
                healthData.health_check.dkim.domain_signature_key,
              ]}
            />
          </div>
          <div
            className='accordion'
            style={{
              filter:
                healthData.health_check.dmarc.dmarc === "block"
                  ? `blur(${"5px"})`
                  : "",
            }}
          >
            <Accordion
              styl
              name='DMARC Check'
              status={
                healthData.length === 0
                  ? ""
                  : healthData.health_check.dmarc.dmarc === "block"
                  ? ["Good", "Bad"][Math.floor(Math.random() * 2)]
                  : healthData.health_check.dmarc.dmarc === true
                  ? "Good"
                  : "Bad"
              }
              color={
                healthData.length === 0
                  ? ""
                  : healthData.health_check.dmarc.color === "block"
                  ? ["rgba(229,242,229)", "rgba(255, 242, 238)"][
                      Math.floor(Math.random() * 2)
                    ]
                  : healthData.health_check.dmarc.color === "green"
                  ? "rgba(229,242,229)"
                  : "rgba(255, 242, 238)"
              }
              StatusColor={
                healthData.length === 0
                  ? ""
                  : healthData.health_check.dmarc.color === "block"
                  ? ["green", "red"][Math.floor(Math.random() * 2)]
                  : healthData.health_check.dmarc.color
              }
              path='/assets/img/lock.png'
              bold_content={
                healthData.health_check.dmarc.msg_key.message === "block"
                  ? "Your DMARC Authentication Is Not Setup Using Best Practices"
                  : healthData.health_check.dmarc.msg_key.message
              }
              explanation='Without having a Requested Mail Receiver DMARC policy published, emails from your domain can be spoofed and sent on your behalf.'
              test={[
                healthData.health_check.dmarc.dmarc_policy,
                healthData.health_check.dmarc.dmarc_record_key,
                healthData.health_check.dmarc.dmarc_version_key,
                healthData.health_check.dmarc.domain_sign_key,
                healthData.health_check.dmarc.spf_align_key,
                healthData.health_check.dmarc.duplicate_dmarc_key,
              ]}
            />
          </div>
          <div
            className='accordion'
            style={{
              filter:
                healthData.domain_age_.color === "block"
                  ? `blur(${"5px"})`
                  : "",
            }}
          >
            <Accordion
              name='Domain age Check'
              status={
                healthData.length === 0
                  ? ""
                  : healthData.domain_age_.color === "block"
                  ? ["Good", "Bad", "Warn"][Math.floor(Math.random() * 3)]
                  : healthData.domain_age_.color === "green"
                  ? "Good"
                  : healthData.domain_age_.color === "yellow"
                  ? "Warn"
                  : "Bad"
              }
              color={
                healthData.length === 0
                  ? ""
                  : healthData.domain_age_ !== undefined
                  ? healthData.domain_age_.color === "block"
                    ? ["rgb(255,248,227)", "rgba(255, 242, 238)"][
                        Math.floor(Math.random() * 2)
                      ]
                    : healthData.domain_age_.color === "green"
                    ? "rgba(229,242,229)"
                    : healthData.domain_age_ !== undefined
                    ? healthData.domain_age_.color === "yellow"
                      ? "#FFBB00"
                      : "rgba(255, 242, 238)"
                    : ""
                  : ""
              }
              StatusColor={
                healthData.length === 0
                  ? ""
                  : healthData.domain_age_ !== undefined
                  ? healthData.domain_age_.color === "green"
                    ? "green"
                    : healthData.domain_age_ !== undefined
                    ? healthData.domain_age_.color === "yellow"
                      ? "#FFBB00"
                      : "red"
                    : ""
                  : ""
              }
              path='/assets/img/domain_age.png'
              bold_content={
                healthData.domain_age_ !== undefined
                  ? healthData.domain_age_.message
                  : ""
              }
              domain_age={
                healthData.domain_age_ !== undefined
                  ? healthData.domain_age_.domain_age_key
                  : ""
              }
              explanation='The younger your domain is, the more difficult it is to attain a great domain reputation.'
              test={[
                healthData.domain_age_ !== undefined
                  ? healthData.domain_age_.domain_age_key
                  : "",
              ]}
            />
          </div>
          <div
            className='accordion'
            style={{
              filter:
                healthData.health_check.domain_suffix.color === "block"
                  ? `blur(${"5px"})`
                  : "",
            }}
          >
            <Accordion
              name='Domain Suffix Check'
              status={
                healthData.length === 0
                  ? ""
                  : healthData.health_check.domain_suffix.color === "block"
                  ? ["Good", "Bad", "Warn"][Math.floor(Math.random() * 3)]
                  : healthData.health_check.domain_suffix.color === "green"
                  ? "Good"
                  : healthData.health_check.domain_suffix.color === "yellow"
                  ? "Warn"
                  : "Bad"
              }
              color={
                healthData.length === 0
                  ? ""
                  : healthData.health_check.domain_suffix.color === "block"
                  ? ["rgba(229,242,229)", "#FFBB00", "rgba(255, 242, 238)"][
                      Math.floor(Math.random() * 3)
                    ]
                  : healthData.health_check.domain_suffix.color === "green"
                  ? "rgba(229,242,229)"
                  : healthData.health_check.domain_suffix.color === "yellow"
                  ? "#FFBB00"
                  : "rgba(255, 242, 238)"
              }
              StatusColor={
                healthData.length === 0
                  ? ""
                  : healthData.health_check.domain_suffix.color === "block"
                  ? ["green", "#FFBB00", "red"][Math.floor(Math.random() * 3)]
                  : healthData.health_check.domain_suffix.color === "green"
                  ? "green"
                  : healthData.health_check.domain_suffix.color === "yellow"
                  ? "#FFBB00"
                  : "red"
              }
              path='/assets/img/domain_suffix.png'
              bold_content={
                healthData.health_check.domain_suffix.message === "block"
                  ? "Your Domain Uses A Trustworthy Suffix!"
                  : healthData.health_check.domain_suffix.message
              }
              explanation='Certain domain suffixes are less likely to reach the inbox.'
              test={[healthData.health_check.domain_suffix.domain_suffix_key]}
            />
          </div>
          <div
            className='accordion'
            style={{
              filter:
                healthData.health_check.ip_blacklist.color === "block"
                  ? `blur(${"5px"})`
                  : "",
            }}
          >
            <Accordion
              name='IP Blacklist Check'
              status={
                healthData === 0
                  ? ""
                  : healthData.health_check.ip_blacklist.color === "block"
                  ? ["Good", "Warn", "Bad"][Math.floor(Math.random() * 3)]
                  : healthData.health_check.ip_blacklist.color === "green"
                  ? "Good"
                  : healthData.health_check.ip_blacklist.color === "yellow"
                  ? "Warn"
                  : "Bad"
              }
              color={
                healthData.length === 0
                  ? ""
                  : healthData.health_check.ip_blacklist.color === "block"
                  ? ["rgba(229,242,229)", "#FFBB00", "rgba(255, 242, 238)"][
                      Math.floor(Math.random() * 3)
                    ]
                  : healthData.health_check.ip_blacklist.color === "green"
                  ? "rgba(229,242,229)"
                  : healthData.health_check.ip_blacklist.color === "yellow"
                  ? "#FFBB00"
                  : "rgba(255, 242, 238)"
              }
              StatusColor={
                healthData.length === 0
                  ? ""
                  : healthData.health_check.ip_blacklist.color === "block"
                  ? ["green", "#FFBB00", "red"][Math.floor(Math.random() * 3)]
                  : healthData.health_check.ip_blacklist.color === "green"
                  ? "green"
                  : healthData.health_check.ip_blacklist.color === "yellow"
                  ? "#FFBB00"
                  : "red"
              }
              path='/assets/img/ip_blacklist.png'
              bold_content={
                healthData.health_check.ip_blacklist.message === "block"
                  ? "Your Mail Server IP Address Is Partially Blacklisted"
                  : healthData.health_check.ip_blacklist.message
              }
              detected_by={healthData.health_check.ip_blacklist.ip_detected_by}
              explanation='Not only can your domain be blacklisted, but the IP address from which you send your emails can as well. If this "Mail Server IP" is blacklisted it can cause your messages to be rejected or flagged as spam. It is important to get delisted and identify the root cause of why you were listed.If you use any tools/softwares to send out emails, it is possible that the shared or dedicated IP address being used by these is on a blacklist. Certain blacklists can have a severe affect on the ability to reach the inbox. We have aggregated a list of ones known to be meaningful.'
              test={[healthData.health_check.ip_blacklist]}
            />
          </div>
          <div className='accordion'>
            <Accordion
              name='Short URLs Check'
              status={
                healthData === 0
                  ? ""
                  : healthData.health_check.short_urls.color === "green"
                  ? "Good"
                  : "Bad"
              }
              color={
                healthData.length === 0
                  ? ""
                  : healthData.health_check.short_urls.color === "green"
                  ? "rgba(229,242,229)"
                  : "rgba(255, 242, 238)"
              }
              StatusColor={
                healthData.length === 0
                  ? ""
                  : healthData.health_check.short_urls.color === "green"
                  ? "green"
                  : "red"
              }
              path='/assets/img/short_url.png'
              bold_content={healthData.health_check.short_urls.message}
              explanation='Using shortened URLs to track receiver behavior can cause spam filters to flag your email.'
              test={[healthData.health_check.short_urls.short_url_key]}
            />
          </div>
          <div className='accordion'>
            <Accordion
              name='Broken Links Check'
              status={
                healthData === 0
                  ? ""
                  : healthData.health_check.broken_links.color === "green"
                  ? "Good"
                  : "Bad"
              }
              color={
                healthData.length === 0
                  ? ""
                  : healthData.health_check.broken_links.color === "green"
                  ? "rgba(229,242,229)"
                  : "rgba(255, 242, 238)"
              }
              StatusColor={
                healthData.length === 0
                  ? ""
                  : healthData.health_check.broken_links.color === "green"
                  ? "green"
                  : "red"
              }
              path='/assets/img/broken_links.png'
              bold_content={healthData.health_check.broken_links.message}
              explanation='Having broken links in your email body can cause Email Service Providers to mark your message as spam.'
              test={[healthData.health_check.broken_links.broken_link_key]}
            />
          </div>
          <div className='accordion'>
            <Accordion
              name='Subject Line Check'
              status={
                healthData === 0
                  ? ""
                  : healthData.health_check.subject.color === "green"
                  ? "Good"
                  : "Bad"
              }
              color={
                healthData.length === 0
                  ? ""
                  : healthData.health_check.subject.color === "green"
                  ? "rgba(229,242,229)"
                  : "rgba(255, 242, 238)"
              }
              StatusColor={
                healthData.length === 0
                  ? ""
                  : healthData.health_check.subject.color
              }
              path='/assets/img/subject_line.png'
              bold_content={healthData.health_check.subject.message}
              explanation='The subject line is parsed and analyzed by spam filters and can cause your email to miss the inbox.'
              test={[
                healthData.health_check.subject.gappy_text,
                healthData.health_check.subject.to_address_in_subject,
                healthData.health_check.subject.irregular_char_in_subject_key,
                healthData.health_check.subject.spam_phrase_key,
              ]}
            />
          </div>
          <div
            className='accordion'
            style={{
              filter:
                healthData.health_check.rDNS.color === "block"
                  ? `blur(${"5px"})`
                  : "",
            }}
          >
            <Accordion
              name='Reverse DNS Check'
              status={
                healthData === 0
                  ? ""
                  : healthData.health_check.rDNS.color === "block"
                  ? ["Good", "Bad"][Math.floor(Math.random() * 2)]
                  : healthData.health_check.rDNS.color === "green"
                  ? "Good"
                  : "Bad"
              }
              color={
                healthData.length === 0
                  ? ""
                  : healthData.health_check.rDNS.color === "block"
                  ? ["rgba(229,242,229)", "rgba(255, 242, 238)"][
                      Math.floor(Math.random() * 2)
                    ]
                  : healthData.health_check.rDNS.color === "green"
                  ? "rgba(229,242,229)"
                  : "rgba(255, 242, 238)"
              }
              StatusColor={
                healthData.length === 0
                  ? ""
                  : healthData.health_check.rDNS.color === "block"
                  ? ["green", "red"][Math.floor(Math.random() * 2)]
                  : healthData.health_check.rDNS.color
              }
              path='/assets/img/reverse_dns.png'
              bold_content={
                healthData.health_check.rDNS.message === "block"
                  ? "Your Mail Server Reverse DNS Is Properly Configured."
                  : healthData.health_check.rDNS.message
              }
              explanation='Email Service Providers like Gmail, Yahoo, etc. may block emails coming from a mail server that does not have Reverse DNS in place.'
              test={[
                healthData.health_check.rDNS.rdns_key,
                healthData.health_check.rDNS.rdns_helo_value,
              ]}
            />
          </div>
          <div
            className='accordion'
            style={{
              filter:
                healthData.health_check.unsubscribe_header.color === "block"
                  ? `blur(${"5px"})`
                  : "",
            }}
          >
            <Accordion
              name='List-Unsubscribe Header Check'
              status={
                healthData === 0
                  ? ""
                  : healthData.health_check.unsubscribe_header.color === "block"
                  ? ["Good", "Bad", "Warn"][Math.floor(Math.random() * 3)]
                  : healthData.health_check.unsubscribe_header.color === "green"
                  ? "Good"
                  : healthData.health_check.unsubscribe_header.color ===
                    "yellow"
                  ? "Warn"
                  : "Bad"
              }
              color={
                healthData.length === 0
                  ? ""
                  : healthData.health_check.unsubscribe_header.color === "block"
                  ? ["rgba(229,242,229)", "#FFBB00", "rgba(255, 242, 238)"][
                      Math.floor(Math.random() * 3)
                    ]
                  : healthData.health_check.unsubscribe_header.color === "green"
                  ? "rgba(229,242,229)"
                  : healthData.health_check.unsubscribe_header.color ===
                    "yellow"
                  ? "#FFBB00"
                  : "rgba(255, 242, 238)"
              }
              StatusColor={
                healthData.length === 0
                  ? ""
                  : healthData.health_check.unsubscribe_header.color === "block"
                  ? ["green", "#FFBB00", "red"][Math.floor(Math.random() * 3)]
                  : healthData.health_check.unsubscribe_header.color === "green"
                  ? "green"
                  : healthData.health_check.unsubscribe_header.color ===
                    "yellow"
                  ? "#FFBB00"
                  : "red"
              }
              path='/assets/img/list_unsubscribe.png'
              bold_content={
                healthData.health_check.unsubscribe_header.message === "block"
                  ? "Your Email Is Missing The List-Unsubscribe Header"
                  : healthData.health_check.unsubscribe_header.message
              }
              explanation='Email Service Providers like Gmail, Yahoo, etc. may block emails coming from a mail server that does not have Reverse DNS in place.'
              test={[
                healthData.health_check.unsubscribe_header.unsubscribe_key,
              ]}
            />
          </div>
          <div
            className='accordion'
            style={{
              filter:
                healthData.health_check.html_best_practise.color === "block"
                  ? `blur(${"5px"})`
                  : "",
            }}
          >
            <Accordion
              name='HTML Best Practice Check'
              status={
                healthData === 0
                  ? ""
                  : healthData.health_check.html_best_practise.color === "block"
                  ? ["Good", "Bad"][Math.floor(Math.random() * 2)]
                  : healthData.health_check.html_best_practise.color === "green"
                  ? "Good"
                  : healthData.health_check.html_best_practise.color ===
                    "yellow"
                  ? "Good"
                  : "Bad"
              }
              color={
                healthData.length === 0
                  ? ""
                  : healthData.health_check.html_best_practise.color === "block"
                  ? ["rgba(229,242,229)", "#FFBB00", "rgba(255, 242, 238)"][
                      Math.floor(Math.random() * 3)
                    ]
                  : healthData.health_check.html_best_practise.color === "green"
                  ? "rgba(229,242,229)"
                  : healthData.health_check.html_best_practise.color ===
                    "yellow"
                  ? "#FFBB00"
                  : "rgba(255, 242, 238)"
              }
              StatusColor={
                healthData.length === 0
                  ? ""
                  : healthData.health_check.html_best_practise.color === "block"
                  ? ["green", "#FFBB00", "red"][Math.floor(Math.random() * 3)]
                  : healthData.health_check.html_best_practise.color === "green"
                  ? "green"
                  : healthData.health_check.html_best_practise.color ===
                    "yellow"
                  ? "#FFBB00"
                  : "red"
              }
              path='/assets/img/html-best-practice.png'
              bold_content={
                healthData.health_check.html_best_practise.message === "block"
                  ? "Your Message Body Is Using HTML Best Practices."
                  : healthData.health_check.html_best_practise.message
              }
              explanation='Email Service Providers like Gmail, Yahoo, etc. may block emails coming from a mail server that does not have Reverse DNS in place.'
              test={[
                healthData.health_check.html_best_practise.long_urls_key,
                healthData.health_check.html_best_practise.to_many_url_key,
              ]}
            />
          </div>
          <div
            className='accordion'
            style={{
              filter:
                healthData.health_check.blacklisted_url.color === "block"
                  ? `blur(${"5px"})`
                  : "",
            }}
          >
            <Accordion
              name='Blacklisted Url Check'
              status={
                healthData === 0
                  ? ""
                  : healthData.health_check.blacklisted_url.color === "block"
                  ? ["Good", "Bad"][Math.floor(Math.random() * 2)]
                  : healthData.health_check.blacklisted_url.color === "green"
                  ? "Good"
                  : healthData.health_check.blacklisted_url.color === "yellow"
                  ? "Good"
                  : "Bad"
              }
              color={
                healthData.length === 0
                  ? ""
                  : healthData.health_check.blacklisted_url.color === "block"
                  ? ["rgba(229,242,229)", "#FFBB00", "rgba(255, 242, 238)"][
                      Math.floor(Math.random() * 3)
                    ]
                  : "green"
                  ? "rgba(229,242,229)"
                  : healthData.health_check.blacklisted_url.color === "yellow"
                  ? "#FFBB00"
                  : "rgba(255, 242, 238)"
              }
              StatusColor={
                healthData.length === 0
                  ? ""
                  : healthData.health_check.blacklisted_url.color === "block"
                  ? ["green", "#FFBB00", "red"][Math.floor(Math.random() * 3)]
                  : healthData.health_check.blacklisted_url.color === "green"
                  ? "green"
                  : healthData.health_check.blacklisted_url.color === "yellow"
                  ? "#FFBB00"
                  : "red"
              }
              path='/assets/img/blacklisted-url.png'
              bold_content={
                healthData.health_check.blacklisted_url.message === "block"
                  ? "Your Email Body Does Not Include Any Blacklisted URLs!"
                  : healthData.health_check.blacklisted_url.message
              }
              explanation='Email Service Providers like Gmail, Yahoo, etc. may block emails coming from a mail server that does not have Reverse DNS in place.'
              test={[
                healthData.health_check.blacklisted_url.blacklisted_url_key,
              ]}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default DomainHealth
