import moment from 'moment'
import LoadingBar from 'react-top-loading-bar'
import { useDispatch, connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { logout } from '../../../store/actions/auth'
import React, { useState, useRef, useEffect } from 'react'

import NotificationService from '../../../api-services/notificationService'
import { ReactComponent as NotifIcon } from '../../Assets/icons/notif-icon.svg'
import { ReactComponent as LogoutIcon } from '../../Assets/icons/logout-dropdown-icon.svg'

const Header = ({ history, user, toggled }) => {
	const ref = useRef(null)

	const dispatch = useDispatch()

	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(false)
	const [unreadCount, setUnreadCount] = useState(0)
	const [unreadFlag, setUnreadFlag] = useState(false)
	const [notifications, setNotifications] = useState([])

	const handleLogout = async event => {
		event.preventDefault()
		setLoading(true)
		ref.current.continuousStart()
		try {
			await dispatch(logout(history))
			history.push('/login')
		} catch (e) {
			setError(e)
		} finally {
			setLoading(false)
			if (ref.current) ref.current.complete()
		}
	}

	const fetchNotifications = async () => {
		let tempArr = []
		const response = await NotificationService.list()

		if (response.success) {
			let tempCount = 0

			response.messages.forEach(message => {
				if (!message.view_message && !message.is_admin) {
					tempCount += 1
					setUnreadFlag(true)
				}

				tempArr.push(message)
			})
			setUnreadCount(tempCount)
			setNotifications(tempArr.reverse())
		} else {
			console.log(response.message)
		}
	}

	const handleMarkAllAsRead = async () => {
		const response = await NotificationService.markAllAsRead()

		if (response.success) {
			setUnreadCount(0)
			setUnreadFlag(false)
			fetchNotifications()
		}
	}

	useEffect(() => {
		fetchNotifications()
	}, [])

	return (
		<>
			<LoadingBar height={5} color='#5D95EA' ref={ref} />
			<header className='header color-white fixed-top'>
				<div className='toprow'>
					<figure className='align-items-center d-flex logo'>
						<Link to='/'>
							<img
								alt='logo'
								className='img-fluid lg-logo'
								src='/assets/img/logo-dashboard.jpg'
							/>
							<img
								alt='logo'
								src='/assets/img/sm-logo.jpg'
								className='img-fluid sm-logo'
							/>
						</Link>
					</figure>
					<div className='top-bar align-items-center d-flex justify-content-between'>
						<div className='align-items-center col-3 col-lg-5 col-md-6 col-sm-2 d-flex pl-0 header-icons'>
							<div className='backlink' onClick={toggled}>
								<a className='menuarrow' style={{ cursor: 'pointer' }}>
									<img
										src='/assets/img/arrow.jpg'
										alt='logo'
										className='img-fluid lg-logom'
									/>
								</a>
							</div>
						</div>
						<div className='col-lg-4 col-sm-9 col-md-6 col-9 userlinks'>
							<ul className='align-items-center justify-content-end d-flex list-unstyled mb-0 userlist'>
								<li className='nav-item dropdown drop-end userdropdown ntifction-dropdown'>
									<a
										href='#'
										aria-expanded='true'
										id='usernotification'
										data-toggle='dropdown'
										data-bs-display='static'
										className='align-items-center d-flex position-relative nav-link dropdown-toggle'
									>
										<NotifIcon />
										{unreadCount === 0 ? (
											<></>
										) : (
											<span
												style={{
													left: '27px',
													bottom: '31px',
													padding: '6px',
												}}
												class='position-absolute translate-middle bg-danger border border-light rounded-pill'
											></span>
										)}
									</a>
									<div
										style={{
											maxWidth: '320px',
										}}
										className='dropdown-menu dropdown-menu-lg-end dropdown-menu-md-end dropdown-menu-sm-end dropdown-menu-lg-end shadow px-2 py-2 rounded'
									>
										<b>Notifications</b>
										<div
											className='p-1'
											id='notification-area'
											style={{ maxHeight: '300px', overflowY: 'auto' }}
										>
											{notifications.length === 0 ? (
												<p className='text-center'>No Notifications</p>
											) : (
												notifications
													.filter(notification => !notification.is_admin)
													.map((notification, i) => (
														<div
															key={i}
															data-bs-placement='top'
															data-bs-toggle='tooltip'
															style={{
																maxWidth: '300px',
															}}
															title={notification.global_messages[0].message}
														>
															<div
																className='my-1 p-1 rounded'
																style={{
																	display: 'flex',
																	cursor: 'default',
																	alignItems: 'end',
																	flexDirection: 'row',
																	transition: 'all 0.2s linear',
																	justifyContent: 'space-between',
																	borderRight: !notification.view_message
																		? '4px solid red'
																		: '',
																	backgroundColor: !notification.view_message
																		? '#ffe6e6'
																		: '',
																}}
															>
																<div className='d-flex flex-column flex-fill justify-content-start'>
																	<div
																		className='flex-fill'
																		style={{
																			fontWeight: 'bold',
																		}}
																	>
																		{notification.global_messages[0].subject}
																	</div>
																	<div
																		className=' flex-fill notifi-desc'
																		style={{
																			maxWidth: '184px',
																			overflow: 'hidden',
																			textOverflow: 'ellipsis',
																		}}
																	>
																		{notification.global_messages[0].message}
																	</div>
																</div>
																<div className='d-flex flex-fill justify-content-end'>
																	<span
																		className='d-flex flex-fill notifi-date fontmed justify-content-end'
																		style={{
																			color: !notification.view_message
																				? '#ff3333'
																				: '#00b6ff',
																		}}
																	>
																		{moment(
																			moment.utc(
																				notification.global_messages[0]
																					.created_at
																			),
																			'YYYYMMDD'
																		).fromNow()}
																	</span>
																</div>
															</div>
															<hr className='my-0' />
														</div>
													))
											)}
										</div>
										{unreadFlag ? (
											<div
												onClick={handleMarkAllAsRead}
												style={{
													transition: 'all 0.2s linear',
													pointerEvents: unreadFlag ? 'initial' : 'none',
												}}
												className={`${
													unreadFlag ? 'dropdown-item rounded' : ''
												}`}
											>
												<p
													className={`my-0 pt-0 text-center ${
														unreadFlag ? '' : 'text-muted'
													}`}
													style={{
														fontWeight: 'bold',
														cursor: unreadFlag ? 'pointer' : 'default',
													}}
												>
													Mark all as read
												</p>
											</div>
										) : (
											<></>
										)}
									</div>
								</li>
								<li className='nav-item dropdown prof-dropdown'>
									<a
										href='#'
										id='profilelink'
										data-toggle='dropdown'
										style={{ transition: 'all 1s linear' }}
										className='align-items-center d-flex dropdown-toggle nav-link profilelink'
									>
										<span className='username text-center'> {user.name} </span>
										{user.avatar === 'default.png' ? (
											<div
												className='col-md-3 acc-icon'
												style={{ textAlign: 'center', paddingTop: '3px' }}
											>
												<span className='text-capitalize'>
													{user.name.slice(0, 1)}
												</span>
											</div>
										) : (
											<img
												alt='user image'
												src={user.avatar}
												className='img-fluid rounded-circle img-fluid profileimg'
											/>
										)}
									</a>
									<div
										id='user-dropdown'
										className='dropdown-menu shadow rounded px-0 py-2 mt-0'
									>
										<div className='col px-2'>
											<div className='row px-1 mx-0 d-flex align-items-center justify-content-evenly'>
												{user.avatar === 'default.png' ? (
													<div
														className='col-md-3 acc-icon'
														style={{ textAlign: 'center', paddingTop: '3px' }}
													>
														<span className='text-capitalize'>
															{user.name.slice(0, 1)}
														</span>
													</div>
												) : (
													<img
														alt='user image'
														src={user.avatar}
														className='img-fluid rounded-circle img-fluid profileimg'
													/>
												)}
												<div className='ml-2'>
													<div className='row'>
														<div className='col'>
															<p
																className='text-capitalize mb-0'
																style={{ fontSize: '15px', fontWeight: '700' }}
															>
																{user.name}
															</p>
														</div>
													</div>
													<div className='row'>
														<div className='col'>
															<p
																className='text-muted mb-0'
																style={{ fontSize: '14px' }}
															>
																{user.email}
															</p>
														</div>
													</div>
												</div>
											</div>
											<hr />
											<div
												onClick={handleLogout}
												className='dropdown-item pr-0'
												style={{
													cursor: 'pointer',
													transition: 'all 0.2s linear',
												}}
											>
												<LogoutIcon
													className='mr-3 pl-1'
													style={{ transform: 'scale(1.5)' }}
												/>
												<b>Logout</b>
											</div>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</header>
		</>
	)
}

function mapStateToProps(state) {
	return {
		user: state.authReducer.user,
	}
}

export default connect(mapStateToProps, null)(withRouter(Header))
