import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

const Layout = ({ children, location, ...rest }) => {
  return <>{children}</>
}

Layout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
}

export default connect(null, null)(withRouter(Layout))
