import './support.scss'
import { toast } from 'react-toastify'
import { Editor } from 'react-draft-wysiwyg'
import LoadingBar from 'react-top-loading-bar'
import { EditorState, convertToRaw } from 'draft-js'
import React, { useState, useEffect, useRef } from 'react'
import TicketService from '../../api-services/ticketService'
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'

const SupportModal = ({ setTickets }) => {
	const ref = useRef(null)

	const [ticket, setTicket] = useState({
		title: '',
		description: '',
		image_file: null,
	})
	const [errors, setErrors] = useState([])
	const [submitting, setSubmiting] = useState(false)
	const [subjectValue, setSubjectValue] = useState('')
	const [editorStateExp, setEditorStateExp] = useState(
		EditorState.createEmpty()
	)
	const [explanationValue, setExplanationValue] = useState('')

	const onEditorStateChangeExp = editorStateExp => {
		setEditorStateExp(editorStateExp)
	}

	const handleOnChange = e => {
		if (e.target.name === 'image_file')
			setTicket({
				...ticket,
				[e.target.name]: e.target.files[0],
			})
		else
			setTicket({
				...ticket,
				[e.target.name]: e.target.value,
			})
	}

	const handleSubmit = async () => {
		setSubmiting(true)

		const ticketFormData = new FormData()

		ticketFormData.append('title', ticket.title)
		ticketFormData.append('image_file', ticket.image_file)
		ticketFormData.append('description', ticket.description)

		try {
			const response = await TicketService.create(ticketFormData)

			toast(response.message, {
				type: 'success',
				autoClose: 5000,
				closeOnClick: true,
				position: 'top-right',
			})

			setTicket({ ...ticket, title: '', description: '', image_file: null })
			setEditorStateExp(EditorState.createEmpty())
			setErrors([])

			const listResponse = await TicketService.list()

			setTickets(listResponse.supportTickets.data)

			const modal = document.getElementById('supportModal')
			document.body.classList.remove('modal-open')
			document.body.removeAttribute('style')
			let header = document.getElementsByClassName('header')
			header[0].removeAttribute('style')
			modal.classList.remove('show')
			modal.setAttribute('aria-hidden', 'true')
			modal.style.display = 'none'
			modal.setAttribute('aria-modal', 'false')
			modal.setAttribute('data-bs-dismiss', 'modal')
			const modalBackdrops = document.getElementsByClassName('modal-backdrop')
			modalBackdrops[0].classList.remove('show')
			document.body.removeChild(modalBackdrops[0])
		} catch (e) {
			setErrors(e.response.data.errors)
			toast(e.response.data.message, {
				type: 'error',
				autoClose: 5000,
				closeOnClick: true,
				position: 'top-right',
			})
		} finally {
			setSubmiting(false)
		}
	}

	useEffect(() => {
		const block = draftToHtml(convertToRaw(editorStateExp.getCurrentContent()))
		setTicket({
			...ticket,
			description: block,
			// .map(block => (!block.text.trim() && '\n') || block.text)
			// .join('\n'),
		})
	}, [editorStateExp])

	return (
		<>
			<LoadingBar height={5} color='#5D95EA' ref={ref} />
			<div
				tabIndex='-1'
				id='supportModal'
				aria-labelledby='exampleModalLabel'
				className='modal fade add-inbox-modal'
			>
				<div
					className='modal-dialog modal-dialog-centered'
					style={{ maxWidth: '1230px' }}
				>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title' id='exampleModalLabel'>
								Add Ticket
								<img
									width='30px'
									height='30px'
									data-dismiss='modal'
									className='float-right'
									style={{ cursor: 'pointer' }}
									src='/assets/img/close-icon.svg'
								/>
							</h5>
						</div>
						<div className='modal-body' style={{ height: 'auto' }}>
							<div className='row'>
								<div className='col-12 mt-3'>
									<div className='row mb-2'>
										<div className='col'>
											<label htmlFor='title'>
												<span className='text-danger'>* </span>Title
											</label>
											<input
												type='text'
												name='title'
												value={ticket.title}
												// placeholder='Subject...'
												onChange={handleOnChange}
												className='editorStyleSub'
											/>
											{errors.hasOwnProperty('title') ? (
												<span className='text-danger mt-2 ml-1'>
													{' '}
													{errors['title']}{' '}
												</span>
											) : (
												''
											)}
										</div>
									</div>
									<div className='row mb-2'>
										<div className='col'>
											<label htmlFor='description'>Description</label>
											<Editor
												toolbarOnFocus
												toolbar={{
													options: [
														'inline',
														'blockType',
														'fontSize',
														'fontFamily',
														'list',
														'textAlign',
														'emoji',
														'image',
														'remove',
														'history',
													],
												}}
												spellCheck={true}
												name='description'
												editorState={editorStateExp}
												placeholder='Explanation...'
												wrapperClassName='wrapperStyle'
												editorClassName='editorStyleExp'
												onEditorStateChange={onEditorStateChangeExp}
											/>
											{errors.hasOwnProperty('description') ? (
												<span className='text-danger mt-2 ml-1'>
													{' '}
													{errors['description']}{' '}
												</span>
											) : (
												''
											)}
										</div>
									</div>
									<div className='row mb-2'>
										<div className='col'>
											<input
												type='file'
												accept='image/*'
												name='image_file'
												onChange={handleOnChange}
											/>
										</div>
									</div>
									<div className='row'>
										<div className='col'>
											{ticket.image_file === null ? (
												<p className='text-center'>
													Select an image to preview...
												</p>
											) : (
												<img
													className='img-thumbnail text-center'
													src={URL.createObjectURL(ticket.image_file)}
												/>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='modal-footer'>
							<button
								disabled={submitting}
								onClick={handleSubmit}
								className='btn btn-secondary bg-primary mt-3 ml-0'
							>
								Submit
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default SupportModal
