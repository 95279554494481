import API from "./api"

const FeatureService = {
  getFeatureConsumptionCount: (data) => {
    return API.get(`/features-count?warmup=${data.warmup}&domainHealth=${data.domainHealth}&spamchecker=${data.spamchecker}&emailgrader=${data.emailgrader}&sequences=${data.sequences}`)
      .then(({ data }) => {
        return data
      })
      .catch((err) => {
        throw err
      })
  }
}

export default FeatureService
