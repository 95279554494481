import React from 'react'
import './affiliate.scss'
import HelpComponent from '../../components/Help/Help'

const PaymentsInfo = () => {
	return (
		<>
			<div className='card'>
				<div className='card-body p-0'>
					<div className='d-flex align-items-center p-4'>
						<div className='shld col-sm-2 text-center p-1'>
							<img src='/assets/img/payment-info-blue.png' alt='warm-up' />
						</div>
						<div className='col-sm-7'>
							<div className='mt-2 text-bold heading'>
								Payments info &nbsp;
								<span>
									<HelpComponent
										filePath={'/help-fragments/3-warmupHelp.html'}
									/>
								</span>
							</div>
							<p className='sub-heading'>
								Fillout details for recieving payouts
							</p>
						</div>
					</div>
				</div>
				<div id='divider' className='mx-4' />
				<div className='d-flex flex-row p-4'>
					{/* <form> */}
					<div className='d-flex flex-column flex-shrink-0 justify-content-evenly  mr-2'>
						<label htmlFor='country' className='col-form-label mb-2'>
							Bank Country <span className='required-symbol'>*</span>
						</label>
						<label htmlFor='inputName' className='col-form-label mb-2'>
							Account Holder Name <span className='required-symbol'>*</span>
						</label>
						<label htmlFor='inputCode' className='col-form-label mb-2'>
							BSD Code <span className='required-symbol'>*</span>
						</label>
						<label htmlFor='inputNumber' className='col-form-label mb-2'>
							Account Number <span className='required-symbol'>*</span>
						</label>
					</div>
					<div className='d-flex flex-column'>
						{/* <label
								htmlFor='country'
								className='flex-shrink-0 col-form-label mr-4'
							>
								Bank Country <span className='required-symbol'>*</span>
							</label> */}
						{/* <div className=''> */}
						<input
							type='text'
							required
							className='form-control mb-2'
							id='country'
							placeholder='Pakistan'
						/>
						{/* </div> */}
						{/* </div>
						<div className='mb-4'> */}
						{/* <label
								htmlFor='inputName'
								className='flex-shrink-0 col-form-label mr-4'
							>
								Account Holder Name <span className='required-symbol'>*</span>
							</label> */}
						{/* <div className=''> */}
						<input
							type='text'
							className='form-control mb-2'
							id='inputName'
							required
							placeholder='Jhon Doe'
						/>
						{/* </div> */}
						{/* </div>
						<div className='mb-4'> */}
						{/* <label htmlFor='inputCode' className='col-sm-2 col-form-label'>
								BSD Code <span className='required-symbol'>*</span>
							</label> */}
						{/* <div className=''> */}
						<input
							type='text'
							className='form-control mb-2'
							id='inputCode'
							required
							placeholder='123456'
							// style={{ padding: '23px' }}
						/>
						{/* </div>
						</div>
						<div className='mb-4'> */}
						{/* <label htmlFor='inputNumber' className='col-sm-2 col-form-label'>
								Account Number <span className='required-symbol'>*</span>
							</label> */}
						{/* <div className=''> */}
						<input
							type='text'
							className='form-control mb-2'
							id='inputNumber'
							required
							placeholder='01234567891212345'
							// style={{ padding: '23px' }}
						/>
						<button className='btn btn-primary btn-sm submit-btn'>Save</button>
						{/* </div> */}
					</div>
					<div className='d-flex flex-column text-right mt-3'>
						<div className='col-sm-7'></div>
					</div>
					{/* </form> */}
				</div>
			</div>
		</>
	)
}

export default PaymentsInfo
