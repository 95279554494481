import './affiliate.scss'
import React, { useState } from 'react'
import PerformanceTable from './PerformanceTable'
import HelpComponent from '../../components/Help/Help'

const PerformanceDetails = ({ myAffiliates }) => {
	const [period, setPeriod] = useState('last7Days')

	const handleTab = e => {
		if (e.target.id === 'last7Days') {
			setPeriod('last7Days')
		} else if (e.target.id === 'lastMonth') {
			setPeriod('lastMonth')
		} else if (e.target.id === 'last6Month') {
			setPeriod('last6Month')
		}
	}
	return (
		<>
			<div className='card'>
				<div className='card-body p-0'>
					<div className='d-flex align-items-center p-4'>
						<div className='shld col-sm-2 text-center p-1'>
							<img
								src='/assets/img/performance-detail-blue.png'
								alt='warm-up'
							/>
						</div>
						<div className='col-sm-7'>
							<div className='mt-2 text-bold heading'>
								Performance Details &nbsp;
								<span>
									<HelpComponent
										filePath={'/help-fragments/3-warmupHelp.html'}
									/>
								</span>
							</div>
							<p className='sub-heading'>
								View your stats as an affiliate
							</p>
						</div>
					</div>
					<div className='row'>
						<div className='col d-flex justify-content-end'>
							<div className='aff_email_menu'>
								<ul>
									<li onClick={handleTab}>
										<div
											className={
												period === 'last7Days' ? 'nav_item_active' : 'nav_item'
											}
											id='last7Days'
										>
											<span
												id='last7Days'
												style={{
													color: period === 'last7Days' ? '#0994F8' : 'black',
												}}
											>
												Last 7 Days
											</span>
										</div>
									</li>
									<li onClick={handleTab}>
										<div
											className={
												period === 'lastMonth' ? 'nav_item_active' : 'nav_item'
											}
											id='lastMonth'
										>
											<span
												id='lastMonth'
												style={{
													color: period === 'lastMonth' ? '#0994F8' : 'black',
												}}
											>
												Last Month
											</span>
										</div>
									</li>
									<li onClick={handleTab}>
										<div
											className={
												period === 'last6Month' ? 'nav_item_active' : 'nav_item'
											}
											id='last6Month'
										>
											<span
												id='last6Month'
												style={{
													color: period === 'last6Month' ? '#0994F8' : 'black',
												}}
											>
												Last 6 Months
											</span>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div id='divider' className='mx-4' />

				<div className='row mt-3 p-0'>
					<PerformanceTable myAffiliates={myAffiliates} />
				</div>
			</div>
		</>
	)
}

export default PerformanceDetails
