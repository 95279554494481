import {
	API_CALL_IN_PROGRESS,
	API_CALL_NOT_IN_PROGRESS,
} from '../../store/types'
import moment from 'moment'
import './spamChecker.scss'
import { Modal } from 'bootstrap'
import Table from '../../atoms/Table'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import '../WarmUpEmails/WampUpEmails.scss'
import { Header } from '../../atoms/Header'
import { Layout } from '../../atoms/Layout'
import Pagination from 'react-js-pagination'
import '../../components/Filter/filter.scss'
import { NotFound } from '../../atoms/NotFound'
import { DropDown } from '../../atoms/DropDown'
import { connect, useDispatch } from 'react-redux'
import ValidateEmail from '../../Helper/validator'
import FadeLoader from 'react-spinners/FadeLoader'
import { GetStarted } from '../../atoms/GetStarted'
import Filter from '../../components/Filter/Filter'
import { convertToRaw, EditorState } from 'draft-js'
import { DeleteModal } from '../../atoms/DeleteModal'
import React, { useState, useEffect, useRef } from 'react'
import warmupService from '../../api-services/warmupService'
import { AddEmailModal } from './addEmailModal/AddEmailModal'
import FeatureService from '../../api-services/featuresService'
import { ReactComponent as PausedIcon } from '../../components/Assets/icons/paused-icon.svg'
import { ReactComponent as ActiveIcon } from '../../components/Assets/icons/active-icon.svg'

const tableHeader = [
	{
		th: 'Inbox',
		sort: true,
	},
	{
		th: 'Emails',
		sort: true,
	},
	{
		th: 'Inboxed',
		sort: true,
	},
	{
		th: 'Important',
		sort: true,
	},
	{
		th: 'Spammed',
		sort: true,
	},
	{
		th: 'Created at',
		sort: true,
	},
	{
		th: 'Status',
		sort: true,
	},
	{
		th: 'Actions',
		sort: false,
	},
]
const InboxSpamChecker = ({ apiCallInProgress, setStep, setShowNav }) => {
	const ref = useRef(null)
	const dispatch = useDispatch()

	const [testId, setTestId] = useState(0)
	const [copy, setCopy] = useState(false)
	const [errors, setErrors] = useState({})
	const [formData, setFormData] = useState({
		password: '',
		to_email: '',
		email_body: '',
		from_email: '',
		subject_line: '',
		inbox_providers: [],
	})
	const [noData, setNoData] = useState(false)
	const [loading, setLoading] = useState(false)
	const [emailList, setEmailList] = useState([])
	const [activePage, setActivePage] = useState(1)
	const [emailCount, setEmailCount] = useState(0)
	const [selectAll, setSelectAll] = useState(false)
	const [searchInput, setSearchInput] = useState('')
	const [perPageData, setPerPageData] = useState([])
	const [editorStateExp, setEditorStateExp] = useState(
		EditorState.createEmpty()
	)
	const [fromEmailErr, setFromEmailErr] = useState(true)
	const [spamCheckerData, setSpamCheckerData] = useState([])
	const [spamCheckerCount, setSpamCheckerCount] = useState({
		max_allowed_count: 0,
		consumed_count: 0,
	})
	const [addingSpamChecker, setAddingSpamChecker] = useState(false)

	let count = 0
	const modalInstanceDel = document.getElementById('SpamModalCenterDlt')
	const modalInstanceAdd = document.getElementById('fromEmailModalCenter')
	const extractedData = React.useMemo(() => {
		return spamCheckerData.map(item => {
			const tableCells = [
				{
					cellData: (
						<Link
							className={item.report === null ? 'disabled-link' : ''}
							to={{
								pathname: '/inbox-spam-checker/spam-checker',
								state: {
									tempObj: item,
								},
							}}
						>
							{item.from_email}
						</Link>
					),
				},
				{
					cellData:
						item.report !== null ? JSON.parse(item.report).total_emails : '--',
				},
				{
					cellData:
						item.report !== null ? JSON.parse(item.report).total_inbox : '--',
				},
				{
					cellData:
						item.report !== null
							? JSON.parse(item.report).total_important
							: '--',
				},
				{
					cellData:
						item.report !== null ? JSON.parse(item.report).total_spam : '--',
				},
				{
					cellData: moment(item.created_at).format('MMM D,YYYY'),
				},
				{
					cellData: (
						<>
							{item.is_pending === 1 ? (
								<PausedIcon className='mr-2' />
							) : (
								<ActiveIcon className='mr-2' />
							)}
							{item.is_pending === 1 ? 'Processing...' : 'Completed'}
						</>
					),
				},
				{
					cellData: (
						<DropDown
							pathName={'/inbox-spam-checker/spam-checker'}
							state={{
								tempObj: item,
							}}
							tableDetail={item}
							handleClick={() => {
								setTestId(item.id)
								openModal(modalInstanceDel)
							}}
							className={item.report === null ? 'd-none' : ''}
							deleteTest={'deleteSpamTest-'}
						/>
					),
				},
			]
			return tableCells
		})
	})

	const onEditorStateChangeExp = editorStateExp => {
		setEditorStateExp(editorStateExp)
	}

	const closeModal = modalInstance => {
		var myModal = Modal.getInstance(modalInstance)
		myModal.hide()
	}

	const openModal = modalInstance => {
		var myModal = Modal.getOrCreateInstance(modalInstance)
		myModal.show()

		setTimeout(() => {
			setShowNav(true)
			setStep(6)
		}, 400)
	}
	const notify = response => {
		response.message === 'Spam Checker Request Deleted Sucessfully'
			? toast.success(response.message)
			: toast.success(response.message)
	}
	const handlePageChange = async pageNumber => {
		setLoading(true)
		dispatch({ type: API_CALL_IN_PROGRESS })

		const response = await warmupService.getSpamCheckers(
			`/spam-checkers?email=${searchInput}&page=${pageNumber}`
		)
		setSpamCheckerData(response.data)
		setPerPageData(response)
		setActivePage(pageNumber)

		if (response.length !== 0) {
			setLoading(false)
		}
		dispatch({ type: API_CALL_NOT_IN_PROGRESS })
	}
	async function fetchData() {
		setLoading(true)
		try {
			const response = await warmupService.getEmailList()
			setEmailList(response)
			setLoading(false)
		} catch (e) {
			// console.log("Error");
			setLoading(false)
		}
	}
	const getSpamCheckers = async () => {
		setLoading(true)
		dispatch({ type: API_CALL_IN_PROGRESS })
		try {
			const response = await warmupService.getSpamCheckers(
				`/spam-checkers?email=${searchInput}&page=${
					searchInput === '' ? activePage : 1
				}`
			)

			if (response.data.length !== 0) {
				setPerPageData(response)
				setSpamCheckerData(response.data)
				setNoData(false)
			} else {
				setNoData(true)
			}
			setLoading(false)
			dispatch({ type: API_CALL_NOT_IN_PROGRESS })
		} catch (e) {
			dispatch({ type: API_CALL_NOT_IN_PROGRESS })
			// console.log("errors");
			setLoading(false)
		}
	}

	// const handleSearch = async () => {
	// 	try {
	// 		setLoading(true)
	// 		const response = await warmupService.getSpamCheckers(
	// 			`/spam-checkers?email=${searchInput}`
	// 		)
	// 		if (response.data.length !== 0) {
	// 			setPerPageData(response)
	// 			setSpamCheckerData(response.data)
	// 		} else {
	// 			setNoData(true)
	// 		}
	// 		setLoading(false)
	// 		let element = document.getElementById('multiCollapseExample1')
	// 		element.classList.remove('show')
	// 	} catch (e) {
	// 		// Log & handle error
	// 	}
	// }

	const handleClearFilter = () => {
		setSearchInput('')
		setNoData(false)
		getSpamCheckers()
		// let element = document.getElementById('multiCollapseExample1')
		// element.classList.remove('show')
	}

	const textCapitalize = string => {
		return string.charAt(0).toUpperCase() + string.slice(1)
	}

	const handleAddEmailChange = e => {
		const { name, value } = e.target

		setFormData({ ...formData, [name]: value })
	}

	const handleAddEmail = async event => {
		event.preventDefault()

		setAddingSpamChecker(true)

		setFromEmailErr(ValidateEmail(formData.from_email))

		// setLoading(true)
		ref.current?.continuousStart()

		let tempFormData = formData
		if (tempFormData.password === '') tempFormData.to_email = ''

		try {
			const response = await warmupService.addFromEmail({
				...tempFormData,
			})

			if (response.message === 'Please Update Package') {
				toast.error(response.message)
			} else {
				setFormData({
					password: '',
					to_email: '',
					email_body: '',
					from_email: '',
					subject_line: '',
					inbox_providers: [],
				})
				notify(response)
				setStep(9)
				closeModal(modalInstanceAdd)
				getFeatureCount()
				getSpamCheckers()
			}
		} catch (e) {
			if (e.response.status == 422) {
				setErrors(e.response.data.errors)
			} else {
				setErrors(e.response.data.errors)
			}
		} finally {
			setAddingSpamChecker(false)
			if (ref.current) ref.current.complete()
		}
	}

	const handleSelect = e => {
		if (selectAll) setSelectAll(!selectAll)
		let isAreadySelected = formData.inbox_providers.find(s => s === e.target.id)
		if (isAreadySelected) {
			let updatedData = formData.inbox_providers.filter(s => s !== e.target.id)
			setFormData({ ...formData, inbox_providers: updatedData })
		} else {
			setFormData({
				...formData,
				inbox_providers: [...formData.inbox_providers, e.target.id],
			})
		}
	}

	const handelSelectAll = () => {
		setSelectAll(!selectAll)
	}

	const handleCopy = () => {
		let updatedCopied = []
		Object.keys(emailList).map(key =>
			emailList[key].map(value => {
				if (
					formData.inbox_providers ===
					['Gmail - Gsuit', 'Yahoo!', 'Aol', 'Outlook', 'iCloud', 'Mail.com']
				) {
					updatedCopied.push(value.username)
				} else {
					if (formData.inbox_providers.length == 0) {
						updatedCopied.push(value.username)
					} else {
						let isSelected = formData.inbox_providers.find(s => s == key)

						if (isSelected) {
							updatedCopied.push(value.username)
						}
					}
				}
			})
		)
		var TempText = document.createElement('input')
		TempText.value = updatedCopied
		document.body.appendChild(TempText)
		TempText.select()
		navigator.clipboard.writeText(TempText.value)
		document.body.removeChild(TempText)
		setCopy(true)
	}

	const handleDelete = async tempId => {
		ref.current.continuousStart()
		try {
			const response = await warmupService.deleteSpamCheckerTest(tempId)
			if (response) {
				closeModal(modalInstanceDel)
				notify(response)
			}
			ref.current.complete()
			getSpamCheckers()
		} catch (e) {
			if (ref.current) ref.current.complete()
		}
	}

	const getFeatureCount = async () => {
		try {
			let tempObj = {}
			const response = await FeatureService.getFeatureConsumptionCount({
				warmup: '',
				domainHealth: '',
				spamchecker: 1,
				emailgrader: '',
				sequences: '',
			})

			tempObj.max_allowed_count = response.max_allowed_count
			tempObj.consumed_count = response.consumed_count

			setSpamCheckerCount(tempObj)
		} catch (error) {
			toast.error('Something went wrong')
		}
	}

	useEffect(() => {
		const block = convertToRaw(editorStateExp.getCurrentContent()).blocks

		if (block[0].text !== '')
			setFormData({
				...formData,
				email_body: block
					.map(block => (!block.text.trim() && '\n') || block.text)
					.join('\n'),
			})
	}, [editorStateExp])

	useEffect(() => {
		localStorage.setItem('spam-checker-data', JSON.stringify(spamCheckerData))
	}, [spamCheckerData])

	useEffect(() => {
		const interval = setInterval(() => {
			if (!apiCallInProgress) getSpamCheckers()
		}, 5000)

		return () => clearInterval(interval)
	}, [apiCallInProgress])

	useEffect(() => {
		fetchData()
		getSpamCheckers()
		getFeatureCount()
	}, [])

	useEffect(() => {
		if (selectAll) {
			setFormData({
				...formData,
				inbox_providers: ['Aol', 'Yahoo!', 'Mail.com', 'Gmail - Gsuit'],
			})
		} else {
			setFormData({ ...formData, inbox_providers: [] })
		}
	}, [selectAll])

	useEffect(() => {
		let updatedCopied = []
		Object.keys(emailList).map(key =>
			emailList[key].map(value => {
				if (
					formData.inbox_providers ===
					['Gmail - Gsuit', 'Yahoo!', 'Aol', 'Mail.com']
				) {
					updatedCopied.push(value.username)
				} else if (formData.inbox_providers.length === 0) {
					updatedCopied = []
				} else {
					if (formData.inbox_providers.length === 0) {
						updatedCopied.push(value.username)
					} else {
						let isSelected = formData.inbox_providers.find(s => s === key)

						if (isSelected) {
							updatedCopied.push(value.username)
						}
					}
				}
			})
		)

		setFormData({ ...formData, to_email: [...updatedCopied] })

		var TempText = document.createElement('input')
		TempText.value = updatedCopied
		document.body.appendChild(TempText)
		TempText.select()
		navigator.clipboard.writeText(TempText.value)
		document.body.removeChild(TempText)
		setCopy(true)
	}, [formData.inbox_providers])

	useEffect(() => {
		setEmailCount(formData.to_email.length)
		// if (formData.inbox_providers.length === 0) setEmailCount(0)
		// else {
		// 	if (
		// 		formData.inbox_providers ===
		// 		['Gmail - Gsuit', 'Yahoo!', 'Aol', 'Mail.com']
		// 	) {
		// 		setEmailCount(
		// 			emailList['Aol'].length +
		// 				emailList['Yahoo!'].length +
		// 				emailList['Mail.com'].length +
		// 				emailList['Gmail - Gsuit'].length
		// 		)
		// 	}
		// 	if (formData.inbox_providers === ['Gmail - Gsuit', 'Yahoo!', 'Aol']) {
		// 		setEmailCount(
		// 			emailList['Aol'].length +
		// 				emailList['Yahoo!'].length +
		// 				emailList['Gmail - Gsuit'].length
		// 		)
		// 	}
		// 	if (formData.inbox_providers === ['Gmail - Gsuit', 'Yahoo!']) {
		// 		setEmailCount(
		// 			emailList['Yahoo!'].length + emailList['Gmail - Gsuit'].length
		// 		)
		// 	}
		// 	if (formData.inbox_providers === ['Gmail - Gsuit']) {
		// 		setEmailCount(emailList['Gmail - Gsuit'].length)
		// 	}

		//  else {
		// 	formData.inbox_providers.forEach(item => {
		// 		setEmailCount(emailList[item].length)
		// 	})
		// }
		// }
	}, [formData.to_email])

	return (
		<Layout innerRef={ref}>
			<Header
				dataTourId={6}
				buttonName={'Add Email'}
				openModal={() => {
					openModal(modalInstanceAdd)
				}}
				iconAltText={'spam-checker'}
				headline={'Inbox Spam Checker'}
				helpPath={'1-dns-checker.html'}
				iconPath={'/assets/img/spam-checker-blue.png'}
				consumedCount={spamCheckerCount.consumed_count}
				maxAllowCount={spamCheckerCount.max_allowed_count}
				headerContent={
					<>
						{/* <span style={{ fontSize: '15px' }} className='text-bold heading'>
							Are Your Emails going to Inbox, Important or Spam?
						</span> */}
						{/* <br /> */}
						Use our free email deliverability tester.
						<br />
						See where your emails are being delivered.
					</>
				}
				filter={
					<Filter
						status={'spam-checker'}
						searchInput={searchInput}
						handleSearch={getSpamCheckers}
						setSearchInput={setSearchInput}
						handleClearFilter={handleClearFilter}
					/>
				}
			/>
			{/* <div className="row my-3">
              <div className="col-sm-3 form-group">
                <label htmlFor="protocol" className="label-search">
                  Enter email address
                </label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email address"
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                    onChange={searchEmailChange}
                  />
                </div>
              </div>
              <div className="col search_btn">
                <button
                  className="btn c-btn"
                  type="button"
                  id="button-addon2"
                  onClick={handleSearchEmail}
                >
                  Search
                </button>
              </div>
            </div> */}
			<div className='row mt-5'>
				<div className='col-sm-12'>
					{noData ? (
						<NotFound dataNotFound={'No Test Found'} />
					) : loading && spamCheckerData.length === 0 ? (
						<div
							style={{
								height: '30px',
								marginLeft: '50%',
								marginBottom: '20px',
							}}
						>
							<FadeLoader
								width={5}
								height={20}
								loading={loading}
								color={'#0092FB'}
							/>
						</div>
					) : spamCheckerData.length === 0 ? (
						<GetStarted
							title={'Inbox Spam Checker'}
							helpPath={'1-dns-checker.html'}
							addFirst={
								<>
									Are Your Emails going to Inbox, Promotions or Spam? <br />
									Use our free email deliverability tester to see where your
									<br />
									emails are being delivered.
								</>
							}
						/>
					) : (
						<>
							<Table
								tableHeader={tableHeader}
								extractedData={extractedData}
								className={'inbox-item-name'}
							/>
							<div className='float-right'>
								<Pagination
									itemClass='page-item'
									linkClass='page-link'
									pageRangeDisplayed={4}
									activePage={activePage}
									totalItemsCount={perPageData.total}
									itemsCountPerPage={perPageData.per_page}
									onChange={pageNumber => handlePageChange(pageNumber)}
								/>
							</div>
						</>
					)}
				</div>
			</div>
			<AddEmailModal
				errors={errors}
				formData={formData}
				emailList={emailList}
				selectAll={selectAll}
				handleCopy={handleCopy}
				emailCount={emailCount}
				handleSelect={handleSelect}
				loading={addingSpamChecker}
				editorStateExp={editorStateExp}
				handleAddEmail={handleAddEmail}
				handelSelectAll={handelSelectAll}
				handleAddEmailChange={handleAddEmailChange}
				onEditorStateChangeExp={onEditorStateChangeExp}
				closeModal={() => closeModal(modalInstanceAdd)}
			/>
			<DeleteModal
				modalCenterDlt={'SpamModalCenterDlt'}
				close={() => closeModal(modalInstanceDel)}
				modalCenterTitle={'exampleModalCenterTitle'}
				DeleteItem={() =>
					handleDelete(
						parseInt(
							document
								.getElementById('deleteSpamTest-' + testId)
								.getAttribute('data-id')
						)
					)
				}
				confirmation={
					' Do you really want to delete this test from your account?'
				}
			/>
		</Layout>
	)
}

const mapStateToProps = state => {
	return {
		apiCallInProgress: state.apiCallsInProgress.apiCallInProgress,
	}
}

export default connect(mapStateToProps, null)(InboxSpamChecker)
