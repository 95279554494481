  import React, { useEffect } from "react";
  import * as am4core from "@amcharts/amcharts4/core";
  import * as am4charts from "@amcharts/amcharts4/charts";
  import am4themes_animated from "@amcharts/amcharts4/themes/animated";
  am4core.useTheme(am4themes_animated);

  const addData = (chart, data, series) => {
    /* Set real data */
    chart.data = data;

    /* Hide series and show again to play the animation */
    series.hide(0);
    series.show();
  };
  function DummyPieChart(props) {
    let id = props.id;
    useEffect(() => {
      /* Normal data */

      if (props.id === "modal") {
        var data = [
          {
            category: "Click-rate",
            rate: parseInt(props.click_rate),
            color: "#DCD562",
          },
          {
            category: "Open-rate",
            rate: parseInt(props.open_rate),
            color: "#98D0A0",
          },
          {
            category: "Bounce-rate",
            rate: parseInt(props.bounce_rate),
            color: "#E2B4F4",
          },
          {
            category: "Spam-rate",
            rate: parseInt(props.spam_rate),
            color: "#FF7D7D",
          },
        ];
      } else {
        var data = [
          {
            category: "Inbox-rate",
            rate: props.inbox_rate,
            color: "#0994F8",
          },
          {
            category: "Spam-rate",
            rate: props.spam_rate,
            color: "#B81212",
          },
        ];
      }
      /* Create chart */
      var chart = am4core.create(id, am4charts.PieChart);
      // chart.radius = am4core.percent(60);
      chart.innerRadius = am4core.percent(40);

      /* Dummy innitial data data */
      chart.data = [
        {
          category: "Chart Data",
          disabled: true,
          rate: 100.00,
          color: am4core.color("#dadada"),
          opacity: 0.3,
          strokeDasharray: "4,4",
        },
      ];

      /* Create series */
      var series = chart.series.push(new am4charts.PieSeries());
      series.numberFormatter = new am4core.NumberFormatter();
      series.dataFields.value = "rate";
      series.dataFields.category = "category";

      series.slices.template.stroke = am4core.color("#fff");
      series.slices.template.strokeWidth = 2;
      series.slices.template.strokeOpacity = 1;
      series.slices.template.cursorOverStyle = [
        {
          property: "cursor",
          value: "pointer",
        },
      ];

      series.labels.template.disabled = true;

      /* Set tup slice appearance */
      var slice = series.slices.template;
      slice.propertyFields.fill = "color";
      // slice.propertyFields.fillOpacity = "opacity";
      // slice.propertyFields.stroke = "color";
      // slice.propertyFields.strokeDasharray = "strokeDasharray";
      // slice.propertyFields.tooltipText = "tooltip";

      series.labels.template.propertyFields.disabled = "disabled";
      series.ticks.template.propertyFields.disabled = "disabled";

      /* This creates initial animation */
      series.hiddenState.properties.opacity = 1;
      series.hiddenState.properties.endAngle = -90;
      series.hiddenState.properties.startAngle = -90;

      let shadow = series.slices.template.filters.push(
        new am4core.DropShadowFilter()
      );
      shadow.opacity = 0;

      let hoverState = series.slices.template.states.getKey("hover");

      let hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter());
      hoverShadow.opacity = 0.7;
      hoverShadow.blur = 7;

      chart.legend = new am4charts.Legend();
      chart.legend.itemContainers.template.clickable = false;
      chart.legend.itemContainers.template.focusable = false;
      chart.legend.itemContainers.template.cursorOverStyle =
        am4core.MouseCursorStyle.default;
      chart.legend.valueLabels.template.text="{rate}%";
      chart.legend.maxWidth = 400;
      chart.legend.marginLeft = 0;
      chart.legend.marginRight = 10;
      if (props.id === "modal") {
        if (
          props.click_rate > 0 ||
          props.open_rate > 0 ||
          props.bounce_rate > 0 ||
          props.spam_rate > 0
        ) {
          addData(chart, data, series);
        }
      } else if (props.inbox_rate > 0 || props.spam_rate > 0) {
        addData(chart, data, series);
      }
    }, [props.click_rate,props.open_rate,props.bounce_rate,props.spam_rate,props.inbox_rate,props.spam_rate]);

    return (
      <div>
        <div
          id={id}
          style={{ height: props.height, width: "100%", position: "relative" }}
        ></div>
      </div>
    );
  }

  export default DummyPieChart;
