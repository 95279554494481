import React from "react"
import Pagination from "react-js-pagination"
import "./spamChecker.scss"
import moment from "moment"

const DetailTable = ({ item, email }) => {
  const handlePageChange = () => {}
  return (
    <div className='table-responsive'>
      <table
        className='table table-sm bg-white mb-0'
        style={{ borderBottom: "1px solid #ddd" }}
      >
        <thead
          style={{ backgroundColor: "#F3FDFF", borderTop: "1px solid #e2f1f4" }}
        >
          <tr>
            <th>
              Email
              <span className='sort-controls'>
                <img className='up' src='/assets/img/up-sort.png' />
                <img className='down' src='/assets/img/down-sort.png' />
              </span>
            </th>
            <th>
              Subject
              <span className='sort-controls'>
                <img className='up' src='/assets/img/up-sort.png' />
                <img className='down' src='/assets/img/down-sort.png' />
              </span>
            </th>
            <th>
              Sent Time
              <span className='sort-controls'>
                <img className='up' src='/assets/img/up-sort.png' />
                <img className='down' src='/assets/img/down-sort.png' />
              </span>
            </th>
            <th className='border-item'>Inbox</th>
            <th className='border-item'>Important</th>
            <th className='border-item'>Spam</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{email}</td>
            <td>{item.subject}</td>
            <td>
              {moment(item.time).format("MMM D, YYYY")}&nbsp;&nbsp;&nbsp;&nbsp;
              {moment(item.time).fromNow()}
            </td>
            <td className='border-item'>
              {item.landed === "Inbox" && (
                <i className='fas fa-check' style={{ color: item.color }}></i>
              )}
            </td>
            <td className='border-item'>
              {item.landed === "Important" && (
                <i className='fas fa-check' style={{ color: "#0092FB" }}></i>
              )}
            </td>
            <td className='border-item'>
              {item.landed === "Spam" && (
                <i className='fas fa-check' style={{ color: item.color }}></i>
              )}
            </td>
          </tr>
        </tbody>
      </table>
      {/* <div className="d-flex justify-content-end mt-3 mr-4">
        <Pagination
          itemClass="page-item"
          linkClass="page-link"
          activePage={1}
          itemsCountPerPage={8}
          totalItemsCount={4}
          pageRangeDisplayed={4}
          onChange={(pageNumber) => handlePageChange(pageNumber)}
        />
      </div> */}
    </div>
  )
}

export default DetailTable
