import React from "react";
import HelpComponent from "../../components/Help/Help";

const PendingRequest = () => {
  return (
    <>
      <div className="row mt-3">
        <div className="col-lg-12 col-md-12 col-sm-12 title-col">
          <div className="card">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card aff-inner-card">
                        <div className="card-body p-0">
                          <div id="user-info-div" className="col-sm-12">
                            <div className="row p-4">
                              <div className="shld col-sm-2 text-center p-1">
                                <img
                                  src="/assets/img/affiliate_icon_blue.png"
                                  alt="warm-up"
                                />
                              </div>
                              <div className="col-sm-7">
                                <div className="mt-2 text-bold heading">
                                  Affiliate Profile &nbsp;
                                  <span>
                                    <HelpComponent
                                      filePath={
                                        "/help-fragments/3-warmupHelp.html"
                                      }
                                    />
                                  </span>
                                </div>
                                <p className="sub-heading">
                                  View your shareable affiliate joining link and
                                  corresponding insights
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="text-center my-3 text-muted">
                            Your request is pending please wait for the approval
                            of admin. Thanks..!!
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PendingRequest;
