import '../Settings.scss'
import React from 'react'
import { Link } from 'react-router-dom'

const Package = ({
	price,
	style,
	options,
	priceId,
	img_src,
	pkg_name,
	duration,
	packageId,
	sequences,
	dnsChecker,
	emailGrader,
	spamChecker,
	subscribing,
	clientSecret,
	email_per_day,
	stripePromise,
	handlePurchase,
	subscriptionId,
	selectedPackageId,
	handleUpgradePlan,
	handleUnsubscription,
	loadingPaymentElements,
	handlePauseSubscription,
	handleResumeSubscription,
	setloadingPaymentElements,
}) => {
	return (
		<>
			<div
				id='plan-item'
				className='mb-3'
				style={{ /*flex: '0 1 31%',*/ margin: '10px' }}
			>
				<div
					id='plan-card'
					className='card text-center'
					style={{
						background:
							JSON.parse(localStorage.getItem('user')).package === null ||
							JSON.parse(localStorage.getItem('user')).package === undefined
								? ''
								: JSON.parse(localStorage.getItem('user')).package.id ===
								  packageId
								? '#E2F1F4'
								: '',
						border:
							JSON.parse(localStorage.getItem('user')).package === null ||
							JSON.parse(localStorage.getItem('user')).package === undefined
								? ''
								: JSON.parse(localStorage.getItem('user')).package.id ===
								  packageId
								? '2px solid #0994F8'
								: '',
					}}
				>
					<div
						className='card-header p-4'
						style={{
							background:
								JSON.parse(localStorage.getItem('user')).package === null ||
								JSON.parse(localStorage.getItem('user')).package === undefined
									? '#ffffff'
									: JSON.parse(localStorage.getItem('user')).package.id ===
									  packageId
									? '#E2F1F4'
									: '#ffffff',
							borderBottom:
								JSON.parse(localStorage.getItem('user')).package === null ||
								JSON.parse(localStorage.getItem('user')).package === undefined
									? ''
									: JSON.parse(localStorage.getItem('user')).package.id ===
									  packageId
									? '2px solid #0994F8'
									: '',
						}}
					>
						<div className='pkg-icon-bg mb-4' style={style}>
							<img src={img_src} alt='package_icon' />
						</div>
						{pkg_name === 'Enterprise' ? (
							<div>
								<span className='price_pkg'></span>
								<span className='month_text'></span>
								<br />
								<span className='pkg_name'>{pkg_name}</span>
							</div>
						) : (
							<div>
								<span className='price_pkg'>${price} </span>
								<span className='month_text'>
									{duration === 30 ? '/mo' : ''}
								</span>
								<br />
								<span className='pkg_name'>
									{pkg_name} <small>{packageId === 1 ? '(1 Week)' : ''}</small>
								</span>
							</div>
						)}
					</div>
					<div className='card-body p-4'>
						<div
							className='mt-2'
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
							}}
						>
							<div>
								<div style={{ display: 'flex', justifyContent: 'center' }}>
									<div className='col-4 px-0'>
										<span className='pkg_detail_clr'>{email_per_day}</span>
									</div>
									<div className='col-8'>
										<span className='pkg_detail'> Warmups/month</span>
									</div>
								</div>
							</div>
							<br />
							<div className='row'>
								<div className='col-4 px-0'>
									<span className='pkg_detail_clr'>{spamChecker} </span>
								</div>
								<div className='col-8'>
									<span className='pkg_detail'>Spam Checks/day</span>
								</div>
							</div>
							<br />

							<div className='row'>
								<div className='col-4 px-0'>
									<span className='pkg_detail_clr'>{dnsChecker} </span>
								</div>
								<div className='col-8'>
									<span className='pkg_detail'>DNS Checks/day</span>
								</div>
							</div>
							<br />

							<div className='row'>
								<div className='col-4 px-0'>
									<span className='pkg_detail_clr'>{emailGrader} </span>
								</div>
								<div className='col-8'>
									<span className='pkg_detail'>Gradings/day</span>
								</div>
							</div>
							<br />

							<div className='row'>
								<div className='col-4 px-0'>
									<span className='pkg_detail_clr'>{sequences} </span>
								</div>
								<div className='col-8'>
									<span className='pkg_detail'>Sequences/day</span>
								</div>
							</div>
							<br />

							<div className='row'>
								<div className='col'>
									{pkg_name === 'Enterprise' ? (
										<Link
											to={{ pathname: '/support' }}
											style={{
												pointerEvents: subscribing ? 'none' : 'initial',
											}}
										>
											<button className='buy_button my-3'>
												<i className='far fa-user-headset mr-1'></i>
												Contact Us
											</button>
										</Link>
									) : // JSON.parse(localStorage.getItem('user')).package ===
									// 		null ||
									//   JSON.parse(localStorage.getItem('user')).package ===
									// 		undefined ?

									JSON.parse(localStorage.getItem('user')).isNewUser === 1 ? (
										packageId === 1 ? (
											<button
												id='payment-form'
												disabled={subscribing}
												className='buy_button my-3'
												onClick={() =>
													handlePurchase(price, packageId, {
														price: price,
														price_id: priceId,
														pkg_name: pkg_name,
														duration: duration,
														sequences: sequences,
														dnsChecker: dnsChecker,
														spamChecker: spamChecker,
														emailGrader: emailGrader,
														email_per_day: email_per_day,
													})
												}
											>
												{subscribing ? (
													<span
														role='status'
														aria-hidden='true'
														class='spinner-border spinner-border-sm mr-1'
													></span>
												) : (
													<i className='far fa-credit-card mr-1'></i>
												)}
												Purchase Now
											</button>
										) : (
											<button
												id='payment-form'
												disabled={subscribing}
												className='buy_button my-3'
												onClick={() =>
													handlePurchase(price, packageId, {
														price: price,
														price_id: priceId,
														pkg_name: pkg_name,
														duration: duration,
														sequences: sequences,
														dnsChecker: dnsChecker,
														spamChecker: spamChecker,
														emailGrader: emailGrader,
														email_per_day: email_per_day,
													})
												}
											>
												{subscribing ? (
													<span
														role='status'
														aria-hidden='true'
														class='spinner-border spinner-border-sm mr-1'
													></span>
												) : (
													<i className='far fa-credit-card mr-1'></i>
												)}
												{packageId === 2 ? 'Purchase Now' : 'Subscribe'}
											</button>
										)
									) : packageId === 1 ? (
										<>
											<br />
											<br />
											<br />
										</>
									) : packageId !== 1 ? (
										JSON.parse(localStorage.getItem('user')).package.id === 2 &&
										packageId === 2 ? (
											<>
												<br />
												<br />
												<br />
											</>
										) : JSON.parse(localStorage.getItem('user')).package.id ===
												packageId &&
										  JSON.parse(localStorage.getItem('user')).stripePayment
												.subscription_id !== null ? (
											JSON.parse(localStorage.getItem('user')).stripePayment
												.is_subscribed ? (
												<button
													disabled={subscribing}
													className='unsub-button my-3'
													onClick={() => handlePauseSubscription(packageId)}
												>
													{subscribing ? (
														<span
															role='status'
															aria-hidden='true'
															class='spinner-border spinner-border-sm mr-1'
														></span>
													) : (
														<i class='far fa-pause mr-1'></i>
													)}
													Pause Subscription
												</button>
											) : (
												<button
													disabled={subscribing}
													className='resumesub-button my-3'
													onClick={() => handleResumeSubscription(packageId)}
												>
													{subscribing ? (
														<span
															role='status'
															aria-hidden='true'
															class='spinner-border spinner-border-sm mr-1'
														></span>
													) : (
														<i class='far fa-play mr-1'></i>
													)}
													Resume Subscription
												</button>
											)
										) : (
											<button
												id='payment-form'
												disabled={subscribing}
												className='buy_button my-3'
												onClick={() => {
													if (
														packageId === 2 ||
														JSON.parse(localStorage.getItem('user')).package
															.id === 2
													) {
														handlePurchase(price, packageId, {
															price: price,
															price_id: priceId,
															pkg_name: pkg_name,
															duration: duration,
															sequences: sequences,
															dnsChecker: dnsChecker,
															spamChecker: spamChecker,
															emailGrader: emailGrader,
															email_per_day: email_per_day,
														})
													} else if (
														JSON.parse(localStorage.getItem('user')).package
															.id !== packageId
													) {
														handleUpgradePlan(price, packageId, {
															price: price,
															price_id: priceId,
															pkg_name: pkg_name,
															duration: duration,
															sequences: sequences,
															dnsChecker: dnsChecker,
															spamChecker: spamChecker,
															emailGrader: emailGrader,
															email_per_day: email_per_day,
														})
													}
													// else {
													// 	handlePurchase(price, packageId, {
													// 		price: price,
													// 		price_id: priceId,
													// 		pkg_name: pkg_name,
													// 		duration: duration,
													// 		sequences: sequences,
													// 		dnsChecker: dnsChecker,
													// 		spamChecker: spamChecker,
													// 		emailGrader: emailGrader,
													// 		email_per_day: email_per_day,
													// 	})
													// }
												}}
											>
												{subscribing ? (
													<span
														role='status'
														aria-hidden='true'
														class='spinner-border spinner-border-sm mr-1'
													></span>
												) : (
													<i className='far fa-credit-card mr-1'></i>
												)}
												{packageId === 2 ? 'Purchase Now' : 'Upgrade'}
											</button>
										)
									) : JSON.parse(localStorage.getItem('user')).package.id ===
											2 && packageId === 2 ? (
										<>
											<br />
											<br />
											<br />
										</>
									) : (
										<></>
										// <button
										// 	id='payment-form'
										// 	disabled={subscribing}
										// 	className='buy_button my-3'
										// 	onClick={() => {
										// 		if (
										// 			packageId === 2 ||
										// 			JSON.parse(localStorage.getItem('user')).package
										// 				.id === 1 ||
										// 			JSON.parse(localStorage.getItem('user')).package
										// 				.id === 2
										// 		) {
										// 			handlePurchase(price, packageId, {
										// 				price: price,
										// 				price_id: priceId,
										// 				pkg_name: pkg_name,
										// 				duration: duration,
										// 				sequences: sequences,
										// 				dnsChecker: dnsChecker,
										// 				spamChecker: spamChecker,
										// 				emailGrader: emailGrader,
										// 				email_per_day: email_per_day,
										// 			})
										// 		} else if (
										// 			JSON.parse(localStorage.getItem('user')).package
										// 				.id !== packageId
										// 		) {
										// 			handleUpgradePlan(price, packageId, {
										// 				price: price,
										// 				price_id: priceId,
										// 				pkg_name: pkg_name,
										// 				duration: duration,
										// 				sequences: sequences,
										// 				dnsChecker: dnsChecker,
										// 				spamChecker: spamChecker,
										// 				emailGrader: emailGrader,
										// 				email_per_day: email_per_day,
										// 			})
										// 		}
										// 	}}
										// >
										// 	{subscribing ? (
										// 		<span
										// 			role='status'
										// 			aria-hidden='true'
										// 			className='spinner-border spinner-border-sm mr-1'
										// 		></span>
										// 	) : packageId === 2 ? (
										// 		<i className='far fa-credit-card mr-1'></i>
										// 	) : (
										// 		<i className='far fa-angle-double-up mr-1'></i>
										// 	)}
										// 	{packageId === 2
										// 		? 'Purchase Now'
										// 		: JSON.parse(localStorage.getItem('user')).package
										// 				.id === 2 ||
										// 		  JSON.parse(localStorage.getItem('user')).package
										// 				.id === 1
										// 		? 'Subscribe'
										// 		: packageId === 8
										// 		? 'Subscribe'
										// 		: JSON.parse(localStorage.getItem('user')).package
										// 				.id === 8
										// 		? 'Subscribe'
										// 		: JSON.parse(localStorage.getItem('user')).package
										// 				.id !== packageId
										// 		? 'Upgrade'
										// 		: 'Subscribe'}
										// </button>
									)}
									{/* {(JSON.parse(localStorage.getItem('user')).package.id === 2 ||
										JSON.parse(localStorage.getItem('user')).package.id ===
											1) &&
									JSON.parse(localStorage.getItem('user')).package.id ===
										packageId ? (
										<>
											<br />
											<br />
											<br />
										</>
									) : JSON.parse(localStorage.getItem('user')).package.id ===
											packageId &&
									  JSON.parse(localStorage.getItem('user')).stripePayment !==
											null ? (
										<>
											{JSON.parse(localStorage.getItem('user')).stripePayment
												.is_subscribed ? (
												<button
													disabled={subscribing}
													className='unsub-button my-3'
													onClick={() => handlePauseSubscription(packageId)}
												>
													Pause Subscription
												</button>
											) : (
												<button
													disabled={subscribing}
													className='resumesub-button my-3'
													onClick={() => handleResumeSubscription(packageId)}
												>
													Resume Subscription
												</button>
											)}
										</>
									) : (
										<></>
									)} */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Package
