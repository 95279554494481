import React from "react"
import Progressbar from "react-js-progressbar"
import getSliceColor from "./getColor"

const OverallResult = ({ percentage }) => {
  const getWording = () => {
    if (percentage < 50) {
      return `This email has a <span style="color:red;font-weight:bold"> Lower </span> chance of being inboxed across multiple ESP and <span style="color:red;font-weight:bold"> will land </span> in the Spam folder or the promotion folder`
    } else {
      return `This email has a <span style="color:#4BC876;font-weight:bold"> Higher </span> chance of being inboxed across multiple ESP and <span style="color:#4BC876;font-weight:bold"> will not land </span> in the Spam or promotion folder.`
    }
  }

  return (
    <>
      <div className='card flex-row py-3 analyzer-card my-4'>
        <div className='row p-4'>
          <div className='col-3 text-center'>
            <Progressbar
              input={percentage}
              pathShadow='none'
              pathWidth={30}
              pathColor={getSliceColor(percentage)}
              trailWidth={50}
              trailColor='#F2F2F2'
              animation={{ ease: "linear" }}
              ease={"linear"}
              size={100}
              pathLinecap={"round"}
              textStyle={{
                fontWeight: "800",
                fontSize: "44px",
              }}
              clockwise={false}
            ></Progressbar>
          </div>
          <div className='col-9 pt-2'>
            <div className='text-style' style={{ fontSize: "20px" }}>
              <b>Overall Results</b>
            </div>
            <div
              className='mt-2 text-style'
              dangerouslySetInnerHTML={{
                __html: getWording(),
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OverallResult
