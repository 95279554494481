import React from "react"
import { ReactComponent as FavoriteIconBlue } from "../../../../components/Assets/icons/favorite-blue-icon.svg"
export const FavoriteSequenceHeader = () => (
  <div className='d-flex align-item-center justify-content-between'>
    <div className='col'>
      <div className='row'>
        <div className='col shld text-center pt-3'>
          <FavoriteIconBlue />
        </div>
        <div className='col'>
          <div className='mt-2 text-bold heading'>
            Favourite Sequences &nbsp;
            <span>
              {/* <HelpComponent
    filePath={"/help-fragments/3-warmupHelp.html"}
  /> */}
            </span>
          </div>
          <p className='col sub-heading'>
            View your favorite email sequences of subscribed brands
          </p>
        </div>
      </div>
    </div>
  </div>
)
