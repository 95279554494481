import React, { useEffect, useState } from 'react'
import HelpComponent from '../../../components/Help/Help'
import InvoiceService from '../../../api-services/invoice.service'

const Invoices = () => {
	const [invoices, setInvoices] = useState([])

	const fetchInvoices = async () => {
		const response = await InvoiceService.list()
		setInvoices(response)
	}

	useEffect(() => {
		fetchInvoices()
	}, [])

	return (
		<>
			<div className='card' id='plan-card' style={{ height: '95%' }}>
				<div
					className='card-header d-flex align-items-center justify-content-between'
					style={{ background: '#FFFFFF' }}
				>
					<h5 className='text-capitalize text-bold heading align-self-center mb-0'>
						Invoices &nbsp;
						<span>
							<HelpComponent filePath={'/help-fragments/4-userHelp.html'} />
						</span>{' '}
					</h5>
				</div>

				<div className='row px-4' style={{ height: '100%', overflowY: 'auto' }}>
					<div className='col-sm-12 table-responsive'>
						{invoices !== undefined ? (
							invoices.length !== 0 ? (
								<table className='table table-striped'>
									<thead>
										<tr>
											<th>Invoice#</th>
											<th className='text-center'>SubTotal</th>
											<th className='text-center'>Total</th>
											<th className='text-center'>Status</th>
											<th className='text-center'>Actions</th>
										</tr>
									</thead>
									<tbody>
										{invoices.map((invoice, i) => (
											<tr key={i}>
												<td>{invoice.invoice_number}</td>
												<td className='text-center'>$ {invoice.subtotal}</td>
												<td className='text-center'>$ {invoice.total}</td>
												<td className='text-center text-capitalize'>
													{invoice.Status === 'open'
														? 'Pending'
														: invoice.Status}
												</td>
												<td className='text-center'>
													<a
														style={{ background: 'none' }}
														href={invoice.invoice_pdf}
														download
													>
														Download Invoice
													</a>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							) : (
								<p className='text-center mt-2'>No Invoices Found</p>
							)
						) : (
							<></>
						)}
					</div>
				</div>
			</div>
		</>
	)
}

export default Invoices
