const PrivacyPolicy = () => {
	return (
		<div className='container-fluid py-4'>
			<header className='pb-3 mb-4 border-bottom'>
				<a
					href='/'
					className='d-flex align-items-center text-dark text-decoration-none'
				>
					<img src='/assets/img/logo.png' />
				</a>
			</header>
			<div className='p-4 mb-4 bg-light rounded-3'>
				<div className='container-fluid py-5'>
					<p className='col-md-12 fs-6'>
						<h3>Privacy Policy</h3>
						<br />
						This page is used to inform visitors regarding my policies with the
						collection, use, and disclosure of Personal Information if anyone
						decided to use my Service. If you choose to use this product, then
						you agree to the collection and use of information in relation to
						this policy. The Personal Information that is collected is used for
						providing and improving the service of product. We will not use or
						share your information with anyone except as described in this
						Privacy Policy. The terms used in this Privacy Policy have the same
						meanings as in our Terms and Conditions.
						<br />
						<b>Information Collection and Use</b>
						<br />
						For a better experience, while using our Service, I may require you
						to provide us with certain personally identifiable information. The
						app does use third party services that may collect information used
						to identify you. The app may use third party code and libraries that
						use “cookies” to collect information and improve their services. You
						have the option to either accept or refuse these cookies and know
						when a cookie is being sent to your device. If you choose to refuse
						our cookies, you may not be able to use some portions of this
						Service.
						<br />
						<b>Service Providers</b>
						<br />
						We employ third-party companies and individuals due to the following
						reasons: To facilitate our Service; To provide the Service on our
						behalf; To perform Service-related services; or To assist us in
						analyzing how our Service is used. The reason is to perform the
						tasks assigned to them on our behalf. However, they are obligated
						not to disclose or use the information for any other purpose.
						<br />
						<b>Security</b>
						<br />
						We value your trust in providing us your Personal Information, thus
						we are striving to use commercially acceptable means of protecting
						it. But remember that no method of transmission over the internet,
						or method of electronic storage is 100% secure and reliable, and we
						cannot guarantee its absolute security.
						<br />
						<b>Links to Other Sites</b>
						<br />
						This Service may contain links to other sites. If you click on a
						third-party link, you will be directed to that site. Note that these
						external sites are not operated by us. Therefore, we strongly advise
						you to review the Privacy Policy of these websites. We have no
						control over and assume no responsibility for the content, privacy
						policies, or practices of any third-party sites or services.
						<br />
						<b>Contact Us</b>
						<br /> If you have any questions or suggestions about my Privacy
						Policy, do not hesitate to contact us at{' '}
						<a href={'mailto:sumair@knowpakistan.net'}>
							sumair@knowpakistan.net
						</a>
						.
					</p>
				</div>
			</div>
		</div>
	)
}

export default PrivacyPolicy
